import { Dialog, DialogContent } from "@material-ui/core";
import firebase from "firebase/app";
import * as React from "react";
import { Fragment, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FluidTypography } from "../../../app-util-components/FluidTypography";
import { LinguiMessage } from "../../../app-util-components/LinguiMessage";
import { objToArray } from "../../../app-utilities/fn-utils";
import { selectProducts } from "../../../deprecated/deprecated-apoly-app/redux/shoppingCart/selectors";
import { getPostalCost } from "../../../deprecated/deprecated-apoly-app/utilities/delivery/deliveryUtils";
import { UrlContext } from "../../../url";
import {
  Address,
  CashOtcOrder,
  DeliveryType,
  EnhancedPharmacy,
  OtcOrder,
  PaymentMethod,
  ShoppingCartItem,
} from "../../apolyApi";
import { appContext } from "../../AppContext";
import { usePharmacyValues } from "../../PharmacyContext";
import { PharmacyChannel } from "../../routes-helper";
import {
  CheckoutValues,
  CompletedCheckoutDefault,
  CompletedCheckoutOffizin,
  CompletedCheckoutPss,
} from "./checkoutTypes";
import { DeliveryType as DeliveryTypeEnum } from "./OtcOrderTypes";
import { SubmitGuestOrder } from "./submitOtcOrder/SubmitGuestOrder";
import { SubmitUserOrder } from "./submitOtcOrder/SubmitUserOrder";

type ReduxShoppingCartProducts = { [key: string]: ShoppingCartItem };

interface PssOrderAdditionalTypes {
  products: ReduxShoppingCartProducts;
  enhancedPharmacy: EnhancedPharmacy;
  isOpen: boolean;
}

const priceFromArticles = (products: ReduxShoppingCartProducts) =>
  objToArray(products).reduce((sum, product) => product.count * Number(product.articlePrice) + sum, 0);

const shoppingCartFromProducs = (products: ReduxShoppingCartProducts, deliveryPrice = 0) => ({
  price: priceFromArticles(products) + Number(deliveryPrice),
  items: objToArray(products),
});

const checkoutPssToOrder = (
  { email, billingAddress, paymentMethod }: CompletedCheckoutPss,
  { enhancedPharmacy, products, isOpen }: PssOrderAdditionalTypes,
): OtcOrder => {
  const res = {
    shoppingCart: shoppingCartFromProducs(products),
    buyer: {
      email,
      name: billingAddress ? billingAddress.name : undefined,
      phone: undefined,
    },
    pharmacyId: enhancedPharmacy.pharmacy.pharmacyId,
    deliveryType: "pss_vending_machine" as DeliveryType,
    shippingAddress: undefined,
    billingAddress,
    courierTimeSpanId: undefined,
    purchased_while_closed: !isOpen,
  };

  // magie mit gleichen checks wird benötigt damit TS-check vernünftig funktioniert mit verschiednen paymentMethods
  if (paymentMethod === PaymentMethod.cash) {
    return {
      ...res,
      paymentType: paymentMethod,
    };
  }

  if (paymentMethod === PaymentMethod.hobex_terminal) {
    return {
      ...res,
      paymentType: paymentMethod,
    };
  }

  return {
    ...res,
    paymentType: paymentMethod,
  };
};

const checkoutOffizinToOrder = (
  { billingAddress }: CompletedCheckoutOffizin,
  { enhancedPharmacy, products, isOpen }: PssOrderAdditionalTypes,
): CashOtcOrder => ({
  shoppingCart: shoppingCartFromProducs(products),
  buyer: {
    email: "test.user@apoly.eu", // todo dummy-email entfernen
    name: billingAddress ? billingAddress.name : undefined,
    phone: undefined,
  },
  paymentType: PaymentMethod.cash,
  pharmacyId: enhancedPharmacy.pharmacy.pharmacyId,
  deliveryType: "offizin_order_schnellkasse" as DeliveryType,
  shippingAddress: undefined,
  billingAddress,
  courierTimeSpanId: undefined,
  purchased_while_closed: !isOpen,
});

const getName = (a?: Address) => (a ? a.name : undefined);

export const toDeliveryType = (delType: DeliveryTypeEnum): DeliveryType => {
  if (delType === DeliveryTypeEnum.pickUp) {
    return "pickUp";
  }

  if (delType === DeliveryTypeEnum.courier) {
    return "courier";
  }

  if (delType === DeliveryTypeEnum.mailOrder) {
    return "postal";
  }

  if (delType === DeliveryTypeEnum.pssReservation) {
    return "pss_vending_machine_reserve";
  }

  throw new Error(`unkown deliveryTypeEnum: ${delType}`);
};

const getDelPrice = (delPrice: number, deliveryType: DeliveryTypeEnum, deliveryAddress?: Address) => {
  if (deliveryType === DeliveryTypeEnum.pickUp || deliveryType === DeliveryTypeEnum.pssReservation) {
    return 0;
  }

  if (deliveryType === DeliveryTypeEnum.courier) {
    return delPrice;
  }

  if (!deliveryAddress) {
    throw new Error("FUUUUUUU");
  }

  return getPostalCost(deliveryAddress.country as never);
};

const checkoutDefaultToOrder = (
  {
    billingAddress,
    telephone,
    deliveryAddress,
    email,
    deliveryType,
    paymentMethod,
    birthday,
  }: CompletedCheckoutDefault,
  { enhancedPharmacy, products, isOpen }: PssOrderAdditionalTypes,
): OtcOrder => {
  const delPrice = enhancedPharmacy.nextCourierTimeSpans[0].price;

  const res = {
    shoppingCart: shoppingCartFromProducs(products, getDelPrice(delPrice, deliveryType, deliveryAddress)),
    buyer: {
      email,
      name: getName(billingAddress || deliveryAddress),
      phone: telephone,
      birthday,
    },
    pharmacyId: enhancedPharmacy.pharmacy.pharmacyId,
    deliveryType: toDeliveryType(deliveryType),
    shippingAddress: deliveryAddress,
    billingAddress,
    courierTimeSpanId: enhancedPharmacy.nextCourierTimeSpans[0].courierTimeSpanId,
    purchased_while_closed: !isOpen,
  };

  // magie mit gleichen checks wird benötigt damit TS-check vernünftig funktioniert mit verschiednen paymentMethods
  if (paymentMethod === PaymentMethod.cash) {
    return {
      ...res,
      paymentType: paymentMethod,
    };
  }

  return {
    ...res,
    paymentType: paymentMethod,
  };
};

const checkoutsToOder = (checkoutValues: CheckoutValues, addData: PssOrderAdditionalTypes) => {
  if (checkoutValues.channel === PharmacyChannel.pss) {
    return checkoutPssToOrder(checkoutValues, addData);
  }

  if (checkoutValues.channel === PharmacyChannel.customerBoard) {
    return checkoutOffizinToOrder(checkoutValues, addData);
  }

  if (checkoutValues.channel === PharmacyChannel.local) {
    return checkoutDefaultToOrder(checkoutValues, addData);
  }

  throw new Error("not implemented");
};

interface Props {
  checkoutValues: CheckoutValues;
  onAbort(): void;
}

const messages = {
  orderIsPosting: {
    id: "orderIsPosting",
    message: "Ihr Bestellung wird momentan übermittelt...",
  },
  doNotReloadPage: {
    id: "doNotReloadPage",
    message: "Sie werden automatisch weitergeleitet, bitte laden Sie die Seite nicht neu.",
  },
};

export function CheckoutSubmitOrder({ checkoutValues, onAbort }: Props) {
  const { serverTime } = useContext(appContext.context);
  const { urlBase } = useContext(UrlContext);

  const { enhancedPharmacy, pharmacyChannel, pharmacy } = usePharmacyValues();

  const products = useSelector<ReduxShoppingCartProducts>(selectProducts);

  const isOpen = useMemo(() => {
    if (!enhancedPharmacy) {
      return false;
    }

    const { openingFromMoment, openingToMoment } = enhancedPharmacy.nextOpeningTimes[0];
    const closingMom = openingToMoment.clone().subtract("5", "minutes");

    return serverTime.value.isBetween(openingFromMoment, closingMom);
  }, [serverTime, enhancedPharmacy]);

  const order = useMemo(() => {
    if (!enhancedPharmacy) {
      return undefined;
    }

    const o = { products, enhancedPharmacy, isOpen };
    return checkoutsToOder(checkoutValues, o);
  }, [enhancedPharmacy, isOpen, checkoutValues, products]);

  const user = firebase.auth().currentUser;

  const [isDialogOpen, setIsDialogOpen] = useState(true);

  if (!order || !pharmacy || !pharmacyChannel || !enhancedPharmacy || !urlBase) {
    return null;
  }

  return (
    <Fragment>
      {user ? (
        <SubmitUserOrder
          onStartEPayment={() => {
            setIsDialogOpen(false);
          }}
          pharmacy={pharmacy}
          pharmacyChannel={pharmacyChannel}
          order={order}
          user={user}
          urlBase={urlBase}
          onAbortClick={() => {
            setIsDialogOpen(false);
            onAbort();
          }}
        />
      ) : (
        <SubmitGuestOrder
          onStartEPayment={() => {
            setIsDialogOpen(false);
          }}
          pharmacy={pharmacy}
          pharmacyChannel={pharmacyChannel}
          order={order}
          urlBase={urlBase}
          onAbortClick={() => {
            setIsDialogOpen(false);
            onAbort();
          }}
        />
      )}

      <Dialog open={isDialogOpen} disableBackdropClick={true} disableEscapeKeyDown={true}>
        <DialogContent style={{ maxWidth: 500 }}>
          <FluidTypography type="headline3" paragraph={true} align="center">
            <LinguiMessage message={messages.orderIsPosting} />
          </FluidTypography>

          <FluidTypography type="body2" paragraph={true} align="center">
            <LinguiMessage message={messages.doNotReloadPage} />
          </FluidTypography>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
