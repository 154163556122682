import moment from "moment-timezone";
import { range } from "ramda";

export const toSecondsOfDay = mom =>
  mom.seconds() + mom.minutes() * 60 + mom.hours() * 60 * 60;

export const secondsOfDayFromTimeStr = str =>
  toSecondsOfDay(moment(str, "HH:mm:ss"));

export const momentWithNewTime = (mom, timeStr) => {
  const timeMoment = moment(timeStr, "HH:mm:ss");

  return mom.clone().set({
    hour: timeMoment.hour(),
    minute: timeMoment.minute(),
    second: timeMoment.second(),
  });
};

export const setNextDay = (time, format) =>
  moment(time, format)
    .add(1, "days")
    .format(format);

// numberOfNextDays is exclusive
export const getNextDays = (numberOfNextDays, mom) =>
  range(1, numberOfNextDays).map(daysToAdd =>
    momentWithNewTime(mom, "00:00:00").add(daysToAdd, "d"),
  );

export const isSameDay = (mom1, mom2) => mom1.isSame(mom2, "day");
export const isNextDay = (mom1, mom2) =>
  mom1
    .clone()
    .add(1, "d")
    .isSame(mom2, "day");

export const timeObjFromMoment = mom => ({
  hours: mom.hours(),
  minutes: mom.minutes(),
  seconds: mom.seconds(),
});

export const toNextDayStartMoment = mom =>
  momentWithNewTime(mom, "00:00:00").add(1, "d");
