import { find, range } from "ramda";

export function gaussian(mat: number[][]): number[] {
  const curRow = find(o => o[0] !== 0, mat);

  if (!curRow) {
    throw new Error("all 0");
  }

  const [val, ...restMat] = curRow;

  const calcRestMat = ([first, ...rest]: number[]) => {
    const factor = first / val;

    return rest.map((cur, i) => cur - factor * restMat[i]);
  };

  const without = mat.filter(row => row !== curRow).map(calcRestMat);

  const scalar = restMat.pop() as number;

  if (without.length === 0) {
    if (restMat.length > 0) {
      throw new Error("matrix ist unterbestimmt");
    }

    return [scalar / val];
  }

  const resultVector = gaussian(without);

  const checkVal = restMat.reduce(
    (sum, curVal, i) => sum + curVal * resultVector[i],
    0,
  );

  return [(scalar - checkVal) / val, ...resultVector];
}

type Pair = [number, number];

export function calcFuncForPairs(p: Pair[]) {
  const funktionsGrade = range(0, p.length).reverse();

  const toKoeffizientenArray = (val: number) =>
    funktionsGrade.map(grad => Math.pow(val, grad));

  const matrix = p.map(pair => [...toKoeffizientenArray(pair[0]), pair[1]]);

  return gaussian(matrix);
}
