import { MessageDescriptor } from "@lingui/core/cjs/i18n";
import { Hidden, Paper } from "@material-ui/core";
import { css } from "@emotion/core";
import { equals } from "ramda";
import * as React from "react";
import { FluidTypography } from "../../../../app-util-components/FluidTypography";
import { LinguiMessage } from "../../../../app-util-components/LinguiMessage";
import {
  addAddressToLocalStorage,
  addressesFromLocalStorage,
} from "../../../../app-utilities/addressesLocalStorage";
import { appGrid } from "../../../../app-utilities/cssClasses";
import {
  both,
  cond,
  first,
  throwF,
  throwImmediately,
} from "../../../../app-utilities/fn-utils";
import { commonMessages } from "../../../../deprecated/deprecated-apoly-app/constants/messages/commonMessages";
import { Address, PaymentMethod } from "../../../apolyApi";
import { PharmacyValuesConsumer } from "../../../PharmacyContext";
import { CheckoutBillingData } from "../CheckoutBillingData";
import { checkoutDesktopGrid } from "../checkoutClasses";
import { CheckoutClosure, ClosureValues } from "../CheckoutClosure";
import { CheckoutPayment } from "../CheckoutPayment";
import { CheckoutPssStep, CompletedCheckoutPss } from "../checkoutTypes";
import { DeliveryType } from "../OtcOrderTypes";
import { CheckoutPssStepper } from "./CheckoutPssStepper";

// NEXT TODOS :
// * offizinCheckout mit reintunen

interface CheckoutPssProps {
  onSubmit: (val: CompletedCheckoutPss) => void;
  pssStep: CheckoutPssStep | undefined;
}

interface CheckoutPssState {
  step: CheckoutPssStep;
  email: string | undefined;
  billingAddress?: Address;
  paymentMethod: PaymentMethod | undefined;
}

const stepToHeadline = cond<CheckoutPssStep, MessageDescriptor>(
  [
    [equals(CheckoutPssStep.billingData), () => commonMessages.billingDataStep],
    [equals(CheckoutPssStep.payment), () => commonMessages.paymentMethod],
    [equals(CheckoutPssStep.closure), () => commonMessages.finish],
  ],
  throwF("unknown step"),
);

export class CheckoutPss extends React.PureComponent<
  CheckoutPssProps,
  CheckoutPssState
> {
  state = {
    step: CheckoutPssStep.billingData,
    email: undefined,
    billingAddress: undefined,
    paymentMethod: undefined,
  };

  componentDidUpdate(prevProps: Readonly<CheckoutPssProps>) {
    if (
      prevProps.pssStep !== this.props.pssStep &&
      this.props.pssStep &&
      this.props.pssStep !== this.state.step
    ) {
      this.setStep(this.props.pssStep);
    }
  }

  submit = ({ agbOptIn, newsletterOptIn }: ClosureValues) =>
    // besser als mit dem throw wäre für jeden konkreten step den exakten möglichen state festzulegen,
    // aber das macht es dann auch wieder ein bisschen komplizierter mit nutzlosen checks um für TS den Typen klarzumachen
    this.props.onSubmit({
      agbOptIn,
      newsletterOptIn,
      email: this.state.email || throwImmediately("email not set"),
      billingAddress: this.state.billingAddress,
      paymentMethod:
        this.state.paymentMethod || throwImmediately("paymentMethod not set"),
    });

  setStep = (step: CheckoutPssStep) => this.setState({ step });

  handleBillingDataSubmit = (data: {
    billingAddress?: Address;
    email: string;
  }) => {
    this.setState({ ...data, step: CheckoutPssStep.payment });
  };

  handlePaymentSubmit = (paymentMethod: PaymentMethod) =>
    this.setState({ paymentMethod, step: CheckoutPssStep.closure });

  renderPaymentStep = () => (
    <PharmacyValuesConsumer>
      {({ pharmacy }) =>
        pharmacy && (
          <CheckoutPayment
            onSubmit={this.handlePaymentSubmit}
            onBackClick={() => this.setStep(CheckoutPssStep.billingData)}
            initialPaymentMethod={this.state.paymentMethod}
            display_helper_text={pharmacy.show_terminal_payment_on_abholautomat}
            paymentMethods={
              [
                pharmacy.show_terminal_payment_on_abholautomat
                  ? "hobex_terminal"
                  : undefined,
                ...pharmacy.paymentMethods
                  .filter(method => {
                    if (method === "cash") {
                      return false;
                    }

                    if (
                      pharmacy.show_terminal_payment_on_abholautomat &&
                      ["visa", "sepa", "mastercard"].indexOf(method) >= 0
                    ) {
                      return false;
                    }

                    return true;
                  })
                  .map(method => PaymentMethod[method]),
              ].filter(Boolean) as PaymentMethod[]
            }
          />
        )
      }
    </PharmacyValuesConsumer>
  );

  renderContent = first<CheckoutPssStep, React.ReactNode>([
    both(equals(CheckoutPssStep.billingData), () => (
      <CheckoutBillingData
        billingAddress={this.state.billingAddress}
        email={this.state.email}
        onSubmit={this.handleBillingDataSubmit}
        addresses={addressesFromLocalStorage}
        onSubmitNewAddress={addAddressToLocalStorage}
      />
    )),
    both(equals(CheckoutPssStep.payment), this.renderPaymentStep),
    both(equals(CheckoutPssStep.closure), () => (
      <CheckoutClosure
        deliveryType={DeliveryType.pssReservation}
        onSubmit={this.submit}
        onBackClick={() => this.setStep(CheckoutPssStep.payment)}
      />
    )),
  ]);

  render() {
    return (
      <React.Fragment>
        <Hidden smUp={true}>
          <CheckoutPssStepper
            {...this.state}
            onEditStepClick={this.setStep}
            renderStepContent={this.renderContent}
          />
        </Hidden>

        <Hidden xsDown={true}>
          <div css={checkoutDesktopGrid}>
            <div>
              <CheckoutPssStepper
                css={css({ backgroundColor: "transparent" })}
                {...this.state}
                onEditStepClick={this.setStep}
              />
            </div>
            <Paper elevation={2} css={appGrid} style={{ padding: 16 }}>
              <FluidTypography type="headline3">
                <LinguiMessage message={stepToHeadline(this.state.step)} />
              </FluidTypography>
              <div>{this.renderContent(this.state.step)}</div>
            </Paper>
          </div>
        </Hidden>
      </React.Fragment>
    );
  }
}
