type Listener = (event: Event) => void;

export class CustomAbortSignal {
  /**
   * Returns true if this AbortSignal's AbortController has signaled to abort, and false
   * otherwise.
   */
  aborted: boolean;
  onabort?: (this: CustomAbortSignal, ev: Event) => any;

  constructor() {
    this.listeners = {};
    this.aborted = false;
  }

  listeners: { [key: string]: Listener[] };

  addEventListener(type: string, callback: Listener) {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  }

  removeEventListener(type: string, callback: Listener) {
    if (!this.listeners[type]) {
      return;
    }
    const stack = this.listeners[type];
    for (let i = 0, l = stack.length; i < l; i++) {
      if (stack[i] === callback) {
        stack.splice(i, 1);
        return;
      }
    }
  }

  dispatchEvent(event: Event) {
    if (event.type === "abort") {
      this.aborted = true;
      if (typeof this.onabort === "function") {
        this.onabort.call(this, event);
      }
    }

    if (!this.listeners[event.type]) {
      return;
    }

    const debounce = (callback: Listener) => {
      setTimeout(() => callback.call(this, event));
    };

    const stack = this.listeners[event.type];
    for (let i = 0, l = stack.length; i < l; i++) {
      debounce(stack[i]);
    }
    return !event.defaultPrevented;
  }

  toString() {
    return "[object AbortSignal]";
  }
}

export class CustomAbortController {
  readonly signal: CustomAbortSignal;

  constructor() {
    this.signal = new CustomAbortSignal();
  }

  abort() {
    let event: Event;
    try {
      event = new Event("abort");
    } catch (e) {
      // @ts-ignore
      event = {
        type: "abort",
        bubbles: false,
        cancelable: false,
      };
    }
    this.signal.dispatchEvent(event);
  }

  toString() {
    return "[object AbortController]";
  }
}
