import { LinguiEuro, LinguiMessage } from "@apoly-42/apoly-components";
import { Divider, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import { commonMessages } from "../../../../../constants/messages/commonMessages";
import {
  fullMaxWidthWithMarginAuto,
  marginAuto,
} from "../../../../../constants/styles/commonClasses";
import { priceWithTax } from "../../../../../constants/styles/cssEmotionClasses";
import ProductImageSmall from "../../../../imageComponents/productImages/ProductImageSmall";
import { getTaxValueMessage } from "../getTaxValueForProduct";

const messages = {
  pricePerPiece: {
    id: "pricePerPiece",
    message: "price per piece",
  },
};

const getTotalProductPrice = (amount, price) => (amount * price).toFixed(2);

const SingleProductResponsive = ({ article, count }) => {
  const { medicine } = article.package;
  const totalProductPrice = getTotalProductPrice(count, article.price);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          paragraph
          dangerouslySetInnerHTML={{ __html: medicine.name }}
        />
        <Typography variant="caption" color="textSecondary">
          {article.package.amountText} | PZN: {article.package.pzn}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container justify="center">
          <Grid item xs={8} sm={4}>
            <ProductImageSmall
              name={medicine.name}
              imgUrl={article.package.imgUrl}
              style={fullMaxWidthWithMarginAuto}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} style={marginAuto}>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={6}>
            <Typography>
              <LinguiMessage message={commonMessages.amount} />:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">{count}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={6}>
            <Typography>
              <LinguiMessage message={messages.pricePerPiece} />:
            </Typography>
          </Grid>
          <Grid item xs={6} css={priceWithTax}>
            <Typography align="right">
              <LinguiEuro value={Number(article.price)} />
            </Typography>
            {getTaxValueMessage(article.package.mwst_code)}
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={7}>
            <Typography>
              <LinguiMessage message={commonMessages.totalAmount} />:
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="right">
              <LinguiEuro value={Number(totalProductPrice)} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

SingleProductResponsive.propTypes = {
  article: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
};

export default SingleProductResponsive;
