import { ShowComponentWhileLoading } from "@apoly-42/material-ui";
import * as React from "react";
import { connect } from "react-redux";
import { DbArticle } from "../../../../app-utilities/app-types";
import LoadArticlesContainer from "../../../../deprecated/deprecated-apoly-app/containers/product/LoadArticlesContainer";
import { selectPharmacyId } from "../../../../deprecated/deprecated-apoly-app/redux/shopPharmacy/reducer";
import {
  getShoppingCartArticlesIdArray,
  selectProducts,
} from "../../../../deprecated/deprecated-apoly-app/redux/shoppingCart/selectors";
import { Pharmacy } from "../../../apolyApi";
import { PharmacyValuesConsumer } from "../../../PharmacyContext";
import { CompletedCheckoutDefault } from "../checkoutTypes";
import { DeliveryType } from "../OtcOrderTypes";
import { CheckoutDefault } from "./CheckoutDefault";

interface CheckoutLocalOrderProps {
  pharmacyId?: number | string;
  articleIdArray?: Array<object> | null;
  deliveryType: DeliveryType;
  onSubmit: (vals: CompletedCheckoutDefault) => void;
}

const availabilityForDelivery = (pharmacy: Pharmacy, articles: DbArticle[]) => {
  const articlesAreAvailable = articles.every(
    article => article.availability === "available",
  );
  if (!articlesAreAvailable) {
    return false;
  }

  if (articlesAreAvailable && pharmacy.trustedPharmacyUrl) {
    return true;
  }

  const allArticlesAreTax2 = articles.every(
    article => article.package.medicine.taxkennzeichen === "tax_2_freie",
  );

  if (articlesAreAvailable && allArticlesAreTax2) {
    return true;
  }

  return false;
};

interface CheckoutLocalOrderState {
  isLoading: boolean;
  articles: null | DbArticle[];
  error: Error | null;
}

class InnerCheckoutLocalOrder extends React.PureComponent<
  CheckoutLocalOrderProps,
  CheckoutLocalOrderState
> {
  state = {
    isLoading: false,
    articles: null,
    error: null,
  };

  setIsLoading = (isLoading: CheckoutLocalOrderState["isLoading"]) =>
    this.setState({ isLoading });

  setError = (error: CheckoutLocalOrderState["error"]) =>
    this.setState({ error });

  setArticles = (articles: CheckoutLocalOrderState["articles"]) =>
    this.setState({ articles });

  render() {
    const { articles } = this.state;

    return (
      <React.Fragment>
        <LoadArticlesContainer
          setIsLoading={this.setIsLoading}
          setError={this.setError}
          setArticles={this.setArticles}
          articleIdArray={this.props.articleIdArray}
          pharmacyId={this.props.pharmacyId}
        />

        {this.state.isLoading && (
          <ShowComponentWhileLoading isLoading={this.state.isLoading} />
        )}

        <PharmacyValuesConsumer>
          {({ pharmacy }) =>
            articles &&
            pharmacy && (
              <CheckoutDefault
                deliveryType={this.props.deliveryType}
                onSubmit={this.props.onSubmit}
              />
            )
          }
        </PharmacyValuesConsumer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  products: selectProducts(state),
  articleIdArray: getShoppingCartArticlesIdArray(state),
  pharmacyId: selectPharmacyId(state),
});

const mapDispatchToProps = {};

export const CheckoutLocalOrder = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnerCheckoutLocalOrder);
