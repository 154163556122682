import { css } from "@emotion/core";
import { appGrid, mdUp } from "../../../app-utilities/cssClasses";

export const checkoutDesktopGrid = css`
  ${appGrid};
  grid-template-columns: 1fr 1fr;
  ${mdUp} {
    grid-template-columns: 1fr 2fr;
  }
`;
