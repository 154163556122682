import { Trans } from "@lingui/macro";
import { Button, Dialog, DialogContent, Typography } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import { Redirect } from "react-router";
import { Load } from "../../../../app-util-components/Load";
import { useAsyncEffect } from "../../../../app-utilities/hook-utils";
import { pause } from "../../../../app-utilities/utils";
import { createOtcOrder } from "../../../../deprecated/apolyApi";
import {
  otcOrderCompletePath,
  otcPaymentFailedPath,
} from "../../../../deprecated/deprecated-apoly-app/routes/paths";
import {
  getHobexTerminalPaymentState,
  initiateHobexTerminalPayment,
  newHobexTerminalPaymentId,
  wirecardPaymentRequest,
} from "../../../../deprecated/deprecated-apoly-app/utilities/apis/apolyApi/apolyApi";
import { rfcUrl, RfcUrl } from "../../../../url";
import {
  CashOtcOrder,
  EPaymentOtcOrder,
  OtcOrder,
  PaymentMethod,
  Pharmacy,
  TerminalOtcOrder,
} from "../../../apolyApi";
import { pharmacyCtxToPath } from "../../../layout/PharmacyBaseLink";
import { usePharmacyValues } from "../../../PharmacyContext";
import { dashboardReservationRoute, pharmacyBase } from "../../../routes";
import { PharmacyChannel } from "../../../routes-helper";
import { PosTerminalIconSvg } from "../CheckoutPayment";
import { WirecardRequestFromApi } from "./wirecard-utils";
import { WirecardWrapper } from "./WirecardWrapper";

type OtcGuestOrderResponse = { otcOrderKey: string };

export function postNewGuestOrder(
  order: OtcOrder,
): Promise<OtcGuestOrderResponse> {
  return createOtcOrder(order);
}

interface Props {
  urlBase: RfcUrl;
  order: OtcOrder;
  pharmacy: Pharmacy;
  pharmacyChannel: PharmacyChannel;
  onStartEPayment?(): void;
  onAbortClick(): void;
}

function SubmitUserOrderTerminal(props: {
  order: TerminalOtcOrder;
  onStartPayment?(): void;
  onAbortClick(): void;
}) {
  const [state, setState] = useState<
    | {
        type: "init" | "pending" | "failed";
      }
    | { type: "fulfilled"; otcOrderKey: string }
  >({ type: "init" });

  const pharmacyCtx = usePharmacyValues();

  type TerminalState = "pending" | "fulfilled" | "failed";
  useAsyncEffect(
    async ({ signal }) => {
      const { otcOrderKey } = await postNewGuestOrder(props.order);
      if (signal.isAborted) return;

      const hobexId = await newHobexTerminalPaymentId(otcOrderKey);
      if (signal.isAborted) return;

      props.onStartPayment && props.onStartPayment();

      await new Promise(async (resolve, reject) => {
        async function checkState(state: TerminalState) {
          switch (state) {
            case "pending":
              await pause(500);
              checkTerminalState().catch(reject);
              break;
            case "fulfilled":
              resolve();
              setState({ type: "fulfilled", otcOrderKey });
              break;
            case "failed":
              resolve();
              setState({ type: "failed" });
              break;
            default:
              throw new Error(`unknown state ${state}`);
          }
        }

        async function checkTerminalState() {
          const state: TerminalState = await getHobexTerminalPaymentState(
            otcOrderKey,
            hobexId,
          );

          checkState(state).catch(reject);
        }

        setState({ type: "pending" });

        if (signal.isAborted) return;
        const state = await initiateHobexTerminalPayment(
          otcOrderKey,
          hobexId,
        ).catch(console.error);

        // wenn state nicht aufgelöst wurde, dann mit get-state den echten rausfinden:
        checkState(state || "pending").catch(reject);
      });
    },
    [props.order],
  );

  return (
    <Dialog open={state.type !== "init"}>
      <DialogContent>
        {state.type === "pending" ? (
          <div className="grid grid-gap-4 justify-items-center">
            <Typography variant="h5">
              <Trans>Befolgen Sie die Anweisungen am Karternterminal</Trans>
            </Typography>
            <div>
              <PosTerminalIconSvg className="w-full h-full max-h-32 fill-current" />
            </div>
            <Typography>
              <Trans>
                Beenden Sie die Bestellung am Karternterminal, danach wird Ihre
                Ware ausgelagert.
              </Trans>
            </Typography>
          </div>
        ) : null}

        {state.type === "failed" ? (
          <div className="grid grid-gap-4 justify-items-center">
            <Typography variant="h5">
              <Trans>Zahlung abgebrochen oder fehlerhaft</Trans>
            </Typography>
            <div className="text-red-500">
              <PosTerminalIconSvg className="w-full h-full max-h-32 fill-current" />
            </div>
            <Typography>
              <Trans>
                Die Zahlung wurde nicht erfolgreich abgeschlossen. Sie können
                eine andere Zahlart nutzen oder es erneut mit der Kartenzahlung
                am Terminal versuchen
              </Trans>
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setState({ type: "init" });
                props.onAbortClick();
              }}
            >
              <Trans>Zurück und neue Zahlart auswählen</Trans>
            </Button>
          </div>
        ) : null}

        {state.type === "fulfilled" ? (
          <Redirect
            push={true}
            to={otcOrderCompletePath(
              pharmacyCtxToPath(pharmacyBase, pharmacyCtx),
              state.otcOrderKey,
            )}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
}

export class SubmitGuestOrder extends React.PureComponent<Props> {
  toUrl = (path: string) => rfcUrl(path, this.props.urlBase).toString();

  /*
  // TODO check wie man am sinnvollsten async await einbaut in webpack-bundle und dann wieder die sachen umschreiben
   postNewGuestOrderWithEPayment = async (
    order: EPaymentOtcOrder
  ): Promise<WirecardRequestFromApi> => {
    const basePath = pharmacyCtxToPath(pharmacyBase, this.props);

    const { otcOrderKey } = await postNewGuestOrder(order);

    return await wirecardPaymentRequest(
      otcOrderKey,
      this.toUrl(otcOrderCompletePath(basePath, otcOrderKey)),
      this.toUrl(otcPaymentFailedPath(basePath, otcOrderKey))
    );
  };
   */
  postNewGuestOrderWithEPayment = (
    order: EPaymentOtcOrder,
  ): Promise<WirecardRequestFromApi> => {
    const basePath = pharmacyCtxToPath(pharmacyBase, this.props);

    return postNewGuestOrder(order).then(({ otcOrderKey }) => {
      const paths =
        order.deliveryType === "pss_vending_machine_reserve"
          ? {
              success: dashboardReservationRoute(basePath, otcOrderKey),
              failed: otcPaymentFailedPath(basePath, otcOrderKey),
            }
          : {
              success: otcOrderCompletePath(basePath, otcOrderKey),
              failed: otcPaymentFailedPath(basePath, otcOrderKey),
            };

      return wirecardPaymentRequest(
        otcOrderKey,
        this.toUrl(paths.success),
        this.toUrl(paths.failed),
      );
    });
  };

  renderSubmitEPaymentUserOrder = (order: EPaymentOtcOrder) => (
    <Load fn={() => this.postNewGuestOrderWithEPayment(order)}>
      {apiState => (
        <React.Fragment>
          {apiState.response && (
            <WirecardWrapper
              onStartPayment={this.props.onStartEPayment}
              wcRequestFromApi={apiState.response}
            />
          )}
        </React.Fragment>
      )}
    </Load>
  );

  renderSubmitCashPaymentUserOrder = (order: CashOtcOrder) => {
    const basePath = pharmacyCtxToPath(pharmacyBase, this.props);

    return (
      <Load fn={() => postNewGuestOrder(order)}>
        {({ response }) => (
          <React.Fragment>
            {response && (
              <Redirect
                push={true}
                to={otcOrderCompletePath(basePath, response.otcOrderKey)}
              />
            )}
          </React.Fragment>
        )}
      </Load>
    );
  };

  render() {
    const { order, onAbortClick, onStartEPayment } = this.props;

    if (order.paymentType === PaymentMethod.hobex_terminal) {
      return (
        <SubmitUserOrderTerminal
          onAbortClick={onAbortClick}
          order={order}
          onStartPayment={onStartEPayment}
        />
      );
    }

    return order.paymentType === PaymentMethod.cash
      ? this.renderSubmitCashPaymentUserOrder(order)
      : this.renderSubmitEPaymentUserOrder(order);
  }
}
