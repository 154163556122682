import { css } from "@emotion/core";
import { faChevronDoubleRight } from "@fortawesome/pro-duotone-svg-icons";
import {
  faBoxCheck,
  faCheck,
  faHome,
  faHourglassHalf,
  faShippingFast,
  faShippingTimed,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { faSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defineMessage, Trans } from "@lingui/macro";
import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  Typography,
} from "@material-ui/core";
import { blueGrey, red, teal } from "@material-ui/core/colors";
import * as React from "react";
import { Fragment, useEffect, useMemo, useState } from "react";

import { AppHelmetI18n } from "../../app-util-components/AppHelmetI18n";
import { CtaButton } from "../../app-util-components/CtaButton";
import { LinguiMessage } from "../../app-util-components/LinguiMessage";
import { appMessages } from "../../app-utilities/appMessages";
import { appGrid, smUp } from "../../app-utilities/cssClasses";
import { useAsyncEffect } from "../../app-utilities/hook-utils";
import { Form } from "../../app-utilities/react-utils";
import { pause } from "../../app-utilities/utils";
import defaultPic from "../../deprecated/deprecated-apoly-app/constants/assets/apolyPille_default.png";
import {
  getHobexTerminalPaymentState,
  initiateHobexTerminalPayment,
  newHobexTerminalPaymentId,
} from "../../deprecated/deprecated-apoly-app/utilities/apis/apolyApi/apolyApi";
import { getPaymentMethodImage } from "../../deprecated/deprecated-apoly-app/utilities/paymentMethod/paymentMethodsUtilities";
import { ABHOL_SECRET, APOLY_API_BASE_URL } from "../../environment";
import { PaymentMethod, PaymentMethodStr } from "../apolyApi";
import { QrCodeScanner } from "../components/QrCodeScanner";
import { TextWithLabel } from "../components/TextWithLabel";
import { Footer } from "../layout/Footer";
import { NavBar } from "../layout/NavBar";
import { usePharmacyValues } from "../PharmacyContext";
import { resetApp } from "../ResetAppTimer";
import { PharmacyChannel } from "../routes-helper";
import { PosTerminalIconSvg } from "./checkout/CheckoutPayment";
import {
  toWcRequest,
  WirecardRequest,
  WirecardRequestFromApi,
} from "./checkout/submitOtcOrder/wirecard-utils";
import { asyncLoadWirecardPaymentPageScript } from "./checkout/submitOtcOrder/WirecardWrapper";
import {
  Address,
  DashboardOrder,
  OrderDelivery,
  OrderPaymentType,
} from "./dashboard-checkout-types";

function paymentSrcFromMethod(method: string): string {
  return getPaymentMethodImage(
    // `as never` um js<=>ts Problem zu umgehen
    method as never,
  );
}

interface DbDashboardOrder extends DashboardOrder {
  order_pss_pick_up_pin: string;
}

const messages = {
  reservationTitle: defineMessage({
    id: "ReservationOverview_reservationTitle",
    message: "Ihre Reservierung bei Ihrer Apotheke",
  }),
};

type OrderDeliveryState =
  | "wait_for_products"
  | "products_delivering"
  | "products_delivering_failed"
  | "products_delivered"
  | "products_ready_for_collection"
  | "products_collected"
  | "products_stored"
  | "products_collected_from_pss"
  | "failed";

const deliveryStateClass = css`
  display: grid;
  grid-gap: 8px;
  align-items: center;
  grid-template-columns: min-content 1fr;
`;

function deliveryStateDescription(deliveryState: OrderDeliveryState) {
  switch (deliveryState) {
    case "wait_for_products":
      return (
        <div style={{ color: red["300"] }} css={deliveryStateClass}>
          <FontAwesomeIcon fixedWidth={true} icon={faHourglassHalf} size="lg" />
          <Trans>Produkte noch nicht verfügbar</Trans>
        </div>
      );
    case "products_delivering":
      return (
        <div style={{ color: teal["500"] }} css={deliveryStateClass}>
          <FontAwesomeIcon fixedWidth={true} icon={faShippingTimed} size="lg" />
          <Trans>Ihre Produkte werden Ihnen momentan zugestellt</Trans>
        </div>
      );
    case "products_delivering_failed":
      return (
        <div style={{ color: red["300"] }} css={deliveryStateClass}>
          <FontAwesomeIcon fixedWidth={true} icon={faTimes} size="lg" />
          <Trans>
            Die Lieferung ist fehlgeschlagen, kontaktieren Sie Ihre Apotheke
          </Trans>
        </div>
      );
    case "products_delivered":
      return (
        <div style={{ color: teal["500"] }} css={deliveryStateClass}>
          <FontAwesomeIcon fixedWidth={true} icon={faCheck} size="lg" />
          Sie haben Ihre Produkte erhalten
        </div>
      );
    case "products_ready_for_collection":
      return (
        <div style={{ color: teal["500"] }} css={deliveryStateClass}>
          <FontAwesomeIcon fixedWidth={true} icon={faCheck} size="lg" />
          <Trans>Ihre Produkte liegen nun für Sie zur Abholung bereit</Trans>
        </div>
      );
    case "products_collected":
      return (
        <div style={{ color: teal["500"] }} css={deliveryStateClass}>
          <FontAwesomeIcon fixedWidth={true} icon={faCheck} size="lg" />
          <Trans>Sie haben Ihre Produkte abgeholt</Trans>
        </div>
      );
    case "products_stored":
      return (
        <div style={{ color: teal["500"] }} css={deliveryStateClass}>
          <FontAwesomeIcon fixedWidth={true} icon={faCheck} size="lg" />
          <Trans>Ihre Produkte liegen nun für Sie zur Abholung bereit</Trans>
        </div>
      );
    case "products_collected_from_pss":
      return (
        <div style={{ color: teal["500"] }} css={deliveryStateClass}>
          <FontAwesomeIcon fixedWidth={true} icon={faCheck} size="lg" />
          <Trans>Sie haben Ihre Produkte am Automaten abgeholt</Trans>
        </div>
      );
    case "failed":
      return (
        <div style={{ color: red["300"] }} css={deliveryStateClass}>
          <FontAwesomeIcon fixedWidth={true} icon={faTimes} size="lg" />
          <Trans>
            Produkte wurden nicht abgeholt, kontaktieren Sie Ihre Apotheke
          </Trans>
        </div>
      );
  }
}

function textFromOrderPayment(p: OrderPaymentType) {
  switch (p) {
    case "payment_in_advance":
      return "Vorkasse";
    case "online":
      return "Online";
    case "payment_on_delivery":
      return "Bezahlung bei Lieferung";
    case "payment_on_pickup":
      return "Bezahlung bei Abholung";
    case "payment_on_pss_reservation_machine":
      return "Bezahlung am Abholautomaten";
  }
}

function textFromAddress(address: Address) {
  return [
    address.name,
    address.street,
    `${address.zip} ${address.city}`,
    address.note,
  ]
    .filter(Boolean)
    .join(", ");
}

function textFromOrderDelivery(d: OrderDelivery) {
  switch (d.delivery_type) {
    case "pickup":
      return (
        <div css={deliveryStateClass}>
          <FontAwesomeIcon
            fixedWidth={true}
            style={{ color: blueGrey["700"] }}
            icon={faHome}
            size="lg"
          />
          <Trans>Abholung an der Schnellkasse</Trans>
        </div>
      );
    case "delivery":
      return (
        <div css={deliveryStateClass}>
          <FontAwesomeIcon
            fixedWidth={true}
            style={{ color: blueGrey["700"] }}
            icon={faShippingFast}
            size="lg"
          />
          <Trans>Lieferung an {textFromAddress(d.delivery_address)}</Trans>
        </div>
      );
    case "pss_reservation":
      return (
        <div css={deliveryStateClass}>
          <FontAwesomeIcon
            fixedWidth={true}
            style={{ color: blueGrey["700"] }}
            icon={faBoxCheck}
            size="lg"
          />
          <Trans>Abholung am Automaten</Trans>
        </div>
      );
  }
}

async function newWcRequest(props: {
  order_key: string;
  payment_type: string;
  redirect_success_url: string;
  redirect_fail_url: string;
}): Promise<WirecardRequest> {
  const response = await fetch(
    `${APOLY_API_BASE_URL}/wirecard_request_from_dashboard_order`,
    {
      method: "post",
      body: JSON.stringify(props),
    },
  );

  const parsedResponse = (await response.json()) as WirecardRequestFromApi;

  return toWcRequest(parsedResponse);
}

async function loadOrder(orderKey: string): Promise<DbDashboardOrder> {
  const url = `${APOLY_API_BASE_URL}/dashboard_order/${orderKey}`;
  const response = await fetch(url);
  const parsedResponse = (await response.json()) as DbDashboardOrder;
  const del = parsedResponse.order_delivery;

  return {
    ...parsedResponse,
    order_delivery:
      del.delivery_type === "delivery"
        ? {
            ...del,
            delivery_price: Number(del.delivery_price),
          }
        : del,
    order_products: parsedResponse.order_products.map((p) => ({
      ...p,
      img_url: p.img_url || defaultPic,
      price: Number(p.price),
    })),
  };
}

function PosTerminalDialog(props: {
  orderKey: string;
  onAbortClick(): void;
  isOpen: boolean;
}) {
  const [state, setState] = useState<
    | {
        type: "init" | "pending" | "failed";
      }
    | { type: "fulfilled"; otcOrderKey: string }
  >({ type: "init" });

  type TerminalState = "pending" | "fulfilled" | "failed";
  useAsyncEffect(
    async (signal) => {
      if (!props.isOpen) {
        return;
      }

      const hobexId = await newHobexTerminalPaymentId(props.orderKey);
      if (signal.aborted) return;

      await new Promise(async (resolve, reject) => {
        async function checkState(state: TerminalState) {
          switch (state) {
            case "pending":
              await pause(500);
              checkTerminalState().catch(reject);
              break;
            case "fulfilled":
              resolve();
              window.location.reload();
              break;
            case "failed":
              resolve();
              setState({ type: "failed" });
              break;
            default:
              throw new Error(`unknown state ${state}`);
          }
        }

        async function checkTerminalState() {
          const state: TerminalState = await getHobexTerminalPaymentState(
            props.orderKey,
            hobexId,
          );

          checkState(state).catch(reject);
        }

        setState({ type: "pending" });

        if (signal.aborted) return;
        const state = await initiateHobexTerminalPayment(
          props.orderKey,
          hobexId,
        ).catch(console.error);

        // wenn state nicht aufgelöst wurde, dann mit get-state den echten rausfinden:
        checkState(state || "pending").catch(reject);
      });
    },
    [props.orderKey, props.isOpen],
  );

  return (
    <Dialog open={props.isOpen}>
      <DialogContent>
        {state.type === "pending" || state.type === "init" ? (
          <div className="grid grid-gap-4 justify-items-center">
            <Typography variant="h5">
              <Trans>Befolgen Sie die Anweisungen am Karternterminal</Trans>
            </Typography>
            <div>
              <PosTerminalIconSvg className="w-full h-full max-h-32 fill-current" />
            </div>
            <Typography>
              <Trans>
                Beenden Sie die Bestellung am Karternterminal, danach wird Ihre
                Ware ausgelagert.
              </Trans>
            </Typography>
          </div>
        ) : null}

        {state.type === "failed" ? (
          <div className="grid grid-gap-4 justify-items-center">
            <Typography variant="h5">
              <Trans>Zahlung abgebrochen oder fehlerhaft</Trans>
            </Typography>
            <div className="text-red-500">
              <PosTerminalIconSvg className="w-full h-full max-h-32 fill-current" />
            </div>
            <Typography>
              <Trans>
                Die Zahlung wurde nicht erfolgreich abgeschlossen. Sie können
                eine andere Zahlart nutzen oder es erneut mit der Kartenzahlung
                am Terminal versuchen
              </Trans>
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setState({ type: "init" });
                props.onAbortClick();
              }}
            >
              <Trans>Zurück und neue Zahlart auswählen</Trans>
            </Button>
          </div>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}

export function ReservationOverview(props: { orderKey: string }) {
  const [order, setOrder] = useState<DbDashboardOrder>();
  const [isEjecting, setIsEjecting] = useState(false);
  const [isEjectedPopupOpen, setIsEjectingPopupOpen] = useState(false);
  const [showQrCodeScanner, setShowQrCodeScanner] = useState(false);

  useAsyncEffect(async () => {
    const o = await loadOrder(props.orderKey);
    setOrder(o);
  }, []);

  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [paymentProvider, setPaymentProvider] = useState<PaymentMethodStr>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { pharmacyChannel, pharmacy } = usePharmacyValues();

  const [isTerminalPaymentOpen, setIsTerminalPaymentOpen] = useState(false);

  const paymentMethods: PaymentMethodStr[] = useMemo(() => {
    if (!pharmacy) {
      return [];
    }

    return [
      pharmacy.show_terminal_payment_on_abholautomat
        ? "hobex_terminal"
        : undefined,
      ...pharmacy.paymentMethods
        .filter((method) => {
          if (method === "cash") {
            return false;
          }

          if (
            pharmacy.show_terminal_payment_on_abholautomat &&
            ["visa", "sepa", "mastercard"].indexOf(method) >= 0
          ) {
            return false;
          }

          return true;
        })
        .map((method) => PaymentMethod[method]),
    ].filter(Boolean) as PaymentMethodStr[];
  }, [pharmacy]);

  useEffect(() => {
    // preload wirecard
    asyncLoadWirecardPaymentPageScript().catch(console.error);
  }, []);

  if (!order || !pharmacy || !pharmacyChannel) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 40,
        }}
      >
        <CircularProgress size={80} />
      </div>
    );
  }

  async function ejectPackages() {
    setIsEjecting(true);
    setIsEjectingPopupOpen(true);

    await fetch(`${APOLY_API_BASE_URL}/eject_order_on_pss_abholautomat`, {
      method: "post",
      body: JSON.stringify({
        order_key: props.orderKey,
      }),
    });

    setIsEjecting(false);
    window.location.reload();
  }

  const {
    order_products,
    order_delivery,
    order_payment_type,
    order_payment_state,
    order_pss_pick_up_pin,
  } = order;

  return (
    <Fragment>
      <AppHelmetI18n pageTitleMessage={messages.reservationTitle} />
      <NavBar hideBtns={true} />

      <PosTerminalDialog
        onAbortClick={() => {
          setIsTerminalPaymentOpen(false);
          setIsSubmitting(false);
        }}
        isOpen={isTerminalPaymentOpen}
        orderKey={props.orderKey}
      />

      <Dialog
        open={isEjectedPopupOpen}
        onClose={() => setIsEjectingPopupOpen(false)}
      >
        <DialogContent
          css={css`
            font-size: 16px;
            color: ${teal["500"]};
            display: grid;
            grid-gap: 8px;
            grid-template-columns: min-content 1fr;
            align-items: center;
          `}
        >
          <FontAwesomeIcon size="4x" icon={faCheck} />
          <Typography color="inherit" variant="h5">
            <Trans>Ihre Ware wird ausgelagert</Trans>
          </Typography>
        </DialogContent>
      </Dialog>

      <div
        css={css`
          display: grid;
          grid-gap: 40px;
          justify-items: center;
          padding: 24px 8px;
          ${smUp} {
            padding: 40px;
          }
        `}
      >
        <Typography variant="h4">
          <Trans>Übersicht über Ihre Reservierung</Trans>
        </Typography>

        <Paper
          elevation={2}
          css={css({ padding: 16, display: "grid", gridGap: 24 })}
        >
          <div
            css={css`
              display: grid;
              grid-gap: 16px;
              max-width: 480px;
            `}
          >
            <div>
              <div
                css={css`
                  display: grid;
                  grid-gap: 16px;
                  grid-template-areas: "product product price";
                  grid-template-columns: 64px 2fr 1fr;
                  align-items: center;
                  justify-items: start;
                `}
              >
                <Typography
                  css={css({ gridArea: "product" })}
                  variant="caption"
                  color="textSecondary"
                >
                  <Trans>Produkt</Trans>
                </Typography>
                <Typography
                  css={css({ gridArea: "price" })}
                  variant="caption"
                  color="textSecondary"
                >
                  <Trans>Preis</Trans>
                </Typography>
              </div>
              <Divider />
            </div>

            {order_products.map((op) => {
              const { package_size, price, name, img_url, pzn, amount } = op;

              return (
                <Fragment key={pzn}>
                  <div
                    css={css`
                      display: grid;
                      grid-gap: 16px;
                      grid-template-columns: 64px 2fr 1fr;
                      align-items: center;
                      justify-items: start;
                    `}
                  >
                    <img
                      css={css`
                        height: auto;
                        width: auto;
                        max-height: 64px;
                        max-width: 100%;
                        justify-self: center;
                      `}
                      alt={name}
                      src={img_url}
                    />
                    <div
                      css={css({
                        display: "grid",
                        gridGap: 8,
                        justifySelf: "start",
                      })}
                    >
                      <Typography>
                        {amount} x {name}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        <Trans>
                          PZN: {pzn}, {package_size}
                        </Trans>
                      </Typography>
                    </div>
                    <Typography>
                      {price.toFixed(2)}&nbsp;€
                      {amount > 1 ? ` (x ${amount})` : ""}
                    </Typography>
                  </div>
                  <Divider />
                </Fragment>
              );
            })}

            {order_delivery.delivery_type === "delivery" && (
              <Fragment>
                <div
                  css={css`
                    display: grid;
                    grid-gap: 16px;
                    grid-template-columns: 64px 2fr 1fr;
                    align-items: center;
                    justify-items: start;
                  `}
                >
                  <span
                    css={css`
                      justify-self: center;
                      font-size: 16px;
                      color: hsla(0, 0%, 0%, 0.7);
                    `}
                  >
                    <FontAwesomeIcon
                      icon={faShippingFast}
                      size="2x"
                      transform="shrink-4"
                    />
                  </span>
                  <Typography>
                    <Trans>Lieferkosten</Trans>
                  </Typography>
                  <Typography>
                    {order_delivery.delivery_price.toFixed(2)}&nbsp;€
                  </Typography>
                </div>
                <Divider />
              </Fragment>
            )}

            <Divider
              css={css({
                // gleich grid-gap aus um doppelte linie zu erzeugen:
                marginTop: -12,
              })}
            />
            <div
              css={css`
                // gesamt-preis soll näher doppel-linie stehen, minus-margin-top gleicht grid-gap aus
                margin-top: -16px;
                display: grid;
                grid-gap: 16px;
                // grid sollte das gleiche sein wie unten bei SelectProductsOrderProduct
                grid-template-columns: 64px 2fr 1fr;
                grid-template-areas: "label label total";
                align-items: baseline;
              `}
            >
              <Typography
                variant="caption"
                color="textSecondary"
                css={css({
                  justifySelf: "end",
                  gridArea: "label",
                })}
              >
                <Trans>Gesamtpreis:</Trans>
              </Typography>
              <Typography css={css({ gridArea: "total" })} variant="h6">
                {order_products
                  .reduce(
                    (sum, op) => sum + op.amount * op.price,
                    order_delivery.delivery_type === "delivery"
                      ? order_delivery.delivery_price
                      : 0,
                  )
                  .toFixed(2)}
                &nbsp;€
              </Typography>
            </div>

            <TextWithLabel
              label="Lieferung / Abholung"
              text={textFromOrderDelivery(order_delivery)}
            />

            <TextWithLabel
              label="Ihr Lieferstatus"
              text={deliveryStateDescription(order_delivery.delivery_state)}
            />

            <TextWithLabel
              label="Zahlart"
              text={
                <span>
                  {textFromOrderPayment(order_payment_type)}{" "}
                  {order_payment_state === "pending" && (
                    <span style={{ color: red["300"] }}>
                      (Zahlung ausstehend)
                    </span>
                  )}
                  {order_payment_state === "payed" && (
                    <span style={{ color: teal["500"] }}>
                      (Zahlung erfolgreich abgeschlossen)
                    </span>
                  )}
                </span>
              }
            />

            {order_delivery.delivery_type === "pss_reservation" &&
              pharmacyChannel !== PharmacyChannel.pss && (
                <div>
                  <Typography color="textSecondary" variant="h6">
                    <Trans>Ihr Abholcode am Automaten</Trans>
                  </Typography>
                  <Typography css={css({ marginTop: -8 })} variant="h4">
                    {order_pss_pick_up_pin}
                  </Typography>
                </div>
              )}

            {order_payment_state === "pending" && (
              <div className="grid grid-gap-1">
                <Typography
                  variant="caption"
                  className="text-gray-700 justify-self-center"
                >
                  Drücken Sie auf den Knopf um die Ware am Automaten zu bezahlen
                  und dann auszulagern:
                </Typography>
                <Button
                  variant={
                    order_payment_type === "online" ||
                    order_payment_type === "payment_on_pss_reservation_machine"
                      ? "contained"
                      : "outlined"
                  }
                  color="primary"
                  onClick={() => setIsPaymentOpen(true)}
                  disabled={isPaymentOpen}
                >
                  <div className="flex align-items-center">
                    <span className="mr-2">Jetzt am Automaten bezahlen</span>
                    <span className="text-base">
                      <FontAwesomeIcon icon={faChevronDoubleRight} />
                    </span>
                  </div>
                </Button>
              </div>
            )}

            {pharmacyChannel === PharmacyChannel.local &&
              order_payment_state === "payed" &&
              order_delivery.delivery_type === "pss_reservation" &&
              order_delivery.delivery_state === "products_stored" && (
                <Button
                  disabled={isEjecting}
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowQrCodeScanner(true)}
                >
                  Jetzt abholen (QR-Code Scan)
                </Button>
              )}

            {showQrCodeScanner && (
              <QrCodeScanner
                onFoundCode={(code) => {
                  if (code === ABHOL_SECRET) {
                    ejectPackages().catch(console.error);
                  }
                }}
                onAbortClick={() => setShowQrCodeScanner(false)}
              />
            )}

            {pharmacyChannel === PharmacyChannel.pss &&
              order_payment_state === "payed" &&
              order_delivery.delivery_type === "pss_reservation" &&
              order_delivery.delivery_state === "products_stored" && (
                <div className="grid grid-gap-1">
                  <Typography variant="caption" className="text-gray-700">
                    <Trans>
                      Um Ihre Produkte jetzt auszulagern, drücken Sie unten
                      stehenden Button:
                    </Trans>
                  </Typography>
                  <Button
                    disabled={isEjecting}
                    onClick={ejectPackages}
                    variant="contained"
                    color="secondary"
                  >
                    <div className="flex align-items-center">
                      <Trans>Produkte jetzt Erhalten</Trans>
                      <span className="ml-2 text-base">
                        <FontAwesomeIcon icon={faChevronDoubleRight} />
                      </span>
                    </div>
                  </Button>
                </div>
              )}

            <Collapse in={isPaymentOpen}>
              <Form
                css={appGrid}
                onSubmit={async () => {
                  setIsSubmitting(true);

                  if (paymentProvider === "hobex_terminal") {
                    setIsTerminalPaymentOpen(true);
                  } else {
                    await asyncLoadWirecardPaymentPageScript();

                    const req = await newWcRequest({
                      order_key: props.orderKey,
                      payment_type: paymentProvider as string,
                      redirect_success_url: window.location.href,
                      redirect_fail_url: window.location.href,
                    });

                    // @ts-ignore
                    window.WirecardPaymentPage.embeddedPay(req);
                  }
                }}
              >
                <FormControl css={appGrid} disabled={isSubmitting}>
                  <FormLabel>
                    <Trans>Wie möchten Sie bezahlen?</Trans>
                  </FormLabel>
                  {paymentMethods.map((method) => {
                    return (
                      <FormControlLabel
                        key={method}
                        control={
                          <Radio
                            name="payment_provider"
                            checked={paymentProvider === method}
                            onChange={() => setPaymentProvider(method)}
                            value={method}
                            required={true}
                            color="primary"
                          />
                        }
                        label={
                          <div
                            css={css`
                              display: flex;
                              align-items: center;
                              justify-content: center;
                            `}
                          >
                            {method === "mastercard" ? (
                              <div
                                css={css`
                                  display: grid;
                                  grid-gap: 8px;
                                  grid-template-columns: min-content min-content min-content;
                                  align-items: center;
                                  justify-content: start;
                                  font-size: 16px;
                                `}
                              >
                                <img
                                  css={css({
                                    height: 40,
                                    width: "auto",
                                  })}
                                  src={paymentSrcFromMethod("mastercard")}
                                  alt={method}
                                />
                                <FontAwesomeIcon
                                  icon={faSlash}
                                  size="2x"
                                  transform="rotate-70 shrink-3"
                                  fixedWidth={true}
                                />
                                <img
                                  css={css`
                                    height: auto;
                                    width: auto;
                                    max-height: 48px;
                                    max-width: 96px;
                                  `}
                                  src={paymentSrcFromMethod("visa")}
                                  alt={method}
                                />
                              </div>
                            ) : method === "hobex_terminal" ? (
                              <div className="flex align-items-center">
                                <PosTerminalIconSvg className="h-24 max-w-full fill-current mr-2" />
                                <Trans>Kartenzahlung am Automaten</Trans>
                              </div>
                            ) : (
                              <img
                                css={css({
                                  height: 48,
                                  width: "auto",
                                })}
                                src={paymentSrcFromMethod(method)}
                                alt={method}
                              />
                            )}
                          </div>
                        }
                      />
                    );
                  })}
                </FormControl>
                <div style={{ display: "flex" }}>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => setIsPaymentOpen(false)}
                  >
                    <LinguiMessage message={appMessages.cancel} />
                  </Button>
                  <CtaButton
                    disabled={isSubmitting}
                    type="submit"
                    style={{ marginLeft: "auto" }}
                  >
                    <LinguiMessage message={appMessages.continue} />
                  </CtaButton>
                </div>
              </Form>
            </Collapse>
          </div>
        </Paper>

        {pharmacyChannel === PharmacyChannel.pss && (
          <Button
            onClick={() => {
              resetApp({ pharmacy, pharmacyChannel });
            }}
            variant="outlined"
          >
            abbrechen / zurück
          </Button>
        )}
      </div>

      <Footer />
    </Fragment>
  );
}
