import { promiseWithError } from "@apoly-42/apoly-utils";
import axiosSetupClient from "axios";

let axiosInstance;

export const setupAxiosInstanceForApi = baseUrl => {
  axiosInstance = axiosSetupClient.create({
    baseURL: baseUrl,
  });
};

export const searchSymptomsDetailed = (
  filter,
  { limit, page, pharmacyId, medicineName, lang, onlyPssAvailable } = {},
) =>
  axiosInstance
    .get("/aut/symptoms", {
      params: {
        ...filter,
        limit,
        page,
        pharmacyId,
        medicineName,
        lang,
        onlyPssAvailable,
      },
    })
    .then(response => response.data);

export const searchAgents = (
  filter,
  { limit, page, pharmacyId, medicineName, onlyPssAvailable } = {},
) =>
  axiosInstance
    .get("/aut/agents", {
      params: {
        ...filter,
        limit,
        page,
        pharmacyId,
        medicineName,
        onlyPssAvailable,
      },
    })
    .then(response => response.data);

// TODO entfernen
export const searchDosageForms = (
  filter,
  { limit, page, pharmacyId, medicineName, onlyPssAvailable } = {},
) =>
  Promise.resolve().then(() => ({
    count: 0,
    dosageForms: [],
  }));

// TODO entfernen
export const searchDosageFormGroups = (
  filter,
  { limit, page, pharmacyId, medicineName, onlyPssAvailable } = {},
) =>
  Promise.resolve().then(() => ({
    count: 0,
    dosageFormGroups: [],
  }));

export const searchBodyRegions = (
  filter,
  { limit, page, pharmacyId, medicineName, onlyPssAvailable } = {},
) =>
  axiosInstance
    .get("/aut/bodyRegions", {
      params: {
        ...filter,
        limit,
        page,
        pharmacyId,
        medicineName,
        onlyPssAvailable,
      },
    })
    .then(response => response.data);

export function get_body_region_id_by_name(name) {
  return axiosInstance
    .get("/aut/bodyRegions/get_body_region_id_by_name", { params: { name } })
    .then(response => {
      return response.data;
    });
}

export const searchProducers = (
  filter,
  { limit, page, pharmacyId, medicineName, onlyPssAvailable } = {},
) =>
  axiosInstance
    .get("/aut/producers", {
      params: {
        ...filter,
        limit,
        page,
        pharmacyId,
        medicineName,
        onlyPssAvailable,
      },
    })
    .then(response => response.data);

export const searchProducerGroups = (
  filter,
  { limit, page, pharmacyId, medicineName, onlyPssAvailable } = {},
) =>
  axiosInstance
    .get("/aut/producerGroups", {
      params: {
        ...filter,
        limit,
        page,
        pharmacyId,
        medicineName,
        onlyPssAvailable,
      },
    })
    .then(response => response.data);

export const searchBodyFlags = (
  filter,
  { limit, page, pharmacyId, medicineName, onlyPssAvailable } = {},
) =>
  axiosInstance
    .get("/aut/bodyFlags", {
      params: {
        ...filter,
        limit,
        page,
        pharmacyId,
        medicineName,
        onlyPssAvailable,
      },
    })
    .then(response => response.data);

export const searchMedicineFlags = (
  filter,
  { limit, page, pharmacyId, medicineName, onlyPssAvailable } = {},
) =>
  axiosInstance
    .get("/aut/medicineFlags", {
      params: {
        ...filter,
        limit,
        page,
        pharmacyId,
        medicineName,
        onlyPssAvailable,
        excludeBodyFlags: 1,
      },
    })
    .then(response => response.data);

export const searchSymptomsQuickSearch = (
  name,
  { limit, orderByRating, ignoreGroups, lang, symptomIds } = {},
) =>
  axiosInstance
    .get("/aut/symptoms/quickSearch", {
      params: {
        limit,
        orderByRating,
        ignoreGroups,
        lang,
        symptomIds,
        name,
      },
    })
    .then(response => response.data);

// TODO müsste man für AUT nochwas anpassen dann
export const loadDefaultPharmacy = zip =>
  axiosInstance
    .get("/pharmacies", {
      params: {
        nearestOrDefault: true,
        zip,
      },
    })
    .then(response => response.data[0]);

export const loadMedicineFromUrlCode = urlCode =>
  axiosInstance
    .get(`/aut/medicines/urlCode=${urlCode}`)
    .then(response => response.data);

export const searchArticlesForPriceComparison = (
  { medicineId, packageId },
  { limit, page } = {},
) =>
  axiosInstance
    .get("/aut/articles", {
      params: {
        medicineId,
        limit,
        page,
        packageId,
      },
    })
    .then(response => response.data);

export const searchArticles = (
  pharmacyId,
  {
    filters,
    medicineName,
    medicineUrlCode,
    medicinePage,
    medicineLimit,
    articleIds,
    bodyRegionNames,
    onlyPssAvailable,
  } = {},
) =>
  axiosInstance
    .get(`/aut/pharmacies/${pharmacyId}/articles`, {
      params: {
        medicineName,
        orderByRating: 1,
        ...filters,
        medicineUrlCode,
        medicinePage,
        medicineLimit,
        articleIds,
        bodyRegionNames,
        onlyPssAvailable,
      },
    })
    .then(response => response.data);

export const getMoreInformationAboutProduct = medicineId =>
  axiosInstance
    .get(`/aut/medicines/${medicineId}/information`)
    .then(response => response.data);

export const getPackageInsertsForProduct = packageId =>
  axiosInstance
    .post(`/aut/packages/${packageId}/packageInsertLink`)
    .then(response => response.data);

export const getPackageLmivForProduct = packageId =>
  axiosInstance
    .post(`/aut/packages/${packageId}/lmivLink`)
    .then(response => response.data);

export const getPackageBiocidalLink = packageId =>
  axiosInstance
    .post(`/aut/packages/${packageId}/biocidalLink`)
    .then(response => response.data);

export const loadPharmacy = pharmacyId =>
  axiosInstance
    .get(`/pharmacies/${pharmacyId}`)
    .then(response => response.data);

export const getPharmacyLegalData = pharmacyId =>
  axiosInstance
    .get(`/pharmacies/${pharmacyId}/legalData`)
    .then(response => response.data);

export const loadPharmacyAuthority = pharmacyId =>
  axiosInstance
    .get(`/pharmacies/${pharmacyId}/authoriy`)
    .then(response => response.data);

export const loadPharmacyAssociation = pharmacyId =>
  axiosInstance
    .get(`/pharmacies/${pharmacyId}/association`)
    .then(response => response.data);

export const loadPharmacyFromUrlCode = pharmacyUrlCode =>
  axiosInstance
    .get(`/pharmacies/urlCode=${pharmacyUrlCode}`)
    .then(response => response.data);

export const loadNoPartnerPharmacyFromUrlCode = noPartnerPharmacyUrlCode =>
  axiosInstance
    .get(`/noPartnerPharmacies/urlCode=${noPartnerPharmacyUrlCode}`)
    .then(response => response.data);

// TODO muss man was anpassen für AUT dann
export const searchPharmaciesByZip = zip =>
  axiosInstance
    .get("/pharmacies", { params: { zip } })
    .then(response => response.data);

export const searchPharmaciesHolidays = ({ pharmacyIds, date, until }) =>
  axiosInstance
    .get("/pharmaciesHolidays", {
      params: {
        pharmacyIds,
        date,
        until,
      },
    })
    .then(response => response.data);

// TODO muss man was anpassen für AUT dann
export const searchNoPartnerPharmaciesByZip = zip =>
  axiosInstance
    .get("/noPartnerPharmacies", { params: { zip } })
    .then(response => response.data);

// TODO das gibt es nicht für aut
export const loadArticlesOrderedByRating = (
  pharmacyId,
  { onlyPssAvailable = 0 } = {},
) =>
  axiosInstance
    .get(`/aut/pharmacies/${pharmacyId}/articles`, {
      params: {
        orderByRating: 1,
        medicineLimit: 5,
        onlyPssAvailable,
      },
    })
    .then(response => response.data);

export const loadTopOffersForPharmacy = (
  pharmacyId,
  { onlyPssAvailable = 0 } = {},
) =>
  axiosInstance
    .get(`/aut/pharmacies/${pharmacyId}/articles`, {
      params: {
        onlySpecialOffers: 1,
        onlyPssAvailable,
        medicineLimit: 30,
      },
    })
    .then(response => response.data);

export const createRxOrder = rxOrder =>
  axiosInstance.post("/rxOrders", rxOrder).then(response => response.data);

export const uploadRxOrderImage = (rxOrderKey, formData) =>
  axiosInstance
    .post(`/rxOrders/rxOrderKey=${rxOrderKey}/images`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(response => response.data);

export const executeRxOrder = rxOrderKey =>
  axiosInstance
    .post(`/rxOrders/rxOrderKey=${rxOrderKey}/execute`)
    .then(response => response.data);

export const createOtcOrder = otcOrder =>
  axiosInstance
    .post("/otcOrders", { shopCountry: "AUT", ...otcOrder })
    .then(response => response.data);

export function newHobexTerminalPaymentId(otcOrderKey) {
  return axiosInstance
    .post(`/otcOrders/otcOrderKey=${otcOrderKey}/new_hobex_terminal_payment`)
    .then(response => response.data);
}

export function initiateHobexTerminalPayment(otcOrderKey, hobexPaymentId) {
  return axiosInstance
    .post(
      `/otcOrders/otcOrderKey=${otcOrderKey}/init_hobex_terminal_payment/${hobexPaymentId}`,
    )
    .then(response => response.data);
}

export function getHobexTerminalPaymentState(otcOrderKey, hobexPaymentId) {
  return axiosInstance
    .get(
      `/otcOrders/otcOrderKey=${otcOrderKey}/get_hobex_terminal_state/${hobexPaymentId}`,
    )
    .then(response => response.data);
}

export const wirecardPaymentRequest = (
  otcOrderKey,
  finishedPaymentRedirectUrl,
  failedPaymentRedirectUrl,
) =>
  axiosInstance
    .post(`/otcOrders/otcOrderKey=${otcOrderKey}/wirecardPaymentRequest`, {
      finishedPaymentRedirectUrl,
      failedPaymentRedirectUrl,
    })
    .then(response => response.data);

// TODO noch nicht implementiert
export const loadPageCategoryFromUrlCode = urlCode =>
  axiosInstance.get(`/categories/${urlCode}`).then(response => response.data);

export const getOtcOrderBaseInfo = otcOrderKey =>
  axiosInstance
    .get(`/otcOrders/otcOrderKey=${otcOrderKey}`)
    .then(response => response.data);

export const getSentMailsForOtcOrder = otcOrderKey =>
  axiosInstance
    .get(`/otcOrders/otcOrderKey=${otcOrderKey}/mails`)
    .then(response => response.data);

export const sentMailForOtcOrder = (otcOrderKey, otcOrderMailType) =>
  axiosInstance
    .post(`/otcOrders/otcOrderKey=${otcOrderKey}/mails`, {
      mailType: otcOrderMailType,
    })
    .then(response => response.data);

export const getStatusForOtcOrder = otcOrderKey =>
  axiosInstance
    .get(`/otcOrders/otcOrderKey=${otcOrderKey}/otcOrderHistories`)
    .then(response => response.data);

export const getSentMailsForRxOrder = rxOrderKey =>
  axiosInstance
    .get(`/rxOrders/rxOrderKey=${rxOrderKey}/mails`)
    .then(response => response.data);

export const sentMailForRxOrder = (rxOrderKey, rxOrderMailType) =>
  axiosInstance
    .post(`/rxOrders/rxOrderKey=${rxOrderKey}/mails`, {
      mailType: rxOrderMailType,
    })
    .then(response => response.data);

export const getStatusForRxOrder = rxOrderKey =>
  axiosInstance
    .get(`/rxOrders/rxOrderKey=${rxOrderKey}/rxOrderHistories`)
    .then(response => response.data);

export const addMailToNewsletterDsipatcher = () =>
  promiseWithError("mailAddedToNewsletter").then(response => response.data);

export const updatedDeliveryAddressFromUser = (userId, newDeliveryAddress) =>
  promiseWithError(newDeliveryAddress).then(response => response.data);

export const getServerTime = () => axiosInstance.post("/serverTime");

// test-call to user-api with Authorization-token
const toAuthToken = token => ({ Authorization: `Bearer ${token}` });

export const createRxOrderForUser = (userToken, userId, rxOrder) =>
  axiosInstance
    .post(`/users/${userId}/rxOrders`, rxOrder, {
      headers: toAuthToken(userToken),
    })
    .then(response => response.data);

export const getRxOrdersForUser = (
  userToken,
  userId,
  { page, limit, from, to },
) =>
  axiosInstance
    .get(`/users/${userId}/rxOrders`, {
      headers: toAuthToken(userToken),
      params: {
        page,
        limit,
        from,
        to,
      },
    })
    .then(response => response.data);

export const getRxOrderByIdForUser = (userToken, userId, rxOrderId) =>
  axiosInstance
    .get(`/users/${userId}/rxOrders/${rxOrderId}`, {
      headers: toAuthToken(userToken),
    })
    .then(response => response.data);

export const uploadRxOrderImageForUser = (
  userToken,
  userId,
  rxOrderId,
  formData,
) =>
  axiosInstance
    .post(`/users/${userId}/rxOrders/${rxOrderId}/images`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...toAuthToken(userToken),
      },
    })
    .then(response => response.data);

export const executeRxOrderForUser = (userToken, userId, rxOrderId) =>
  axiosInstance
    .post(`/users/${userId}/rxOrders/${rxOrderId}/execute`, null, {
      headers: toAuthToken(userToken),
    })
    .then(response => response.data);

export const sentMailForRxOrderForUser = (
  userToken,
  userId,
  rxOrderId,
  rxOrderMailType,
) =>
  axiosInstance
    .post(
      `/users/${userId}/rxOrders/${rxOrderId}/mails`,
      { mailType: rxOrderMailType },
      {
        headers: toAuthToken(userToken),
      },
    )
    .then(response => response.data);

export const createOtcOrderForUser = (userToken, userId, otcOrder) =>
  axiosInstance
    .post(
      `/users/${userId}/otcOrders`,
      { shopCountry: "AUT", ...otcOrder },
      {
        headers: toAuthToken(userToken),
      },
    )
    .then(response => response.data);

export const getOtcOrdersForUser = (
  userToken,
  userId,
  { page, limit, from, to },
) =>
  axiosInstance
    .get(`/users/${userId}/otcOrders`, {
      headers: toAuthToken(userToken),
      params: {
        page,
        limit,
        from,
        to,
      },
    })
    .then(response => response.data);

export const getOtcOrderByIdForUser = (userToken, userId, otcOrderId) =>
  axiosInstance
    .get(`/users/${userId}/otcOrders/${otcOrderId}`, {
      headers: toAuthToken(userToken),
    })
    .then(response => response.data);

export function newHobexTerminalPaymentIdForUser(
  userToken,
  userId,
  otcOrderId,
) {
  return axiosInstance
    .post(`/users/${userId}/otcOrders/${otcOrderId}/new_hobex_terminal_payment`)
    .then(response => response.data);
}

export function initiateHobexTerminalPaymentForUser(
  userToken,
  userId,
  otcOrderId,
  hobexPaymentId,
) {
  return axiosInstance
    .post(
      `/users/${userId}/otcOrders/${otcOrderId}/init_hobex_terminal_payment/${hobexPaymentId}`,
      {
        headers: toAuthToken(userToken),
      },
    )
    .then(response => response.data);
}

export function getHobexTerminalPaymentStateForUser(
  userToken,
  userId,
  otcOrderId,
  hobexPaymentId,
) {
  return axiosInstance
    .get(
      `/users/${userId}/otcOrders/${otcOrderId}/get_hobex_terminal_state/${hobexPaymentId}`,
      {
        headers: toAuthToken(userToken),
      },
    )
    .then(response => response.data);
}

export function wirecardPaymentRequestForUser(
  userToken,
  userId,
  otcOrderId,
  finishedPaymentRedirectUrl,
  failedPaymentRedirectUrl,
) {
  return axiosInstance
    .post(
      `/users/${userId}/otcOrders/${otcOrderId}/wirecardPaymentRequest`,
      {
        finishedPaymentRedirectUrl,
        failedPaymentRedirectUrl,
      },
      { headers: toAuthToken(userToken) },
    )
    .then(response => response.data);
}

export const sentMailForOtcOrderForUser = (
  userToken,
  userId,
  otcOrderId,
  otcOrderMailType,
) =>
  axiosInstance
    .post(
      `/users/${userId}/otcOrders/${otcOrderId}/mails`,
      { mailType: otcOrderMailType },
      {
        headers: toAuthToken(userToken),
      },
    )
    .then(response => response.data);

export const sendContactEmail = ({ email, name, message }) =>
  axiosInstance
    .post("/contactEmails", {
      email,
      name,
      message,
    })
    .then(response => response.data);

export const dsgvoLogging = type =>
  axiosInstance.post("/dsgvoLogging", {
    type,
    cookies: document.cookie,
  });

export const symptomsForRegion = ({
  region,
  limit,
  page,
  lang,
  regionId,
  ...other
}) =>
  axiosInstance
    .get("/aut/bodyRegions/symptomsForRegion", {
      params: {
        limit,
        page,
        region,
        regionId,
        lang,
        ...other,
      },
    })
    .then(response => response.data);
