import { faMinusSquare, faPlusSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from '@lingui/macro';
import {
  Typography,
  Collapse,
  IconButton,
  Input,
  InputAdornment,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { linguiEq } from '../../../../../../app-utilities/utils';
import { useIsPssChannel } from '../../../../../../app/routes-helper';

const styles = {
  inputAmount: {
    color: "black",
    alignItems: "center",
    "& > *": {
      textAlign: "center",
    },
  },
};

function AddRemoveButtonGroup({
  article,
  classes,
  fullWidth,
  addFunc,
  removeFunc,
  minusDisabled,
  value,
  willBeRemoved,
  usePssAvailability = false
}) {
  const isPss = useIsPssChannel();
  const availability = isPss || usePssAvailability ? article.pss_availability : article.availability;
  // berechne korrekten stock, da available und co aktuell nicht immer so sicher korrekt sind:
  let stock = isPss ? article.pss_stock : article.stock;
  if (stock === "" || stock === null || stock === undefined) {
    stock = 0;
  }
  stock = Number(stock);
  if (stock <= 0 && availability === "available") {
    stock = 1;
  }

  const isPlusDisabled = value >= stock;

  useEffect(() => {
    // falls er doch mehrere hinzugefügt hat, werden die entfernt:
    if (value > stock) {
      removeFunc();
    }
  }, [value, stock]);

  return (
    <div
      style={{
        margin: "auto",
        width: fullWidth ? "100%" : "70%",
      }}
    >
      <Input
        fullWidth
        startAdornment={
          <InputAdornment position="start">
            <IconButton
              color="primary"
              onClick={removeFunc}
              disabled={willBeRemoved || minusDisabled}
            >
              <FontAwesomeIcon icon={faMinusSquare} />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              color="primary"
              onClick={addFunc}
              disabled={willBeRemoved || isPlusDisabled}
            >
              <FontAwesomeIcon icon={faPlusSquare} />
            </IconButton>
          </InputAdornment>
        }
        className={classes.inputAmount}
        value={value}
        disabled
        style={{
          textDecoration: willBeRemoved ? "line-through" : undefined,
        }}
        disableUnderline
      />
      <Collapse in={isPlusDisabled}>
        <Typography color="error" align="center">
          <Trans>maximal {linguiEq(stock)} verfügbar</Trans>
        </Typography>
      </Collapse>
    </div>
  );
}

AddRemoveButtonGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  addFunc: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  minusDisabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  willBeRemoved: PropTypes.bool,
};

AddRemoveButtonGroup.defaultProps = {
  minusDisabled: false,
  fullWidth: true,
};

export default withStyles(styles)(AddRemoveButtonGroup);
