import {
  REACT_APP_ENVIRONMENT,
  REACT_APP_RELEASE,
  REACT_APP_SENTRY_DSN,
} from "../../../environment";

async function loadSentry() {
  const Sentry = await import("@sentry/browser");

  Sentry.init({
    dsn:
      process.env.NODE_ENV === "production" ? REACT_APP_SENTRY_DSN : undefined,
    release: REACT_APP_RELEASE,
    environment: REACT_APP_ENVIRONMENT,
  });

  return Sentry;
}

let _SentryPromise: Promise<typeof import("@sentry/browser")>;
function getSentry(): Promise<typeof import("@sentry/browser")> {
  if (!_SentryPromise) {
    _SentryPromise = loadSentry();
  }

  return _SentryPromise;
}

export async function captureException(
  exception: any,
  info?: { [key: string]: any },
) {
  const Sentry = await getSentry();

  if (info) {
    Sentry.withScope(scope => {
      Object.keys(info).forEach(key => {
        scope.setExtra(key, info[key]);
      });
    });
  }

  return Sentry.captureException(exception);
}
