import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  iconLeft?: IconProp;
  iconRight?: IconProp;
}

export function TextWithIcon({
  style,
  children,
  iconRight,
  iconLeft,
  ...props
}: Props) {
  return (
    <span
      style={{ display: "flex", alignItems: "center", ...style }}
      {...props}
    >
      {iconLeft && (
        <span style={{ fontSize: 16, marginRight: 8 }}>
          <FontAwesomeIcon fixedWidth={true} icon={iconLeft} />
        </span>
      )}
      <span style={{ flex: 1 }}>{children}</span>
      {iconRight && (
        <span style={{ fontSize: 16, marginLeft: 8 }}>
          <FontAwesomeIcon fixedWidth={true} icon={iconRight} />
        </span>
      )}
    </span>
  );
}
