import { createMuiTheme, Theme } from "@material-ui/core";
import * as React from "react";
import { PharmacyTheme } from "../../app-theme/pharmacy-theme/PharmacyThemeInterface";
import { Languages, LanguageValues } from "../../languages/LanguageContext";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset, ThemeProvider } from "@material-ui/styles";

// Configure JSS
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
  insertionPoint: document.head.firstChild as HTMLElement,
});

// Custom Material-UI class name generator.

interface AppMuiThemeProps {
  palette: PharmacyTheme["palette"];
  type?: Theme["palette"]["type"];
}

type CreateAppMuiThemeProps = AppMuiThemeProps & {
  direction: Theme["direction"];
};

export const createAppMuiTheme = ({
  direction,
  type = "light",
  palette,
}: CreateAppMuiThemeProps) =>
  createMuiTheme({
    palette: {
      primary: palette.primary,
      secondary: palette.secondary,
      type,
    },
    direction,
  });

class InnerAppMuiTheme extends React.PureComponent<CreateAppMuiThemeProps> {
  componentDidMount() {
    document.body.dir = this.props.direction;
  }

  componentDidUpdate() {
    document.body.dir = this.props.direction;
  }

  render() {
    return (
      <StylesProvider jss={jss}>
        <ThemeProvider theme={createAppMuiTheme(this.props)}>
          {this.props.children}
        </ThemeProvider>
      </StylesProvider>
    );
  }
}

export const AppMuiTheme: React.SFC<AppMuiThemeProps> = props => (
  <LanguageValues>
    {langCtx => (
      <InnerAppMuiTheme
        {...props}
        direction={langCtx.language === Languages.ar ? "rtl" : "ltr"}
      />
    )}
  </LanguageValues>
);
