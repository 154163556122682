import { ApolyPageLoadable } from "../ApolyPageLoadable";

export const LoadSignUpOrContinueAsGuest = ApolyPageLoadable({
  loader: () =>
    import("./checkout/loginOrContinueAsGuest/SignUpOrContinueAsGuest"),
});

export const LoadLoginOrContinueAsGuest = ApolyPageLoadable({
  loader: () =>
    import("./checkout/loginOrContinueAsGuest/LoginOrContinueAsGuest"),
});

export const LoadAboutUsPage = ApolyPageLoadable({
  loader: () => import("./aboutUs/AboutUsPage"),
});

export const LoadCheckoutPage = ApolyPageLoadable({
  loader: () => import("./checkout/CheckoutPage"),
});

export const LoadChoosePharmacyPage = ApolyPageLoadable({
  loader: () => import("./choosePharmacy/ChoosePharmacyPage"),
});

export const LoadContactPage = ApolyPageLoadable({
  loader: () => import("./contact/ContactPage"),
});

export const LoadDataSecurityPage = ApolyPageLoadable({
  loader: () => import("./dataSecurity/DataSecurityPage"),
});

export const LoadDeliveryCostsPage = ApolyPageLoadable({
  loader: () => import("./deliveryCosts/DeliveryCostsPage"),
});

export const LoadFaqPage = ApolyPageLoadable({
  loader: () => import("./faq/FaqPage"),
});

export const LoadImprintPage = ApolyPageLoadable({
  loader: () => import("./imprint/ImprintPage"),
});

export const LoadJobsPage = ApolyPageLoadable({
  loader: () => import("./jobs/JobsPage"),
});

export const LoadLandingPage = ApolyPageLoadable({
  loader: () => import("./landing/LandingPage"),
});

export const LoadLoggedOutPage = ApolyPageLoadable({
  loader: () => import("./loggedOut/LoggedOutPage"),
});

export const LoadLoginPage = ApolyPageLoadable({
  loader: () => import("./loginRegister/LoginPage"),
});

export const LoadRegisterPage = ApolyPageLoadable({
  loader: () => import("./loginRegister/RegisterPage"),
});

export const LoadMedicineSeoPage = ApolyPageLoadable({
  loader: () => import("./medicineSeo/MedicineSeoPage"),
});

export const LoadMedicSearch = ApolyPageLoadable({
  loader: () => import("./medicSearch/MedicSearch"),
});

export const LoadNoPartnerPharmacySeoPage = ApolyPageLoadable({
  loader: () => import("./noPartnerPharmacySeo/NoPartnerPharmacySeoPage"),
});

export const LoadSubmitContactFormSuccessfulPage = ApolyPageLoadable({
  loader: () =>
    import(
      "./noPartnerPharmacySeoContactFormSubmitted/SubmitContactFormSuccessfulPage"
    ),
});

export const LoadOtcOrderCompletePage = ApolyPageLoadable({
  loader: () => import("./orderComplete/otcOrderComplete/OtcOrderCompletePage"),
});

export const LoadRxOrderCompletePage = ApolyPageLoadable({
  loader: () => import("./orderComplete/rxOrderComplete/RxOrderCompletePage"),
});

export const LoadPartnerPharmacyPage = ApolyPageLoadable({
  loader: () => import("./partnerPharmacy/PartnerPharmacyPage"),
});

export const LoadOtcPaymentFailedPage = ApolyPageLoadable({
  loader: () => import("./paymentFailed/OtcPaymentFailedPage"),
});

export const LoadPharmacySeoPage = ApolyPageLoadable({
  loader: () => import("./pharmacySeo/PharmacySeoPage"),
});

export const LoadPressPage = ApolyPageLoadable({
  loader: () => import("./press/PressPage"),
});

export const LoadProductPage = ApolyPageLoadable({
  loader: () => import("./product/ProductPage"),
});

export const LoadRegionFinderPage = ApolyPageLoadable({
  loader: () => import("./regionFinder/RegionFinderPage"),
});

export const LoadShoppingCartPage = ApolyPageLoadable({
  loader: () => import("./shoppingCart/ShoppingCartPage"),
});

export const LoadSubmitPrescriptionPage = ApolyPageLoadable({
  loader: () => import("./submitPrescription/SubmitPrescriptionPage"),
});

export const LoadUserPages = ApolyPageLoadable({
  loader: () => import("./userSubPages/UserPages"),
});
