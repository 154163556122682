import { createAction, createReducer } from "@apoly-42/apoly-utils";
import { compose, prop } from "ramda";
import initialLoadingState from "../../utilities/redux/initialLoadingState";
import { selectAppStateRoot } from "../selectors";

export const setServerTimeError = createAction("setError");

const selectServerTimeLoadingState = compose(
  prop("serverTimeLoadingState"),
  selectAppStateRoot,
);

export const selectServerTimeError = compose(
  prop("error"),
  selectServerTimeLoadingState,
);

export default createReducer(initialLoadingState, {
  [setServerTimeError]: (state, { payload }) => ({ ...state, error: payload }),
});
