import { LinguiMessage } from "@apoly-42/apoly-components";
import { Grid } from "@material-ui/core";
import { css } from "@emotion/core";
import { equals } from "ramda";
import React from "react";

import {
  blackWhiteFilter,
  flexAlignStart,
} from "../../../app-utilities/cssClasses";
import { cond, throwF } from "../../../app-utilities/fn-utils";
import { BRAND_NAME } from "../../../environment";
import cash from "./assets/bar-w.png";
import mastercard from "./assets/mastercard-w.png";
import paypal from "./assets/paypal-w.png";
import sepa from "./assets/sepa-w.png";
import sofort from "./assets/sofort-ueberweisung-w.png";
import visa from "./assets/visa-w.png";
import { FooterHeadline } from "./FooterHeadline";

const getPaymentMethodImageForFooter = cond<string, string>(
  [
    [equals("cash"), () => cash],
    [equals("sofort"), () => sofort],
    [equals("visa"), () => visa],
    [equals("sepa"), () => sepa],
    [equals("mastercard"), () => mastercard],
    [equals("paypal"), () => paypal],
  ],
  throwF(val => `unknown payment ${val}`),
);

const image = css`
  max-width: 100%;
  margin: auto;
  ${blackWhiteFilter};
`;

const messages = {
  possiblePaymentMethods: {
    id: "possiblePaymentMethods",
    message: "possible payment Methods at {brand}",
  },
};

const possiblePaymentMethods = [
  "cash",
  "sofort",
  "visa",
  "sepa",
  "mastercard",
  "paypal",
];

export const PossiblePaymentMethods: React.SFC = () => (
  <Grid container={true} spacing={2}>
    <Grid item={true} xs={12}>
      <FooterHeadline>
        <LinguiMessage
          message={messages.possiblePaymentMethods}
          values={{ brand: BRAND_NAME }}
        />
      </FooterHeadline>
    </Grid>
    <Grid item={true} xs={12}>
      <Grid container={true} spacing={2}>
        {possiblePaymentMethods.map(paymentMethod => (
          <Grid item={true} xs={3} key={paymentMethod} css={flexAlignStart}>
            <img
              css={image}
              alt={paymentMethod}
              src={getPaymentMethodImageForFooter(paymentMethod)}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
);
