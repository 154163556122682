import { LinguiMessage } from "@apoly-42/apoly-components";
import { ButtonWithIcon } from "@apoly-42/material-ui";
import { faChevronDoubleLeft } from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { commonMessages } from "../constants/messages/commonMessages";
import { selectBasePath } from "../redux/shopPharmacy/reducer";
import { landingPath, partnerPharmacyPath } from "../routes/paths";

const InnerReturnToHomeButton = ({ basePath }) => (
  <Grid container>
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <ButtonWithIcon
        fullWidth
        icon={faChevronDoubleLeft}
        color="primary"
        variant="contained"
        component={Link}
        to={basePath ? partnerPharmacyPath(basePath) : landingPath()}
      >
        <LinguiMessage message={commonMessages.backToLandingPage} />
      </ButtonWithIcon>
    </Grid>
  </Grid>
);

const mapStateToProps = state => ({
  basePath: selectBasePath(state),
});

const enhance = compose(connect(mapStateToProps));

const ReturnToHomeButton = enhance(InnerReturnToHomeButton);

InnerReturnToHomeButton.propTypes = {
  basePath: PropTypes.string,
};

InnerReturnToHomeButton.defaultProps = {
  basePath: null,
};

export default ReturnToHomeButton;
