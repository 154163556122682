import {
  GridContainer,
  GridRow,
  HeadlineWithDivider,
} from "@apoly-42/material-ui";
import { equals } from "ramda";
import * as React from "react";

import { AppHelmetI18n } from "../../../app-util-components/AppHelmetI18n";
import { FluidTypography } from "../../../app-util-components/FluidTypography";
import { LinguiMessage } from "../../../app-util-components/LinguiMessage";
import { both, first } from "../../../app-utilities/fn-utils";
import { commonMessages } from "../../../deprecated/deprecated-apoly-app/constants/messages/commonMessages";
import { Footer } from "../../layout/Footer";
import { NavBar } from "../../layout/NavBar";
import { PageContentContainer } from "../../PageContentContainer";
import { PharmacyValuesConsumer } from "../../PharmacyContext";
import { PharmacyChannel } from "../../routes-helper";
import { CheckoutLocalOrder } from "./checkoutDefault/CheckouLocalOrder";
import { CheckoutMailOrder } from "./checkoutDefault/CheckoutMailOrder";
import {
  CheckoutOffizin,
  CompletedCheckoutOffizin,
} from "./checkoutOffizin/CheckoutOffizin";
import { CheckoutPss, CompletedCheckoutPss } from "./checkoutPss/CheckoutPss";
import { CheckoutSubmitOrder } from "./CheckoutSubmitOrder";
import {
  CheckoutPssStep,
  CheckoutValues,
  CompletedCheckoutDefault,
} from "./checkoutTypes";
import { DeliveryType } from "./OtcOrderTypes";

const messages = {
  titleCheckout: {
    id: "titleCheckout",
    message: "Einkauf bei Ihrer Apotheke abschließen",
  },
};

interface CheckoutProps {
  deliveryType: DeliveryType;
  isOffizin?: boolean;
}

export class Checkout extends React.PureComponent<
  CheckoutProps,
  {
    checkoutValues: CheckoutValues | undefined;
    pssStep: CheckoutPssStep | undefined;
  }
> {
  state = {
    checkoutValues: undefined,
    pssStep: undefined,
  };

  handleCheckoutDefaultSubmit = (vals: CompletedCheckoutDefault) =>
    this.setState({
      checkoutValues: { ...vals, channel: PharmacyChannel.local },
    });

  handlePssSubmit = (vals: CompletedCheckoutPss) =>
    this.setState({
      checkoutValues: { ...vals, channel: PharmacyChannel.pss },
    });

  handleOffizinSubmit = (vals: CompletedCheckoutOffizin) =>
    this.setState({
      checkoutValues: { ...vals, channel: PharmacyChannel.customerBoard },
    });

  renderCheckout = first<PharmacyChannel, React.ReactNode>([
    both(equals(PharmacyChannel.pss), () => (
      <CheckoutPss
        onSubmit={this.handlePssSubmit}
        pssStep={this.state.pssStep}
      />
    )),
    both(equals(PharmacyChannel.customerBoard), () => (
      <CheckoutOffizin onSubmit={this.handleOffizinSubmit} />
    )),
    both(equals(PharmacyChannel.local), () => (
      <CheckoutLocalOrder
        deliveryType={this.props.deliveryType}
        onSubmit={this.handleCheckoutDefaultSubmit}
      />
    )),
    both(equals(PharmacyChannel.mailOrder), () => (
      <CheckoutMailOrder
        deliveryType={DeliveryType.mailOrder}
        onSubmit={this.handleCheckoutDefaultSubmit}
      />
    )),
  ]);

  render() {
    return (
      <PharmacyValuesConsumer>
        {({ pharmacyChannel }) => (
          <React.Fragment>
            <AppHelmetI18n pageTitleMessage={messages.titleCheckout} />
            <NavBar />
            <PageContentContainer>
              <GridContainer
                style={{ marginBottom: 16 }}
                withNoScrollPaddingWorkaround
                justify="center"
              >
                <GridRow item xs={12} lg={10}>
                  {this.props.isOffizin ? (
                    <HeadlineWithDivider
                      responsive
                      align="left"
                      headlineType="h1"
                    >
                      <LinguiMessage message={commonMessages.shoppingCart} />
                    </HeadlineWithDivider>
                  ) : (
                    <FluidTypography type="headline1" paragraph={true}>
                      <LinguiMessage message={commonMessages.finishPayment} />
                    </FluidTypography>
                  )}
                </GridRow>
                <GridRow item xs={12} lg={10}>
                  {pharmacyChannel && this.renderCheckout(pharmacyChannel)}
                </GridRow>
              </GridContainer>
            </PageContentContainer>

            {this.state.checkoutValues && (
              <CheckoutSubmitOrder
                checkoutValues={this.state.checkoutValues}
                onAbort={() => {
                  this.setState({
                    checkoutValues: undefined,
                    pssStep: CheckoutPssStep.payment,
                  });
                }}
              />
            )}

            <Footer />
          </React.Fragment>
        )}
      </PharmacyValuesConsumer>
    );
  }
}
