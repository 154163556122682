import { Grid } from "@material-ui/core";
import React from "react";
import { LinguiMessage } from "../../../app-util-components/LinguiMessage";
import { UrlList } from "../../../app-util-components/UrlList";
import { appMessages } from "../../../app-utilities/appMessages";
import {
  PharmacyContextValues,
  PharmacyValuesConsumer,
} from "../../PharmacyContext";
import { contactPathSetPharmacy, faqPath, pharmacyBase } from "../../routes";
import { pharmacyCtxToPath } from "../PharmacyBaseLink";
import { FooterHeadline } from "./FooterHeadline";

const getLinkListArray = (pharmacyCtx: PharmacyContextValues) => [
  {
    text: <LinguiMessage message={appMessages.linkToLandingPage} />,
    url: pharmacyCtxToPath(pharmacyBase, pharmacyCtx),
  },
  {
    text: <LinguiMessage message={appMessages.faq} />,
    url: pharmacyCtxToPath(faqPath, pharmacyCtx),
  },
  {
    text: <LinguiMessage message={appMessages.contact} />,
    url: pharmacyCtxToPath(contactPathSetPharmacy, pharmacyCtx),
  },
  // aktuell nur für veritas => da mu
  /*{
    text: <LinguiMessage message={appMessages.pressAndPartners} />,
    url: pharmacyCtxToPath(pressPath, pharmacyCtx),
  },
  {
    text: (
      <LinguiMessage
        message={appMessages.jobsAtBrand}
        values={{ brand: BRAND_NAME }}
      />
    ),
    url: pharmacyCtxToPath(jobsPath, pharmacyCtx),
  },*/
];

export const MoreInformation: React.SFC = () => (
  <Grid container={true} spacing={2}>
    <Grid item={true} xs={12}>
      <FooterHeadline>
        <LinguiMessage message={appMessages.moreInformation} />
      </FooterHeadline>
    </Grid>
    <PharmacyValuesConsumer>
      {pharmacyCtx => (
        <Grid item={true} xs={12} style={{ color: "#fff" }}>
          <UrlList linkList={getLinkListArray(pharmacyCtx)} />
        </Grid>
      )}
    </PharmacyValuesConsumer>
  </Grid>
);
