import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import {
  faCamera,
  faEnvelope,
  faHome,
  faHospital,
  faInfoCircle,
  faSearch,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Theme,
  Toolbar,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { css } from "@emotion/core";
import React from "react";
import { LinguiMessage } from "../../../../app-util-components/LinguiMessage";
import { appMessages } from "../../../../app-utilities/appMessages";
import apolyLogoColorNoClaimPng from "../../../../assets/apoly/apoly-logo-color-no-claim.png";
import { CtxPathLink } from "../../../../url-utils";
import { Pharmacy } from "../../../apolyApi";
import { PharmacyContextValues } from "../../../PharmacyContext";
import {
  contactPathSetPharmacy,
  faqPath,
  pharmacyBase,
  submitPrescriptionPath,
} from "../../../routes";
import { PharmacyChannel } from "../../../routes-helper";
import { pharmacyCtxToPath } from "../../PharmacyBaseLink";
import { ChangeLocaleDrawer } from "./drawerComponents/ChangeLocaleDrawer";
import { CloseDrawerButton } from "./drawerComponents/CloseDrawerButton";
import { ListItemButtonWithIcon } from "./ListItemButtonWithIcon";
import { ListItemLinkWithIcon } from "./ListItemLinkWithIcon";
import { PaymentMethods } from "./PaymentMethods";

export const pharmacyDrawerMessages = {
  changePharmacy: {
    id: "changePharmacy",
    message: "change pharmacy",
  },
  searchForMedicine: {
    id: "searchForMedicine",
    message: "search for medicine",
  },
  paymentMethods: {
    id: "paymentMethods",
    message: "payment methods",
  },
};

export interface RightDrawerProps {
  pharmacy: Pharmacy;
  open: boolean;
  handleDrawer: () => void;
  toggleSearchBarAndDrawer: () => void;
  pharmacyCtx: PharmacyContextValues;
}

const drawer = css`
  min-width: 250px;
  max-width: 300px;
`;

export const RightDrawer: React.SFC<RightDrawerProps> = ({
  pharmacy,
  open,
  toggleSearchBarAndDrawer,
  handleDrawer,
  pharmacyCtx,
}) => {
  const theme = useTheme<Theme>();

  return (
    <Drawer anchor="right" open={open} onClose={handleDrawer}>
      <div tabIndex={0} role="button" onKeyDown={handleDrawer}>
        <AppBar position="static" color="primary">
          <Toolbar
            css={css`
              width: 100%;
              display: grid;
              align-items: center;
              justify-items: center;
              grid-gap: 16px;
              grid-template-columns: minmax(0, 1fr) min-content;
            `}
          >
            <img
              css={css`
                max-height: 40px;
              `}
              alt="Apoly Logo"
              src={apolyLogoColorNoClaimPng}
            />
            <IconButton onClick={handleDrawer}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  height: 16,
                  width: 16,
                  color: theme.palette.primary.contrastText,
                }}
              />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List css={drawer}>
          <div>
            <ListItemLinkWithIcon
              text={pharmacy.name}
              icon={faHospital}
              linkComponent={compPops => (
                <CtxPathLink
                  {...compPops}
                  path={pharmacyCtxToPath(pharmacyBase, pharmacyCtx)}
                />
              )}
            />
            <ListItem>
              <ListItemText
                inset={true}
                primary={
                  <LinguiMessage
                    message={pharmacyDrawerMessages.paymentMethods}
                  />
                }
              />
            </ListItem>
            <ListItem divider={true}>
              {pharmacy && (
                <ListItemText
                  primary={
                    <PaymentMethods
                      availablePaymentMethods={pharmacy.paymentMethods}
                    />
                  }
                />
              )}
            </ListItem>
          </div>
          <ListItemLinkWithIcon
            text={<LinguiMessage message={appMessages.home} />}
            icon={faHome}
            linkComponent={compPops => (
              <CtxPathLink
                {...compPops}
                path={pharmacyCtxToPath(pharmacyBase, pharmacyCtx)}
              />
            )}
          />
          {/* gibts vorerst nicht, deswegen erstmal weg
        <ListItemLinkWithIcon
          text={<LinguiMessage message={pharmacyDrawerMessages.changePharmacy} />}
          icon={faRetweet}
          linkProp={'find-pharmacy'}
        />*/}
          <ListItemButtonWithIcon
            text={
              <LinguiMessage
                message={pharmacyDrawerMessages.searchForMedicine}
              />
            }
            icon={faSearch}
            onClickEvent={toggleSearchBarAndDrawer}
          />
          {pharmacyCtx.pharmacyChannel === PharmacyChannel.local && (
            <ListItemLinkWithIcon
              text={<LinguiMessage message={appMessages.submitPrescription} />}
              icon={faCamera}
              linkComponent={compPops => (
                <CtxPathLink
                  {...compPops}
                  path={pharmacyCtxToPath(submitPrescriptionPath, pharmacyCtx)}
                />
              )}
            />
          )}
          <ListItemLinkWithIcon
            text={<LinguiMessage message={appMessages.faq} />}
            icon={faInfoCircle}
            linkComponent={compPops => (
              <CtxPathLink
                {...compPops}
                path={pharmacyCtxToPath(faqPath, pharmacyCtx)}
              />
            )}
          />
          <ListItemLinkWithIcon
            text={<LinguiMessage message={appMessages.contact} />}
            icon={faEnvelope}
            linkComponent={compPops => (
              <CtxPathLink
                {...compPops}
                path={pharmacyCtxToPath(contactPathSetPharmacy, pharmacyCtx)}
              />
            )}
          />
          <ListItem divider={true}>
            <ChangeLocaleDrawer />
          </ListItem>
          <CloseDrawerButton handleDrawer={handleDrawer} />
        </List>
      </div>
    </Drawer>
  );
};
