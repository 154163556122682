import { Moment } from "moment-timezone";
import React from "react";
import { ContextStore } from "react-dynamic-context";
import { connect } from "react-redux";
import { EnhancedPharmacy, Pharmacy } from "../app/apolyApi";
import { AppConsumer, AppContextValues } from "../app/AppContext";
import {
  PharmacyContextValues,
  PharmacyValuesConsumer,
} from "../app/PharmacyContext";
import { pharmacyBase } from "../app/routes";
import {
  langStringToLanguage,
  LanguageConsumer,
  Languages,
} from "../languages/LanguageContext";
import { build } from "../tpl/tpl";
import {
  selectLocale,
  setLocale,
} from "./deprecated-apoly-app/redux/language/reducer";
import {
  changeGermanServerTimeMoment,
  changeServerTimeMoment,
} from "./deprecated-apoly-app/redux/serverTime/serverTime";
import {
  setBasePath,
  setEnhancedPharmacy,
  setIsPostal,
  setPharmacy,
} from "./deprecated-apoly-app/redux/shopPharmacy/reducer";
import { channelToRoute, PharmacyChannel } from "../app/routes-helper";

interface PharmacyDispatcher {
  setPharmacy: (val: Pharmacy) => void;
  setBasePath: (val: string) => void;
  setIsPostal: (val: boolean) => void;
  setEnhancedPharmacy: (val: EnhancedPharmacy) => void;
}

const SyncWithPharmacyRedux = connect<
  {},
  PharmacyDispatcher,
  PharmacyContextValues
>(null, {
  setPharmacy,
  setBasePath,
  setIsPostal,
  setEnhancedPharmacy,
})(
  class InnerSyncWithPharmacyRedux extends React.PureComponent<
    PharmacyDispatcher & PharmacyContextValues
  > {
    componentDidMount() {
      this.sync();
    }

    componentDidUpdate() {
      this.sync();
    }

    sync() {
      const { pharmacy, enhancedPharmacy, pharmacyChannel } = this.props;

      if (pharmacy) {
        this.props.setPharmacy(pharmacy);
      }

      if (pharmacy && pharmacyChannel !== undefined) {
        this.props.setBasePath(
          build(pharmacyBase, {
            pharmacyChannel: channelToRoute(pharmacyChannel),
            pharmacyUrlCode: pharmacy.urlCode,
          }),
        );
        this.props.setIsPostal(pharmacyChannel === PharmacyChannel.mailOrder);
      }

      if (enhancedPharmacy) {
        this.props.setEnhancedPharmacy(enhancedPharmacy);
      }
    }

    render() {
      return null;
    }
  },
);

export const SyncPharmacyCtxWithRedux = () => (
  <PharmacyValuesConsumer>
    {pharmacyCtx => <SyncWithPharmacyRedux {...pharmacyCtx} />}
  </PharmacyValuesConsumer>
);

type Locale = { locale: string };
type LocaleDispatcher = { dispSetLocale: (val: string) => void };
type SyncWithLanguageReduxProps = {
  language: Languages;
  setLanguage: (lang: Languages) => void;
};

const SyncWithLanguageRedux = connect<
  Locale,
  LocaleDispatcher,
  SyncWithLanguageReduxProps
>(state => ({ locale: selectLocale(state) as string }), {
  dispSetLocale: setLocale,
})(
  // tslint:disable-next-line:max-classes-per-file
  class InnerSyncWithPharmacyRedux extends React.PureComponent<
    Locale & LocaleDispatcher & SyncWithLanguageReduxProps
  > {
    componentDidMount() {
      this.props.dispSetLocale(this.props.language.toString());
    }

    componentDidUpdate(prevProps: Locale & SyncWithLanguageReduxProps) {
      const { locale, language, setLanguage, dispSetLocale } = this.props;

      if (locale) {
        const langFromLoc = langStringToLanguage(locale);

        if (!langFromLoc) {
          throw new Error(`unknown locale set via redux: ${locale}`);
        }

        if (
          prevProps.locale !== this.props.locale &&
          langFromLoc !== language
        ) {
          setLanguage(langFromLoc);
        }
      }

      if (prevProps.language !== language && language.toString() !== locale) {
        dispSetLocale(language.toString());
      }
    }

    render() {
      return null;
    }
  },
);

export const SyncLanguageCtxWithRedux = () => (
  <LanguageConsumer>
    {languageCtx => (
      <SyncWithLanguageRedux
        language={languageCtx.language.value}
        setLanguage={languageCtx.language.setValue}
      />
    )}
  </LanguageConsumer>
);

type ServerTimeDispatcher = {
  changeServerTimeMoment: (moment: Moment) => void;
  changeGermanServerTimeMoment: (moment: Moment) => void;
};

const SyncServerTimeWithRedux = connect<
  {},
  ServerTimeDispatcher,
  ContextStore<AppContextValues>
>(null, { changeServerTimeMoment, changeGermanServerTimeMoment })(
  // tslint:disable-next-line:max-classes-per-file
  class InnerSyncWithPharmacyRedux extends React.PureComponent<
    ServerTimeDispatcher & ContextStore<AppContextValues>
  > {
    componentDidMount() {
      this.sync();
    }

    componentDidUpdate() {
      this.sync();
    }

    sync() {
      const val = this.props.serverTime.value;

      this.props.changeServerTimeMoment(val);
      this.props.changeGermanServerTimeMoment(val);
    }

    render() {
      return null;
    }
  },
);

export const SyncServerTimeCtxWithRedux = () => (
  <AppConsumer>{appCtx => <SyncServerTimeWithRedux {...appCtx} />}</AppConsumer>
);
