import { FormEvent, FormHTMLAttributes } from "react";

interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  onSubmit(ev: FormEvent<HTMLFormElement>): void;
}

export function Form(props: FormProps) {
  return (
    <form
      {...props}
      onSubmit={e => {
        e.preventDefault();
        // stopPropagation verhindert,
        // dass nested formulare mit einem mal submitted werden
        e.stopPropagation();
        props.onSubmit(e);
      }}
    />
  );
}
