export const flex = {
  display: "flex",
};

export const flexAlignFlexStart = {
  display: "flex",
  alignItems: "flex-start",
};

export const flexAlignCenter = {
  display: "flex",
  alignItems: "center",
};

export const marginAuto = {
  margin: "auto",
};

export const flexRight = {
  marginLeft: "auto",
};
export const flexBottom = {
  marginTop: "auto",
};

export const fullMaxWidth = {
  maxWidth: "100%",
};
export const fullMaxWidthMarginAuto = {
  maxWidth: "100%",
  margin: "auto",
};

export const fullWidth = {
  width: "100%",
};
export const pointer = {
  cursor: "pointer",
};

export const centerXs = {
  "@media (max-width: 600px)": {
    textAlign: "center",
  },
};

export const buttonRightCenterXs = {
  display: "flex",
  marginLeft: "auto",
  marginRight: "16px",
  marginTop: 16,
  "@media(max-width: 600px)": {
    margin: "auto!important",
  },
};

export const fullMaxWidthWithMarginAuto = {
  maxWidth: "100%",
  margin: "auto",
};

export const footerHeadline = {
  fontSize: 14,
  letterSpacing: ".15em",
  textTransform: "uppercase",
  fontWeight: 600,
};

export const findButton = {
  height: "100%",
  width: "100%",
};

export const link = {
  textDecoration: "none",
};

export const tableCellXs = {
  "@media(max-width: 600px)": {
    padding: 0,
  },
};

export const toPrint = {
  "@media print": {
    position: "absolute",
    left: 0,
    top: 0,
    visibility: "visible",
  },
};

export const noPrint = {
  "@media print": {
    visibility: "hidden",
  },
};

export const paddingHome = {
  padding: "16px 24px 16px 24px",
  "@media(max-width: 960px)": {
    padding: "8px 16px 8px 16px",
  },
};

export const buttonXs = {
  "@media (max-width: 600px)": {
    minWidth: 0,
  },
};

export const verticalCenteredInFlex = {
  margin: "auto 8px",
};

export const marginTopBottomAuto = {
  marginBottom: "auto",
  marginTop: "auto",
};

export const blackWhiteFilter = {
  filter: "grayscale(100%)",
  "&:hover": {
    filter: "grayscale(0%)",
  },
};
