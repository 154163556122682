import { curry } from "ramda";

// https://developers.google.com/maps/documentation/javascript/reference#ComponentRestrictions
export const germanComponentRestrictions = { country: "de" };

// see https://developers.google.com/maps/documentation/javascript/reference#AutocompletionRequest
export const AUTO_COMPLETE_REQUEST_OPTIONS = {
  types: ["geocode"], // geocode for addresses
  componentRestrictions: germanComponentRestrictions,
};

export const promiseGeocode = (geocoderOkStatus, geocoder, params) =>
  new Promise((resolve, reject) =>
    geocoder.geocode(params, (results, status) =>
      status === geocoderOkStatus ? resolve(results) : reject(status),
    ),
  );

// https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingRequests
export const geocodeGermanAddressWithGoogleGeocoder = curry(
  (geocoderOkStatus, geocoder, address) =>
    promiseGeocode(geocoderOkStatus, geocoder, {
      address,
      componentRestrictions: germanComponentRestrictions,
    }),
);
