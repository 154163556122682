import { LinguiEuro } from "@apoly-42/apoly-components";
import { Divider, Grid, Typography } from "@material-ui/core";
import { css } from "@emotion/core";
import PropTypes from "prop-types";
import React from "react";
import { priceWithTax } from "../../../../../constants/styles/cssEmotionClasses";
import ProductImageSmall from "../../../../imageComponents/productImages/ProductImageSmall";
import { getTaxValueMessage } from "../getTaxValueForProduct";

const getTotalProductPrice = (amount, price) => (amount * price).toFixed(2);

const SingleProduct = ({ article, count }) => {
  const { medicine, imgUrl } = article.package;
  const totalProductPrice = getTotalProductPrice(count, article.price);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center" alignContent="center">
          <Grid item xs={8}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <ProductImageSmall
                  name={medicine.name}
                  imgUrl={imgUrl}
                  style={{ maxHeight: 120, maxWidth: "100%" }}
                />
              </Grid>
              <Grid item xs={9}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      dangerouslySetInnerHTML={{ __html: medicine.name }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" color="textSecondary">
                      {article.package.amountText} | PZN: {article.package.pzn}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Typography align="center">
              {count} x <LinguiEuro value={Number(article.price)} />
            </Typography>
          </Grid>
          <Grid item xs={2} css={priceWithTax}>
            <Typography align="right">
              <LinguiEuro value={Number(totalProductPrice)} />
            </Typography>
            {getTaxValueMessage(article.package.mwst_code)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

SingleProduct.propTypes = {
  article: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
};

export default SingleProduct;
