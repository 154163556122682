import { captureException } from "./sentryWrapper";

const getErrorMessage = error => error && error.message;

export function logErrorGracefully(error, { extra } = {}) {
  const errMsg = getErrorMessage(error) || "no error-message";
  console.error(
    `an unknown error occured (${errMsg}) and will logged gracefully. information about the error below`,
  );
  console.log(error);
  if (extra) {
    console.log(extra);
  }

  if (
    (error && error.name === "NetworkError") ||
    // network-error bei axios:
    errMsg === "Network Error"
  ) {
    console.log(
      "this error is an network-error, it will not logged gracefully",
    );
    return Promise.resolve();
  }

  return captureException(error, { extra });
}

function failGracefully(error, { extra } = {}) {
  logErrorGracefully(error, { extra }).catch(console.error);

  throw error;
}

export default failGracefully;
