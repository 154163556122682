import {
  createContainer,
  isObject,
  onMountOrUpdate,
  tryParseJson,
} from "@apoly-42/apoly-utils";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectMedicSearchFilterState } from "../../../redux/medicSearchFilter/reducer";

const saveOnSessionStorage = filter =>
  sessionStorage.setItem("__searchFilter__", JSON.stringify(filter));

export const loadSearchFilterFromSessionStorage = () => {
  const val = tryParseJson(sessionStorage.getItem("__searchFilter__"));

  return isObject(val) ? val : {};
};

const isString = val => typeof val === "string";

const syncSearchKeyword = (searchKeyword, setSearchKeyword, history) => {
  const fromSession = sessionStorage.getItem("__searchKeyword__");

  if (isString(searchKeyword)) {
    sessionStorage.setItem("__searchKeyword__", searchKeyword);
    history.replace(history.location.pathname, { searchKeyword });
  }

  if (!isString(searchKeyword) && searchKeyword !== fromSession) {
    setSearchKeyword(fromSession);
  }
};

export const WriteSearchFilterIntoSessionStorage = createContainer(
  withRouter,
  connect(state => ({ searchFilter: selectMedicSearchFilterState(state) })),
  onMountOrUpdate(["searchFilter"], props =>
    saveOnSessionStorage(props.searchFilter),
  ),
  onMountOrUpdate(["searchKeyword"], props =>
    syncSearchKeyword(
      props.searchKeyword,
      props.setSearchKeyword,
      props.history,
    ),
  ),
);

WriteSearchFilterIntoSessionStorage.propTypes = {
  searchKeyword: PropTypes.string,
  setSearchKeyword: PropTypes.func.isRequired,
};

WriteSearchFilterIntoSessionStorage.defaultProps = {
  searchKeyword: null,
};
