import { WithIcon } from "@apoly-42/apoly-components";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Button, FormControlLabel, Radio, Theme } from "@material-ui/core";
import { FormikFields } from "formik-fields";
import * as React from "react";

import { AddressDialog } from "../../../../app-util-components/address-utils/AddressDialog";
import { CtaButton } from "../../../../app-util-components/CtaButton";
import { FluidTypography } from "../../../../app-util-components/FluidTypography";
import { LinguiMessage } from "../../../../app-util-components/LinguiMessage";
import {
  appMessages,
  formMessages,
} from "../../../../app-utilities/appMessages";
import { appGrid } from "../../../../app-utilities/cssClasses";
import { addressToString } from "../../../../app-utilities/toStringUtils";
import { commonMessages } from "../../../../deprecated/deprecated-apoly-app/constants/messages/commonMessages";
import { PharmacyValuesConsumer } from "../../../PharmacyContext";
import { Address, DeliveryType } from "../OtcOrderTypes";

const messages = {
  chooseYourDeliveryAddress: {
    id: "chooseYourDeliveryAddress",
    message:
      "Wählen Sie Ihre Lieferadresse aus oder fügen Sie eine neue hinzu.",
  },
  addDeliveryAddress: {
    id: "addDeliveryAddress",
    message: "Fügen Sie bitte eine neue Lieferadresse hinzu.",
  },
};

interface CheckoutMailOrderDeliveryDataProps {
  onSubmit: (data: {
    deliveryAddress?: Address;
    deliveryType: DeliveryType;
  }) => void;
  onBackClick: () => void;
  deliveryAddress?: Address;
  deliveryType: DeliveryType.mailOrder;
  addresses: Address[];
  onSubmitNewAddress: (address: Address) => void;
}

interface CheckoutMailOrderDeliveryDataState {
  deliveryType: DeliveryType.mailOrder;
  addresses: Address[];
  showDialog: boolean;
}

type DeliveryAddressForm = {
  deliveryAddress: Address | undefined;
};

export class CheckoutMailOrderDeliveryData extends React.Component<
  CheckoutMailOrderDeliveryDataProps,
  CheckoutMailOrderDeliveryDataState
> {
  state = {
    addresses: this.props.addresses,
    deliveryType: this.props.deliveryType,
    showDialog: false,
  };

  newAddress = (address: Address) => {
    this.setState({ addresses: [...this.state.addresses, address] });
  };

  toggleShowDialog = () =>
    this.setState({ showDialog: !this.state.showDialog });

  validateAddress = (address: Address | undefined) => {
    if (!address || this.state.addresses.indexOf(address) < 0) {
      return (
        <FluidTypography color="error">
          <LinguiMessage message={formMessages.noEmptyField} />
        </FluidTypography>
      );
    }

    return undefined;
  };

  validate = (values: DeliveryAddressForm) => ({
    deliveryAddress: this.validateAddress(values.deliveryAddress),
  });

  render() {
    const { deliveryType, addresses, showDialog } = this.state;

    const { onBackClick, deliveryAddress, onSubmitNewAddress } = this.props;

    return (
      <PharmacyValuesConsumer>
        {({ pharmacy }) =>
          pharmacy && (
            <FormikFields<DeliveryAddressForm>
              validate={this.validate}
              fields={{
                deliveryAddress: { initialValue: deliveryAddress },
              }}
              onSubmit={newValues => {
                this.props.onSubmit({
                  deliveryAddress: newValues.deliveryAddress,
                  deliveryType,
                });
              }}
            >
              {(fields, formikBag) => (
                <React.Fragment>
                  <form css={appGrid} onSubmit={formikBag.handleSubmit}>
                    <div css={appGrid}>
                      <FluidTypography>
                        <LinguiMessage
                          message={
                            addresses.length > 0
                              ? messages.chooseYourDeliveryAddress
                              : messages.addDeliveryAddress
                          }
                        />
                      </FluidTypography>

                      {addresses.map((address, i) => (
                        <FormControlLabel
                          key={i}
                          name={fields.deliveryAddress.name}
                          checked={fields.deliveryAddress.value === address}
                          onChange={(_, checked) =>
                            checked && fields.deliveryAddress.setValue(address)
                          }
                          control={
                            <Radio
                              color={
                                fields.deliveryAddress.error
                                  ? "secondary"
                                  : "primary"
                              }
                            />
                          }
                          label={addressToString(address)}
                        />
                      ))}

                      {fields.deliveryAddress.error}

                      <Button
                        style={{ justifySelf: "flex-start" }}
                        variant={
                          addresses.length > 0 ? "outlined" : "contained"
                        }
                        color={addresses.length > 0 ? undefined : "secondary"}
                        onClick={this.toggleShowDialog}
                      >
                        <WithIcon icon={faPlus} iconLeft={true}>
                          <LinguiMessage message={commonMessages.addAddress} />
                        </WithIcon>
                      </Button>
                    </div>

                    <div style={{ alignSelf: "flex-end", display: "flex" }}>
                      <Button onClick={onBackClick}>
                        <LinguiMessage message={appMessages.back} />
                      </Button>
                      <CtaButton type="submit" style={{ marginLeft: "auto" }}>
                        <LinguiMessage message={appMessages.continue} />
                      </CtaButton>
                    </div>
                  </form>
                  <AddressDialog
                    isOpen={showDialog}
                    onSubmit={address => {
                      onSubmitNewAddress(address);
                      fields.deliveryAddress.setValue(address);
                      this.setState({
                        addresses: [...addresses, address],
                        showDialog: false,
                      });
                    }}
                    onCloseClick={this.toggleShowDialog}
                  />
                </React.Fragment>
              )}
            </FormikFields>
          )
        }
      </PharmacyValuesConsumer>
    );
  }
}
