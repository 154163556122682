import { createAction, createReducer } from "@apoly-42/apoly-utils";
import { compose, prop } from "ramda";
import { selectAppStateRoot } from "../selectors";

export const selectLanguageState = compose(
  prop("language"),
  selectAppStateRoot,
);

export const setLocale = createAction("setLocale");

export const selectLocale = compose(prop("locale"), selectLanguageState);
export const selectTimeHourFormat = state =>
  selectLocale(state) === "en" ? "hh:mm A" : "HH:mm";
export const selectDateFormat = state =>
  selectLocale(state) === "en" ? "YYYY DD MM" : "DD MM YYYY";
export const selectTimHourDayMonthYearFormat = state =>
  selectLocale(state) === "en" ? "YYYY DD MM, hh:mm A" : "DD MM YYYY, HH:mm";

const reducer = createReducer(
  { locale: "" },
  {
    [setLocale]: (state, { payload }) => ({ locale: payload }),
  },
);

export default reducer;
