const removeFirstIfExist = (first: string, str: string) =>
  str.substr(0, 1) === first ? str.substr(1) : str;

/**
 * A major type means that this URL should be accessible from the browser via the forward/back button.
 * A minor type indicates that this URL can be skipped when using the forward/back button of the browser.
 */
export enum UrlType {
  major = 1,
  minor,
}

export interface RfcUrl {
  readonly hash: string;
  readonly host: string;
  readonly hostname: string;
  readonly href: string;
  readonly origin: string;
  readonly password: string;
  readonly pathname: string;
  readonly port: string;
  readonly protocol: string;
  readonly search: string;
  readonly username: string;
  readonly path: string;
  readonly query: string;
  readonly fragment: string;
  readonly urlType: UrlType;
  toString(): string;
}

interface PQF {
  path?: string;
  query?: string;
  fragment?: string;
}

const toSearch = (query: string | undefined) => (query ? `?${query}` : "");
const toHash = (fragment: string | undefined) =>
  fragment ? `#${fragment}` : "";
export const pqfToString = (pqf: PQF) =>
  `${pqf.path || ""}${toSearch(pqf.query)}${toHash(pqf.fragment)}`;

export function rfcUrl(
  url: string | PQF,
  base?: string | URL | RfcUrl,
  urlType: UrlType = UrlType.major,
): RfcUrl {
  const urlStr = typeof url === "string" ? url : pqfToString(url);
  const baseS = base || urlStr;

  const urlInstance = new URL(
    urlStr,
    typeof baseS === "string" ? baseS : (baseS as URL),
  );

  return {
    hash: urlInstance.hash,
    host: urlInstance.host,
    hostname: urlInstance.hostname,
    href: urlInstance.href,
    origin: urlInstance.origin,
    password: urlInstance.password,
    pathname: urlInstance.pathname,
    port: urlInstance.port,
    protocol: urlInstance.protocol,
    search: urlInstance.search,
    username: urlInstance.username,
    urlType,
    path: urlInstance.pathname,
    query: removeFirstIfExist("?", urlInstance.search),
    fragment: removeFirstIfExist("#", urlInstance.hash),
    toString: urlInstance.toString.bind(urlInstance),
  };
}

export const urlFromLocation = (location: Location) => {
  return rfcUrl(location.href);
};
