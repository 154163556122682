import { Button, Divider, FormHelperText, Hidden } from "@material-ui/core";
import { FormikFields } from "formik-fields";
import { useContext } from "react";
import * as React from "react";
import { CtaButton } from "../../../app-util-components/CtaButton";
import { noEmptyFormField } from "../../../app-util-components/form-utils/formikFieldValidators";
import { MuiFormikCheckboxField } from "../../../app-util-components/form-utils/MuiFormikCheckboxField";
import { LinguiMessage } from "../../../app-util-components/LinguiMessage";
import { appMessages } from "../../../app-utilities/appMessages";
import { appGrid } from "../../../app-utilities/cssClasses";
import ClosurePrice from "../../../deprecated/deprecated-apoly-app/components/pages/checkout/closure/ClosurePrice";
import ClosureProducts from "../../../deprecated/deprecated-apoly-app/components/pages/checkout/closure/productTable/ClosureProducts";
import ClosureProductsTableHead from "../../../deprecated/deprecated-apoly-app/components/pages/checkout/closure/productTable/ClosureProductsTableHead";
import { commonMessages } from "../../../deprecated/deprecated-apoly-app/constants/messages/commonMessages";
import { BRAND_NAME } from "../../../deprecated/deprecated-apoly-app/environment";
import { agbPath } from "../../../deprecated/deprecated-apoly-app/routes/paths";
import { LanguageContext, Languages } from "../../../languages/LanguageContext";
import { Pharmacy } from "../../apolyApi";
import { pharmacyCtxToPath } from "../../layout/PharmacyBaseLink";
import {
  PharmacyContextValues,
  PharmacyValuesConsumer,
} from "../../PharmacyContext";
import { dataSecurityPath } from "../../routes";
import { toDeliveryType } from "./CheckoutSubmitOrder";
import { DeliveryType } from "./OtcOrderTypes";

interface CheckoutClosureProps {
  deliveryType: DeliveryType;
  onSubmit: (values: ClosureValues) => void;
  onBackClick?: () => void;
  backComponent?: React.Component | null;
  deliveryAddressCountry?: string;
}
export const checkoutClosureMessages = {
  orderWithCosts: {
    id: "orderWithCosts",
    message: "order with costs",
  },
  brandAgb: {
    id: "brandAgb",
    message: "{brand}-AGB",
  },
  agreeToAgbAnDisclaimerWithLinks: {
    id: "agreeToAgbAnDisclaimerWithLinks",
    message: "Yes, i agree to the {agbLink} and the {cancellationPolicyLink}.",
  },
  agreeToNewsletter: {
    id: "agreeToNewsletter",
    message:
      "I want to get information about new health care topics. (optional)",
  },
  cancellationPolicy: {
    id: "cancellationPolicy",
    message: "cancellation policy",
  },
  newsletterHelperText: {
    id: "newsletterHelperText",
    message:
      "Mit Aktivieren der Anmeldeoption durch Häkchensetzung stimmen Sie der Verwendung von Cookies und der Erhebung von Gesundheitsdaten zu. Einzelheiten dazu sowie Ihrem Widerrufsrecht entnehmen Sie den {dataProtectionLink}",
  },
};

export function langToWrbName(language: Languages, pharmacyId: string) {
  switch (language) {
    case Languages.de:
      return `widerrufsbelehrung_${pharmacyId}.pdf`;
    case Languages.ar:
      return `widerrufsbelehrung_ar_${pharmacyId}.pdf`;
    case Languages.en:
      return `widerrufsbelehrung_en_${pharmacyId}.pdf`;
    case Languages.ru:
      return `widerrufsbelehrung_ru_${pharmacyId}.pdf`;
    case Languages.tr:
      return `widerrufsbelehrung_tu_${pharmacyId}.pdf`;
    default:
      return `widerrufsbelehrung_${pharmacyId}.pdf`;
  }
}

export interface ClosureValues {
  newsletterOptIn: boolean;
  agbOptIn: boolean;
}

function renderAgbLabel(pharmacy: Pharmacy | undefined, lng: Languages) {
  return (
    <LinguiMessage
      message={checkoutClosureMessages.agreeToAgbAnDisclaimerWithLinks}
      values={{
        agbLink: (
          <a href={pharmacy ? pharmacy.agbUrl : ""} target="_blank">
            <LinguiMessage message={commonMessages.agb} />
          </a>
        ),
        cancellationPolicyLink: (
          <a
            href={
              pharmacy
                ? `https://dashboard.apoly.de/data/pharmacyData/widerrufsbelehrungen/${langToWrbName(
                    lng,
                    pharmacy.pharmacyId,
                  )}`
                : ""
            }
            target="_blank"
          >
            <LinguiMessage
              message={checkoutClosureMessages.cancellationPolicy}
            />
          </a>
        ),
      }}
    />
  );
}

function renderNewsletterHelperText(pharmacyCtx: PharmacyContextValues) {
  return (
    <LinguiMessage
      message={checkoutClosureMessages.newsletterHelperText}
      values={{
        dataProtectionLink: (
          <a
            href={pharmacyCtxToPath(dataSecurityPath, pharmacyCtx)}
            target="_blank"
          >
            <LinguiMessage message={commonMessages.privacyPolicy} />
          </a>
        ),
      }}
    />
  );
}

function renderOrderLegalText(pharmacyCtx: PharmacyContextValues) {
  return (
    <LinguiMessage
      message={commonMessages.agreeToUserDataSecurityPolicy}
      values={{
        websiteAgbLink: (
          <a href={agbPath()} target="_blank">
            <LinguiMessage
              message={checkoutClosureMessages.brandAgb}
              values={{ brand: BRAND_NAME }}
            />
          </a>
        ),
        privacyPolicyLink: (
          <a
            href={pharmacyCtxToPath(dataSecurityPath, pharmacyCtx)}
            target="_blank"
          >
            <LinguiMessage message={commonMessages.privacyPolicy} />
          </a>
        ),
      }}
    />
  );
}

export function CheckoutClosure(props: CheckoutClosureProps) {
  const {
    onSubmit,
    backComponent,
    onBackClick,
    deliveryType,
    deliveryAddressCountry,
  } = props;

  const lng = useContext(LanguageContext.context);

  return (
    <PharmacyValuesConsumer>
      {pharmacyCtx => (
        <FormikFields<ClosureValues>
          fields={{
            agbOptIn: { initialValue: false, validate: noEmptyFormField },
            newsletterOptIn: { initialValue: false },
          }}
          onSubmit={onSubmit}
        >
          {(fields, formikBag) => (
            <form onSubmit={formikBag.handleSubmit} css={appGrid}>
              <Hidden smDown={true}>
                <ClosureProductsTableHead />
              </Hidden>
              <ClosureProducts />
              <Divider />
              <ClosurePrice
                deliveryAddressCountry={deliveryAddressCountry}
                transmission={toDeliveryType(deliveryType)}
              />
              <div>
                <MuiFormikCheckboxField
                  field={fields.newsletterOptIn}
                  label={
                    <LinguiMessage
                      message={checkoutClosureMessages.agreeToNewsletter}
                    />
                  }
                />
                <FormHelperText>
                  {renderNewsletterHelperText(pharmacyCtx)}
                </FormHelperText>
              </div>
              <MuiFormikCheckboxField
                field={fields.agbOptIn}
                label={renderAgbLabel(pharmacyCtx.pharmacy, lng.language.value)}
              />
              <FormHelperText>
                {renderOrderLegalText(pharmacyCtx)}
              </FormHelperText>
              <div style={{ alignSelf: "flex-end", display: "flex" }}>
                {backComponent ? (
                  // @ts-ignore
                  <Button component={backComponent}>
                    <LinguiMessage message={appMessages.back} />
                  </Button>
                ) : (
                  <Button onClick={onBackClick}>
                    <LinguiMessage message={appMessages.back} />
                  </Button>
                )}
                <CtaButton type="submit" style={{ marginLeft: "auto" }}>
                  <LinguiMessage
                    message={checkoutClosureMessages.orderWithCosts}
                  />
                </CtaButton>
              </div>
            </form>
          )}
        </FormikFields>
      )}
    </PharmacyValuesConsumer>
  );
}
