import Allergien from "./Allergien.png";
import Beruhigungsmittel_Schlafmittel from "./Beruhigungsmittel_Schlafmittel.png";
import Blase_Niere_Prostata from "./Blase_Niere_Prostata.png";
import default_grey from "./default_grey.png";
import Depression from "./Depression.png";
import erkaltung from "./Erkältung.png";
import Fieber from "./Fieber.png";
import Haut_Schleimhaut from "./Haut_Schleimhaut.png";
import Husten from "./Husten.png";
import Kopfschmerz from "./Kopfschmerz.png";
import Magen from "./Magen-Darm-Beschwerden.png";
import Nahrungsergaenzungsmittel_Vitaminmangel from "./Nahrungsergaenzungsmittel_Vitaminmangel.png";
import Pflegeprodukte from "./Pflegeprodukte.png";
import Pilzerkrankungen from "./Pilzerkrankungen.png";
import Schmerzen from "./Schmerzen.png";
import Schnupfen from "./Schnupfen.png";
import Schwindel from "./Schwindel.png";
import Verhuetung from "./Verhuetung.png";
import Verletzung from "./Verletzung.png";
import defaultSymptomImage from "./default.png";

export function getSymptomImage(imgName: string) {
  switch (imgName) {
    case "Allergien.png":
      return Allergien;
    case "Beruhigungsmittel_Schlafmittel.png":
      return Beruhigungsmittel_Schlafmittel;
    case "Blase_Niere_Prostata.png":
      return Blase_Niere_Prostata;
    case "default_grey.png":
      return default_grey;
    case "Depression.png":
      return Depression;
    case "Erkältung.png":
      return erkaltung;
    case "Fieber.png":
      return Fieber;
    case "Haut_Schleimhaut.png":
      return Haut_Schleimhaut;
    case "Husten.png":
      return Husten;
    case "Kopfschmerz.png":
      return Kopfschmerz;
    case "Magen-Darm-Beschwerden.png":
      return Magen;
    case "Nahrungsergaenzungsmittel_Vitaminmangel.png":
      return Nahrungsergaenzungsmittel_Vitaminmangel;
    case "Pflegeprodukte.png":
      return Pflegeprodukte;
    case "Pilzerkrankungen.png":
      return Pilzerkrankungen;
    case "Schmerzen.png":
      return Schmerzen;
    case "Schnupfen.png":
      return Schnupfen;
    case "Schwindel.png":
      return Schwindel;
    case "Verhuetung.png":
      return Verhuetung;
    case "Verletzung.png":
      return Verletzung;
    case "default.png":
      return defaultSymptomImage;
    default:
      return defaultSymptomImage;
  }
}
