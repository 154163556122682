export const SUPPORT_MAIL_NAME = "support";
export const PARTNER_MAIL_NAME = "partner";

export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME;
export const E_MAIL_DOMAIN = process.env.REACT_APP_E_MAIL_DOMAIN;

export const BERLIN_TIME_ZONE = "Europe/Berlin";

// https://beta.medixpress.de/api/v3
export const APOLY_API_BASE_URL = process.env.APOLY_API_BASE_URL;

export const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const REACT_APP_RELEASE = process.env.REACT_APP_RELEASE;

// eigentlich müsste das immer von db kommen usw, aber vorerst soll das reichen
export const ABHOL_SECRET =
  "IG=oSaUjj6e}QQycv,q8#5prB{b7pZ,^1>R#A82/B2C*Kw]J%$zg2m2?xQBWsb^X";
