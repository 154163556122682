import { Grid } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";
import { css } from "@emotion/core";
import React from "react";
import { FluidTypography } from "../../../app-util-components/FluidTypography";

const footerHeadline = css`
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-weight: 600;
`;

interface FooterHeadlineProps extends TypographyProps {
  children: React.ReactNode;
}

export const FooterHeadline: React.SFC<FooterHeadlineProps> = ({
  children,
  ...props
}) => (
  <Grid item={true} xs={12}>
    <FluidTypography color="textPrimary" css={footerHeadline} {...props}>
      {children}
    </FluidTypography>
  </Grid>
);
