import { Loading } from "@apoly-42/material-ui";
import { Trans } from "@lingui/macro";
import { Button, Dialog, DialogContent, Typography } from "@material-ui/core";
import firebase from "firebase";
import * as React from "react";
import { useState } from "react";
import { Redirect } from "react-router";
import { Load } from "../../../../app-util-components/Load";
import { useAsyncEffect } from "../../../../app-utilities/hook-utils";
import { pause } from "../../../../app-utilities/utils";
import { createOtcOrderForUser } from "../../../../deprecated/apolyApi";
import {
  otcOrderCompletePath,
  otcPaymentFailedPath,
  userOtcOrderCompletePath,
} from "../../../../deprecated/deprecated-apoly-app/routes/paths";
import {
  getHobexTerminalPaymentStateForUser,
  initiateHobexTerminalPaymentForUser,
  newHobexTerminalPaymentIdForUser,
  wirecardPaymentRequestForUser,
} from "../../../../deprecated/deprecated-apoly-app/utilities/apis/apolyApi/apolyApi";
import { rfcUrl, RfcUrl } from "../../../../url";
import {
  CashOtcOrder,
  EPaymentOtcOrder,
  OtcOrder,
  PaymentMethod,
  Pharmacy,
  TerminalOtcOrder,
} from "../../../apolyApi";
import { pharmacyCtxToPath } from "../../../layout/PharmacyBaseLink";
import { usePharmacyValues } from "../../../PharmacyContext";
import { dashboardReservationRoute, pharmacyBase } from "../../../routes";
import { PharmacyChannel } from "../../../routes-helper";
import { PosTerminalIconSvg } from "../CheckoutPayment";
import { WirecardRequestFromApi } from "./wirecard-utils";
import { WirecardWrapper } from "./WirecardWrapper";

interface SubmitUserOrderProps {
  order: OtcOrder;
  user: firebase.User;
  pharmacy: Pharmacy;
  pharmacyChannel: PharmacyChannel;
  urlBase: RfcUrl;
  onStartEPayment?(): void;
  onAbortClick(): void;
}

type OtcUserOrderResponse = { otcOrderId: string; otcOrderKey: string };

type UserOtcData = { order: OtcOrder; user: firebase.User };

/*
// TODO check wie man am sinnvollsten async await einbaut in webpack-bundle und dann wieder die sachen umschreiben
const postNewUserOrder = async ({
  order,
  user,
}: UserOtcData): Promise<OtcUserOrderResponse> => {
  const userToken = await user.getIdToken();

  return await createOtcOrderForUser(userToken, user.uid, order);
};
 */
export function postNewUserOrder({
  order,
  user,
}: UserOtcData): Promise<OtcUserOrderResponse> {
  return user
    .getIdToken()
    .then(userToken => createOtcOrderForUser(userToken, user.uid, order));
}

/*
// TODO check wie man am sinnvollsten async await einbaut in webpack-bundle und dann wieder die sachen umschreiben
postNewUserOrderWithEPayment = async (
    order: EPaymentOtcOrder
  ): Promise<WirecardRequestFromApi> => {
    const { user } = this.props;

    const basePath = pharmacyCtxToPath(pharmacyBase, this.props);

    const { otcOrderId } = await postNewUserOrder({ order, user });

    const userToken = await user.getIdToken(); // dont reuse old token, could be expired for slow connections

    return await wirecardPaymentRequestForUser(
      userToken,
      user.uid,
      otcOrderId,
      this.toUrl(userOtcOrderCompletePath(basePath, otcOrderId)),
      this.toUrl(otcPaymentFailedPath(basePath, otcOrderId))
    );
  };
 */

function SubmitUserOrderTerminal(props: {
  order: TerminalOtcOrder;
  user: firebase.User;
  onStartPayment?(): void;
  onAbortClick(): void;
}) {
  const [state, setState] = useState<
    | {
        type: "init" | "pending" | "failed";
      }
    | { type: "fulfilled"; otcOrderId: string; otcOrderKey: string }
  >({ type: "init" });

  const pharmacyCtx = usePharmacyValues();

  type TerminalState = "pending" | "fulfilled" | "failed";
  useAsyncEffect(
    async ({ signal }) => {
      const { otcOrderId, otcOrderKey } = await postNewUserOrder({
        order: props.order,
        user: props.user,
      });
      if (signal.isAborted) return;

      const hobexId = await newHobexTerminalPaymentIdForUser(
        await props.user.getIdToken(),
        props.user.uid,
        otcOrderId,
      );
      if (signal.isAborted) return;

      props.onStartPayment && props.onStartPayment();

      await new Promise(async (resolve, reject) => {
        async function checkState(state: TerminalState) {
          switch (state) {
            case "pending":
              await pause(500);
              checkTerminalState().catch(reject);
              break;
            case "fulfilled":
              resolve();
              setState({ type: "failed" });
              break;
            case "failed":
              resolve();
              setState({ type: "fulfilled", otcOrderId, otcOrderKey });
              break;
            default:
              throw new Error(`unknown state ${state}`);
          }
        }

        async function checkTerminalState() {
          const state: TerminalState = await getHobexTerminalPaymentStateForUser(
            await props.user.getIdToken(),
            props.user.uid,
            otcOrderId,
            hobexId,
          );

          checkState(state).catch(reject);
        }

        setState({ type: "pending" });

        if (signal.isAborted) return;
        const state = await initiateHobexTerminalPaymentForUser(
          await props.user.getIdToken(),
          props.user.uid,
          otcOrderId,
          hobexId,
        ).catch(console.error);

        // wenn state nicht aufgelöst wurde, dann mit get-state den echten rausfinden:
        checkState(state || "pending").catch(reject);
      });
    },
    [props.order, props.user],
  );
  return (
    <Dialog open={state.type !== "init"}>
      <DialogContent>
        {state.type === "pending" ? (
          <div className="grid grid-gap-4 justify-items-center">
            <Typography variant="h5">
              <Trans>Befolgen Sie die Anweisungen am Karternterminal</Trans>
            </Typography>
            <div>
              <PosTerminalIconSvg className="w-full h-full max-h-32 fill-current" />
            </div>
            <Typography>
              <Trans>
                Beenden Sie die Bestellung am Karternterminal, danach wird Ihre
                Ware ausgelagert.
              </Trans>
            </Typography>
          </div>
        ) : null}

        {state.type === "failed" ? (
          <div className="grid grid-gap-4 justify-items-center">
            <Typography variant="h5">
              <Trans>Zahlung abgebrochen oder fehlerhaft</Trans>
            </Typography>
            <div className="text-red-500">
              <PosTerminalIconSvg className="w-full h-full max-h-32 fill-current" />
            </div>
            <Typography>
              <Trans>
                Die Zahlung wurde nicht erfolgreich abgeschlossen. Sie können
                eine andere Zahlart nutzen oder es erneut mit der Kartenzahlung
                am Terminal versuchen
              </Trans>
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setState({ type: "init" });
                props.onAbortClick();
              }}
            >
              <Trans>Zurück und neue Zahlart auswählen</Trans>
            </Button>
          </div>
        ) : null}

        {state.type === "fulfilled" ? (
          <Redirect
            push={true}
            to={otcOrderCompletePath(
              pharmacyCtxToPath(pharmacyBase, pharmacyCtx),
              state.otcOrderKey,
            )}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
}

export class SubmitUserOrder extends React.PureComponent<SubmitUserOrderProps> {
  toUrl = (path: string) => rfcUrl(path, this.props.urlBase).toString();

  postNewUserOrderWithEPayment = (
    order: EPaymentOtcOrder,
  ): Promise<WirecardRequestFromApi> => {
    const { user } = this.props;

    const basePath = pharmacyCtxToPath(pharmacyBase, this.props);

    return postNewUserOrder({ order, user }).then(
      ({ otcOrderId, otcOrderKey }) => {
        const paths =
          order.deliveryType === "pss_vending_machine_reserve"
            ? {
                success: dashboardReservationRoute(basePath, otcOrderKey),
                failed: otcPaymentFailedPath(basePath, otcOrderKey),
              }
            : {
                success: userOtcOrderCompletePath(basePath, otcOrderId),
                failed: otcPaymentFailedPath(basePath, otcOrderKey),
              };

        return user.getIdToken().then(userToken => {
          return wirecardPaymentRequestForUser(
            userToken,
            user.uid,
            otcOrderId,
            this.toUrl(paths.success),
            this.toUrl(paths.failed),
          );
        });
      },
    );
  };

  renderSubmitEPaymentUserOrder = (order: EPaymentOtcOrder) => (
    <Load fn={() => this.postNewUserOrderWithEPayment(order)}>
      {apiState => (
        <React.Fragment>
          {apiState.response && (
            <WirecardWrapper
              onStartPayment={this.props.onStartEPayment}
              wcRequestFromApi={apiState.response}
            />
          )}
        </React.Fragment>
      )}
    </Load>
  );

  renderSubmitCashPaymentUserOrder = (order: CashOtcOrder) => {
    const { user } = this.props;
    const basePath = pharmacyCtxToPath(pharmacyBase, this.props);

    return (
      <Load fn={() => postNewUserOrder({ order, user })}>
        {({ response, isLoading }) => (
          <React.Fragment>
            {response && (
              <Redirect
                push={true}
                to={userOtcOrderCompletePath(basePath, response.otcOrderId)}
              />
            )}
          </React.Fragment>
        )}
      </Load>
    );
  };

  render() {
    const { order, user, onAbortClick, onStartEPayment } = this.props;

    if (order.paymentType === PaymentMethod.hobex_terminal) {
      return (
        <SubmitUserOrderTerminal
          order={order}
          user={user}
          onAbortClick={onAbortClick}
          onStartPayment={onStartEPayment}
        />
      );
    }

    return order.paymentType === PaymentMethod.cash
      ? this.renderSubmitCashPaymentUserOrder(order)
      : this.renderSubmitEPaymentUserOrder(order);
  }
}
