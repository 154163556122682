import * as React from "react";
import { RenderChildrenProp } from "../app-utilities/reactUtilTypes";

enum StorageKeys {
  language = "__apoly_language",
}

export type StorageValue<Key extends string> = {
  key: Key;
  value: string | null;
  setValue: (value: string | null) => void;
};

export interface StorageContextProps {
  language: StorageValue<StorageKeys.language>;
}

export type StorageContextValues = {
  [key in keyof StorageContextProps]: string | null;
};

const StorageContext = React.createContext<StorageContextProps>({
  language: {
    key: StorageKeys.language,
    value: null,
    setValue: () => undefined,
  },
});

interface StorageProviderProps {
  initialValues: StorageContextValues;
}

export class StorageProvider extends React.PureComponent<
  StorageProviderProps,
  StorageContextProps
> {
  constructor(props: StorageProviderProps) {
    super(props);

    this.state = {
      language: {
        key: StorageKeys.language,
        value: props.initialValues.language,
        setValue: this.updateState("language"),
      },
    };
  }

  updateState = (key: keyof StorageContextProps) => (str: string | null) =>
    this.setState({
      [key]: {
        ...this.state[key],
        value: str,
      },
    });

  render() {
    return (
      <StorageContext.Provider
        value={this.state}
        children={this.props.children}
      />
    );
  }
}

export const StorageConsumer = StorageContext.Consumer;

export const StorageValues = (
  props: RenderChildrenProp<StorageContextValues>,
) => (
  <StorageConsumer>
    {ctx => props.children({ language: ctx.language.value })}
  </StorageConsumer>
);

export const valuesFromStorage = (storage: Storage) => ({
  language: storage.getItem(StorageKeys.language),
});
