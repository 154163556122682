import { tpl } from "../tpl/tpl";
import { TplProps } from "../tpl/tplTypes";
import { Pharmacy } from "./apolyApi";
import { channelToRoute, PharmacyChannel } from "./routes-helper";

const pharmacyRoute = "apo";

type DynRoute = { prop: string };
export const dynRoute = ({ prop }: TplProps<DynRoute>) => tpl`/foo/${prop}/bar`;

// type PharmacyName = { pharmacyUrlCode: string };
// type PharmacyChannelObj = { pharmacyChannel: string };

export type PharmacyBasePathProps = {
  pharmacyUrlCode: string;
  pharmacyChannel: string;
};

type MedicineCode = { medicineCode: string };
type MedicinePackageProps = MedicineCode & { pzn: string };

export const agbPath = () => tpl`/files/Apoly-Nutzungsbedingungen-AGB.pdf`;

export function pharmacyBasePath(ctx: {
  pharmacy: Pharmacy;
  pharmacyChannel: PharmacyChannel;
}) {
  const routeStr = channelToRoute(ctx.pharmacyChannel);
  return `/${pharmacyRoute}/${ctx.pharmacy.urlCode}/${routeStr}`;
}

export const pharmacyBase = (props: TplProps<PharmacyBasePathProps>) =>
  tpl`/${pharmacyRoute}/${props.pharmacyUrlCode}/${props.pharmacyChannel}`;

export const medicineSeoRoute = (props: TplProps<MedicineCode>) =>
  tpl`/${props.medicineCode}`;

export const medicinePackageSeoRoute = (
  props: TplProps<MedicinePackageProps>,
) => tpl`/${props.medicineCode}/${props.pzn}`;

export const pharmacyMedicineRoute = (
  props: TplProps<MedicineCode & PharmacyBasePathProps>,
) => [...pharmacyBase(props), ...tpl`/medikamente/${props.medicineCode}`];

export const pharmacyMedicinePackageRoute = (
  props: TplProps<MedicinePackageProps & PharmacyBasePathProps>,
) => [
  ...pharmacyBase(props),
  ...tpl`/medikamente/${props.medicineCode}/${props.pzn}`,
];

export const pharmacyMedicineSearch = (
  props: TplProps<PharmacyBasePathProps>,
) => [...pharmacyBase(props), "/suche"];

// schwächen url-templating:
// * query-string => :/
// * joining

export const shoppingCartPath = (props: TplProps<PharmacyBasePathProps>) => [
  ...pharmacyBase(props),
  `/warenkorb`,
];

export const submitPrescriptionPath = (
  props: TplProps<PharmacyBasePathProps>,
) => [...pharmacyBase(props), `/rezept-bestellen`];

export const checkoutPath = (props: TplProps<PharmacyBasePathProps>) => [
  ...pharmacyBase(props),
  `/kasse`,
];

export const regionFinderPath = (props: TplProps<PharmacyBasePathProps>) => [
  ...pharmacyBase(props),
  `/koerperteilauswahl`,
];

export const checkoutPathLoginOrContinueAsGuest = (
  props: TplProps<PharmacyBasePathProps>,
) => [...pharmacyBase(props), `/kasse/login-oder-gast`];

export const checkoutPathSignUpContinueAsGuest = (
  props: TplProps<PharmacyBasePathProps>,
) => [...pharmacyBase(props), `/kasse/anmeldung-oder-gast`];

export const medicSearchPath = (props: TplProps<PharmacyBasePathProps>) => [
  ...pharmacyBase(props),
  `/medikamente-suchen`,
];

export const registerPath = (props: TplProps<PharmacyBasePathProps>) => [
  ...pharmacyBase(props),
  `/registrierung`,
];

export const loginPath = (props: TplProps<PharmacyBasePathProps>) => [
  ...pharmacyBase(props),
  `/login`,
];

export const faqPath = (props: TplProps<PharmacyBasePathProps>) => [
  ...pharmacyBase(props),
  `/faq-fragen`,
];

export const pressPath = (props: TplProps<PharmacyBasePathProps>) => [
  ...pharmacyBase(props),
  `/presse-informationen-unternehmensueberblick`,
];

export const jobsPath = (props: TplProps<PharmacyBasePathProps>) => [
  ...pharmacyBase(props),
];

export const deliveryCostsPath = (props: TplProps<PharmacyBasePathProps>) => [
  ...pharmacyBase(props),
  `/lieferkosten`,
];

export const imprintPath = (props: TplProps<PharmacyBasePathProps>) => [
  ...pharmacyBase(props),
  `/impressum`,
];

export const dataSecurityPath = (props: TplProps<PharmacyBasePathProps>) => [
  ...pharmacyBase(props),
  `/datenschutz`,
];

export const contactPathSetPharmacy = (
  props: TplProps<PharmacyBasePathProps>,
) => [...pharmacyBase(props), `/kontakt`];

type OtcPathVar = PharmacyBasePathProps & { otcOrderCode: string };
type RxPathVar = PharmacyBasePathProps & { rxOrderCode: string };

export const otcOrderCompletePath = (props: TplProps<OtcPathVar>) => [
  ...pharmacyBase(props),
  ...tpl`/bestellung-abgeschlossen/${props.otcOrderCode}`,
];

export const rxOrderCompletePath = (props: TplProps<RxPathVar>) => [
  ...pharmacyBase(props),
  ...tpl`/rezept-bestellung-abgeschlossen/${props.rxOrderCode}`,
];

export const otcPaymentFailedPath = (props: TplProps<OtcPathVar>) => [
  ...pharmacyBase(props),
  ...tpl`/bestellung-abgebrochen/${props.otcOrderCode}`,
];

export const userPath = (props: TplProps<PharmacyBasePathProps>) => [
  ...pharmacyBase(props),
  `/user`,
];

export function dashboardReservationRoute(base: string, orderKey: string) {
  return `${base}/reservierungen/${orderKey}`;
}

export function widerrufsbelehrungPath(basePath) {
  return `${basePath}/widerrufsbelehrung`;
}
