import { useContext } from "react";
import * as React from "react";
import { createDynamicContext } from "react-dynamic-context";
import { Subtract } from "utility-types";
import { EnhancedPharmacy, Pharmacy } from "./apolyApi";
import { PharmacyBasePathProps } from "./routes";
import { channelToRoute, PharmacyChannel } from "./routes-helper";

export interface PharmacyContextValues {
  pharmacy: Pharmacy | undefined;
  pharmacyIsLoading: boolean;
  enhancedPharmacy: EnhancedPharmacy | undefined;
  pharmacyChannel: PharmacyChannel | undefined;
}

export const PharmacyCtx = createDynamicContext<PharmacyContextValues>({
  pharmacy: undefined,
  pharmacyIsLoading: false,
  enhancedPharmacy: undefined,
  pharmacyChannel: undefined,
});

export function usePharmacyValues(): PharmacyContextValues {
  const ctx = useContext(PharmacyCtx.context);

  return {
    pharmacy: ctx.pharmacy.value,
    pharmacyIsLoading: ctx.pharmacyIsLoading.value,
    enhancedPharmacy: ctx.enhancedPharmacy.value,
    pharmacyChannel: ctx.pharmacyChannel.value,
  };
}

export const PharmacyConsumer = PharmacyCtx.Consumer;
export const PharmacyValuesConsumer = PharmacyCtx.ValuesConsumer;

export const pharmacyCtxToRouteProps = ({
  pharmacy,
  pharmacyChannel,
}: PharmacyContextValues): PharmacyBasePathProps | undefined =>
  pharmacy && pharmacyChannel
    ? {
        pharmacyChannel: channelToRoute(pharmacyChannel),
        pharmacyUrlCode: pharmacy.urlCode,
      }
    : undefined;

export interface WithPharmacyCtxProps {
  pharmacyCtx: PharmacyContextValues;
}

export const withPharmacyCtx = <WrappedProps extends WithPharmacyCtxProps>(
  WrappedComponent: React.ComponentType<WrappedProps>,
) => {
  return class WithPharmacyCtx extends React.Component<
    Subtract<WrappedProps, WithPharmacyCtxProps>
  > {
    render() {
      return (
        <PharmacyValuesConsumer>
          {ctx => <WrappedComponent pharmacyCtx={ctx} {...this.props} />}
        </PharmacyValuesConsumer>
      );
    }
  };
};
