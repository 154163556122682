import { equals } from "ramda";
import * as React from "react";
import { cond } from "../../app-utilities/fn-utils";
import defaultLogo from "../apoly-logo-white-no-claim.png";
import {
  apolyLandingSlogan,
  appPalette,
  defaultPanoramaComponent,
} from "../apolyThemeValues";
import {
  veritasCustomisations,
  veritasPharmacyCode,
} from "./customisations-veritas";
import { PharmacyTheme } from "./PharmacyThemeInterface";

export const pharmacyDefaults: PharmacyTheme = {
  palette: appPalette,
  logo: defaultLogo,
  PanoramaComponent: defaultPanoramaComponent,
  landingSlogan: apolyLandingSlogan,
};

const urlCodeToCustomisations = cond<string, Partial<PharmacyTheme>>([
  [equals(veritasPharmacyCode), () => veritasCustomisations],
]);

export function pharmacyUrlCodeToPharmacyTheme(urlCode: string) {
  return Object.assign({}, pharmacyDefaults, urlCodeToCustomisations(urlCode));
}

export const PharmacyThemeCtx = React.createContext<PharmacyTheme>(
  pharmacyDefaults,
);

export const PharmacyThemeContext = PharmacyThemeCtx.Consumer;
export const PharmacyThemeProvider = PharmacyThemeCtx.Provider;
