import moment, { Moment } from "moment-timezone";
import { createDynamicContext } from "react-dynamic-context";
import { BERLIN_TIME_ZONE } from "../environment";

export interface AppContextValues {
  serverTime: Moment;
}

export const appContext = createDynamicContext<AppContextValues>({
  serverTime: moment.tz(BERLIN_TIME_ZONE),
});

export const AppConsumer = appContext.Consumer;

export const AppValues = appContext.ValuesConsumer;
