import {
  createAction,
  createReducer,
  pLocalStorage,
} from "@apoly-42/apoly-utils";
import { compose, prop } from "ramda";
import { selectAppStateRoot } from "./selectors";

export const changeCookieConsent = createAction("changeCookieConsent");

const initialState =
  pLocalStorage.getItem("__ACCEPT_DATA_PROTECTION_OPT_IN__") &&
  Number(pLocalStorage.getItem("__ACCEPT_DATA_PROTECTION_OPT_IN__")) === 1;

export const selectCookieConsent = compose(
  Boolean,
  prop("cookieConsent"),
  selectAppStateRoot,
);

export default createReducer(initialState, {
  [changeCookieConsent]: (state, action) => action.payload,
});
