import { switchF } from "@apoly-42/apoly-utils";
import PropTypes from "prop-types";
import { withLingui } from "@apoly-42/apoly-components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { selectGermanServerTimeMoment } from "../../redux/serverTime/serverTime";

const messages = {
  pharmacyIsOpenWithHours: {
    id: "pharmacyIsOpenWithHours",
    message:
      "open, closes in approx. {numberOfHours, plural, one {one hour} other {{numberOfHours} hours}} (at {closesAt})",
  },
  pharmacyIsOpenWithMinutes: {
    id: "pharmacyIsOpenWithMinutes",
    message:
      "open, closes soon (in about {numberOfMinutes, plural, one {one minute} other {{numberOfMinutes} minutes}} at {closesAt})",
  },
  pharmacyIsClosedOpensWithHours: {
    id: "pharmacyIsClosedOpensWithHours",
    message:
      "closed, opens in approx. {numberOfHours, plural, one {one hour} other {{numberOfHours} hours}} (at {opensAt})",
  },
  pharmacyIsClosedOpensWithMinutes: {
    id: "pharmacyIsClosedOpensWithMinutes",
    message:
      "closed, opens in about {numberOfMinutes, plural, one {one minute} other {{numberOfMinutes} minutes}} (at {opensAt})",
  },
  pharmacyIsClosed: {
    id: "pharmacyIsClosed",
    message: "closed, opens next {weekday} ({date}) at {opensAt}",
  },
};

const openedMessage = (i18n, moment, openingToMoment) => {
  const diffHours = openingToMoment.diff(moment, "hours", true);
  const diffMinutes = openingToMoment.diff(moment, "minutes");

  const i18nValues = {
    numberOfHours: Math.round(diffHours),
    numberOfMinutes: diffMinutes,
    closesAt: openingToMoment.format("LT"),
  };

  const write = message => i18n._(message, i18nValues);

  return switchF(
    [() => diffHours >= 1, () => write(messages.pharmacyIsOpenWithHours)],
    [() => true, () => write(messages.pharmacyIsOpenWithMinutes)],
  );
};

const closedMessage = (i18n, moment, openingFromMoment) => {
  const isSameDay = moment.isSame(openingFromMoment, "day");
  const diffHours = openingFromMoment.diff(moment, "hours", true);
  const diffMinutes = openingFromMoment.diff(moment, "minutes");

  const i18nValues = {
    numberOfHours: Math.round(diffHours),
    numberOfMinutes: diffMinutes,
    opensAt: openingFromMoment.format("LT"),
    weekday: openingFromMoment.format("dddd"),
    date: openingFromMoment.format("L"),
  };

  const write = message => i18n._(message, i18nValues);

  return switchF(
    [
      () => isSameDay && diffHours >= 1,
      () => write(messages.pharmacyIsClosedOpensWithHours),
    ],
    [() => isSameDay, () => write(messages.pharmacyIsClosedOpensWithMinutes)],
    [() => true, () => write(messages.pharmacyIsClosed)],
  );
};

const InnerPharmacyOpeningTimeSpan = props =>
  switchF(
    [
      () => props.isOpen,
      () =>
        openedMessage(
          props.i18n,
          props.germanServerTimeMoment,
          props.openingToMoment,
        ),
    ],
    [
      () => !props.isOpen,
      () =>
        closedMessage(
          props.i18n,
          props.germanServerTimeMoment,
          props.openingFromMoment,
        ),
    ],
  );

const mapStateToProps = state => ({
  germanServerTimeMoment: selectGermanServerTimeMoment(state),
});

const enhance = compose(withLingui, connect(mapStateToProps));

const PharmacyOpeningTimeSpan = enhance(InnerPharmacyOpeningTimeSpan);

PharmacyOpeningTimeSpan.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openingFromMoment: PropTypes.object.isRequired,
  openingToMoment: PropTypes.object.isRequired,
};

InnerPharmacyOpeningTimeSpan.propTypes = {
  germanServerTimeMoment: PropTypes.object.isRequired,

  ...PharmacyOpeningTimeSpan.propTypes,
};

export default PharmacyOpeningTimeSpan;
