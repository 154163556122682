import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";
import { ListItemTextProps } from "@material-ui/core/ListItemText";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface ListItemButtonWithIconProps {
  secondaryText?: ListItemTextProps["secondary"];
  text: ListItemTextProps["primary"];
  icon: IconProp;
  onClickEvent: () => void;
}

export const ListItemButtonWithIcon: React.SFC<ListItemButtonWithIconProps> = ({
  text,
  icon,
  onClickEvent,
  secondaryText,
}) => (
  <ListItem button onClick={onClickEvent} divider>
    <ListItemIcon>
      <FontAwesomeIcon icon={icon} />
    </ListItemIcon>
    <ListItemText primary={text} secondary={secondaryText} />
  </ListItem>
);
