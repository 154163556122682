import { createAction, createReducer } from "@apoly-42/apoly-utils";
import { compose, prop } from "ramda";
import { selectAppStateRoot } from "../selectors";

export const selectCheckoutState = compose(
  prop("checkout"),
  selectAppStateRoot,
);

export const setCheckoutStep = createAction("setCheckoutStep");
export const setPaymentMethod = createAction("setPaymentMethod");
export const setBillingAddress = createAction("setBillingAddress");
export const setDeliveryAddress = createAction("setDeliveryAddress");
export const setBuyer = createAction("setBuyer");
export const setTransmission = createAction("setTransmission");

export const selectCheckoutStep = compose(
  prop("checkoutStep"),
  selectCheckoutState,
);
export const selectBillingAddress = compose(
  prop("billingAddress"),
  selectCheckoutState,
);
export const selectDeliveryAddress = compose(
  prop("deliveryAddress"),
  selectCheckoutState,
);
export const selectDeliveryAddressCountry = compose(
  prop("country"),
  prop("deliveryAddress"),
  selectCheckoutState,
);
export const selectBuyer = compose(prop("buyer"), selectCheckoutState);
export const selectTransmission = compose(
  prop("transmission"),
  selectCheckoutState,
);
export const selectPaymentMethod = compose(
  prop("paymentMethod"),
  selectCheckoutState,
);

const reducer = createReducer(
  {
    checkoutStep: "costumerData",
    deliveryAddress: {},
    billingAddress: {},
    transmission: "pickUp",
    buyer: {},
    paymentMethod: "",
  },
  {
    [setCheckoutStep]: (state, { payload }) => ({
      ...state,
      checkoutStep: payload,
    }),
    [setPaymentMethod]: (state, { payload }) => ({
      ...state,
      paymentMethod: payload,
    }),
    [setBillingAddress]: (state, { payload }) => ({
      ...state,
      billingAddress: payload,
    }),
    [setDeliveryAddress]: (state, { payload }) => ({
      ...state,
      deliveryAddress: payload,
    }),
    [setBuyer]: (state, { payload }) => ({ ...state, buyer: payload }),
    [setTransmission]: (state, { payload }) => ({
      ...state,
      transmission: payload,
    }),
  },
);

export default reducer;
