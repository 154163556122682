import { createContainer } from "@apoly-42/apoly-utils";
import { withRouter } from "react-router-dom";
import { lifecycle } from "recompose";

const hasDifferentPathNames = (location, prevLocation) =>
  !location || !prevLocation || location.pathname !== prevLocation.pathname;

// from
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
const ScrollToTopOnTransition = createContainer(
  withRouter,
  lifecycle({
    componentDidUpdate(prevProps) {
      if (hasDifferentPathNames(this.props.location, prevProps.location)) {
        window.scrollTo(0, 0);
      }
    },
  }),
);

export default ScrollToTopOnTransition;
