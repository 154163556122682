import {
  faCheck,
  faQuestion,
  faSync,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import { Button, Fade, IconButton, Paper, Typography } from "@material-ui/core";
import { css } from "@emotion/core";
import jsQR from "jsqr";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { ButtonWithIcon } from "../../app-util-components/ButtonWithIcon";
import { appGrid, smUp } from "../../app-utilities/cssClasses";
import { useAsyncEffect } from "../../app-utilities/hook-utils";
import { onImageData, Size, stopMediaStream } from "../media-utils";
import { onResize, useArray, useClientSize } from "../react-utils";
import infoGif from "./assets/2019-05-10_17-19-23.gif";

// https://stackoverflow.com/a/50841972/5627010
const videoDefaultSrcSize = { width: 640, height: 480 };

interface Props {
  onFoundCode(code: string): void;
  onAbortClick(): void;
  zIndex?: number;
}

export function QrCodeScanner({
  onFoundCode,
  onAbortClick,
  zIndex = 1,
}: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [srcSize, setSrcSize] = useState<Size>(videoDefaultSrcSize);
  const [devices, devicesDispatcher] = useArray<MediaDeviceInfo>();
  const clientSize = useClientSize();
  const [mediaError, setMediaError] = useState<any>();
  const [hasMediaStream, setHasMediaStream] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  // the video will stretched to full-screen
  const videoSize = useMemo<Size>(() => {
    const fullWHeight = srcSize.height * (clientSize.width / srcSize.width);

    if (fullWHeight > clientSize.height) {
      // use full-height
      return {
        width: srcSize.width * (clientSize.height / srcSize.height),
        height: clientSize.height,
      };
    }

    return {
      width: clientSize.width,
      height: fullWHeight,
    };
  }, [clientSize, srcSize]);

  const [qrCode, setQrCode] = useState<string>();

  useEffect(() => {
    if (!videoRef.current || !canvasRef.current) {
      return;
    }

    function handleImageData(imageData: ImageData) {
      const code = jsQR(imageData.data, imageData.width, imageData.height);

      if (code && code.data !== qrCode) {
        setQrCode(code.data);
      }
    }

    return onImageData(handleImageData, {
      video: videoRef.current,
      canvas: canvasRef.current,
      videoSize,
    });
  }, [videoRef.current, canvasRef.current]);

  useEffect(() => {
    if (qrCode) {
      onFoundCode(qrCode);
    }
  }, [qrCode]);

  useAsyncEffect(async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      devicesDispatcher({
        type: "set_items",
        items: devices.filter(device => device.kind === "videoinput"),
      });
    } catch (e) {
      setMediaError(e);
    }
  }, []);

  useAsyncEffect(async () => {
    if (!devices.item || !videoRef.current) {
      return;
    }

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          width: videoDefaultSrcSize.width,
          height: videoDefaultSrcSize.height,
          deviceId: { exact: devices.item.deviceId },
        },
      });
      setHasMediaStream(true);

      videoRef.current.srcObject = mediaStream;
      await videoRef.current.play();

      setSrcSize({
        width: videoRef.current.videoWidth,
        height: videoRef.current.videoHeight,
      });

      return () => {
        stopMediaStream(mediaStream);
      };
    } catch (e) {
      setMediaError(e);
    }

    return;
  }, [devices.item, videoRef.current]);

  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(window.scrollY);
    const disposeEvent = onResize(() => setTop(window.scrollY));

    const prevVal = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    return () => {
      disposeEvent();
      document.body.style.overflow = prevVal;
    };
  }, []);

  return (
    <Fragment>
      <canvas
        ref={canvasRef}
        css={css`
          position: absolute;
          width: ${videoSize.width}px;
          height: ${videoSize.height}px;
          z-index: 0;
          left: 0;
          top: ${top}px;
        `}
        width={videoSize.width}
        height={videoSize.height}
      />

      <div
        css={css`
          position: absolute;
          z-index: ${zIndex};
          top: ${top}px;
          left: 0;
          background-color: black;
          width: ${clientSize.width}px;
          height: ${clientSize.height}px;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <video
          width={videoSize.width}
          height={videoSize.height}
          ref={videoRef}
          autoPlay={false}
          muted={false}
          playsInline={true}
          css={css({
            width: videoSize.width,
            height: videoSize.height,
          })}
        />
      </div>

      <div
        onClick={() => isInfoVisible && setIsInfoVisible(false)}
        css={css`
          position: absolute;
          z-index: ${isInfoVisible ? zIndex + 2 : zIndex + 1};
          width: ${clientSize.width}px;
          height: ${clientSize.height}px;
          left: 0;
          top: ${top}px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <Fade in={isInfoVisible}>
          <Paper
            elevation={2}
            onClick={e => {
              // damit innerhalb des papers geklickt werden kann, darf es nicht anaäußeren click-handler übvergeben werden!
              e.stopPropagation();
            }}
            css={css`
              padding: 24px;
              max-width: ${clientSize.width}px;
              height: ${clientSize.height}px;
              display: grid;
              grid-gap: 16px;
              grid-template-rows: min-content min-content minmax(0, 1fr) min-content;
              align-items: center;
              ${smUp} {
                grid-template-rows: min-content min-content auto min-content;
                height: auto;
                max-height: ${clientSize.height}px;
              }
            `}
          >
            <Typography variant="h5">
              <Trans>Was muss ich scannen?</Trans>
            </Typography>
            <Typography>
              <Trans>
                Gehen Sie zum Abholautomaten an der Außenfassade Ihrer Apotheke.
                Scannen Sie dort den QR-Code auf dem Bildschirm des
                Abholautomaten ab:
              </Trans>
            </Typography>
            <img
              src={infoGif}
              alt="QRCode-scan Erklärung"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                justifySelf: "center",
              }}
            />
            <ButtonWithIcon
              iconLeft={true}
              icon={faCheck}
              variant="contained"
              color="secondary"
              onClick={() => setIsInfoVisible(false)}
            >
              Verstanden
            </ButtonWithIcon>
          </Paper>
        </Fade>
      </div>

      {mediaError && (
        <div
          css={css`
            position: absolute;
            z-index: ${zIndex + 1};
            width: ${clientSize.width}px;
            height: ${clientSize.height}px;
            left: 0;
            top: ${top}px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Paper
            elevation={2}
            css={appGrid}
            style={{ padding: 24, maxWidth: 360 }}
          >
            <Typography variant="h5">
              <Trans>Das hat leider nicht geklappt</Trans>
            </Typography>
            <Typography>
              <Trans>
                Wir können leider nicht auf Ihre Kamera zugreifen,
                möglicherweise unterstützt Ihr Gerät oder Ihr Browser die
                Funktion nicht, Sie haben uns die Berechtigung zum Zugriff auf
                Ihre Kamera verwehrt oder Sie haben keine Kamera an Ihrem Gerät.
              </Trans>
            </Typography>
            <Typography>
              <Trans>
                Bitte nutzen Sie die PIN-Eingabe am Bildschirm des
                Abholautomaten, um Ihre Produkte abzuholen.
              </Trans>
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={onAbortClick}
            >
              <Trans>Zurück zur Reservierungsübersicht</Trans>
            </Button>
          </Paper>
        </div>
      )}

      {!hasMediaStream && !mediaError && (
        <div
          css={css`
            position: absolute;
            z-index: ${zIndex + 1};
            width: ${clientSize.width}px;
            height: ${clientSize.height}px;
            left: 0;
            top: ${top}px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Paper
            elevation={2}
            css={appGrid}
            style={{ padding: 24, maxWidth: 360 }}
          >
            <Typography variant="h5">
              <Trans>Warte auf Kamerazugriff...</Trans>
            </Typography>
            <Typography>
              <Trans>
                Um Ihre Produkte auszulagern, müssen Sie den QR-Code auf dem
                Bildschirm des Abholautomaten mit Ihrer Kamera scannen. Dazu
                benötigen wir Zugriff auf Ihre Kamera.
              </Trans>
            </Typography>
            <Button onClick={onAbortClick}>
              <Trans>Zurück zur Reservierungsübersicht</Trans>
            </Button>
          </Paper>
        </div>
      )}

      <div
        css={css`
          position: absolute;
          z-index: ${zIndex + 1};
          left: 0;
          width: ${clientSize.width}px;
          top: ${top}px;
          display: flex;
          align-items: center;
          margin-top: 24px;
        `}
      >
        <ButtonWithIcon
          css={css`
            margin-left: 16px;
            background-color: hsla(189, 22%, 20%, 0.8);
          `}
          variant="outlined"
          color="primary"
          onClick={() => setIsInfoVisible(true)}
          icon={faQuestion}
        >
          Was muss ich scannen
        </ButtonWithIcon>
        <IconButton
          css={css`
            margin-left: auto;
            margin-right: 16px;
            background-color: hsla(189, 22%, 50%, 0.2);
          `}
          color="primary"
          onClick={onAbortClick}
        >
          <FontAwesomeIcon
            style={{ fontSize: 16, width: 24, height: 24 }}
            icon={faTimes}
          />
        </IconButton>
      </div>

      {devices.items && devices.items.length > 1 && (
        <div
          css={css`
            position: absolute;
            z-index: ${zIndex + 1};
            width: ${clientSize.width}px;
            left: 0;
            bottom: -${top}px;
            display: flex;
            justify-content: center;
          `}
        >
          <IconButton
            css={css`
              margin-bottom: 24px;
              background-color: hsla(189, 22%, 50%, 0.2);
            `}
            color="primary"
            onClick={() => devicesDispatcher({ type: "toggle_next_item" })}
          >
            <FontAwesomeIcon
              style={{ fontSize: 16, width: 24, height: 24 }}
              icon={faSync}
            />
          </IconButton>
        </div>
      )}
    </Fragment>
  );
}
