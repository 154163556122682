import * as React from "react";

import { AppHelmetI18n } from "../../app-util-components/AppHelmetI18n";
import { BRAND_NAME } from "../../environment";
import { Footer } from "./Footer";
import { NavBar } from "./NavBar";

const messages = {
  defaultPageTitle: {
    id: "defaultPageTitle",
    message: "{brand} | digital healthcare",
  },
};

export class DefaultPage extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <AppHelmetI18n
          pageTitleMessage={messages.defaultPageTitle}
          pageTitleValues={{ brand: BRAND_NAME }}
        />
        <NavBar />

        {this.props.children}

        <Footer />
      </React.Fragment>
    );
  }
}
