import { Moment } from "moment";
import { DbArticle } from "../app-utilities/app-types";
import { EnhancedOpeningTime } from "./apolyApi";

export type PACKAGE_OPENING_STATE =
  | "not_available"
  | "is_sofort_verfugbar"
  | "is_closing_soon_but_pss_available"
  | "is_closing_soon_and_needs_confirmation"
  | "is_closed_but_pss_available"
  | "is_closed_and_needs_confirmation";

const stateOrder: PACKAGE_OPENING_STATE[] = [
  "not_available",
  "is_closed_and_needs_confirmation",
  "is_closing_soon_and_needs_confirmation",
  "is_closed_but_pss_available",
  "is_closing_soon_but_pss_available",
  "is_sofort_verfugbar",
];

export function getOpeningStateForArticles(
  now: Moment,
  articles: DbArticle[],
  nextOpeningTime: EnhancedOpeningTime,
): PACKAGE_OPENING_STATE {
  if (articles.length <= 0) {
    return "not_available";
  }

  const states = articles.map(article => {
    return getPackageOpeningState(now, article, nextOpeningTime);
  });

  // Der state mit niedrigstem index hat höchste prio
  // wir können also den minimum-index aus einer liste bestimmen,
  // um den allgemein gültigen state für eine lsite von artikeln zu erhalten
  const minIndex = Math.min(...states.map(state => stateOrder.indexOf(state)));

  return stateOrder[minIndex];
}

export function getPackageOpeningState(
  now: Moment,
  article: DbArticle,
  nextOpeningTime: EnhancedOpeningTime,
): PACKAGE_OPENING_STATE {
  const isAvailable = article.availability === "available";
  const isTax2 = article.package.medicine.taxkennzeichen === "tax_2_freie";
  const isPssAvailable = isTax2 && article.pss_availability === "available";
  const { openingToMoment, openingFromMoment } = nextOpeningTime;

  // ziehe 5 minuten vom tatsächlichen schluss ab, da es sonst zu knapp wird bestellung innerhalb des Zeitfensters zu tätigen.
  const closingMom = openingToMoment.clone().subtract("5", "minutes");

  const isOpen = now.isBetween(openingFromMoment, closingMom);

  const isClosingSoon = now.isBetween(
    openingToMoment.clone().subtract("45", "minutes"),
    closingMom, // do not show information
  );

  if (!isAvailable) {
    return "not_available";
  }

  // noch lange geöffnet
  if (isOpen && !isClosingSoon) {
    return "is_sofort_verfugbar";
  }

  // schließt bald, aber tax-2 (freies Produkt) und im Automat vorrätig
  if (isOpen && isClosingSoon && isPssAvailable) {
    return "is_closing_soon_but_pss_available";
  }

  // schließt bald, aber ist Medikament oder ist nicht im Automaten verfügbar
  if (isOpen && isClosingSoon && !isPssAvailable) {
    return "is_closing_soon_and_needs_confirmation";
  }

  // geschlossen, aber im Automaten sofort verfügbar
  if (!isOpen && isPssAvailable) {
    return "is_closed_but_pss_available";
  }

  if (!isOpen && !isPssAvailable) {
    return "is_closed_and_needs_confirmation";
  }

  const errorInfo = { now, article, nextOpeningTime };
  throw new Error(`unknown open-state: ${JSON.stringify(errorInfo)}`);
}

export function throttle<T extends Function>(
  fn: T,
  timeout: number,
  opts: { abortSignal?: AbortSignal } = {},
) {
  let timeoutId: NodeJS.Timeout;

  return () => {
    const localTimeoutId = setTimeout(() => {
      if (opts.abortSignal && opts.abortSignal.aborted) {
        return;
      }

      if (localTimeoutId === timeoutId) {
        fn();
      }
    }, timeout);

    timeoutId = localTimeoutId;
  };
}

export function abortableFn(
  abortSignal: AbortSignal,
  fn: () => void,
): () => void {
  return () => {
    if (abortSignal.aborted) {
      return;
    }
    fn();
  };
}

// onEvent is window.addEventListen, but it returns an unsubscribe-function
export function onEvent<K extends keyof WindowEventMap>(
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions,
): () => void;
export function onEvent(
  type: string,
  listener: EventListener,
  options?: boolean | AddEventListenerOptions,
): () => void;
export function onEvent(
  type: string,
  listener: EventListener,
  options?: boolean | AddEventListenerOptions,
) {
  window.addEventListener(type, listener, options);
  return () => {
    window.removeEventListener(type, listener, options);
  };
}

// wird genutzt um linguis algorithmus zu umgehen
// und es nicht als Komponente zu erkennen sondern als variable die er einsetzen soll
// lokalise kann bei der automatischen Übersetzung die komponenten-placeholder (zb `<0>hallo</0>`)
// nicht erkennen und überschreibt diese dann falsch, deswegen kann mit transEq es leicht umgangen werden,
// sodass innere Komponenten trotzdem als Variablen definiert werden
export function transEq<T>(val: T): T {
  return val;
}
