import { MessageDescriptor } from "@lingui/core/cjs/i18n";
import { useLingui } from "@lingui/react";
import * as React from "react";
import { ReactNode } from "react";
import {
  MuiFormikTextField,
  MuiFormikTextFieldProps,
} from "./MuiFormikTextField";

type MuiFormikTextFieldI18nProps = MuiFormikTextFieldProps & {
  labelMessage?: MessageDescriptor;
  labelValues?: { [key: string]: ReactNode };
  placeholderMessage?: MessageDescriptor;
  placeholderValues?: { [key: string]: ReactNode };
};

export function MuiFormikTextFieldI18n({
  labelMessage,
  labelValues,
  label,
  placeholderMessage,
  placeholderValues,
  placeholder,
  ...rest
}: MuiFormikTextFieldI18nProps) {
  const { i18n } = useLingui();
  return (
    // @ts-ignore
    <MuiFormikTextField
      label={labelMessage ? i18n._(labelMessage, labelValues) : label}
      placeholder={
        placeholderMessage
          ? i18n._(placeholderMessage, placeholderValues)
          : placeholder
      }
      {...rest}
    />
  );
}
