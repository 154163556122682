import { switchF, withProp } from "@apoly-42/apoly-utils";
import PropTypes from "prop-types";
import { __, contains, ifElse } from "ramda";
import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { compose } from "recompose";
import { selectLocale } from "../../redux/language/reducer";
import { getUrlForPath, withLanguageCode } from "../../routes/paths";

const metaRobotsText = content => <meta name="robots" content={content} />;

const getRobotsMetaTag = (noIndex, noFollow, follow) =>
  switchF(
    [() => noIndex && noFollow, () => metaRobotsText("noindex,nofollow")],
    [() => noIndex && follow, () => metaRobotsText("noindex,follow")],
    [() => noIndex, () => metaRobotsText("noindex")],
    [() => noFollow, () => metaRobotsText("nofollow")],
    [() => true, () => null],
  );

const LANGUAGE_CODES_WITHOUT_DEFAULT = ["en", "tr", "ru", "ar", "de"];

const getCanonicalUrl = (canonicalBase, languageCode) =>
  ifElse(
    contains(__, LANGUAGE_CODES_WITHOUT_DEFAULT),
    withLanguageCode(canonicalBase),
    () => canonicalBase,
  )(languageCode);

const InnerHelmetComponent = props => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{props.pageTitle}</title>

    {props.description && (
      <meta name="description" content={props.description} />
    )}

    {props.keywords && <meta name="keywords" content={props.keywords} />}

    {props.canonicalBase && (
      <link
        rel="canonical"
        href={getCanonicalUrl(props.canonicalBase, props.locale)}
      />
    )}
    {props.canonicalBase && !props.noLanguageRef && (
      <link
        rel="alternate"
        hrefLang="x-default"
        href={withLanguageCode(props.canonicalBase, "de")}
      />
    )}

    {props.canonicalBase &&
      !props.noLanguageRef &&
      LANGUAGE_CODES_WITHOUT_DEFAULT.map(languageCode => (
        <link
          key={`${props.canonicalBase}-${props.languageCode}`}
          rel="alternate"
          hrefLang={languageCode}
          href={withLanguageCode(props.canonicalBase, languageCode)}
        />
      ))}

    {getRobotsMetaTag(props.noIndex, props.noFollow, props.follow)}
  </Helmet>
);

const enhance = compose(
  connect(state => ({ locale: selectLocale(state) })),
  withProp("canonicalBase", props => props.path && getUrlForPath(props.path)),
);

const HelmetComponent = enhance(InnerHelmetComponent);

HelmetComponent.propTypes = {
  noLanguageRef: PropTypes.bool,
  pageTitle: PropTypes.any.isRequired,
  keywords: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
  noIndex: PropTypes.bool,
  noFollow: PropTypes.bool,
  follow: PropTypes.bool,
};

HelmetComponent.defaultProps = {
  noLanguageRef: false,
  keywords: null,
  description: null,
  path: null,
  noIndex: false,
  noFollow: false,
  follow: false,
};

InnerHelmetComponent.propTypes = {
  canonicalBase: PropTypes.string,
  ...HelmetComponent.propTypes,
};

InnerHelmetComponent.defaultProps = {
  canonicalBase: null,
};

export default HelmetComponent;
