import { LinguiEuro, LinguiMessage } from "@apoly-42/apoly-components";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import {
  faBoxCheck,
  faClock,
  faHome,
  faShippingFast,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defineMessage, Trans } from "@lingui/macro";
import {
  Card,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  Radio,
  Theme,
  Typography,
  withTheme,
} from "@material-ui/core";
import { css } from "@emotion/core";
import { Moment } from "moment-timezone/moment-timezone";
import * as React from "react";

import { PharmacyThemeContext } from "../../../app-theme/pharmacy-theme/pharmacyTheme";
import { DbArticle } from "../../../app-utilities/app-types";
import {
  appGrid,
  flex,
  gridArea,
  smUp,
} from "../../../app-utilities/cssClasses";
import PharmacyLogoDefault from "../../../deprecated/deprecated-apoly-app/components/pharmacyLogoDefault/PharmacyLogoDefault";
import PharmacyOpeningTime from "../../../deprecated/deprecated-apoly-app/components/pharmacyOpeningTImes/PharmacyOpeningTime";
import { commonMessages } from "../../../deprecated/deprecated-apoly-app/constants/messages/commonMessages";
import { EnhancedPharmacy } from "../../apolyApi";
import { getOpeningStateForArticles, PACKAGE_OPENING_STATE } from "../../utils";
import { DeliveryType } from "./OtcOrderTypes";

const PharmacyWithLogo = css`
  ${appGrid};
  grid-template-columns: 16.66% 1fr;
  grid-template-areas: "text text";
  ${smUp} {
    grid-template-areas: "image text";
  }
`;

const pharmacyCard = css`
  margin-left: 2px;
  padding: 16px;
  max-width: 90%;
  @media (max-width: 600px) {
    padding: 8px;
  }
`;

const logo = css`
  margin: auto;
  height: auto;
  width: 80%;
  max-width: 90%;
`;

const messages = {
  infoPssAbholerForTax2: defineMessage({
    id: "infoPssAbholerForTax2",
    message:
      "Ihre Apotheke schickt Ihnen nach Kaufabschluss Ihren Abholcode per E-Mail zu. Danach können Sie die Ware zu jeder Tages- und Nachtzeit abholen.",
  }),
  infoPssAbholerForTax2ButNotAvailable: defineMessage({
    id: "infoPssAbholerForTax2ButNotAvailable",
    message:
      '"Ihre Produkte sind derzeit nicht im Automaten eingelagert. Die Produkte können durch das Personal erst nach Öffnung der Apotheke ({shortDay}, {time} Uhr) in den Abholautomaten eingelagert werden. Nach erfolgreicher Einlagerung schickt Ihnen die Apotheke Ihren Abholcode per E-Mail zu. Danach können Sie die Ware zu jeder Tages- und Nachtzeit abholen.',
  }),
  infoPssAbholerMedicine: defineMessage({
    id: "infoPssAbholerinfoPssAbholerMedicine",
    message:
      "Sie möchten ein Medikament kaufen. Zur Abklärung von Wirkung, Nebenwirkungen und Wechselwirkungen wird die Apotheke Sie telefonisch kontaktieren. Hiernach schickt Ihnen die Apotheke Ihren Abholcode per E-Mail zu. Danach können Sie die Ware zu jeder Tages- und Nachtzeit abholen.",
  }),
  pharmacyClosed: defineMessage({
    id: "pharmacyClosed",
    message:
      "Apotheke momentan geschlossen, Ihre Bestellung ist voraussichtlich verfügbar ab {shortDay} {time} Uhr",
  }),
  infoPssAbholerAtNightForMedicine: defineMessage({
    id: "infoPssAbholerAtNightForMedicine",
    message:
      "Ihre Apotheke muss die bestellten Medikamente prüfen und schickt Ihnen dann Ihren Abholcode per E-Mail zu. Danach können Sie die Ware zu jeder Tages- und Nachtzeit abholen.",
  }),
};

interface Props {
  deliveryType: DeliveryType;
  setDeliveryType: (deliveryType: DeliveryType) => void;
  theme: Theme;
  enhancedPharmacy: EnhancedPharmacy;
  now: Moment;
  articles: DbArticle[];
}

function PssInfo(props: {
  state: PACKAGE_OPENING_STATE;
  theme: Theme;
  openingFromMoment: Moment;
  articles: DbArticle[];
}) {
  if (props.state === "not_available") {
    return (
      <div>
        <Trans>nicht verfügbar</Trans>
      </div>
    );
  }

  const isTax2 = props.articles.every(article => {
    return article.package.medicine.taxkennzeichen === "tax_2_freie";
  });

  if (isTax2) {
    switch (props.state) {
      // wir zeigen tagsüber
      case "is_closing_soon_but_pss_available":
      case "is_closed_but_pss_available":
      case "is_sofort_verfugbar":
        return (
          <div
            css={css`
              display: grid;
              grid-gap: 16px;
              grid-template-columns: min-content 1fr;
              align-items: center;
            `}
          >
            <div
              style={{
                fontSize: 32,
                color: props.theme.palette.text.hint,
              }}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </div>
            <Typography>
              <LinguiMessage message={messages.infoPssAbholerForTax2} />
            </Typography>
          </div>
        );
      case "is_closing_soon_and_needs_confirmation":
      case "is_closed_and_needs_confirmation":
        return (
          <div
            css={css`
              display: grid;
              grid-gap: 16px;
              grid-template-columns: min-content 1fr;
              align-items: center;
            `}
          >
            <div
              style={{
                fontSize: 32,
                color: props.theme.palette.text.hint,
              }}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </div>
            <Typography>
              <LinguiMessage
                message={messages.infoPssAbholerForTax2ButNotAvailable}
                values={{
                  shortDay: props.openingFromMoment.format("dd[.]"),
                  time: props.openingFromMoment.format("HH:mm"),
                }}
              />
            </Typography>
          </div>
        );
    }
  }

  return (
    <div
      css={css`
        display: grid;
        grid-gap: 16px;
        grid-template-columns: min-content 1fr;
        align-items: center;
      `}
    >
      <div
        style={{
          fontSize: 32,
          color: props.theme.palette.text.hint,
        }}
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </div>
      <Typography>
        <LinguiMessage message={messages.infoPssAbholerMedicine} />
      </Typography>
    </div>
  );
}

function InnerSelectDeliveryTypeView({
  deliveryType,
  setDeliveryType,
  theme,
  now,
  articles,
  enhancedPharmacy,
}: Props) {
  if (enhancedPharmacy.nextOpeningTimes.length <= 0) {
    return "Apotheke anscheinend aktuell geschlossen oder im Urlaub";
  }

  const pharmacy = enhancedPharmacy.pharmacy;

  const { openingFromMoment } = enhancedPharmacy.nextOpeningTimes[0];

  const orderOpeningState = getOpeningStateForArticles(
    now,
    articles,
    enhancedPharmacy.nextOpeningTimes[0],
  );

  return (
    <div>
      <FormControl>
        <FormGroup>
          <FormControlLabel
            control={
              <Radio
                checked={deliveryType === DeliveryType.pssReservation}
                onChange={() => setDeliveryType(DeliveryType.pssReservation)}
                value="pssReservation"
                name="receiveProducts"
              />
            }
            label={
              <div
                css={css`
                  display: grid;
                  grid-gap: 8px;
                  grid-template-columns: 40px 1fr;
                  align-items: center;
                `}
              >
                <span
                  style={{
                    fontSize: 16,
                    color:
                      deliveryType === DeliveryType.pssReservation
                        ? theme.palette.secondary.main
                        : theme.palette.action.disabled,
                  }}
                >
                  <FontAwesomeIcon
                    size="2x"
                    transform="shrink-4"
                    icon={faBoxCheck}
                  />
                </span>
                <LinguiMessage message={commonMessages.pssDelivery} />
              </div>
            }
          />

          <FormControlLabel
            control={
              <Radio
                checked={deliveryType === DeliveryType.pickUp}
                onChange={() => setDeliveryType(DeliveryType.pickUp)}
                value="pickUp"
                name="receiveProducts"
              />
            }
            label={
              <div
                css={css`
                  display: grid;
                  grid-gap: 8px;
                  grid-template-columns: 40px 1fr;
                  align-items: center;
                `}
              >
                <span
                  style={{
                    fontSize: 16,
                    color:
                      deliveryType === DeliveryType.pickUp
                        ? theme.palette.secondary.main
                        : theme.palette.action.disabled,
                  }}
                >
                  <FontAwesomeIcon
                    size="2x"
                    transform="shrink-4"
                    icon={faHome}
                  />
                </span>
                <LinguiMessage message={commonMessages.pickUp} />
              </div>
            }
          />

          <FormControlLabel
            control={
              <Radio
                checked={deliveryType === DeliveryType.courier}
                onChange={() => setDeliveryType(DeliveryType.courier)}
                value="courier"
                name="receiveProducts"
              />
            }
            label={
              <div
                css={css`
                  display: grid;
                  grid-gap: 8px;
                  grid-template-columns: 40px 1fr;
                  align-items: center;
                `}
              >
                <span
                  style={{
                    fontSize: 16,
                    color:
                      deliveryType === DeliveryType.courier
                        ? theme.palette.secondary.main
                        : theme.palette.action.disabled,
                  }}
                >
                  <FontAwesomeIcon
                    size="2x"
                    transform="shrink-4"
                    icon={faShippingFast}
                  />
                </span>
                <LinguiMessage
                  message={commonMessages.deliveryForExtraCharge}
                  values={{
                    price: (
                      <LinguiEuro
                        value={Number(
                          enhancedPharmacy.nextCourierTimeSpans[0].price,
                        )}
                      />
                    ),
                  }}
                />
              </div>
            }
          />
        </FormGroup>
      </FormControl>

      <Collapse
        in={
          deliveryType === DeliveryType.pickUp ||
          deliveryType === DeliveryType.pssReservation
        }
      >
        <div style={{ padding: "8px 0px" }}>
          <Card css={pharmacyCard}>
            <div css={PharmacyWithLogo}>
              <Hidden xsDown={true}>
                <div css={[gridArea("image"), flex]}>
                  <PharmacyThemeContext>
                    {pharmacyThemeCtx =>
                      pharmacyThemeCtx.logo ? (
                        <img src={pharmacyThemeCtx.logo} css={logo} />
                      ) : (
                        <PharmacyLogoDefault logoLarge={true} css={logo} />
                      )
                    }
                  </PharmacyThemeContext>
                </div>
              </Hidden>
              <div css={gridArea("text")}>
                <Grid container={true} spacing={2} style={{ height: "100%" }}>
                  <Grid item={true} xs={12}>
                    <Typography variant="h6">{pharmacy.name}</Typography>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Typography>
                      {pharmacy.address.street}, {pharmacy.address.zip}{" "}
                      {pharmacy.address.city}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Divider />
              </Grid>
              <Grid item={true} xs={12}>
                <Collapse in={deliveryType === DeliveryType.pssReservation}>
                  <PssInfo
                    openingFromMoment={openingFromMoment}
                    state={orderOpeningState}
                    theme={theme}
                    articles={articles}
                  />
                </Collapse>
                <Collapse in={deliveryType === DeliveryType.pickUp}>
                  {enhancedPharmacy && (
                    <div
                      css={css`
                        display: grid;
                        grid-gap: 8px;
                        grid-template-columns: min-content 1fr;
                        align-items: center;
                      `}
                    >
                      <div
                        style={{
                          fontSize: 32,
                          color: theme.palette.text.hint,
                        }}
                      >
                        <FontAwesomeIcon icon={faClock} />
                      </div>
                      <Typography>
                        <PharmacyOpeningTime
                          enhancedNextOpeningTimes={
                            enhancedPharmacy.nextOpeningTimes
                          }
                          isOpen={enhancedPharmacy.isOpen}
                        />
                      </Typography>
                    </div>
                  )}
                </Collapse>
              </Grid>
            </Grid>
          </Card>
        </div>
      </Collapse>
      {/* padding to fix cut radio button and cut add Address Button */}
    </div>
  );
}

export const SelectDeliveryTypeView = withTheme(InnerSelectDeliveryTypeView);
