import { captureException } from "@sentry/browser";
import * as React from "react";
import {
  StorageConsumer,
  StorageContextProps,
  StorageValue,
} from "./StorageContext";

interface SyncStorageWithContextProps<T extends string> {
  storageValue: StorageValue<T>;
  storage: Storage;
}

class SyncStorageValueWithContext<T extends string> extends React.PureComponent<
  SyncStorageWithContextProps<T>
> {
  componentDidMount() {
    this.syncLocalStorage();
  }

  componentDidUpdate(prevProps: SyncStorageWithContextProps<T>) {
    if (prevProps.storageValue.value !== this.props.storageValue.value) {
      this.syncLocalStorage();
    }
  }

  syncLocalStorage() {
    const { key, value } = this.props.storageValue;

    try {
      if (value === null) {
        this.props.storage.removeItem(key);
      } else {
        this.props.storage.setItem(key, value);
      }
    } catch (e) {
      // https://developer.mozilla.org/de/docs/Web/API/Storage/setItem#Fehler => Fehler können bei localStorage vorkommen, zb privater modus in safari
      captureException(e);

      if (process.env.NODE_ENV === "development") {
        // tslint:disable-next-line:no-console
        console.error(
          e,
          value === null
            ? `failed to removeItem for key ${key} (da value null ist)`
            : `failed to set storage for ${key} to "${value}"`,
        );
      }
    }
  }

  render() {
    return null;
  }
}

export const SyncStorageWithContext: React.SFC<{
  storage: Storage;
}> = props => (
  <StorageConsumer>
    {ctx =>
      Object.keys(ctx).map(key => (
        <SyncStorageValueWithContext
          key={ctx[key as keyof StorageContextProps].key}
          storage={props.storage}
          storageValue={ctx[key as keyof StorageContextProps]}
        />
      ))
    }
  </StorageConsumer>
);
