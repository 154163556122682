import { WithIcon } from "@apoly-42/apoly-components";
import {
  faChevronDoubleRight,
  faFrown,
} from "@fortawesome/pro-regular-svg-icons";
import { Button, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import * as React from "react";

import { appMessages } from "../../app-utilities/appMessages";
import { addressToString } from "../../app-utilities/toStringUtils";
import { Address } from "../../app/apolyApi";
import { FluidTypography } from "../FluidTypography";
import { LinguiMessage } from "../LinguiMessage";

interface SelectAddressProps {
  addresses: Address[];
  onSubmit: (address: Address) => void;
  onBackButtonClick: () => void;
}

const messages = {
  selectAddressLabel: {
    id: "selectAddressLabel",
    message: "Wählen Sie Ihre Adresse aus",
  },
  addressNotFound: {
    id: "addressNotFound",
    message: "Wir konnten Ihre Adresse leider nicht finden",
  },
  checkYourInput: {
    id: "checkYourInput",
    message:
      "Bitte überprüfen Sie Ihre Eingaben und geben Sie eine korrekte Straße ein.",
  },
  selectAddress: {
    id: "selectAddress",
    message: "Adresse auswählen",
  },
};

export class SelectAddress extends React.PureComponent<SelectAddressProps> {
  state = {
    addressIndex: 0,
  };

  render() {
    const { onBackButtonClick, onSubmit, addresses } = this.props;

    return (
      <div style={{ height: "100%", display: "grid", gridGap: 16 }}>
        {addresses.length > 0 ? (
          <React.Fragment>
            <div style={{ alignSelf: "center" }}>
              <FluidTypography>
                <LinguiMessage message={messages.selectAddressLabel} />
              </FluidTypography>
              <RadioGroup
                onChange={(_, value) =>
                  this.setState({ addressIndex: Number(value) })
                }
                value={this.state.addressIndex.toString()}
                name="adddresses"
              >
                {addresses.map((address, i) => (
                  <FormControlLabel
                    key={i}
                    value={i.toString()}
                    control={<Radio />}
                    label={addressToString(address)}
                  />
                ))}
              </RadioGroup>
            </div>
            <div style={{ alignSelf: "flex-end", display: "flex" }}>
              <Button onClick={onBackButtonClick}>
                <LinguiMessage message={appMessages.editAddressInput} />
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onSubmit(addresses[this.state.addressIndex])}
                style={{ marginLeft: "auto" }}
              >
                <WithIcon icon={faChevronDoubleRight}>
                  <LinguiMessage message={appMessages.selectAddress} />
                </WithIcon>
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <FluidTypography>
              <WithIcon icon={faFrown}>
                <LinguiMessage message={messages.checkYourInput} />
              </WithIcon>
            </FluidTypography>
            <Button
              style={{ alignSelf: "flex-end" }}
              variant="contained"
              color="secondary"
              onClick={onBackButtonClick}
            >
              <LinguiMessage message={appMessages.editAddressInput} />
            </Button>
          </React.Fragment>
        )}
      </div>
    );
  }
}
