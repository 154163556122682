import { LinguiMessage } from "@apoly-42/apoly-components";
import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { commonMessages } from "../../../../../constants/messages/commonMessages";

const ClosureProductsTableHead = () => (
  <Grid container spacing={2}>
    <Grid item xs={8}>
      <Typography variant="subtitle1">
        <LinguiMessage message={commonMessages.medicine} />
      </Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography variant="subtitle1">
        <LinguiMessage message={commonMessages.amount} /> x{" "}
        <LinguiMessage message={commonMessages.price} />
      </Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography align="right" variant="subtitle1">
        <LinguiMessage message={commonMessages.totalAmount} />
      </Typography>
    </Grid>
  </Grid>
);

export default ClosureProductsTableHead;
