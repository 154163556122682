import { ButtonWithIcon } from "@apoly-42/material-ui";
import { faExchange, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Trans } from "@lingui/macro";
import {
  Button,
  Divider,
  Hidden,
  IconButton,
  SwipeableDrawer,
  Theme,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { css } from "@emotion/core";
import React, { useContext, useState } from "react";

import { PharmacyThemeCtx } from "../../app-theme/pharmacy-theme/pharmacyTheme";
import { LinguiMessage } from "../../app-util-components/LinguiMessage";
import { SizedFaIcon } from "../../app-util-components/SizedFaIcon";
import {
  appGrid,
  contentContainer,
  flex,
  flexAlignCenter,
  flexRight,
  lgUp,
  mdUp,
  smUp,
} from "../../app-utilities/cssClasses";
import { usePharmacyValues } from "../PharmacyContext";
import { PharmacyChannel } from "../routes-helper";
import { AppMuiTheme } from "./AppMuiTheme";
import { AboutCompany } from "./footer/AboutCompany";
import { ChangeLocale } from "./footer/ChangeLocale";
import { FootNotes } from "./footer/FootNotes";
import { LawAgreements } from "./footer/LawAgreements";
import { MoreInformation } from "./footer/MoreInformation";
import { PossiblePaymentMethods } from "./footer/PossiblePaymentMethods";
import { SafeWithBrand } from "./footer/SafeWithBrand";
import { SocialMedia } from "./footer/SocialMedia";

const footer = (theme: Theme) => css`
  background-color: ${theme.palette.grey[900]};
  padding-bottom: 16px;
`;

const messages = {
  followUs: {
    id: "followUs",
    message: "Follow us now!",
  },
  hideFooter: {
    id: "hide footer",
    message: "hide footer",
  },
  showFooter: {
    id: "show footer",
    message: "Impressum, Datenschutz & mehr",
  },
};

const footerElement = css`
  margin-right: 8px;
`;

const footerGrid = css`
  ${appGrid};
  ${contentContainer};
  grid-template-columns: 1fr;
  ${smUp} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${lgUp} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const dividerInGrid = css`
  grid-column: span 1;
  ${smUp} {
    grid-column: span 2;
  }
  ${mdUp} {
    grid-column: span 4;
  }
`;

const FooterDivider = () => (
  <div css={dividerInGrid}>
    <Divider />
  </div>
);

export function Footer() {
  const pharmacyCtx = usePharmacyValues();
  const theme = useTheme<Theme>();
  const pharmacyTheme = useContext(PharmacyThemeCtx);

  const [isFooterOpen, setIsFooterOpen] = useState(false);

  function toggleIsFooterOpen() {
    setIsFooterOpen(!isFooterOpen);
  }

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          marginTop: 40,
          marginRight: 8,
          marginBottom: 8,
        }}
      >
        <div style={{ flex: 1, display: "flex" }}>
          <div style={{ margin: "auto" }}>
            <LawAgreements {...pharmacyCtx} />
          </div>
        </div>

        <Button onClick={toggleIsFooterOpen} style={{ marginLeft: 16 }}>
          <Trans>Links & mehr</Trans>
        </Button>
      </div>
      <SwipeableDrawer
        anchor="bottom"
        open={isFooterOpen}
        onClose={toggleIsFooterOpen}
        onOpen={toggleIsFooterOpen}
      >
        <div>
          <AppMuiTheme type="dark" palette={pharmacyTheme.palette}>
            <div css={footer(theme)}>
              <Hidden mdUp={true}>
                <div css={[footerElement, flex]}>
                  <ButtonWithIcon
                    css={flexRight}
                    icon={faTimes}
                    iconRight={true}
                    variant="outlined"
                    onClick={toggleIsFooterOpen}
                  >
                    <LinguiMessage message={messages.hideFooter} />
                  </ButtonWithIcon>
                </div>
              </Hidden>
              <div css={footerGrid}>
                <div css={footerElement}>
                  <ChangeLocale />
                </div>
                <Hidden smUp={true}>
                  <FooterDivider />
                </Hidden>
                <div css={footerElement}>
                  <MoreInformation />
                </div>
                <Hidden lgUp={true}>
                  <FooterDivider />
                </Hidden>
                <div css={footerElement}>
                  <PossiblePaymentMethods />
                </div>
                <Hidden only={["sm", "md"]}>
                  <FooterDivider />
                </Hidden>
                <div css={footerElement}>
                  <AboutCompany />
                </div>
                <Hidden lgUp={true}>
                  <FooterDivider />
                </Hidden>
                <div css={footerElement}>
                  <SafeWithBrand />
                </div>
                <Hidden smUp={true}>
                  <FooterDivider />
                </Hidden>
                <div css={footerElement}>
                  <SocialMedia
                    title={<LinguiMessage message={messages.followUs} />}
                  />
                </div>
              </div>
              <div css={flexAlignCenter}>
                <LawAgreements {...pharmacyCtx} />
              </div>
              <div css={contentContainer} style={{ paddingBottom: 16 }}>
                <FootNotes />
              </div>
              <div css={[footerElement, flex]}>
                <ButtonWithIcon
                  css={flexRight}
                  icon={faTimes}
                  iconRight={true}
                  variant="outlined"
                  onClick={toggleIsFooterOpen}
                >
                  <LinguiMessage message={messages.hideFooter} />
                </ButtonWithIcon>
              </div>
              {pharmacyCtx.pharmacyChannel ===
                PharmacyChannel.customerBoard && (
                <div css={flex} style={{ marginTop: 16, marginRight: 16 }}>
                  <IconButton
                    component="a"
                    href="https://apotheken-boards.apoly.at/veritas-apotheke-2af1y/eroeffnungstag"
                    css={flexRight}
                  >
                    <SizedFaIcon icon={faExchange} />
                  </IconButton>
                </div>
              )}
            </div>
          </AppMuiTheme>
        </div>
      </SwipeableDrawer>
    </React.Fragment>
  );
}
