import { OnDidMount, pLocalStorage } from "@apoly-42/apoly-utils";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { compose } from "recompose";
import { selectUser } from "../../redux/auth/reducer";
import { loginPath } from "../../routes/paths";

const InnerPrivateRoute = ({
  user,
  component: Component,
  location,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      user ? (
        <Component {...props} />
      ) : (
        <React.Fragment>
          <OnDidMount
            handler={() =>
              pLocalStorage.setItem("redirectUrlAfterLogIn", location.pathname)
            }
          />
          <Redirect to={loginPath()} />
        </React.Fragment>
      )
    }
  />
);

InnerPrivateRoute.propTypes = {
  user: PropTypes.object,
  ...Route.propTypes,
};

InnerPrivateRoute.defaultProps = {
  user: null,
};

const mapStateToProps = state => ({
  user: selectUser(state),
});
const enhance = compose(connect(mapStateToProps));

const PrivateRoute = enhance(InnerPrivateRoute);

PrivateRoute.propTypes = {
  ...Route.propTypes,
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
