import PropTypes from "prop-types";
import React from "react";
import { withLingui } from "@apoly-42/apoly-components";
import { compose } from "recompose";
import HelmetComponent from "./HelmetComponent";

const InnerHelmetComponentWithMessage = ({
  pageTitle,
  values,
  i18n,
  keywords,
  description,
  ...props
}) => (
  <HelmetComponent
    pageTitle={i18n._(pageTitle, values)}
    keywords={keywords ? i18n._(keywords, values) : undefined}
    description={description ? i18n._(description, values) : undefined}
    {...props}
  />
);

const enhance = compose(withLingui);

const HelmetComponentWithMessage = enhance(InnerHelmetComponentWithMessage);

HelmetComponentWithMessage.propTypes = {
  noLanguageRef: PropTypes.bool,
  pageTitle: PropTypes.any.isRequired,
  keywords: PropTypes.any,
  description: PropTypes.any,
  path: PropTypes.string,
  values: PropTypes.object,
  noIndex: PropTypes.bool,
  noFollow: PropTypes.bool,
  follow: PropTypes.bool,
};

HelmetComponentWithMessage.defaultProps = {
  noLanguageRef: false,
  keywords: null,
  description: null,
  values: undefined,
  path: null,
  noIndex: false,
  noFollow: false,
  follow: false,
};

InnerHelmetComponentWithMessage.propTypes = {
  ...HelmetComponentWithMessage.propTypes,
};

export default HelmetComponentWithMessage;
