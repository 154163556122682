import { WithIcon } from "@apoly-42/apoly-components";
import {
  faExclamationTriangle,
  faInfoCircle,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { faClock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  Radio,
  Theme,
  Typography,
  withTheme,
} from "@material-ui/core";
import { css } from "@emotion/core";
import { FormikFields } from "formik-fields";
import * as React from "react";

import { Link } from "react-router-dom";
import { PharmacyThemeContext } from "../../../../app-theme/pharmacy-theme/pharmacyTheme";
import { AddressDialog } from "../../../../app-util-components/address-utils/AddressDialog";
import { CtaButton } from "../../../../app-util-components/CtaButton";
import { FluidTypography } from "../../../../app-util-components/FluidTypography";
import { LinguiMessage } from "../../../../app-util-components/LinguiMessage";
import {
  appMessages,
  formMessages,
} from "../../../../app-utilities/appMessages";
import {
  appGrid,
  flex,
  gridArea,
  smUp,
} from "../../../../app-utilities/cssClasses";
import { addressToString } from "../../../../app-utilities/toStringUtils";
import PharmacyLogoDefault from "../../../../deprecated/deprecated-apoly-app/components/pharmacyLogoDefault/PharmacyLogoDefault";
import PharmacyOpeningTime from "../../../../deprecated/deprecated-apoly-app/components/pharmacyOpeningTImes/PharmacyOpeningTime";
import { commonMessages } from "../../../../deprecated/deprecated-apoly-app/constants/messages/commonMessages";
import {
  checkoutPath,
  pharmacyShopRouteMailOrder,
} from "../../../../deprecated/deprecated-apoly-app/routes/paths";
import { Pharmacy } from "../../../apolyApi";
import { PharmacyValuesConsumer } from "../../../PharmacyContext";
import { Address, DeliveryType } from "../OtcOrderTypes";
import { SelectDeliveryType } from "../SelectDeliveryType";

const messages = {
  chooseYourDeliveryAddress: {
    id: "chooseYourDeliveryAddress",
    message:
      "Wählen Sie Ihre Lieferadresse aus oder fügen Sie eine neue hinzu.",
  },
  addDeliveryAddress: {
    id: "addDeliveryAddress",
    message: "Fügen Sie bitte eine neue Lieferadresse hinzu.",
  },
  zipNotAvailableForPharmacy: {
    id: "zipNotAvailableForPharmacy",
    message:
      "Ihre ausgewählte Adresse liegt nicht im Liefergebiet dieser Apotheke. Möchten Sie bei dieser Apotheke per Versandhandel bestellen (Lieferung per Post)?",
  },
  switchToMailOrder: {
    id: "switchToMailOrder",
    message: "Postalische Lieferung wählen",
  },
};

type PossDelType =
  | DeliveryType.pickUp
  | DeliveryType.pssReservation
  | DeliveryType.courier;

interface CheckoutDeliveryDataProps {
  onSubmit: (data: {
    deliveryAddress?: Address;
    deliveryType: DeliveryType;
  }) => void;
  onBackClick: () => void;
  deliveryAddress?: Address;
  deliveryType: PossDelType;
  addresses: Address[];
  onSubmitNewAddress: (address: Address) => void;
  theme: Theme;
}

interface CheckoutDeliveryDataState {
  deliveryType: PossDelType;
  addresses: Address[];
  showDialog: boolean;
}

type DeliveryAddressForm = {
  deliveryAddress: Address | undefined;
};

class InnerCheckoutDeliveryData extends React.Component<
  CheckoutDeliveryDataProps,
  CheckoutDeliveryDataState
> {
  state = {
    addresses: this.props.addresses,
    deliveryType: this.props.deliveryType,
    showDialog: false,
  };

  newAddress = (address: Address) => {
    this.setState({ addresses: [...this.state.addresses, address] });
  };

  toggleShowDialog = () =>
    this.setState({ showDialog: !this.state.showDialog });

  setDeliveryType = (deliveryType: PossDelType) =>
    this.setState({ deliveryType });

  validateAddress = (pharmacy: Pharmacy, address: Address | undefined) => {
    if (this.state.deliveryType !== DeliveryType.courier) {
      return undefined;
    }

    if (!address || this.state.addresses.indexOf(address) < 0) {
      return (
        <FluidTypography color="error">
          <LinguiMessage message={formMessages.noEmptyField} />
        </FluidTypography>
      );
    }

    if (pharmacy.availableZips.indexOf(address.zip) < 0) {
      return (
        <div>
          <WithIcon
            iconLeft={true}
            icon={faExclamationTriangle}
            spacing={16}
            iconFontSize={24}
          >
            <FluidTypography type="headline4">
              <LinguiMessage message={messages.zipNotAvailableForPharmacy} />
            </FluidTypography>
          </WithIcon>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 8,
            }}
          >
            <CtaButton
              variant="contained"
              color="secondary"
              component={Link}
              to={checkoutPath(pharmacyShopRouteMailOrder(pharmacy.urlCode))}
            >
              <LinguiMessage message={messages.switchToMailOrder} />
            </CtaButton>
          </div>
        </div>
      );
    }

    return undefined;
  };

  validate = (pharmacy: Pharmacy) => (values: DeliveryAddressForm) => ({
    deliveryAddress: this.validateAddress(pharmacy, values.deliveryAddress),
  });

  render() {
    const { deliveryType, addresses, showDialog } = this.state;

    const {
      onBackClick,
      deliveryAddress,
      onSubmitNewAddress,
      theme,
    } = this.props;

    return (
      <PharmacyValuesConsumer>
        {({ pharmacy, enhancedPharmacy }) =>
          pharmacy && (
            <FormikFields<DeliveryAddressForm>
              validate={this.validate(pharmacy)}
              fields={{
                deliveryAddress: { initialValue: deliveryAddress },
              }}
              onSubmit={values => {
                this.props.onSubmit({
                  deliveryAddress: values.deliveryAddress,
                  deliveryType,
                });
              }}
            >
              {(fields, formikBag) => (
                <React.Fragment>
                  <form css={appGrid} onSubmit={formikBag.handleSubmit}>
                    <div style={{ paddingLeft: 4 }}>
                      {/* padding to be even with cut radio button fix*/}
                      <SelectDeliveryType
                        deliveryType={deliveryType}
                        setDeliveryType={this.setDeliveryType}
                      />
                    </div>

                    {/* padding to fix cut radio button and cut add Address Button */}
                    <Collapse
                      in={deliveryType === DeliveryType.courier}
                      style={{ paddingLeft: 4, paddingBottom: 1 }}
                    >
                      <div css={appGrid}>
                        <FluidTypography>
                          <LinguiMessage
                            message={
                              addresses.length > 0
                                ? messages.chooseYourDeliveryAddress
                                : messages.addDeliveryAddress
                            }
                          />
                        </FluidTypography>

                        {addresses.map((address, i) => (
                          <FormControlLabel
                            key={i}
                            name={fields.deliveryAddress.name}
                            checked={fields.deliveryAddress.value === address}
                            onChange={(_, checked) =>
                              checked &&
                              fields.deliveryAddress.setValue(address)
                            }
                            control={
                              <Radio
                                color={
                                  fields.deliveryAddress.error
                                    ? "secondary"
                                    : "primary"
                                }
                              />
                            }
                            label={addressToString(address)}
                          />
                        ))}

                        {fields.deliveryAddress.error}

                        <Button
                          style={{ justifySelf: "flex-start" }}
                          variant={
                            addresses.length > 0 ? "outlined" : "contained"
                          }
                          color={addresses.length > 0 ? undefined : "secondary"}
                          onClick={this.toggleShowDialog}
                        >
                          <WithIcon icon={faPlus} iconLeft={true}>
                            <LinguiMessage
                              message={commonMessages.addAddress}
                            />
                          </WithIcon>
                        </Button>
                      </div>
                    </Collapse>

                    <div style={{ alignSelf: "flex-end", display: "flex" }}>
                      <Button onClick={onBackClick}>
                        <LinguiMessage message={appMessages.back} />
                      </Button>
                      <CtaButton type="submit" style={{ marginLeft: "auto" }}>
                        <LinguiMessage message={appMessages.continue} />
                      </CtaButton>
                    </div>
                  </form>
                  <AddressDialog
                    isOpen={showDialog}
                    onSubmit={address => {
                      onSubmitNewAddress(address);
                      fields.deliveryAddress.setValue(address);
                      this.setState({
                        addresses: [...addresses, address],
                        showDialog: false,
                      });
                    }}
                    onCloseClick={this.toggleShowDialog}
                  />
                </React.Fragment>
              )}
            </FormikFields>
          )
        }
      </PharmacyValuesConsumer>
    );
  }
}

export const CheckoutDeliveryData = withTheme(InnerCheckoutDeliveryData);
