import { FlagIcon } from "@apoly-42/apoly-components";
import { Button } from "@material-ui/core";
import React from "react";
import {
  flex,
  verticalCenteredInFlex,
} from "../../../../../app-utilities/cssClasses";
import {
  LanguageConsumer,
  LanguageContextProps,
} from "../../../../../languages/LanguageContext";

export interface FlagButtonProps {
  language: LanguageContextProps["language"];
  title: string;
  flagCode: string;
}

export const FlagButton: React.SFC<FlagButtonProps> = ({
  language,
  title,
  flagCode,
}) => (
  <LanguageConsumer>
    {lngCtx => (
      <Button
        css={flex}
        onClick={() => lngCtx.language.setValue(language)}
        disabled={lngCtx.language.value === language}
      >
        <FlagIcon css={verticalCenteredInFlex} code={flagCode} />
        {title}
      </Button>
    )}
  </LanguageConsumer>
);
