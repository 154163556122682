/* eslint-disable */
// from https://developers.google.com/web/tools/workbox/guides/advanced-recipes

// TODO implement this via react and callback => registerServiceWorker should be a React-Component so we can show a nicer UI!
// bis das gemacht worden ist zeigen wir erstmal nur alert an wie früher
// eslint-disable-next-line no-unused-vars
function showRefreshUI(registration) {
  // TODO: Display a toast or refresh UI.

  // This demo creates and injects a button.

  const button = document.createElement("button");
  button.style.position = "absolute";
  button.style.bottom = "24px";
  button.style.left = "24px";
  button.textContent = "This site has updated. Please click to see changes.";
  document.body.appendChild(button);

  button.addEventListener("click", () => {
    if (!registration.waiting) {
      // Just to ensure registration.waiting is available before
      // calling postMessage()
      return;
    }

    button.disabled = true;

    registration.waiting.postMessage("skipWaiting");
  });

  document.body.appendChild(button);
}

const showRefreshUiWithAlert = () =>
  alert(
    "New content is available; please refresh the website to get the current version.",
  );

function onNewServiceWorker(registration, callback) {
  if (registration.waiting) {
    // SW is waiting to activate. Can occur if multiple clients open and
    // one of the clients is refreshed.
    // return callback(); => auskommentiert, da es unendlich oft es angezeigt hat wenn mehrere tabs offen waren
  }

  function listenInstalledStateChange() {
    registration.installing.addEventListener(
      "statechange",
      event => event.target.state === "installed" && callback(),
    );
  }

  if (registration.installing) {
    return listenInstalledStateChange();
  }

  // We are currently controlled so a new SW may be found...
  // Add a listener in case a new SW is found,
  return registration.addEventListener(
    "updatefound",
    listenInstalledStateChange,
  );
}

export const registerServiceWorker = () => {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    // wenn das mal von nem CDN kommt muss man möglicherweise weitere checks machen
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then(registration => {
          // Track updates to the Service Worker.
          if (!navigator.serviceWorker.controller) {
            // The window client isn't currently controlled so it's a new service
            // worker that will activate immediately
            return;
          }

          // When the user asks to refresh the UI, we'll need to reload the window
          let preventDevToolsReloadLoop;
          navigator.serviceWorker.addEventListener("controllerchange", () => {
            // Ensure refresh is only called once.
            // This works around a bug in "force update on reload".
            if (preventDevToolsReloadLoop) return;
            preventDevToolsReloadLoop = true;
            console.log("Controller loaded");
            window.location.reload();
          });

          onNewServiceWorker(registration, () => {
            showRefreshUiWithAlert(registration);
          });
        });
    });
  }
};
