import { Grid, Typography } from "@material-ui/core";
import { css } from "@emotion/core";

import { equals } from "ramda";
import React from "react";

import { LinguiMessage } from "../../../../app-util-components/LinguiMessage";
import { appMessages } from "../../../../app-utilities/appMessages";
import { cond } from "../../../../app-utilities/fn-utils";
import cash from "./assets/bar.png";
import mastercard from "./assets/mastercard.png";
import paypal from "./assets/paypal.png";
import sepa from "./assets/sepa.png";
import sofort from "./assets/sofort-ueberweisung.png";
import visa from "./assets/visa.png";

const messages = {
  paymentCash: {
    id: "paymentCash",
    message: "cash",
  },
  paymentSofort: {
    id: "paymentSofort",
    message: "sofort",
  },
  paymentVisa: {
    id: "paymentVisa",
    message: "visa",
  },
  paymentSepa: {
    id: "paymentSepa",
    message: "sepa",
  },
  paymentMastercard: {
    id: "paymentMastercard",
    message: "Mastercard",
  },
  paymentPaypal: {
    id: "paymentPaypal",
    message: "PayPal",
  },
};

const getPaymentMethodImage = cond<string, string>(
  [
    [equals("cash"), () => cash],
    [equals("sofort"), () => sofort],
    [equals("visa"), () => visa],
    [equals("sepa"), () => sepa],
    [equals("mastercard"), () => mastercard],
    [equals("paypal"), () => paypal],
  ],
  () => "",
);

const paymentMethodToMessage = cond(
  [
    [equals("cash"), () => messages.paymentCash],
    [equals("sofort"), () => messages.paymentSofort],
    [equals("visa"), () => messages.paymentVisa],
    [equals("sepa"), () => messages.paymentSepa],
    [equals("mastercard"), () => messages.paymentMastercard],
    [equals("paypal"), () => messages.paymentPaypal],
  ],
  () => appMessages.unknown,
);

export interface PaymentMethodsProps {
  availablePaymentMethods: string[];
}

export const PaymentMethods: React.SFC<PaymentMethodsProps> = ({
  availablePaymentMethods,
}) => (
  <Grid container={true} spacing={2}>
    {availablePaymentMethods &&
      availablePaymentMethods.map(paymentMethod => (
        <Grid item={true} xs={6} key={paymentMethod}>
          <div
            css={css`
              display: grid;
              align-items: center;
              justify-items: center;
            `}
          >
            <img
              css={css`
                max-width: 100%;
                max-height: 32px;
              `}
              alt={paymentMethod}
              src={getPaymentMethodImage(paymentMethod)}
            />
            <Typography variant="caption" color="textSecondary">
              <LinguiMessage message={paymentMethodToMessage(paymentMethod)} />
            </Typography>
          </div>
        </Grid>
      ))}
  </Grid>
);
