import { FontAwesomeIcon, Props } from "@fortawesome/react-fontawesome";
import { css } from "@emotion/core";
import * as React from "react";
import { Omit } from "utility-types";

type FaIconFontSize = 16 | 32 | 48 | 64 | 80 | "inherit";

interface SizedFaIconProps extends Omit<Props, "size"> {
  // Icons should be multiples of 16 otherwise they will appear blurred. see https://github.com/FortAwesome/Font-Awesome/issues/11918#issuecomment-364453553
  // to use another font-size, use className and define font-size there, it will overwrite the number-font-size
  fontSize?: FaIconFontSize;
}

const faIconFontSize = (fontSize: FaIconFontSize) => css`
  font-size: ${fontSize === "inherit" ? "inherit" : `${fontSize}px`};
`;

export class SizedFaIcon extends React.PureComponent<SizedFaIconProps> {
  render() {
    const { fontSize = 16, ...rest } = this.props;

    return <FontAwesomeIcon css={faIconFontSize(fontSize)} {...rest} />;
  }
}
