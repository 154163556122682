import { stringify } from "qs";
import * as React from "react";
import { Helmet } from "react-helmet";
import { both, cond, prop } from "../app-utilities/fn-utils";
import { newQs, toQsLanguage } from "../app-utilities/queryStringInterfaces";
import {
  Languages,
  LanguagesKeys,
  LanguageValues,
} from "../languages/LanguageContext";
import { rfcUrl, RfcUrl } from "../url/index";

export interface AppHelmetProps {
  pageTitle: string;
  noLanguageRef?: boolean;
  keywords?: string;
  description?: string;
  noIndex?: boolean;
  noFollow?: boolean;
  follow?: boolean;
  url?: RfcUrl;
}

type RobotsProps = { noIndex: boolean; noFollow: boolean; follow: boolean };

const metaRobotsText = (
  content: "noindex,nofollow" | "noindex,follow" | "noindex" | "nofollow",
) => <meta name="robots" content={content} />;

const noIndexAndNoFollow = both<RobotsProps>(prop("noIndex"), prop("noFollow"));
const noIndexAndFollow = both<RobotsProps>(prop("noIndex"), prop("follow"));

const getRobotsMetaTag = cond<RobotsProps, React.ReactNode>([
  [noIndexAndNoFollow, () => metaRobotsText("noindex,nofollow")],
  [noIndexAndFollow, () => metaRobotsText("noindex,follow")],
  [prop("noIndex"), () => metaRobotsText("noindex")],
  [prop("noFollow"), () => metaRobotsText("nofollow")],
]);

const urlWithLanguageCode = (url: RfcUrl, lang: Languages) => {
  const mergedQs = newQs(url, toQsLanguage(lang.toString()));

  return rfcUrl({ query: stringify(mergedQs) }, url);
};

const toHref = (url: RfcUrl, lang: Languages) =>
  urlWithLanguageCode(url, lang).toString();

export class AppHelmet extends React.PureComponent<AppHelmetProps> {
  render() {
    const {
      pageTitle,
      keywords,
      description,
      noLanguageRef = false,
      noIndex = false,
      noFollow = false,
      follow = false,
      url,
    } = this.props;

    return (
      <LanguageValues>
        {ctx => (
          <Helmet>
            <meta charSet="utf-8" />
            <title>{pageTitle}</title>

            {description && <meta name="description" content={description} />}

            {keywords && <meta name="keywords" content={keywords} />}

            {url && <link rel="canonical" href={toHref(url, ctx.language)} />}

            {url && !noLanguageRef && (
              <link
                rel="alternate"
                hrefLang="x-default"
                href={toHref(url, Languages.de)}
              />
            )}

            {url &&
              !noLanguageRef &&
              LanguagesKeys.map(languageCode => (
                <link
                  key={languageCode}
                  rel="alternate"
                  hrefLang={languageCode}
                  href={toHref(url, Languages[languageCode])}
                />
              ))}

            {undefined}

            {getRobotsMetaTag({ noIndex, noFollow, follow })}
          </Helmet>
        )}
      </LanguageValues>
    );
  }
}
