import { ListWithBulletPoints } from "@apoly-42/material-ui";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Grid, Theme, WithStyles, withStyles } from "@material-ui/core";
import React from "react";

import { LinguiMessage } from "../../../app-util-components/LinguiMessage";
import { appMessages } from "../../../app-utilities/appMessages";
import { BRAND_NAME } from "../../../environment";
import { FooterHeadline } from "./FooterHeadline";

const messages = {
  MadeInGermany: {
    id: "MadeInGermany",
    message: "made in germany",
  },
  highestAvailability: {
    id: "highestAvailability",
    message: "highest availability",
  },
};

// tslint:disable:jsx-key
export const secureWithBrand: React.ReactNode[] = [
  <LinguiMessage message={appMessages.germanServer} />,
  <LinguiMessage message={messages.MadeInGermany} />,
  <LinguiMessage message={appMessages.sslSecureConnection} />,
  <LinguiMessage message={messages.highestAvailability} />,
];

const styles = (theme: Theme) => ({
  listItem: theme.typography.body1,
});

export const InnerSafeWithBrand: React.SFC<WithStyles<typeof styles>> = ({
  classes,
}) => (
  <Grid container={true} spacing={2}>
    <Grid item={true} xs={12}>
      <FooterHeadline>
        <LinguiMessage
          message={appMessages.safeWithApoly}
          values={{ brand: BRAND_NAME }}
        />
      </FooterHeadline>
    </Grid>
    <Grid item={true} xs={12} style={{ color: "#fff" }}>
      <ListWithBulletPoints
        listArray={secureWithBrand}
        icon={faCheck}
        listItemTextclassName={classes.listItem}
      />
    </Grid>
  </Grid>
);

export const SafeWithBrand = withStyles(styles)(InnerSafeWithBrand);
