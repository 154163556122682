import { Loading } from "@apoly-42/material-ui";
import { Button } from "@material-ui/core";
import { T, uniqBy } from "ramda";
import * as React from "react";

import { appMessages } from "../../app-utilities/appMessages";
import {
  AddressFromInput,
  addressToString,
} from "../../app-utilities/toStringUtils";
import { Address } from "../../app/apolyApi";
import googleAddressToAddressObject, {
  toAddress,
} from "../../app/pages/checkout/geocoderResultToAddress";
import { FluidTypography } from "../FluidTypography";
import { LinguiMessage } from "../LinguiMessage";
import { Load } from "../Load";
import { geocode, LoadGoogle } from "../LoadGoogle";
import { SelectAddress } from "./SelectAddress";
import { prop } from "../../app-utilities/fn-utils";

interface CheckAddressProps {
  address: AddressFromInput;
  onBackButtonClick: () => void;
  validateAddress?: (address: Address) => boolean;
  onSubmit: (address: Address) => void;
}

const messages = {
  unknownAddress: {
    id: "unknownAddress",
    message:
      "Leider konnten wir für Ihre eingegebene Adresse (${addressString}) keinen Ort finden. Bitte überprüfen Sie Ihre Eingabe",
  },
};

export class CheckAddress extends React.PureComponent<CheckAddressProps> {
  filterAddresses = (results: google.maps.GeocoderResult[]) => {
    const { validateAddress = T } = this.props;

    return uniqBy(prop("formatted_address"), results)
      .map(googleAddressToAddressObject)
      .map((googleAddress) => toAddress(googleAddress, this.props.address))
      .filter((address) => address && validateAddress(address)) as Address[];
  };

  render() {
    const { address, onSubmit, onBackButtonClick } = this.props;

    return (
      <LoadGoogle>
        {(googleMaps) =>
          googleMaps ? (
            <Load
              fn={() =>
                geocode(
                  googleMaps,
                  addressToString({
                    city: address.city,
                    street: address.street,
                    zip: address.zip,
                  }),
                )
              }
              crashComponentOnError={false}
            >
              {(apiState) => {
                return (
                  <React.Fragment>
                    {apiState.isLoading && <Loading />}

                    {apiState.response && (
                      <SelectAddress
                        addresses={this.filterAddresses(apiState.response)}
                        onSubmit={onSubmit}
                        onBackButtonClick={onBackButtonClick}
                      />
                    )}

                    {apiState.error && (
                      <div
                        style={{ height: "100%", display: "grid", gridGap: 8 }}
                      >
                        <FluidTypography>
                          <LinguiMessage
                            message={messages.unknownAddress}
                            values={{ addressString: addressToString(address) }}
                          />
                        </FluidTypography>
                        <div style={{ alignSelf: "flex-end", display: "flex" }}>
                          <Button
                            style={{ marginLeft: "auto" }}
                            variant="contained"
                            color="secondary"
                            onClick={onBackButtonClick}
                          >
                            <LinguiMessage
                              message={appMessages.editAddressInput}
                            />
                          </Button>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              }}
            </Load>
          ) : (
            <Loading />
          )
        }
      </LoadGoogle>
    );
  }
}
