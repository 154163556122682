import { MessageDescriptor } from "@lingui/core/cjs/i18n";
import { Hidden, Paper } from "@material-ui/core";
import { css } from "@emotion/core";
import { equals } from "ramda";
import * as React from "react";
import { FluidTypography } from "../../../../app-util-components/FluidTypography";
import { LinguiMessage } from "../../../../app-util-components/LinguiMessage";
import { addAddressToLocalStorage, addressesFromLocalStorage } from "../../../../app-utilities/addressesLocalStorage";
import { appGrid } from "../../../../app-utilities/cssClasses";
import { both, cond, first, throwF, throwImmediately } from "../../../../app-utilities/fn-utils";
import { commonMessages } from "../../../../deprecated/deprecated-apoly-app/constants/messages/commonMessages";
import { PaymentMethod } from "../../../apolyApi";
import { PharmacyValuesConsumer } from "../../../PharmacyContext";
import { CheckoutBillingData } from "../CheckoutBillingData";
import { checkoutDesktopGrid } from "../checkoutClasses";
import { CheckoutClosure, ClosureValues } from "../CheckoutClosure";
import { CheckoutPayment } from "../CheckoutPayment";
import { CheckoutDefaultStep, CompletedCheckoutDefault } from "../checkoutTypes";
import { Address, DeliveryType } from "../OtcOrderTypes";
import { CheckoutDefaultStepper, checkoutDefaultStepperMessages } from "./CheckoutDefaultStepper";
import { CheckoutDeliveryData } from "./CheckoutDeliveryData";

// NEXT TODOS :
// * offizinCheckout mit reintunen

interface CheckoutDefaultProps {
  deliveryType: DeliveryType;
  onSubmit: (val: CompletedCheckoutDefault) => void;
}

interface CheckoutDefaultState {
  step: CheckoutDefaultStep;
  email: string | undefined;
  telephone?: string | undefined;
  billingAddress?: Address | undefined;
  deliveryAddress?: Address | undefined;
  deliveryType: DeliveryType;
  paymentMethod: PaymentMethod | undefined;
}

export interface CompletedCheckoutDefault {
  email: string;
  telephone?: string;
  billingAddress?: Address | undefined;
  deliveryAddress?: Address;
  deliveryType: DeliveryType;
  paymentMethod: PaymentMethod;
  newsletterOptIn: boolean;
  agbOptIn: boolean;
}

const stepToHeadline = cond<CheckoutDefaultStep, MessageDescriptor>(
  [
    [equals(CheckoutDefaultStep.billingData), () => commonMessages.billingDataStep],
    [equals(CheckoutDefaultStep.deliveryData), () => checkoutDefaultStepperMessages.deliveryOrPickup],
    [equals(CheckoutDefaultStep.payment), () => commonMessages.paymentMethod],
    [equals(CheckoutDefaultStep.closure), () => commonMessages.finish],
  ],
  throwF("unknown step"),
);

export class CheckoutDefault extends React.PureComponent<CheckoutDefaultProps, CheckoutDefaultState> {
  state = {
    step: CheckoutDefaultStep.billingData,
    email: undefined,
    telephone: undefined,
    birthday: undefined,
    deliveryAddress: undefined,
    deliveryType: this.props.deliveryType,
    billingAddress: undefined,
    paymentMethod: undefined,
  };

  submit = ({ agbOptIn, newsletterOptIn }: ClosureValues) =>
    // besser als mit dem throw wäre für jeden konkreten step den exakten möglichen state festzulegen,
    // aber das macht es dann auch wieder ein bisschen komplizierter mit nutzlosen checks um für TS den Typen klarzumachen
    this.props.onSubmit({
      agbOptIn,
      newsletterOptIn,
      email: this.state.email || throwImmediately("email not set"),
      telephone: this.state.telephone || throwImmediately("telephone not set"),
      birthday: this.state.birthday || throwImmediately("birthday not set"),
      billingAddress: this.state.billingAddress,
      deliveryAddress: this.state.deliveryAddress,
      deliveryType: this.state.deliveryType,
      paymentMethod: this.state.paymentMethod || throwImmediately("paymentMethod not set"),
    });

  setStep = (step: CheckoutDefaultStep) => this.setState({ step });

  handleDeliveryDataSubmit = (data: { deliveryAddress?: Address; deliveryType: DeliveryType }) => {
    return this.setState({ ...data, step: CheckoutDefaultStep.payment });
  };

  handleBillingDataSubmit = (data: { billingAddress?: Address | undefined; telephone?: string; email: string }) => {
    this.setState({ ...data, step: CheckoutDefaultStep.deliveryData });
  };

  handlePaymentSubmit = (paymentMethod: PaymentMethod) =>
    this.setState({ paymentMethod, step: CheckoutDefaultStep.closure });

  renderPaymentStep = () => (
    <PharmacyValuesConsumer>
      {({ pharmacy }) =>
        pharmacy && (
          <CheckoutPayment
            disableDelivery={false}
            onSubmit={this.handlePaymentSubmit}
            onBackClick={() => this.setStep(CheckoutDefaultStep.deliveryData)}
            initialPaymentMethod={this.state.paymentMethod}
            paymentMethods={
              this.state.deliveryType === DeliveryType.mailOrder ||
              this.state.deliveryType === DeliveryType.pssReservation
                ? pharmacy.paymentMethods.filter((method) => method !== "cash")
                : pharmacy.paymentMethods
            }
          />
        )
      }
    </PharmacyValuesConsumer>
  );

  renderContent = first<CheckoutDefaultStep, React.ReactNode>([
    both(equals(CheckoutDefaultStep.billingData), () => (
      <CheckoutBillingData
        billingAddress={this.state.billingAddress}
        email={this.state.email}
        showTelephone={true}
        telephone={this.state.telephone}
        onSubmit={this.handleBillingDataSubmit}
        addresses={addressesFromLocalStorage}
        onSubmitNewAddress={addAddressToLocalStorage}
        birthday={this.state.birthday}
      />
    )),
    both(equals(CheckoutDefaultStep.deliveryData), () => (
      <CheckoutDeliveryData
        deliveryAddress={this.state.deliveryAddress}
        deliveryType={this.state.deliveryType}
        onBackClick={() => this.setStep(CheckoutDefaultStep.billingData)}
        onSubmit={this.handleDeliveryDataSubmit}
        addresses={addressesFromLocalStorage}
        onSubmitNewAddress={addAddressToLocalStorage}
      />
    )),
    both(equals(CheckoutDefaultStep.payment), this.renderPaymentStep),
    both(equals(CheckoutDefaultStep.closure), () => (
      <CheckoutClosure
        deliveryAddressCountry={this.state.deliveryAddress ? this.state.deliveryAddress.country : undefined}
        deliveryType={this.state.deliveryType}
        onSubmit={this.submit}
        onBackClick={() => this.setStep(CheckoutDefaultStep.payment)}
      />
    )),
  ]);

  render() {
    return (
      <React.Fragment>
        <Hidden smUp={true}>
          <CheckoutDefaultStepper
            {...this.state}
            onEditStepClick={this.setStep}
            renderStepContent={this.renderContent}
          />
        </Hidden>

        <Hidden xsDown={true}>
          <div css={checkoutDesktopGrid}>
            <div>
              <CheckoutDefaultStepper
                css={css({ backgroundColor: "transparent" })}
                {...this.state}
                onEditStepClick={this.setStep}
              />
            </div>
            <Paper elevation={2} css={appGrid} style={{ padding: 16 }}>
              <FluidTypography type="headline3">
                <LinguiMessage message={stepToHeadline(this.state.step)} />
              </FluidTypography>
              <div>{this.renderContent(this.state.step)}</div>
            </Paper>
          </div>
        </Hidden>
      </React.Fragment>
    );
  }
}
