import { Typography } from "@material-ui/core";
import React from "react";
import { LinguiMessage } from "../../../../../../app-util-components/LinguiMessage";
import { commonMessages } from "../../../../constants/messages/commonMessages";

export const getTaxValueForProduct = MWSTCode => {
  if (Number(MWSTCode) === 0) {
    return "13%";
  }
  if (Number(MWSTCode) === 1) {
    return "10%";
  }
  if (Number(MWSTCode) === 2) {
    return "20%";
  }
  return "19%";
};

export const getTaxValueMessage = MWSTCode => (
  <Typography variant="caption" color="textSecondary" align="right">
    <LinguiMessage
      message={commonMessages.inclusiveMwStWithValue}
      values={{
        taxValue: getTaxValueForProduct(MWSTCode),
      }}
    />
  </Typography>
);
