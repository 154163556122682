import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { LinguiMessage } from "../../../app-util-components/LinguiMessage";
import { BRAND_NAME } from "../../../environment";
import { FooterHeadline } from "./FooterHeadline";

const messages = {
  about: {
    id: "about",
    message: "about {brand}",
  },
  orderMedicsAtPharmacy: {
    id: "orderMedicsAtPharmacy",
    message:
      "Order your prescriptions of medicines or your over the counter medicine at your local partner pharmacy anytime!",
  },
};

export const AboutCompany: React.SFC = () => (
  <Grid container={true} spacing={2}>
    <Grid item={true} xs={12}>
      <FooterHeadline>
        <LinguiMessage
          message={messages.about}
          values={{ brand: BRAND_NAME }}
        />
      </FooterHeadline>
    </Grid>
    <Grid item={true} xs={12}>
      <Typography color="textPrimary">
        <LinguiMessage message={messages.orderMedicsAtPharmacy} />
        <sup>1,2,3</sup>
      </Typography>
    </Grid>
  </Grid>
);
