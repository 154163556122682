import { faChevronDoubleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import * as React from "react";

interface CtaButtonProps extends ButtonProps {}

export class CtaButton extends React.PureComponent<CtaButtonProps> {
  render() {
    const { children, style, ...rest } = this.props;

    return (
      <Button
        style={{ display: "flex", alignItems: "center", ...style }}
        variant="contained"
        color="secondary"
        {...rest}
      >
        <span style={{ flex: 1 }}>{children}</span>
        <span style={{ marginLeft: 8, fontSize: 16 }}>
          <FontAwesomeIcon icon={faChevronDoubleRight} />
        </span>
      </Button>
    );
  }
}
