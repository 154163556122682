import {
  FnItemStage1,
  FnItemStage2,
  ItemTypes,
  Stage1Item,
  Stage2Item,
  StrItemStage1,
  StrItemStage2,
} from "./tplItemTypes";

function calcEndDependingFromNextItem<Values>(
  str: string,
  nextItem: Stage2Item<Values> | undefined,
) {
  if (!nextItem) {
    return str.length;
  }

  // if next is string, the start of the next string must be the end of this string
  if (nextItem.type === ItemTypes.StrItemStage2) {
    return nextItem.start;
  }

  // if next is fn, the guaranteed end is also the end for the prev item
  return nextItem.end;
}

function startForStrItem<Values>(
  str: string,
  strItemStage1: StrItemStage1<Values>,
  nextItem: Stage2Item<Values> | undefined,
) {
  return strItemStage1.possibleStart;

  if (strItemStage1.possibleStart < 0 || !strItemStage1.prevItem) {
    return strItemStage1.possibleStart;
  }

  const nextPossibleStart =
    strItemStage1.possibleStart + strItemStage1.tplVar.length;

  const end = calcEndDependingFromNextItem(str, nextItem);

  const checkStr = str.substring(nextPossibleStart, end);

  if (!checkStr) {
    return strItemStage1.possibleStart;
  }

  const res = checkStr.lastIndexOf(strItemStage1.tplVar);

  if (res < 0) {
    return strItemStage1.possibleStart;
  }

  return res + nextPossibleStart;
}

function createFnItemStage2<Values>(
  str: string,
  fnItemStage1: FnItemStage1<Values>,
  nextItem: Stage2Item<Values> | undefined,
): FnItemStage2<Values> {
  return {
    type: ItemTypes.FnItemStage2,
    tplVar: fnItemStage1.tplVar,
    end: calcEndDependingFromNextItem(str, nextItem),
  };
}

function createStrItemStage2<Values>(
  str: string,
  strItemStage1: StrItemStage1<Values>,
  nextItem: Stage2Item<Values> | undefined,
): StrItemStage2 {
  const start = startForStrItem(str, strItemStage1, nextItem);

  return {
    type: ItemTypes.StrItemStage2,
    start,
    end: start >= 0 ? start + strItemStage1.tplVar.length : -1,
    tplVar: strItemStage1.tplVar,
  };
}

function createStage2Item<Values>(
  str: string,
  stage1Item: Stage1Item<Values>,
  nextItem: Stage2Item<Values> | undefined,
) {
  if (stage1Item.type === ItemTypes.StrItemStage1) {
    return createStrItemStage2(str, stage1Item, nextItem);
  } else {
    return createFnItemStage2(str, stage1Item, nextItem);
  }
}

export function createStage2Array<Values>(
  str: string,
  stage1Array: Stage1Item<Values>[],
) {
  let nextItem: Stage2Item<Values>;

  // double reverse; with first reverse() we go from the end to the beginning and then return the correct order with second reverse().
  return stage1Array
    .reverse()
    .map(stage1Item => {
      nextItem = createStage2Item(str, stage1Item, nextItem);
      return nextItem;
    })
    .reverse();
}
