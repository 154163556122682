import firebase from "firebase/app";
import "firebase/auth";
/* eslint-disable react/prop-types */
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import ObserveLoginStateWrapper from "../deprecated-apoly-app/components/auth/ObserveLoginStateWrapper";
import CheckRedirectResultFromProviderSignIn from "../deprecated-apoly-app/containers/auth/CheckRedirectResultFromProviderSignIn";
import HandleCookieConsent from "../deprecated-apoly-app/containers/HandleCookieConsent";
import ScrollToTopOnTransition from "../deprecated-apoly-app/containers/ScrollToTopOnTransition";
import SyncShoppingCartStateWithLocalStorage from "../deprecated-apoly-app/redux/shoppingCart/containers/SyncShoppingCartStateWithLocalStorage";
import { setupAxiosInstanceForApi } from "../deprecated-apoly-app/utilities/apis/apolyApi/apolyApi";
import loadInitTagManager from "../deprecated-apoly-app/utilities/bootstrap/loadInitTagManager";
import setupFirebase from "../deprecated-apoly-app/utilities/bootstrap/setupFirebase";
import { DeprecatedReduxAppProvider } from "./redux-provider";

export const initDeprecatedApp = () => {
  setupAxiosInstanceForApi(process.env.REACT_APP_APOLY_API_BASE_URL);
  setupFirebase();
  loadInitTagManager();
};

export const DeprecatedAppBootstrap = props => (
  <DeprecatedReduxAppProvider>
    <ObserveLoginStateWrapper>
      <BrowserRouter>
        <React.Fragment>
          <ScrollToTopOnTransition />
          <HandleCookieConsent />
          <SyncShoppingCartStateWithLocalStorage />
          <CheckRedirectResultFromProviderSignIn />

          {props.children}
        </React.Fragment>
      </BrowserRouter>
    </ObserveLoginStateWrapper>
  </DeprecatedReduxAppProvider>
);
