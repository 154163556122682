import { switchF, withProp } from "@apoly-42/apoly-utils";
import React from "react";
import { withGoogleMap, withScriptjs } from "react-google-maps";
import { compose, withHandlers, withProps, withPropsOnChange } from "recompose";
import { germanComponentRestrictions } from "../google/googleGeocodingUtilities";

export const GOOGLE_MAP_LIB_URL =
  process.env.NODE_ENV === "production"
    ? // live-key for production.resources (project: apoly) - gilt nur für domains apotheken.apoly.de + apotheken.apoly.at
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyAXgY9hC2OQ4licM8Qi9qXk39zppL4drys&libraries=places"
    : // dev-key for production.resources (project: apoly)
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBVNM51jHS3I7QvPCyAdUJCgzRnp3GYcnk&libraries=places";
const withGoogleMapProps = withProps({
  googleMapURL: GOOGLE_MAP_LIB_URL,
  loadingElement: <div style={{ height: 0 }} />,
  containerElement: <div style={{ height: "100%" }} />,
  mapElement: <div style={{ height: "100%" }} />,
});

export const withLoadGoogleMapScript = compose(
  withGoogleMapProps,
  withScriptjs,
  withProp("google", () => window.google),
);

const withGoogleMapScript = compose(
  withGoogleMapProps,
  withScriptjs,
  // withGoogleMap must come directly after withScriptjs
  withGoogleMap,
  withProp("google", () => window.google),
);

const doGeocoding = (geocoder, google, address, params) =>
  new Promise((resolve, reject) => {
    const checkGeocodeResult = (results, status) =>
      switchF(
        [
          () => status === google.maps.GeocoderStatus.OK,
          () => resolve(results),
        ],
        [
          () => status === google.maps.GeocoderStatus.ZERO_RESULTS,
          () => resolve([]),
        ],
        [() => true, () => reject(status)],
      );

    geocoder.geocode({ address, ...params }, checkGeocodeResult);
  });

export const withGoogleGeocoder = compose(
  withLoadGoogleMapScript,
  withPropsOnChange(["google"], (props) => ({
    // eslint-disable-next-line no-undef
    geocoder: new props.google.maps.Geocoder(),
    // eslint-disable-next-line no-undef
    geocoderOk: props.google.maps.GeocoderStatus.OK,
    geocoderNoResults: props.google.maps.GeocoderStatus.ZERO_RESULTS,
  })),
  withHandlers({
    // params: https://developers.google.com/maps/documentation/javascript/geocoding?hl=de#GeocodingRequests
    geocode: (props) => (address, params) =>
      doGeocoding(props.geocoder, props.google, address, params),
  }),
);

export const withGermanGeocoder = compose(
  withGoogleGeocoder,
  withHandlers({
    geocodeGermanAddress: (props) => (address, params) =>
      props.geocode(address, {
        componentRestrictions: germanComponentRestrictions,
        ...params,
      }),
  }),
);

export default withGoogleMapScript;
