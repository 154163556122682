import { faBars } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLingui } from "@lingui/react";
import { Button } from "@material-ui/core";
import React from "react";

const messages = {
  toggleSideBarMenu: {
    id: "toggleSideBarMenu",
    message: "toggle sidebar menu",
  },
};

export interface ToggleDrawerButtonProps {
  toggleDrawer: () => void;
}

export function ToggleDrawerButton({ toggleDrawer }: ToggleDrawerButtonProps) {
  const { i18n } = useLingui();

  return (
    <Button
      style={{ minWidth: 0 }}
      color="inherit"
      aria-label={i18n._(messages.toggleSideBarMenu)}
      onClick={toggleDrawer}
    >
      <span>
        <FontAwesomeIcon icon={faBars} />
      </span>
    </Button>
  );
}
