import { defineMessage } from "@lingui/macro";

export const commonMessages = {
  sofortAvailable: defineMessage({
    id: "sofortAvailable",
    message: "sofort verfügbar",
  }),
  available: defineMessage({
    id: "available",
    message: "verfügbar",
  }),
  inclusiveMwStWithValue: {
    id: "inclusiveMwStWithValue",
    message: "Inkl. {taxValue} MwSt.",
  },
  inclusiveMwSt: {
    id: "inclusiveMwSt",
    message: "inclusive {taxValue} MwSt.",
  },
  showMedicsForRegion: {
    id: "showMedicsForRegion",
    message: "Show Medics for {region}",
  },
  selectNewRegion: {
    id: "selectNewRegion",
    message: "Select new Region",
  },
  titleRxOrderComplete: {
    id: "titleRxOrderComplete",
    message: "Rezept-Bestellung erfolgreich",
  },
  titleOtcOrderComplete: {
    id: "titleOtcOrderComplete",
    message: "Medikament-Bestellung erfolgreich",
  },
  unknown: {
    id: "unknown",
    message: "unknown",
  },
  address: {
    id: "address",
    message: "address",
  },
  agbError: {
    id: "agbError",
    message: "you have to agree to our terms and conditions",
  },
  agreeToUserDataSecurityPolicy: {
    id: "agreeToUserDataSecurityPolicy",
    message:
      "With this order, you agree to our {websiteAgbLink} and our {privacyPolicyLink}.",
  },
  agb: {
    id: "agb",
    message: "agb",
  },
  backToMedicine: {
    id: "backToMedicine",
    message: "back to medicine",
  },
  amount: {
    id: "amount",
    message: "amount",
  },
  from: {
    id: "from",
    message: "from",
  },
  to: {
    id: "to",
    message: "to",
  },
  cash: {
    id: "cash",
    message: "cash",
  },
  save: {
    id: "save",
    message: "save",
  },
  change: {
    id: "change",
    message: "change",
  },
  chatBot: {
    id: "chatBot",
    message: "chatBot",
  },
  clock: {
    id: "clock",
    message: "o'clock",
  },
  close: {
    id: "close",
    message: "close",
  },
  closed: {
    id: "closed",
    message: "closed",
  },
  closeDrawer: {
    id: "closeDrawer",
    message: "close drawer",
  },
  contact: {
    id: "contact",
    message: "Contact",
  },
  continue: {
    id: "continue",
    message: "continue",
  },
  dataSecurity: {
    id: "dataSecurity",
    message: "data security",
  },
  privacyPolicy: {
    id: "privacyPolicy",
    message: "privacy policy",
  },
  delete: {
    id: "delete",
    message: "delete",
  },
  deliverable: {
    id: "deliverable",
    message: "deliverable",
  },
  delivery: {
    id: "delivery",
    message: "delivery",
  },
  pickUpAtPharmacy: {
    id: "pickUpAtPharmacy",
    message: "Pick up at pharmacy",
  },
  faq: {
    id: "faq",
    message: "faq",
  },
  insertDeliveryAddress: {
    id: "insertDeliveryAddress",
    message: "Insert delivery address...",
  },
  deliveryAddress: {
    id: "deliveryAddress",
    message: "Delivery address",
  },
  billingAddress: {
    id: "billingAddress",
    message: "Billing address",
  },
  findPharmacy: {
    id: "findPharmacy",
    message: "find Pharmacy",
  },
  finish: {
    id: "finish",
    message: "finish",
  },
  home: {
    id: "home",
    message: "Home",
  },
  imprint: {
    id: "imprint",
    message: "imprint",
  },
  linkToLandingPage: {
    id: "linkToLandingPage",
    message: "home",
  },
  loading: {
    id: "loading",
    message: "loading...",
  },
  login: {
    id: "login",
    message: "Login",
  },
  logOut: {
    id: "logOut",
    message: "Logout",
  },
  medicine: {
    id: "medicine",
    message: "medicine",
  },
  medicines: {
    id: "medicines",
    message: "medicines",
  },
  medicSearch: {
    id: "medicSearch",
    message: "medic search",
  },
  moreInformation: {
    id: "moreInformation",
    message: "more information",
  },
  notDeliverable: {
    id: "notDeliverable",
    message: "not deliverable",
  },
  notRegistered: {
    id: "notRegistered",
    message: "Not registered?",
  },
  only: {
    id: "only",
    message: "only ",
  },
  open: {
    id: "open",
    message: "open ",
  },
  cancel: {
    id: "cancel",
    message: "cancel ",
  },
  or: {
    id: "or",
    message: "or",
  },
  showMore: {
    id: "showMore",
    message: "show more",
  },
  showLess: {
    id: "showLess",
    message: "show less",
  },
  showAll: {
    id: "showAll",
    message: "show all",
  },
  orderSuccess: {
    id: "orderSuccess",
    message: "order completed successful",
  },
  paymentFailed: {
    id: "paymentFailed",
    message: "payment failed or canceled",
  },
  orderFailedToComplete: {
    id: "orderFailedToComplete",
    message: "Your Order could not be completed successfully.",
  },
  paymentMethod: {
    id: "paymentMethod",
    message: "payment method",
  },
  per: {
    id: "per",
    message: " per ",
  },
  prescription: {
    id: "prescription",
    message: "prescription",
  },
  price: {
    id: "price",
    message: "price",
  },
  recipies: {
    id: "recipies",
    message: "prescription",
  },
  register: {
    id: "register",
    message: "register",
  },
  risksAndSideEffects: {
    id: "risksAndSideEffects",
    message:
      "Please consult your doctor or pharmacist about any risks and side effects.",
  },
  rx: {
    id: "rx",
    message: "Rx",
  },
  safeWithApoly: {
    id: "safeWithApoly",
    message: "be safe with {brand}",
  },
  select: {
    id: "select",
    message: "select ",
  },
  shoppingCart: {
    id: "shoppingCart",
    message: "shopping cart",
  },
  signUp: {
    id: "signUp",
    message: "Sign up",
  },
  slowConnection: {
    id: "slowConnection",
    message:
      "It takes a lot of time to load this page. You may have a bad internet connection currently.",
  },
  submitPrescription: {
    id: "submitPrescription",
    message: "submit prescription",
  },
  symptoms: {
    id: "symptoms",
    message: "symptoms",
  },
  totalAmount: {
    id: "totalAmount",
    message: "total",
  },
  deliveryTime: {
    id: "deliveryTime",
    message: "delivery time",
  },
  costs: {
    id: "costs",
    message: "costs",
  },
  note: {
    id: "note",
    message: "note",
  },
  deliveryIn: {
    id: "deliveryIn",
    message: "{inDays} days",
  },
  postalDeliveryGermany: {
    id: "postalDeliveryGermany",
    message: "delivery in between 1-3 workdays",
  },
  deliveredByPost: {
    id: "deliveredByPost",
    message: "delivered by post",
  },
  deliveredByPostToLocation: {
    id: "deliveredByPostToLocation",
    message: "delivered by post in {location}",
  },
  dependingOnPartnerPharmacy: {
    id: "dependingOnPartnerPharmacy",
    message:
      "Depending on region, assortment and opening times from the partner pharmacy",
  },
  openingTimes: {
    id: "openingTimes",
    message: "opening times",
  },
  addToShoppingCart: {
    id: "addToShoppingCart",
    message: "add to shoppingCart",
  },
  withMwStPlus: {
    id: "withMwStPlus",
    message: "inclusive. MwSt, plus",
  },
  packageSize: {
    id: "packageSize",
    message: "package size",
  },
  activeComponents: {
    id: "activeComponents",
    message: "active components",
  },
  finishPayment: {
    id: "finishPayment",
    message: "finish payment",
  },
  finishPaymentXs: {
    id: "finishPaymentXs",
    message: "complete",
  },
  findMedicine: {
    id: "findMedicine",
    message: "find medicine",
  },
  nextOpenDay: {
    id: "nextOpenDay",
    message: "closed, open at {day} at {openingTime} o'clock",
  },
  closingAt: {
    id: "closingAt",
    message: "open, closing at {openingTime} o'clock",
  },
  continueShopping: {
    id: "continueShopping",
    message: "continue shopping",
  },
  germanServer: {
    id: "germanServer",
    message: "server in germany",
  },
  sslSecureConnection: {
    id: "sslSecureConnection",
    message: "SSL - encrypted connection",
  },
  deliveryBetween: {
    id: "deliveryBetween",
    message: "{day}, between {from} o'clock and {to} o'clock",
  },
  deliveryCost: {
    id: "deliveryCost",
    message: "delivery cost",
  },
  characteristics: {
    id: "characteristics",
    message: "characteristics",
  },
  selectLanguage: {
    id: "selectLanguage",
    message: "select language",
  },
  generic: {
    id: "generic",
    message: "generic",
  },
  herbal: {
    id: "herbal",
    message: "herbal",
  },
  homeopathic: {
    id: "homeopathic",
    message: "homoeopathisch",
  },
  anthroposophic: {
    id: "anthroposophic",
    message: "anthroposophisch",
  },
  dopingRelevance: {
    id: "dopingRelevance",
    message: "doping",
  },
  phytopharmaceutical: {
    id: "phytopharmaceutical",
    message: "phytopharmaceutical",
  },
  lifestyle: {
    id: "lifestyle",
    message: "lifestyle",
  },
  import: {
    id: "import",
    message: "import",
  },
  goToPharmacyShop: {
    id: "goToPharmacyShop",
    message: "go to pharmacy shop",
  },
  dietarysupplement: {
    id: "dietarysupplement",
    message: "dietarysupplement",
  },
  cosmetic: {
    id: "cosmetic",
    message: "cosmetic",
  },
  childFlag: {
    id: "childFlag",
    message: "Für Kinder geeignet",
  },
  maleFlag: {
    id: "maleFlag",
    message: "Mann",
  },
  femaleFlag: {
    id: "femaleFlag",
    message: "Frau",
  },
  hours: {
    id: "hours",
    message:
      "{numberOfHours, plural, one {one hour} other {{numberOfHours} hours}}",
  },
  minutes: {
    id: "minutes",
    message:
      "{numberOfMinutes, plural, one {one minute} other {{numberOfMinutes} minutes}}",
  },
  now: {
    id: "now",
    message: "now",
  },
  selectPharmacy: {
    id: "selectPharmacy",
    message: "select {pharmacyName}",
  },
  userDataOverview: {
    id: "userDataOverview",
    message: "User data overview",
  },
  changeUserData: {
    id: "changeUserData",
    message: "Edit user data",
  },
  editDeliveryAddress: {
    id: "editDeliveryAddress",
    message: "Edit delivery address",
  },
  otcPurchaseHistory: {
    id: "otcPurchaseHistory",
    message: "Otc-orders purchase history",
  },
  rxPurchaseHistory: {
    id: "rxPurchaseHistory",
    message: "Rx-orders purchase history",
  },
  orderNumber: {
    id: "orderNumber",
    message: "Order number",
  },
  orderStatus: {
    id: "orderStatus",
    message: "Status",
  },
  orderDate: {
    id: "orderDate",
    message: "Order date",
  },
  orderedAtDate: {
    id: "orderedAtDate",
    message: "Order date: {orderDate}",
  },
  details: {
    id: "details",
    message: "Details",
  },
  watchDetails: {
    id: "watchDetails",
    message: "Watch Details",
  },
  singleOrderWithNumber: {
    id: "singleOrderWithNumber",
    message: "Order {orderNumber}",
  },
  product: {
    id: "product",
    message: "product",
  },
  totalPrice: {
    id: "totalPrice",
    message: "Total Price",
  },
  total: {
    id: "total",
    message: "Total",
  },
  date: {
    id: "date",
    message: "Date",
  },
  status: {
    id: "status",
    message: "Status",
  },
  comment: {
    id: "comment",
    message: "Comment",
  },
  pzn: {
    id: "pzn",
    message: "Pzn",
  },
  print: {
    id: "print",
    message: "Print",
  },
  bill: {
    id: "bill",
    message: "Bill",
  },
  accountExistsWithDifferentCredential: {
    id: "accountExistsWithDifferentCredential",
    message:
      "There already exists an account with the email address asserted by the credential. Resolve this by calling firebase.auth.Auth#fetchProvidersForEmail and then asking the user to sign in using one of the returned providers. Once the user is signed in, the original credential can be linked to the user with firebase.",
  },
  packageAmount: {
    id: "packageAmount",
    message: "Package amount",
  },
  deliveryOrderNumber: {
    id: "deliveryOrderNumber",
    message: "Delivery order number: {orderId}",
  },
  billNumber: {
    id: "billNumber",
    message: "Bill number: {billNumber}",
  },
  deliveryDate: {
    id: "deliveryDate",
    message: "Delivery date: {deliveryDate}",
  },
  billDate: {
    id: "billDate",
    message: "Bill date: {billDate}",
  },
  shippingNote: {
    id: "shippingNote",
    message: "Shipping note",
  },
  deliveryCosts: {
    id: "deliveryCosts",
    message: "Delivery costs",
  },
  deliveryMadeInContextOfAgb: {
    id: "deliveryMadeInContextOfAgb",
    message:
      "Die Lieferung erfolgt ausschließlich zu unseren Allgemeinen Geschäftsbedingungen (AGB).",
  },
  valueAddedTax: {
    id: "valueAddedTax",
    message: "Value added tax (19%)",
  },
  afterTax: {
    id: "afterTax",
    message: "After tax",
  },
  backdoorNotePlaceholder: {
    id: "backdoorNotePlaceholder",
    message: "backdoor, ...",
  },
  changePassword: {
    id: "changePassword",
    message: "Change Password",
  },
  thanksForOrder: {
    id: "thanksForOrder",
    message: "Thank you very much for your Order!",
  },
  sentMailWithOrderInfo: {
    id: "sentMailWithOrderInfo",
    message:
      "We sent you an email with all details and information about your order.",
  },
  takeNoteOfOrder: {
    id: "takeNoteOfOrder",
    message: "Please take note of the delivery terms written in this email.",
  },
  backToLandingPage: {
    id: "backToLandingPage",
    message: "back to home",
  },
  currentStatus: {
    id: "currentStatus",
    message: "Current order status",
  },
  pickUpAt: {
    id: "pickUpAt",
    message: "Pick up at",
  },
  pickUp: {
    id: "pickUp",
    message: "Pick up",
  },
  deliveryTypePickUp: {
    id: "deliveryTypePickUp",
    message: "The medications will be picked up by {buyer} at the pharmacy.",
  },
  deliveryTypeCourier: {
    id: "deliveryTypeCourier",
    message:
      "The medications are delivery to {buyer} by the delivery service of the pharmacy.",
  },
  deliveryTypePssVendingMachine: {
    id: "deliveryTypePssVendingMachine",
    message: "Die Medikamente wurden direkt am Automaten ausgegeben.",
  },
  deliveryTypeSchnellkasse: {
    id: "deliveryTypeSchnellkasse",
    message:
      "Die Apotheke hat Ihre Bestellung erhalten. Sie können jetzt zur Schnellkasse gehen.",
  },
  deliveryTypePostal: {
    id: "deliveryTypePostal",
    message: "The medications are delivery to {buyer} by post.",
  },
  deliveryTimeWithin: {
    id: "deliveryTimeWithin",
    message: "In between the next {deliveryWithin} hours after order",
  },
  deliveryTimeBetween: {
    id: "deliveryTimeBetween",
    message: "Next {day} after order, between {from} o'clock and {to} o'clock",
  },
  deliveryTimePostal: {
    id: "deliveryTimePostal",
    message: "In between the next 1-3 workdays after order",
  },
  personalData: {
    id: "personalData",
    message: "Personal data",
  },
  chosenPaymentMethod: {
    id: "chosenPaymentMethod",
    message: "Chosen payment method",
  },
  deliveryInformation: {
    id: "deliveryInformation",
    message: "Delivery information",
  },
  telephoneNeedReason: {
    id: "telephoneNeedReason",
    message:
      "The pharmacist need your telephone number, to inform you, if there is any kind of deliveryproblem or medical issue.",
  },
  deliveryForExtraCharge: {
    id: "deliveryForExtraCharge",
    message: "delivery (for an extra charge of {price})",
  },
  pssDelivery: {
    id: "pssDelivery",
    message: "Im Abholautomaten reservieren",
  },
  continueAsGuest: {
    id: "continueAsGuest",
    message: "Continue as guest",
  },
  noOrderFound: {
    id: "noOrderFound",
    message: "No order found",
  },
  noOrdersFound: {
    id: "noOrdersFound",
    message: "No orders found",
  },
  backToPurchaseHistory: {
    id: "backToPurchaseHistory",
    message: "Back to purchase history",
  },
  costumerData: {
    id: "costumerData",
    message: "Costumer data",
  },
  pharmacyData: {
    id: "pharmacyData",
    message: "Pharmacy data",
  },
  selectDate: {
    id: "selectDate",
    message: "select Date",
  },
  previousPage: {
    id: "previousPage",
    message: "Previous page",
  },
  nextPage: {
    id: "nextPage",
    message: "Next page",
  },
  articlesPerPage: {
    id: "articlesPerPage",
    message: "Articles per page",
  },
  ordersPerPage: {
    id: "ordersPerPage",
    message: "Orders per page",
  },
  deliveryInWholeGermany: {
    id: "deliveryInWholeGermany",
    message: "Delivery in whole germany",
  },
  noOrderForKeyFound: {
    id: "noOrderForKeyFound",
    message: "No Order for the given order key found!",
  },
  selectAgents: {
    id: "selectAgents",
    message: "Select agents",
  },
  selectCharacteristics: {
    id: "selectCharacteristics",
    message: "Select medicine flags",
  },
  selectBodyFlagsFilter: {
    id: "selectBodyFlagsFilter",
    message: "Geschlecht/Kind auswählen",
  },
  selectSymptoms: {
    id: "selectSymptoms",
    message: "Select symptoms",
  },
  selectDosageForms: {
    id: "selectDosageForms",
    message: "Select dosage forms",
  },
  selectBodyRegions: {
    id: "selectBodyRegions",
    message: "Select body regions",
  },
  selectProducers: {
    id: "selectProducers",
    message: "Select producers",
  },
  selectBody: {
    id: "selectBody",
    message: "Geschecht auswählen",
  },
  pressAndPartners: {
    id: "pressAndPartners",
    message: "Press & partners",
  },
  partners: {
    id: "partners",
    message: "Partners",
  },
  jobs: {
    id: "jobs",
    message: "jobs",
  },
  back: {
    id: "back",
    message: "back",
  },
  jobsAtBrand: {
    id: "jobsAtBrand",
    message: "Jobs at {brand}",
  },
  searchResults: {
    id: "searchResults",
    message: "Search results",
  },
  searchFilter: {
    id: "searchFilter",
    message: "Search filter",
  },
  contactForm: {
    id: "contactForm",
    message: "contact form",
  },
  abort: {
    id: "abort",
    message: "abort",
  },
  regionFinder: {
    id: "regionFinder",
    message: "Region finder",
  },
  symptomsForRegion: {
    id: "symptomsForRegion",
    message: "Symptoms for {region}",
  },
  noSymptomsFoundForRegion: {
    id: "noSymptomsFoundForRegion",
    message: "No Symptoms Found for selected Region.",
  },
  age: {
    id: "age",
    message: "age",
  },
  region: {
    id: "region",
    message: "region",
  },
  ageAndGender: {
    id: "ageAndGender",
    message: "Age and Gender",
  },
  aboutUs: {
    id: "aboutUs",
    message: "About us",
  },
  optional: {
    id: "optional",
    message: "optional",
  },
  understood: {
    id: "understood",
    message: "verstanden",
  },
  editAddress: {
    id: "editAddress",
    message: "edit address",
  },
  couldNotFoundAddress: {
    id: "couldNotFoundAddress",
    message: "could not found address",
  },
  doYouMean: {
    id: "doYouMean",
    message: "do you mean",
  },
  clickOnAddressToSelect: {
    id: "clickOnAddressToSelect",
    message: "click on address to select",
  },
  addNewAddress: {
    id: "addNewAddress",
    message: "Add new address",
  },
  selectAddress: {
    id: "selectAddress",
    message: "Select address",
  },
  addAddress: {
    id: "addAddress",
    message: "Add address",
  },
  noAddressDeclared: {
    id: "noAddressDeclared",
    message: "No Address declared",
  },
  addSelectedAddress: {
    id: "addSelectedAddress",
    message: "Add selected address",
  },
  increasedDeliveryCosts: {
    id: "increasedDeliveryCosts",
    message: "Shipping outside of Germany is causing Increased delivery costs",
  },
  increasedDeliveryCostsAut: {
    id: "increasedDeliveryCostsAut",
    message:
      "Der Versand außerhalb Österreichs verursacht erhöhte Versandkosten",
  },
  deliveryTimeDependingOnLocation: {
    id: "deliveryTimeDependingOnLocation",
    message: "Delivery time depends on your location",
  },
  europe: {
    id: "europe",
    message: "europe",
  },
  billingDataStep: {
    id: "billingDataStep",
    message: "Rechungsdaten",
  },
};
