import { css } from "@emotion/core";

export const mediaUp = minWidth => `@media (min-width: ${minWidth})`;

export const mediaDown = maxWidth => `@media (max-width: ${maxWidth})`;

export const mediaBetween = (minWidth, maxWidth) =>
  `@media (min-width: ${minWidth}) and (max-width: ${maxWidth})`;

export const smUp = mediaUp("600px"); // tablet

export const mdUp = mediaUp("960px"); // small laptops / tablet landscape

export const lgUp = mediaUp("1280px"); // greater laptops / desktop-PC

export const gridBase = css`
  display: grid;
  grid-gap: 8px;
  height: 100%;
`;

export const gridArea = area => css`
  grid-area: ${area};
`;

export const mainColumn = css`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 8px;
  ${mdUp} {
    width: 80%;
    padding: 0;
  }
`;
