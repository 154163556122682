import { combineReducers } from "redux";
import auth from "./auth/reducer";
import checkout from "./checkout/reducer";
import cookieConsent from "./cookieConsent";
import language from "./language/reducer";
import medicSearchFilter from "./medicSearchFilter/reducer";
import pharmacyIdToAddress from "./searchPharmacies/pharmacyIdToAddress";
import searchPharmaciesAddress from "./searchPharmacies/searchPharmaciesAddress";
import serverTime from "./serverTime/serverTime";
import serverTimeLoadingState from "./serverTime/serverTimeLoadingState";
import shopPharmacy from "./shopPharmacy/reducer";
import shoppingCart from "./shoppingCart/reducer";
import submitPrescription from "./submitPrescription/reducer";

const rootReducer = combineReducers({
  auth,
  cookieConsent,
  language,
  checkout,
  shoppingCart,
  searchPharmaciesAddress,
  pharmacyIdToAddress,
  submitPrescription,
  serverTime,
  serverTimeLoadingState,
  shopPharmacy,
  medicSearchFilter,
});

export default rootReducer;
