import React from "react";
import { LinguiMessage } from "../../app-util-components/LinguiMessage";
import { PharmacySlider } from "../../app/landingPage/PharmacySlider";
import {
  veritasLandingPageSliderImages,
  veritasLogo,
} from "../../assets/veritas/veritasImages";
import { PharmacyTheme } from "./PharmacyThemeInterface";

const messages = {
  veritasLandingSlogan: {
    id: "veritasLandingSlogan",
    message: "Willkommen bei Ihrer Veritas-Apotheke",
  },
};

const landingSlogan = <LinguiMessage message={messages.veritasLandingSlogan} />;

const VeritasPanoramaComponent: React.SFC = props => (
  <PharmacySlider sliderImages={veritasLandingPageSliderImages}>
    {props.children}
  </PharmacySlider>
);

export const veritasCustomisations: Partial<PharmacyTheme> = {
  palette: {
    primary: {
      light: "#e9ffff",
      main: "#b7cdd1",
      dark: "#879ca0",
      contrastText: "#000000",
    },
    secondary: {
      light: "#fff6e9",
      main: "#d1c3b7",
      dark: "#a09387",
      contrastText: "#000000",
    },
  },
  logo: veritasLogo,
  PanoramaComponent: VeritasPanoramaComponent,
  landingSlogan,
};

/*
prev secondary: {
      light: '#ffa270',
      main: '#ff7043',
      dark: '#c63f17',
      contrastText: '#ffffff',
   }
 */

export const veritasPharmacyCode = process.env.CUSTOM_PHARMACIES_VERITAS;
