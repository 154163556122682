import { css } from "@emotion/core";
import * as React from "react";
import { rfcUrl, UrlType } from "../url";
import {
  RequiredBaseUrlConsumer,
  UrlContextValuesWithRequiredBase,
} from "./consumers/RequiredBaseUrlConsumer";
import { CtxLink, CtxLinkBase } from "./CtxLink";

interface CtxLinkPropsPath {
  path: string;
  query?: string;
  fragment?: string;
  urlType?: UrlType;
  noDeco?: boolean;
}

const noDecoClass = css`
  text-decoration: inherit;
  color: inherit;
`;

export class CtxPathLink extends React.PureComponent<
  CtxLinkBase & CtxLinkPropsPath
> {
  renderCtxLink = (ctx: UrlContextValuesWithRequiredBase) => {
    const {
      path,
      query,
      fragment,
      urlType = UrlType.major,
      noDeco = false,
      ...rest
    } = this.props;

    return (
      <CtxLink
        to={rfcUrl({ path, query, fragment }, ctx.urlBase, urlType)}
        css={[noDeco && noDecoClass]}
        {...rest}
      />
    );
  };

  render() {
    return (
      <RequiredBaseUrlConsumer>{this.renderCtxLink}</RequiredBaseUrlConsumer>
    );
  }
}
