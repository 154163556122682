import { getErrorFromProp } from "@apoly-42/apoly-utils";
import { ShowComponentWhileLoading } from "@apoly-42/material-ui";
import PropTypes from "prop-types";
import React from "react";
import Loadable from "react-loadable";
import NavBar from "./layout/navBar/navbar/NavBar";

const LoadingContainer = ({ error }) => {
  if (error) {
    throw getErrorFromProp("unknown error in LoadingContainer", error);
  }

  return (
    <React.Fragment>
      <NavBar />
      <div style={{ marginTop: 40 }}>
        <ShowComponentWhileLoading isLoading />
      </div>
    </React.Fragment>
  );
};

LoadingContainer.propTypes = {
  error: PropTypes.object,
};

LoadingContainer.defaultProps = {
  error: null,
};

const defaultOptions = {
  loading: LoadingContainer,
  delay: 0,
  timeout: 10000,
};

export const ApolyPageLoadable = opts =>
  Loadable({
    ...defaultOptions,
    ...opts,
  });
