import { RootRef, Step, StepButton, StepContent } from "@material-ui/core";
import { css } from "@emotion/core";
import * as React from "react";

interface MuiStepperStepProps {
  currentStep: number;
  step: number;
  stepLabel: React.ReactNode;
  summary?: React.ReactNode;
  onEditStepClick: (step: number) => void;
}

const stepBtnFix = css`
  padding-bottom: 8px;
  margin-bottom: -8px;
  padding-top: 8px;
  margin-top: -8px;
  text-align: start;
`;

export class MuiStepperStep extends React.PureComponent<MuiStepperStepProps> {
  // da step verrückt auf diese Sachen zugreift muss es mit rootref eingebunden werden
  rootRef = React.createRef();

  render() {
    const {
      currentStep,
      step,
      summary,
      stepLabel,
      onEditStepClick,
      ...rest
    } = this.props;

    return (
      <RootRef rootRef={this.rootRef}>
        <Step {...rest} active={currentStep === step}>
          <StepButton
            css={stepBtnFix}
            active={currentStep === step}
            completed={currentStep > step}
            disabled={currentStep <= step}
            optional={summary}
            onClick={() => onEditStepClick(step)}
          >
            {stepLabel}
          </StepButton>
          <StepContent>{this.props.children}</StepContent>
        </Step>
      </RootRef>
    );
  }
}
