import * as React from "react";
import ShoppingCart from "../../../../deprecated/deprecated-apoly-app/components/pages/shoppingCart/ShoppingCart";
import { Address } from "../../../apolyApi";
import { PharmacyValuesConsumer } from "../../../PharmacyContext";
import { ClosureValues } from "../CheckoutClosure";
import {
  CheckoutOffizinStep,
  CompletedCheckoutOffizin,
} from "../checkoutTypes";

interface CheckoutOffizinProps {
  onSubmit: (val: CompletedCheckoutOffizin) => void;
}

interface CheckoutOffizinState {
  step: CheckoutOffizinStep;
  billingAddress?: Address;
}

export class CheckoutOffizin extends React.PureComponent<
  CheckoutOffizinProps,
  CheckoutOffizinState
> {
  state = {
    step: CheckoutOffizinStep.billingData,
    email: undefined,
    billingAddress: undefined,
  };

  submit = ({ agbOptIn, newsletterOptIn }: ClosureValues) =>
    // besser als mit dem throw wäre für jeden konkreten step den exakten möglichen state festzulegen,
    // aber das macht es dann auch wieder ein bisschen komplizierter mit nutzlosen checks um für TS den Typen klarzumachen
    this.props.onSubmit({
      agbOptIn,
      newsletterOptIn,
      billingAddress: this.state.billingAddress,
    });

  setStep = (step: CheckoutOffizinStep) => this.setState({ step });

  render() {
    return (
      <PharmacyValuesConsumer>
        {({ pharmacy }) =>
          pharmacy && (
            <ShoppingCart
              pharmacyId={pharmacy.pharmacyId}
              submit={this.submit}
            />
          )
        }
      </PharmacyValuesConsumer>
    );
  }
}
