import React from "react";
import {
  FluidTypography,
  FluidTypographyProps,
} from "../../../../../app-util-components/FluidTypography";
import { BRAND_NAME } from "../../../../../environment";

export interface BrandNameProps extends FluidTypographyProps {}

export const BrandName: React.SFC<BrandNameProps> = ({ ...props }) => (
  <FluidTypography variant="h6" color="inherit" noWrap={true} {...props}>
    {BRAND_NAME}
  </FluidTypography>
);
