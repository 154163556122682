import { faFrownOpen } from "@fortawesome/pro-light-svg-icons";
import { Button } from "@material-ui/core";
import { css } from "@emotion/core";
import * as React from "react";
import { FluidTypography } from "../app-util-components/FluidTypography";
import { SizedFaIcon } from "../app-util-components/SizedFaIcon";
import { contentContainer } from "../app-utilities/cssClasses";
import { AppMuiTheme } from "./layout/AppMuiTheme";
import { Footer } from "./layout/Footer";
import { NavBar } from "./layout/NavBar";
import { appPalette } from "../app-theme/apolyThemeValues";

export const errorContainerCss = css`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export class EternalAppError extends React.PureComponent {
  render() {
    // AppMuiTheme wird nochmal eingebunden da im Fall "eternal" kein einziger Provider oberhalb des trees ist
    // ebenso kein i18n da auch dieser Provider nicht geladen ist
    return (
      <AppMuiTheme palette={appPalette}>
        <NavBar />

        <div css={[contentContainer, errorContainerCss]}>
          <FluidTypography type="headline1" paragraph={true}>
            Oh nein!
          </FluidTypography>
          <FluidTypography
            type="headline3"
            style={{ display: "flex" }}
            paragraph={true}
          >
            Leider ist ein Fehler aufgetreten{" "}
            <SizedFaIcon
              style={{ margin: "auto 8px" }}
              fontSize={32}
              icon={faFrownOpen}
            />
          </FluidTypography>
          <FluidTypography paragraph={true}>
            Entschuldigen Sie bitte die Unannehmlichkeiten. Wir werden versuchen
            den Fehler schnellstmöglich zu beheben. <br />
            Sie können versuchen die Seite erneut zu laden und den Vorgang zu
            wiederholen.
          </FluidTypography>
          <Button
            onClick={() => window.location.reload(true)}
            variant="contained"
            color="secondary"
          >
            reload page
          </Button>
        </div>

        <Footer />
      </AppMuiTheme>
    );
  }
}
