import { LinguiMessage } from "@apoly-42/apoly-components";
import { Headline } from "@apoly-42/material-ui";
import { Grid } from "@material-ui/core";
import React from "react";
import { commonMessages } from "../../../../constants/messages/commonMessages";

const ShoppingCartTableHead = () => (
  <Grid container spacing={2}>
    <Grid item xs={8}>
      <Headline headlineType="h3">
        <LinguiMessage message={commonMessages.medicine} />
      </Headline>
    </Grid>
    <Grid item xs={1}>
      <Headline align="center" headlineType="h3">
        <LinguiMessage message={commonMessages.price} />
      </Headline>
    </Grid>
    <Grid item xs={2}>
      <Headline align="center" headlineType="h3">
        <LinguiMessage message={commonMessages.amount} />
      </Headline>
    </Grid>
    <Grid item xs={1}>
      <Headline align="center" headlineType="h3">
        <LinguiMessage message={commonMessages.deliverable} />
      </Headline>
    </Grid>
  </Grid>
);

export default ShoppingCartTableHead;
