import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import * as React from "react";

interface CtaButtonProps extends ButtonProps {
  iconLeft?: boolean;
  icon: IconProp;
}

export class ButtonWithIcon extends React.PureComponent<CtaButtonProps> {
  render() {
    const { children, icon, iconLeft, ...rest } = this.props;

    return (
      <Button variant="contained" color="secondary" {...rest}>
        <span style={{ display: "flex", alignItems: "center" }}>
          {iconLeft && (
            <span style={{ marginRight: 8, fontSize: 16 }}>
              <FontAwesomeIcon icon={icon} />
            </span>
          )}
          <span style={{ flex: 1 }}>{children}</span>
          {!iconLeft && (
            <span style={{ marginLeft: 8, fontSize: 16 }}>
              <FontAwesomeIcon icon={icon} />
            </span>
          )}
        </span>
      </Button>
    );
  }
}
