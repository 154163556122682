import { ShowComponentWhileLoading } from "@apoly-42/material-ui";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose, withState } from "recompose";
import ObserveLoginStateContainer from "../../containers/auth/ObserveLoginStateContainer";
import {
  selectAuthIsLoaded,
  userLoggedIn,
  userLoggedOut,
} from "../../redux/auth/reducer";

const InnerObserveLoginStateWrapper = props => (
  <React.Fragment>
    <ObserveLoginStateContainer
      userLoggedIn={props.userLoggedIn}
      userLoggedOut={props.userLoggedOut}
      setIsLoading={props.setIsLoading}
    />

    {props.isLoading || !props.authIsLoaded ? (
      <ShowComponentWhileLoading
        isLoading={props.isLoading || !props.authIsLoaded}
      />
    ) : (
      props.children
    )}
  </React.Fragment>
);

const mapDispatchToProps = { userLoggedIn, userLoggedOut };

const enhance = compose(
  withState("isLoading", "setIsLoading", false),
  connect(
    state => ({ authIsLoaded: selectAuthIsLoaded(state) }),
    mapDispatchToProps,
  ),
);

const ObserveLoginStateWrapper = enhance(InnerObserveLoginStateWrapper);

InnerObserveLoginStateWrapper.propTypes = {
  userLoggedIn: PropTypes.func.isRequired,
  userLoggedOut: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  ...ObserveLoginStateWrapper.propTypes,
};

export default ObserveLoginStateWrapper;
