import { List, ListItem, ListItemText } from "@material-ui/core";
import { ListItemTextProps } from "@material-ui/core/ListItemText";
import { css } from "@emotion/core";
import React from "react";
import { CtxPathLink } from "../url-utils/index";

/* eslint-disable react/no-array-index-key */

const listPointCss = css`
    padding: 8px 8px 8px 0px;
    &> * {
      color: inherit;
    },
  `;

export interface UrlListProps {
  linkList: Array<{ url: string; text: ListItemTextProps["primary"] }>;
}

export const UrlList: React.SFC<UrlListProps> = ({ linkList }) => (
  <List>
    {linkList &&
      linkList.map((listPoint, index) => (
        <ListItem
          component={props => <CtxPathLink {...props} path={listPoint.url} />}
          key={index}
          css={listPointCss}
          style={{ color: "inherit" }}
        >
          <ListItemText style={{ color: "inherit" }} primary={listPoint.text} />
        </ListItem>
      ))}
  </List>
);
