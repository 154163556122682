import { LinguiMessage } from "@apoly-42/apoly-components";
import { withHandler } from "@apoly-42/apoly-utils";
import { withIsSm } from "@apoly-42/material-ui";
import { Button, Fade, Snackbar, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import {
  changeCookieConsent,
  selectCookieConsent,
} from "../redux/cookieConsent";
import { dataSecurityPath } from "../routes/paths";
import { dsgvoLogging } from "../utilities/apis/apolyApi/apolyApi";
import { logErrorGracefully } from "../utilities/failGracefully";

const messages = {
  readDataSecurity: {
    id: "readDataSecurity",
    message: "Datenschutzerklärung und Widerrufsrecht lesen",
  },
  cookiesUsages: {
    id: "cookiesUsages",
    message:
      "Um die Webseite optimal gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies und der Datenerhebung gemäß der Datenschutzerklärung zu. {link}",
  },
  iAgreeToDataSecurity: {
    id: "iAgreeToDataSecurity",
    message: "Ja, ich willige in die Datenschutzerhebung ein",
  },
};

const InnerDataProtectionAndCookieConsent = props => (
  <Snackbar
    style={{ maxWidth: 600 }}
    open={!props.cookieConsent}
    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    TransitionComponent={Fade}
    message={
      <Typography color="inherit" variant={props.isSm ? "caption" : undefined}>
        <LinguiMessage
          message={messages.cookiesUsages}
          values={{
            link: (
              <Link to={dataSecurityPath()} style={{ color: "inherit" }}>
                <LinguiMessage message={messages.readDataSecurity} />
              </Link>
            ),
          }}
        />
      </Typography>
    }
    action={
      <Button
        size={props.isSm ? "small" : undefined}
        variant="contained"
        color="secondary"
        onClick={props.toggleCookieConsent}
      >
        <LinguiMessage message={messages.iAgreeToDataSecurity} />
      </Button>
    }
  />
);

const mapStateToProps = state => ({
  cookieConsent: selectCookieConsent(state),
});

const enhance = compose(
  connect(mapStateToProps, { changeCookieConsent }),
  withHandler("toggleCookieConsent", props => () => [
    props.changeCookieConsent(true),
    dsgvoLogging("cookie-consent").catch(logErrorGracefully),
  ]),
  withIsSm("isSm"),
);

const DataProtectionAndCookieConsent = enhance(
  InnerDataProtectionAndCookieConsent,
);

InnerDataProtectionAndCookieConsent.propTypes = {
  cookieConsent: PropTypes.bool.isRequired,
  toggleCookieConsent: PropTypes.func.isRequired,
  isSm: PropTypes.bool.isRequired,
};

DataProtectionAndCookieConsent.defaultProps = {};

export default DataProtectionAndCookieConsent;
