/* eslint-disable quote-props */
import {
  BlindAnchor,
  LinguiEuro,
  LinguiMessage,
} from "@apoly-42/apoly-components";
import { withHandler } from "@apoly-42/apoly-utils";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Grid, Typography, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { pipe, tap } from "ramda";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { commonMessages } from "../../../../constants/messages/commonMessages";
import {
  flex,
  flexAlignFlexStart,
  marginAuto,
} from "../../../../constants/styles/commonClasses";
import { singleProductSetPharmacyPath } from "../../../../routes/paths";
import { articleBaseInformation } from "../../../../utilities/analyticsLayerUtils/productLayerEvents";
import googleDataLayerPush from "../../../../utilities/googleDataLayerPush";
import {
  checkDeliveryStatus,
  getArticleWithArticleId,
} from "../../../../utilities/product/productUtilities";
import ProductImageSmall from "../../../imageComponents/productImages/ProductImageSmall";
import AddRemoveButtonGroup from "./AddRemoveButtonGroup";
import { css, keyframes } from "@emotion/core";
import { flash } from "react-animations";

const animKeyframes = keyframes`${flash}`;

const anim = css`
  animation: 1s ${animKeyframes};
`;

const styles = {
  link: {
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  productImg: {
    maxWidth: "100%",
    margin: "auto",
  },
};

const InnerShoppingCartItem = ({
  classes,
  articles,
  articleId,
  deleteItem,
  increaseItemAmount,
  decreaseItemAmount,
  count,
  basePath,
  trackDecrease,
  trackIncrease,
  trackRemove,
  removeNonPssAvailable = false,
  usePssAvailability
}) => {
  const article = getArticleWithArticleId(articles, articleId);
  const minusDisabled = count <= 1;
  const { medicine } = article.package;
  const url = singleProductSetPharmacyPath(
    basePath,
    article.package.medicine.urlCode,
    article.package.urlCode,
  );

  const isTax2 = medicine.taxkennzeichen === "tax_2_freie";
  const isPssAvailable = isTax2 && article.pss_availability === "available";
  const willBeRemoved = !isPssAvailable && removeNonPssAvailable;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container spacing={2}>
          <Grid item xs={3} style={flexAlignFlexStart}>
            <ProductImageSmall
              css={willBeRemoved ? anim : undefined}
              name={medicine.name}
              imgUrl={article.package.imgUrl}
              style={{
                maxWidth: "100%",
                margin: "auto",
                maxHeight: 160,
                filter: willBeRemoved ? "grayscale(100%)" : undefined,
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component={Link}
                  color="textPrimary"
                  to={url}
                  className={classes.link + " " + (willBeRemoved ? anim : "")}
                  dangerouslySetInnerHTML={{ __html: medicine.name }}
                  style={{
                    textDecoration: willBeRemoved ? "line-through" : undefined,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  css={willBeRemoved ? anim : undefined}
                  style={{
                    textDecoration: willBeRemoved ? "line-through" : undefined,
                  }}
                >
                  {article.package.amountText} | PZN: {article.package.pzn}
                </Typography>
              </Grid>
              <Grid item xs={12} css={willBeRemoved ? anim : undefined}>
                <BlindAnchor onClick={pipe(tap(trackRemove), deleteItem)}>
                  <Typography variant="caption" color="textSecondary">
                    <LinguiMessage message={commonMessages.delete} />
                  </Typography>
                </BlindAnchor>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} md={1} style={flex}>
        <Typography
          css={willBeRemoved ? anim : undefined}
          style={{
            textDecoration: willBeRemoved ? "line-through" : undefined,
            ...marginAuto,
          }}
        >
          <LinguiEuro value={Number(article.price)} />
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={2}
        style={flex}
        css={willBeRemoved ? anim : undefined}
      >
        <AddRemoveButtonGroup
          usePssAvailability={usePssAvailability}
          article={article}
          willBeRemoved={willBeRemoved}
          minusDisabled={minusDisabled}
          value={count}
          addFunc={pipe(tap(trackIncrease), increaseItemAmount)}
          removeFunc={pipe(tap(trackDecrease), decreaseItemAmount)}
        />
      </Grid>
      <Grid item xs={3} md={1} style={flex}>
        <FontAwesomeIcon
          css={willBeRemoved ? anim : undefined}
          style={marginAuto}
          icon={
            willBeRemoved ? faTimes : checkDeliveryStatus(article.availability)
          }
        />
      </Grid>
    </Grid>
  );
};

const mapPropsToIncreaseEvent = (props, event, bla) => ({
  event,
  ecommerce: {
    currencyCode: "EUR",
    actionField: { list: "Warenkorb" },
    remove: {
      products: [
        {
          ...articleBaseInformation(
            getArticleWithArticleId(props.articles, props.articleId).package
              .medicine,
            getArticleWithArticleId(props.articles, props.articleId).package,
            getArticleWithArticleId(props.articles, props.articleId),
          ),
          quantity: bla === null ? props.count : bla,
        },
      ],
    },
  },
});

const enhance = compose(
  withStyles(styles),
  withHandler("trackDecrease", props => () =>
    googleDataLayerPush(mapPropsToIncreaseEvent(props, "removeFromCart", null)),
  ),
  withHandler("trackIncrease", props => () =>
    googleDataLayerPush(mapPropsToIncreaseEvent(props, "addToCart", null)),
  ),
  withHandler("trackRemove", props => () =>
    googleDataLayerPush(mapPropsToIncreaseEvent(props, "removeFromCart", 0)),
  ),
);

const ShoppingCartItem = enhance(InnerShoppingCartItem);

ShoppingCartItem.propTypes = {
  articles: PropTypes.array.isRequired,
  articleId: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  deleteItem: PropTypes.func.isRequired,
  increaseItemAmount: PropTypes.func.isRequired,
  decreaseItemAmount: PropTypes.func.isRequired,
  removeNonPssAvailable: PropTypes.bool.isRequired,
};

InnerShoppingCartItem.propTypes = {
  classes: PropTypes.object.isRequired,
  ...ShoppingCartItem.propTypes,
};

export default ShoppingCartItem;
