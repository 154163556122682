import { useEffect, useState } from "react";

export interface Size {
  width: number;
  height: number;
}

export function stopMediaStream(mediaStream: MediaStream) {
  try {
    mediaStream.getTracks().forEach(track => track.stop());
  } catch (e) {
    console.error(e);
    try {
      mediaStream.stop();
    } catch (e) {
      console.error(e);
      console.error("cannot stopd mediastream");
    }
  }
}

export function onImageData(
  handler: (imgData: ImageData) => void,
  {
    video,
    canvas,
    videoSize,
    interval = 200,
  }: {
    video: HTMLVideoElement;
    canvas: HTMLCanvasElement;
    videoSize: Size;
    interval?: number;
  },
) {
  let timeoutId: number;
  const canvasCtx = canvas.getContext("2d");

  function checkCode() {
    if (!video || !canvasCtx) {
      timeoutId = window.setTimeout(checkCode, interval);
      return;
    }

    canvasCtx.drawImage(video, 0, 0, videoSize.width, videoSize.height);

    handler(canvasCtx.getImageData(0, 0, videoSize.width, videoSize.height));

    timeoutId = window.setTimeout(checkCode, interval);
  }

  checkCode();

  return () => {
    clearTimeout(timeoutId);
  };
}
