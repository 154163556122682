import { Avatar, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { withLingui } from "@apoly-42/apoly-components";
import { compose } from "recompose";
import apoLogoBig from "../../constants/assets/apo-logo-big.jpg";
import apoLogo from "../../constants/assets/apo-logo.jpg";

const styles = {
  pharmacyAvatar: {
    backgroundColor: "white", // apotheken-A muss auf weißem Hintergrund sein (Vorschrift!)
    padding: "8px", // Apotheken-A muss darf nicht abgeschnitten sein
    height: "40px", // wirkt im vergleich zu den anderen logos bissel groß, aber sonst ist es kaum sichtbar
    width: "40px",
  },
  pharmacyAvatarBig: {
    backgroundColor: "white", // apotheken-A muss auf weißem Hintergrund sein (Vorschrift!)
    padding: "8px", // Apotheken-A muss darf nicht abgeschnitten sein
  },
};

const messages = {
  pharmacyLogoDefault: {
    id: "pharmacyLogoDefault",
    message: "pharmacy logo default",
  },
};

const InnerPharmacyLogoDefault = ({
  classes,
  logoLarge,
  i18n,
  className,
  ...props
}) =>
  logoLarge ? (
    <Avatar
      className={`${className} ${classes.pharmacyAvatarBig}`}
      alt={i18n._(messages.pharmacyLogoDefault)}
      src={apoLogoBig}
      {...props}
    />
  ) : (
    <Avatar
      className={`${className} ${classes.pharmacyAvatar}`}
      alt={i18n._(messages.pharmacyLogoDefault)}
      src={apoLogo}
      {...props}
    />
  );

const enhance = compose(withStyles(styles), withLingui);

const PharmacyLogoDefault = enhance(InnerPharmacyLogoDefault);

PharmacyLogoDefault.propTypes = {
  logoLarge: PropTypes.bool,
  className: PropTypes.string,
};

PharmacyLogoDefault.defaultProps = {
  logoLarge: false,
  className: "",
};

InnerPharmacyLogoDefault.propTypes = {
  ...PharmacyLogoDefault.propTypes,
};

export default PharmacyLogoDefault;
