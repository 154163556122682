import { Button, Modal, Theme, WithTheme, withTheme } from "@material-ui/core";
import { css } from "@emotion/core";
import firebase from "firebase/app";
import { parse } from "qs";
import React from "react";

import { FluidTypography } from "../app-util-components/FluidTypography";
import { LinguiMessage } from "../app-util-components/LinguiMessage";
import { appMessages } from "../app-utilities/appMessages";
import { flex, flexRight } from "../app-utilities/cssClasses";
import { Pharmacy } from "./apolyApi";
import { pharmacyCtxToPath } from "./layout/PharmacyBaseLink";
import {
  PharmacyContextValues,
  PharmacyValuesConsumer,
} from "./PharmacyContext";
import { pharmacyBase, regionFinderPath } from "./routes";
import { PharmacyChannel } from "./routes-helper";

const search = window.location.href.split("?")[1];
const resetUrl = search ? parse(search).resettourl : undefined;

const secondsForTimeout = 120;
const resetAppTimer = 15;

const messages = {
  resetPageInSeconds: {
    id: "restPageInSeconds",
    message: "Die Seite wird zurückgesetzt in {secondCount} Sekunden!",
  },
  lowActivityDetected: {
    id: "lowActivityDetected",
    message: "Haben Sie den Bildschirm verlassen?",
  },
};

const modalStyle = (theme: Theme) => css`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  min-width: ${theme.spacing(50)}px;
  background-color: ${theme.palette.background.paper};
  box-shadow: ${theme.shadows[5]};
  padding: ${theme.spacing(4)}px;
`;

const deleteAllCookies = () =>
  document.cookie.split(";").forEach(cookie => {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  });

interface InnerResetAppTimerProps {
  pharmacyCtx: PharmacyContextValues;
}
interface State {
  isTimedOut: boolean;
  resetAppTimer: number;
}

interface ResetProps {
  pharmacyChannel: PharmacyChannel | undefined;
  pharmacy: Pharmacy | undefined;
}

function getResetUrl(pharmacyCtx: ResetProps) {
  if (pharmacyCtx.pharmacyChannel === PharmacyChannel.customerBoard) {
    return pharmacyCtxToPath(regionFinderPath, pharmacyCtx) + "?body=female";
  }

  return pharmacyCtxToPath(pharmacyBase, pharmacyCtx);
}

export function resetApp(pharmacyCtx: ResetProps) {
  try {
    deleteAllCookies();
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e);
  }

  window.localStorage.clear();
  window.sessionStorage.clear();

  window.location.href = resetUrl || getResetUrl(pharmacyCtx);
}

class InnerResetAppTimer extends React.PureComponent<
  InnerResetAppTimerProps & WithTheme
> {
  state: State = {
    isTimedOut: false,
    resetAppTimer,
  };

  scrollEventListenerIsApplied: boolean;

  timeoutId: number;
  intervalId: number;

  componentDidMount() {
    if (
      process.env.NODE_ENV === "development" &&
      window.localStorage.getItem("__NO_TIMER__") === "1"
    ) {
      return;
    }

    // nicht automatisch starten sondern erst mit erster Aktion
    document.addEventListener("click", this.resetTimeout);
    // document.addEventListener('scroll', this.resetTimeout);
  }

  componentDidUpdate(prevProps: Readonly<InnerResetAppTimerProps & WithTheme>) {
    if (
      process.env.NODE_ENV === "development" &&
      window.localStorage.getItem("__NO_TIMER__") === "1"
    ) {
      return;
    }

    if (
      prevProps.pharmacyCtx !== this.props.pharmacyCtx &&
      this.props.pharmacyCtx.pharmacy
    ) {
      const checkUrl = resetUrl || getResetUrl(this.props.pharmacyCtx);
      // vergleiche nur den letzten part (abhängig von checkUrl-länge),
      // da bei href http://... dabei ist und bei checkUrl nur path steht
      if (window.location.href.substr(-checkUrl.length) !== checkUrl) {
        // falls wir nicht auf der eigentlichen startseite sind, direkt mit timer anfangen
        // (kann zb beim neuladen von reservierungsseite nach eject passieren)
        this.resetTimeout();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.resetTimeout);
    document.removeEventListener("scroll", this.resetTimeout);
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }

    if (this.intervalId) {
      window.clearInterval(this.intervalId);
    }
  }

  resetTimeout = () => {
    if (!this.scrollEventListenerIsApplied) {
      this.scrollEventListenerIsApplied = true;
      document.addEventListener("scroll", this.resetTimeout);
    }

    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }

    if (this.intervalId) {
      window.clearInterval(this.intervalId);
    }

    if (this.state.isTimedOut) {
      this.setState({ isTimedOut: false });
    }
    if (this.state.resetAppTimer !== resetAppTimer) {
      this.setState({ resetAppTimer });
    }

    const localTimeoutId = window.setTimeout(() => {
      if (this.timeoutId === localTimeoutId) {
        this.setState({ isTimedOut: true });
        this.triggerResetPageCountDown();
      }
    }, secondsForTimeout * 1000);

    this.timeoutId = localTimeoutId;
  };

  getResetUrl = () => {
    if (
      this.props.pharmacyCtx.pharmacyChannel === PharmacyChannel.customerBoard
    ) {
      return (
        pharmacyCtxToPath(regionFinderPath, this.props.pharmacyCtx) +
        "?body=female"
      );
    }

    return pharmacyCtxToPath(pharmacyBase, this.props.pharmacyCtx);
  };

  triggerResetPageCountDown = () => {
    const localIntervalId = window.setInterval(() => {
      if (this.intervalId === localIntervalId) {
        this.setState({ resetAppTimer: this.state.resetAppTimer - 1 });
        if (this.state.resetAppTimer <= 0) {
          if (firebase.auth().currentUser) {
            firebase
              .auth()
              .signOut()
              // tslint:disable-next-line:no-console
              .catch(console.error)
              .then(() => resetApp(this.props.pharmacyCtx));
          } else {
            resetApp(this.props.pharmacyCtx);
          }
        }
      }
    }, 1000);

    this.intervalId = localIntervalId;
  };

  render() {
    const { theme } = this.props;

    return (
      <Modal
        style={{ zIndex: 10000002 }}
        open={this.state.isTimedOut}
        onClose={this.resetTimeout}
      >
        <div css={modalStyle(theme)}>
          <FluidTypography type="headline3" id="modal-title" paragraph={true}>
            <LinguiMessage message={messages.lowActivityDetected} />
          </FluidTypography>
          <FluidTypography id="modal-title">
            <LinguiMessage
              message={messages.resetPageInSeconds}
              values={{ secondCount: this.state.resetAppTimer }}
            />
          </FluidTypography>
          <div css={flex}>
            <Button
              css={flexRight}
              onClick={this.resetTimeout}
              color="secondary"
            >
              <LinguiMessage message={appMessages.cancel} />
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export const ResetAppTimer = withTheme(props => (
  <PharmacyValuesConsumer>
    {pharmacyCtx => (
      <InnerResetAppTimer theme={props.theme} pharmacyCtx={pharmacyCtx} />
    )}
  </PharmacyValuesConsumer>
));
