import * as React from "react";

import { LinguiMessage } from "../app-util-components/LinguiMessage";
import PharmacyPanorama from "../deprecated/deprecated-apoly-app/components/pages/partnerPharmacy/PharmacyPanorama";

const messages = {
  welcomeToPartnerPharmacy: {
    id: "welcomeToPartnerPharmacy",
    message: "Welcome to your {brand}-partner pharmacy",
  },
};

export const appPalette = {
  primary: {
    light: "#77c2cc",
    main: "#45919b",
    dark: "#03636d",
    contrastText: "#fff",
  },
  secondary: {
    light: "#ffa040",
    main: "#ff6f00",
    dark: "#c43e00",
    contrastText: "#000000",
  },
};

export const apolyLandingSlogan = (
  <LinguiMessage
    message={messages.welcomeToPartnerPharmacy}
    values={{ brand: "apoly" }}
  />
);

export const defaultPanoramaComponent = PharmacyPanorama;
