import { cond, equals } from "ramda";

import { commonMessages } from "../../constants/messages/commonMessages";
import cash from "./assets/bar.png";
import mastercard from "./assets/mastercard.png";
import paypal from "./assets/paypal.png";
import sepa from "./assets/sepa.png";
import sofort from "./assets/sofort-ueberweisung.png";
import visa from "./assets/visa.png";
import pos_terminal from "./assets/hobex-terminal.svg";

const messages = {
  paymentCash: {
    id: "paymentCash",
    message: "cash",
  },
  paymentSofort: {
    id: "paymentSofort",
    message: "sofort",
  },
  paymentVisa: {
    id: "paymentVisa",
    message: "visa",
  },
  paymentSepa: {
    id: "paymentSepa",
    message: "sepa",
  },
  paymentMastercard: {
    id: "paymentMastercard",
    message: "Mastercard",
  },
  paymentPaypal: {
    id: "paymentPaypal",
    message: "PayPal",
  },
};

export const getPaymentMethodImage = cond([
  [equals("cash"), () => cash],
  [equals("sofort"), () => sofort],
  [equals("visa"), () => visa],
  [equals("sepa"), () => sepa],
  [equals("mastercard"), () => mastercard],
  [equals("paypal"), () => paypal],
  [equals("hobex_terminal"), () => pos_terminal],
  [() => true, () => ""],
]);

export const paymentMethodToMessage = cond([
  [equals("cash"), () => messages.paymentCash],
  [equals("sofort"), () => messages.paymentSofort],
  [equals("visa"), () => messages.paymentVisa],
  [equals("sepa"), () => messages.paymentSepa],
  [equals("mastercard"), () => messages.paymentMastercard],
  [equals("paypal"), () => messages.paymentPaypal],
  [() => true, () => commonMessages.unknown],
]);
