import { Button } from "@material-ui/core";
import { css } from "@emotion/core";
import { FormikFields } from "formik-fields";
import * as React from "react";

import { formMessages } from "../../app-utilities/appMessages";
import { AddressFromInput } from "../../app-utilities/toStringUtils";
import { commonMessages } from "../../deprecated/deprecated-apoly-app/constants/messages/commonMessages";
import { noEmptyFormField } from "../form-utils/formikFieldValidators";
import { MuiFormikTextFieldI18n } from "../form-utils/MuiFormikTextFieldI18n";
import { LinguiMessage } from "../LinguiMessage";

interface AddressFormProps {
  initialAddress?: AddressFromInput;
  onSubmit: (address: AddressFromInput) => void;
  onCancelClick: () => void;
}

const textField = css`
  width: 100%;
`;
const flexClass = css`
  display: flex;
  justify-content: space-between;
`;
const textFieldBig = css`
  width: 60%;
`;
const textFieldSmall = css`
  width: 35%;
`;

const messages = {
  checkAddress: {
    id: "checkAddress",
    message: "Adresse überprüfen",
  },
};

export class AddressForm extends React.PureComponent<AddressFormProps> {
  render() {
    const { onSubmit, onCancelClick, initialAddress } = this.props;

    return (
      <FormikFields<AddressFromInput>
        fields={{
          name: {
            initialValue: initialAddress ? initialAddress.name : "",
            validate: noEmptyFormField,
          },
          street: {
            initialValue: initialAddress ? initialAddress.street : "",
            validate: noEmptyFormField,
          },
          zip: {
            initialValue: initialAddress ? initialAddress.zip : "",
            validate: noEmptyFormField,
          },
          city: {
            initialValue: initialAddress ? initialAddress.city : "",
            validate: noEmptyFormField,
          },
          notes: { initialValue: initialAddress ? initialAddress.notes : "" },
        }}
        onSubmit={onSubmit}
      >
        {(fields, formikBag) => (
          <form
            style={{ height: "100%", display: "grid", gridGap: 8 }}
            onSubmit={formikBag.handleSubmit}
          >
            <MuiFormikTextFieldI18n
              labelMessage={formMessages.name}
              type="text"
              margin="normal"
              autoComplete="name"
              required={true}
              placeholderMessage={formMessages.namePlaceholder}
              css={textField}
              field={fields.name}
            />
            <MuiFormikTextFieldI18n
              labelMessage={formMessages.street}
              type="text"
              autoComplete="street-address"
              margin="normal"
              required={true}
              placeholderMessage={formMessages.streetPlaceholder}
              css={textField}
              field={fields.street}
            />
            <div css={flexClass}>
              <MuiFormikTextFieldI18n
                labelMessage={formMessages.zipCode}
                type="text"
                autoComplete="postal-code"
                margin="normal"
                required={true}
                placeholder={"04299"}
                css={textFieldSmall}
                field={fields.zip}
              />
              <MuiFormikTextFieldI18n
                labelMessage={formMessages.city}
                type="text"
                autoComplete="address-level2"
                margin="normal"
                required={true}
                placeholderMessage={formMessages.cityPlaceholder}
                css={textFieldBig}
                field={fields.city}
              />
            </div>
            <MuiFormikTextFieldI18n
              labelMessage={commonMessages.note}
              type="text"
              multiline={true}
              rows={4}
              margin="normal"
              placeholderMessage={commonMessages.backdoorNotePlaceholder}
              css={textField}
              field={fields.notes}
            />
            <div style={{ alignSelf: "flex-end", display: "flex" }}>
              <Button onClick={onCancelClick}>
                <LinguiMessage message={commonMessages.cancel} />
              </Button>
              <Button
                style={{ marginLeft: "auto" }}
                type="submit"
                variant="contained"
                color="secondary"
              >
                <LinguiMessage message={messages.checkAddress} />
              </Button>
            </div>
          </form>
        )}
      </FormikFields>
    );
  }
}
