import { createAction, createReducer } from "@apoly-42/apoly-utils";
import { compose, prop } from "ramda";
import { selectAppStateRoot } from "../selectors";

const SET_SEARCH_PHARMACIES_ADDRESS =
  "searchAddress/SET_SEARCH_PHARMACIES_ADDRESS";

export const setFoundAddress = createAction("searchAddress/setFoundAddress");
export const setAddressSearchTerm = createAction(
  "searchAddress/setAddressSearchTerm",
);
export const setSearchPharmaciesAddress = ({
  foundAddress,
  addressSearchTerm,
}) => ({
  type: SET_SEARCH_PHARMACIES_ADDRESS,
  payload: { foundAddress, addressSearchTerm },
});

const initialState = {
  addressSearchTerm: "",
  foundAddress: null,
};

const selectThisDuckRoot = compose(
  prop("searchPharmaciesAddress"),
  selectAppStateRoot,
);

export const selectAddressForPharmacySearch = compose(
  prop("foundAddress"),
  selectThisDuckRoot,
);
export const selectAddressSearchTermForPharmacySearch = compose(
  prop("addressSearchTerm"),
  selectThisDuckRoot,
);

export default createReducer(initialState, {
  [SET_SEARCH_PHARMACIES_ADDRESS]: (state, { payload }) => payload,
  [setFoundAddress]: (state, { payload }) => ({
    ...state,
    foundAddress: payload,
  }),
  [setAddressSearchTerm]: (state, { payload }) => ({
    ...state,
    addressSearchTerm: payload,
  }),
});
