import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { useLingui } from "@lingui/react";
import { Button } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { SizedFaIcon } from "../../../../../app-util-components/SizedFaIcon";
import {
  buttonXs,
  verticalCenteredInFlex,
} from "../../../../../app-utilities/cssClasses";

const messages = {
  toggleSearchBar: {
    id: "toggleSearchBar",
    message: "toggle search bar",
  },
};

export interface FindMedicButtonProps {
  toggleSearchBar: () => void;
  disabled: boolean;
}

export function FindMedicButton({
  toggleSearchBar,
  disabled,
}: FindMedicButtonProps) {
  const { i18n } = useLingui();

  return (
    <Button
      css={[verticalCenteredInFlex, buttonXs]}
      disabled={disabled}
      color="inherit"
      aria-label={i18n._(messages.toggleSearchBar)}
      onClick={toggleSearchBar}
    >
      <span>
        <SizedFaIcon icon={faSearch} />
      </span>
    </Button>
  );
}
