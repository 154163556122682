import {
  createContainer,
  onMountOrUpdate,
  pLocalStorage,
} from "@apoly-42/apoly-utils";
import { pipe, prop, tap, when } from "ramda";
import { connect } from "react-redux";
import { selectCookieConsent } from "../redux/cookieConsent";
import googleDataLayerPush from "../utilities/googleDataLayerPush";

const syncWithLocalStorage = props =>
  pLocalStorage.setItem(
    "__ACCEPT_DATA_PROTECTION_OPT_IN__",
    props.cookieConsent ? 1 : 0,
  );

const loadOnConsent = when(prop("cookieConsent"), () =>
  googleDataLayerPush({
    event: "con-push",
    consent: 1,
  }),
);

const HandleCookieConsent = createContainer(
  connect(state => ({ cookieConsent: selectCookieConsent(state) })),
  onMountOrUpdate(
    ["cookieConsent"],
    pipe(tap(syncWithLocalStorage), loadOnConsent),
  ),
);

HandleCookieConsent.propTypes = {};

export default HandleCookieConsent;
