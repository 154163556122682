import { LinguiEuro, LinguiMessage } from "@apoly-42/apoly-components";
import { withProp } from "@apoly-42/apoly-utils";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import { connect } from "react-redux";
import { compose } from "recompose";
import { commonMessages } from "../../../../constants/messages/commonMessages";
import {
  selectDeliveryAddressCountry,
  selectTransmission,
} from "../../../../redux/checkout/reducer";
import { selectEnhancedPharmacy } from "../../../../redux/shopPharmacy/reducer";
import { getShoppingCartTotalPrice } from "../../../../redux/shoppingCart/selectors";
import {
  deliveryPriceFromEnhancedPharmacy,
  getPostalCost,
} from "../../../../utilities/delivery/deliveryUtils";
import { getTotalPrice } from "../../../../utilities/product/productUtilities";

const messages = {
  courierCharge: {
    id: "courierCharge",
    message: "courier charge",
  },
  deliveryCharge: {
    id: "deliveryCharge",
    message: "delivery charge",
  },
  inclusiveMwSt: {
    id: "inclusiveMwSt",
    message: "inclusive 19% MwSt.",
  },
  inclusiveMwstTotal: {
    id: "inclusiveMwstTotal",
    message: "Inklusive MwSt.",
  },
};

const ClosurePrice = ({
  shoppingCartPrice,
  deliveryAddressCountry,
  transmission,
  deliveryPrice,
}) => (
  <Grid container spacing={2} justify="flex-end">
    <Grid item>
      {transmission === "courier" && (
        <Typography align="right">
          <LinguiMessage message={messages.courierCharge} />
          {deliveryAddressCountry !== "AT" && "*"}
          :&nbsp;
          <LinguiEuro value={Number(deliveryPrice)} />
        </Typography>
      )}

      {transmission === "postal" && (
        <Typography align="right">
          <LinguiMessage message={messages.deliveryCharge} />
          {deliveryAddressCountry !== "AT" && "*"}
          :&nbsp;
          <LinguiEuro value={Number(getPostalCost(deliveryAddressCountry))} />
        </Typography>
      )}

      <Typography variant="body2" align="right">
        <LinguiMessage message={commonMessages.totalAmount} />
        :&nbsp;
        <LinguiEuro
          value={getTotalPrice(shoppingCartPrice, transmission, deliveryPrice)}
        />
      </Typography>
      <Typography variant="caption" color="textSecondary" align="right">
        <LinguiMessage message={messages.inclusiveMwstTotal} />
      </Typography>
      {transmission === "postal" && deliveryAddressCountry !== "AT" && (
        <Typography variant="caption" color="textSecondary" align="right">
          *&npsp;
          <LinguiMessage message={commonMessages.increasedDeliveryCostsAut} />
        </Typography>
      )}
    </Grid>
  </Grid>
);

const mapStateToProps = state => ({
  shoppingCartPrice: getShoppingCartTotalPrice(state),
  enhancedPharmacy: selectEnhancedPharmacy(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withProp("deliveryPrice", props =>
    deliveryPriceFromEnhancedPharmacy(
      props.enhancedPharmacy,
      props.deliveryAddressCountry,
      props.transmission,
    ),
  ),
);

ClosurePrice.propTypes = {
  shoppingCartPrice: PropTypes.string.isRequired,
};

export default enhance(ClosurePrice);
