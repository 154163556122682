/* eslint-disable no-underscore-dangle,react/prop-types,import/no-extraneous-dependencies */
import React from "react";
import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "../deprecated-apoly-app/redux/reducer";

const getExtension = () =>
  process.env.NODE_ENV === "development" &&
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__();

const store = createStore(rootReducer, getExtension());

export const DeprecatedReduxAppProvider = props => (
  <Provider store={store}>{props.children}</Provider>
);
