// @ts-ignore
import { LinguiMessage } from "@apoly-42/apoly-components";
// @ts-ignore
import { Headline } from "@apoly-42/material-ui";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Card, CardContent, Divider, Typography } from "@material-ui/core";
import { css } from "@emotion/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { contentContainer, mdUp } from "../../app-utilities/cssClasses";
import HelmetComponent from "../../deprecated/deprecated-apoly-app/components/helmet/HelmetComponent";
import { imprintMessages } from "../../deprecated/deprecated-apoly-app/components/pages/imprint/ImprintPage";
import {
  selectBasePath,
  selectPharmacy,
} from "../../deprecated/deprecated-apoly-app/redux/shopPharmacy/reducer";
import { Pharmacy } from "../apolyApi";
import { Footer } from "../layout/Footer";
import { NavBar } from "../layout/NavBar";
import { widerrufsbelehrungPath } from "../routes";

export function Widerrufsbelehrung() {
  const pharmacy = useSelector<Pharmacy>(selectPharmacy);
  const basePath = useSelector(selectBasePath);
  const { i18n } = useLingui();

  return (
    <div>
      <HelmetComponent
        pageTitle={i18n._(t`Widerrufsbelehrung | Apoly`)}
        path={widerrufsbelehrungPath(basePath)}
        noIndex
      />

      <NavBar />

      <div
        css={[
          contentContainer,
          css`
            display: grid;
            grid-gap: 16px;
          `,
        ]}
      >
        <Headline
          headlineType="h1"
          responsive={true}
          css={css`
            margin-top: 8px;
            ${mdUp} {
              margin-top: 16px;
            }
          `}
        >
          <Trans id="Widerrufsbelehrung_Cancellation and Refund policy">
            Cancellation and Refund policy
          </Trans>
        </Headline>

        <Typography variant="h6">
          <Trans id="Widerrufsbelehrung_for contracts concluded outside of business premises and distance contracts">
            for contracts concluded outside of business premises and distance
            contracts
          </Trans>
        </Typography>

        <Typography>
          <Trans id="Widerrufsbelehrung_Consumers shall be entitled to the conclusion of contracts concluded outside of business premises and distance selling contracts according to the law, as long as this right has not been excluded or has expired. The legally binding version of this document is the version supplied in German.">
            Consumers shall be entitled to the conclusion of contracts concluded
            outside of business premises and distance selling contracts
            according to the law, as long as this right has not been excluded or
            has expired. The legally binding version of this document is the
            version supplied in German.
          </Trans>
        </Typography>

        <ul>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_'distance contracts' means contracts where the supplier or a person acting on his behalf and the consumer for the contract negotiations and the conclusion of the contract exclusively use means of telecommunication, unless the distribution of the contract is not carried out within the framework of a distance selling or service provision system.">
                'distance contracts' means contracts where the supplier or a
                person acting on his behalf and the consumer for the contract
                negotiations and the conclusion of the contract exclusively use
                means of telecommunication, unless the distribution of the
                contract is not carried out within the framework of a distance
                selling or service provision system.
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_'means of distance communication' means any means of communication which may be used to initiate or conclude a contract, without the simultaneous physical presence of the Contracting Parties, such as letters, catalogues, telephone calls, faxes, e-mails, etc., via the Mobile phone service sent messages (SMS) as well as radio and telemedia.">
                'means of distance communication' means any means of
                communication which may be used to initiate or conclude a
                contract, without the simultaneous physical presence of the
                Contracting Parties, such as letters, catalogues, telephone
                calls, faxes, e-mails, etc., via the Mobile phone service sent
                messages (SMS) as well as radio and telemedia.
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_'Consumer' means any natural person who enters into a legal transaction for purposes which are predominantly not attributable to your commercial activities or to those of your business nor can they be attributed to self-employed occupational activity.">
                'Consumer' means any natural person who enters into a legal
                transaction for purposes which are predominantly not
                attributable to your commercial activities or to those of your
                business nor can they be attributed to self-employed
                occupational activity.
              </Trans>
            </Typography>
          </li>
        </ul>

        <Typography variant="h4">
          <Trans id="Widerrufsbelehrung_Right of revocation">
            Right of revocation
          </Trans>
        </Typography>

        <Typography>
          <Trans id="Widerrufsbelehrung_You have the right to revoke this contract within fourteen days without any given reasons.">
            You have the right to revoke this contract within fourteen days
            without any given reasons.
          </Trans>
        </Typography>
        <Typography>
          <Trans id="Widerrufsbelehrung_The revocation period is fourteen days,">
            The revocation period is fourteen days,
          </Trans>
        </Typography>

        <ul>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_in the case of a contract of sale for one or more goods ordered uniformly and delivered together, from the date on which you, or a third party designated by you other than the carrier who took possession of the goods;">
                in the case of a contract of sale for one or more goods ordered
                uniformly and delivered together, from the date on which you, or
                a third party designated by you other than the carrier who took
                possession of the goods;
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_in the case of a contract for the sale of several goods which you have ordered under a single order and which have been delivered separately, from the date on which you, or a third party designated by you other than the carrier, have taken possession of the last goods;">
                in the case of a contract for the sale of several goods which
                you have ordered under a single order and which have been
                delivered separately, from the date on which you, or a third
                party designated by you other than the carrier, have taken
                possession of the last goods;
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_in the case of a contract for the delivery of goods in several instalments or pieces,from the date on which you, or a third party designated by you other than the carrier, have taken possession of the last part or the last piece.">
                in the case of a contract for the delivery of goods in several
                instalments or pieces,from the date on which you, or a third
                party designated by you other than the carrier, have taken
                possession of the last part or the last piece.
              </Trans>
            </Typography>
          </li>
        </ul>

        <Typography>
          <Trans id="Widerrufsbelehrung_In order to exercise your right of revocation, you must send us">
            In order to exercise your right of revocation, you must send us
          </Trans>
        </Typography>

        <Typography>
          {pharmacy.name}
          <br />
          <LinguiMessage message={imprintMessages.owner} />: {pharmacy.owner}
          <br />
          {pharmacy.address.street}
          <br />
          {pharmacy.address.zip} {pharmacy.address.city}
          <br />
          <LinguiMessage message={imprintMessages.email} />: {pharmacy.email}
          <br />
          <LinguiMessage message={imprintMessages.phone} />: {pharmacy.phone}
          <br />
          <LinguiMessage message={imprintMessages.fax} />: {pharmacy.fax}
        </Typography>

        <Typography>
          <Trans id="Widerrufsbelehrung_a clear statement (e.g. a letter sent by post, fax or e-mail) that you have decided to withdraw from this contract. You can use the attached template form, even though this is not mandatory.">
            a clear statement (e.g. a letter sent by post, fax or e-mail) that
            you have decided to withdraw from this contract. You can use the
            attached template form, even though this is not mandatory.
          </Trans>
        </Typography>
        <Typography>
          <Trans id="Widerrufsbelehrung_In order to comply with the revocation period, it is sufficient for you to send the notification of exercising the right of revocation before the expiration of the revocation period.">
            In order to comply with the revocation period, it is sufficient for
            you to send the notification of exercising the right of revocation
            before the expiration of the revocation period.
          </Trans>
        </Typography>

        <Typography variant="h5">
          <Trans id="Widerrufsbelehrung_Consequences of revocation">
            Consequences of revocation
          </Trans>
        </Typography>

        <Typography>
          <Trans id="Widerrufsbelehrung_If you revoke this contract we shall reimburse you immediately and no later than fourteen days from the date on which we received notice of your revocation of this contract for all payments we have received from you, including delivery charges for our standard delivery (additional costs arising from your choice of a delivery method other than the cheapest standard delivery offered by us will not be reimbursed). We will use the same means of payment used by you in the original transaction for such refund, unless expressly agreed otherwise with you and in no event will you be charged for such refund. We may refuse to refund until we have received the previously purchased goods back or until you have provided evidence that you have returned the goods, whichever is earlier.">
            If you revoke this contract we shall reimburse you immediately and
            no later than fourteen days from the date on which we received
            notice of your revocation of this contract for all payments we have
            received from you, including delivery charges for our standard
            delivery (additional costs arising from your choice of a delivery
            method other than the cheapest standard delivery offered by us will
            not be reimbursed). We will use the same means of payment used by
            you in the original transaction for such refund, unless expressly
            agreed otherwise with you and in no event will you be charged for
            such refund. We may refuse to refund until we have received the
            previously purchased goods back or until you have provided evidence
            that you have returned the goods, whichever is earlier.
          </Trans>
        </Typography>
        <Typography>
          <Trans id="Widerrufsbelehrung_You must return or hand over the goods to us immediately and in any event within fourteen days of the day on which you notify us of the revocation of this contract at the latest. This deadline shall be deemed to have been met if you dispatch the goods before the expiration of the fourteen-day period.">
            You must return or hand over the goods to us immediately and in any
            event within fourteen days of the day on which you notify us of the
            revocation of this contract at the latest. This deadline shall be
            deemed to have been met if you dispatch the goods before the
            expiration of the fourteen-day period.
          </Trans>
        </Typography>
        <Typography>
          <Trans id="Widerrufsbelehrung_You shall bear the direct costs of returning the goods.">
            You shall bear the direct costs of returning the goods.
          </Trans>
        </Typography>
        <Typography>
          <Trans id="Widerrufsbelehrung_You shall only be liable for any loss in value of the goods if such loss in value is due to handling of the goods that is not necessary for testing their condition, properties and functionality.">
            You shall only be liable for any loss in value of the goods if such
            loss in value is due to handling of the goods that is not necessary
            for testing their condition, properties and functionality.
          </Trans>
        </Typography>

        <Typography variant="h5" style={{ marginBottom: 16 }}>
          <Trans id="Widerrufsbelehrung_End of the revocation instruction">
            End of the revocation instruction
          </Trans>
        </Typography>

        <Typography variant="h4">
          <Trans id="Widerrufsbelehrung_Exclusion of the right of revocation">
            Exclusion of the right of revocation
          </Trans>
        </Typography>

        <Typography>
          <Trans id="Widerrufsbelehrung_Unless the parties have agreed otherwise, the right of revocation does not apply to the following contracts:">
            Unless the parties have agreed otherwise, the right of revocation
            does not apply to the following contracts:
          </Trans>
        </Typography>

        <ul
          css={css`
            list-style: decimal;
          `}
        >
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_Contracts for the delivery of goods which are not prefabricated and for which the manufacturing requires an individual selection or specification by the consumer or which are clearly tailored to the personal needs of the consumer;">
                Contracts for the delivery of goods which are not prefabricated
                and for which the manufacturing requires an individual selection
                or specification by the consumer or which are clearly tailored
                to the personal needs of the consumer;
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_Contracts for the supply of goods which are liable to deteriorate rapidly or whose date of expiry would be exceeded rapidly;">
                Contracts for the supply of goods which are liable to
                deteriorate rapidly or whose date of expiry would be exceeded
                rapidly;
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_Contracts for the supply of alcoholic beverages whose price was agreed at the time of conclusion of the contract but which cannot be supplied before 30 days after conclusion of the contract and the current value of which depends on fluctuations in the market over which the supplier has no control;">
                Contracts for the supply of alcoholic beverages whose price was
                agreed at the time of conclusion of the contract but which
                cannot be supplied before 30 days after conclusion of the
                contract and the current value of which depends on fluctuations
                in the market over which the supplier has no control;
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_Contracts for the delivery of newspapers, periodicals or magazines, with the exception of subscription contracts;">
                Contracts for the delivery of newspapers, periodicals or
                magazines, with the exception of subscription contracts;
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_Contracts for the supply of goods or services, including financial services, whose price depends on fluctuations in the financial market over which the supplier has no control and which may occur within the revocation period, in particular services in connection with shares, shares in open investment funds within the meaning of ยง 1 paragraph 4 of the German Investment Code (Kapitalanlagegesetzbuchs) and other tradable securities, foreign exchange, derivatives or money market instruments;">
                Contracts for the supply of goods or services, including
                financial services, whose price depends on fluctuations in the
                financial market over which the supplier has no control and
                which may occur within the revocation period, in particular
                services in connection with shares, shares in open investment
                funds within the meaning of ยง 1 paragraph 4 of the German
                Investment Code (Kapitalanlagegesetzbuchs) and other tradable
                securities, foreign exchange, derivatives or money market
                instruments;
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_Contracts for the provision of services in the areas of accommodation for purposes other than for residential purposes, the carriage of goods, the hiring of motor vehicles, the delivery of food and drink as well as the provision of other services in connection with leisure activities, if the contract hereby provides a specific date or period for the provision of such services, whereby the right of revocation is not excluded, however, in the case of contracts for travel services pursuant to ยง 651a, which have been concluded outside business premises, unless the oral negotiations on which the conclusion of the contract is based were conducted on the prior order of the consumer;">
                Contracts for the provision of services in the areas of
                accommodation for purposes other than for residential purposes,
                the carriage of goods, the hiring of motor vehicles, the
                delivery of food and drink as well as the provision of other
                services in connection with leisure activities, if the contract
                hereby provides a specific date or period for the provision of
                such services, whereby the right of revocation is not excluded,
                however, in the case of contracts for travel services pursuant
                to ยง 651a, which have been concluded outside business premises,
                unless the oral negotiations on which the conclusion of the
                contract is based were conducted on the prior order of the
                consumer;
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_Contracts concluded within the framework of a form of distribution/marketing in which the supplier offers goods or services to consumers who are personally present or to whom this possibility is granted, in a transparent procedure carried out by the auctioneer based on competing bids, in which the successful bidder is obliged to purchase the goods or services (public auction);">
                Contracts concluded within the framework of a form of
                distribution/marketing in which the supplier offers goods or
                services to consumers who are personally present or to whom this
                possibility is granted, in a transparent procedure carried out
                by the auctioneer based on competing bids, in which the
                successful bidder is obliged to purchase the goods or services
                (public auction);
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_Contracts where the consumer has expressly requested the supplier to visit him to carry out urgent repair or maintenance work, except in the case of other services provided during the visit which the consumer has not expressly requested, or in the case of goods supplied during the visit which are not necessarily needed as spare parts during the maintenance or repair;">
                Contracts where the consumer has expressly requested the
                supplier to visit him to carry out urgent repair or maintenance
                work, except in the case of other services provided during the
                visit which the consumer has not expressly requested, or in the
                case of goods supplied during the visit which are not
                necessarily needed as spare parts during the maintenance or
                repair;
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_Contracts for the provision of betting and lottery services, unless the consumer has made his contract declaration by telephone or the contract was concluded away from business premises, and">
                Contracts for the provision of betting and lottery services,
                unless the consumer has made his contract declaration by
                telephone or the contract was concluded away from business
                premises, and
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_notarised contracts; this applies to distance contracts for financial services only if the notary confirms that the rights of the consumer under ยง 312d Paragraph 2 BGB are safeguarded.">
                notarised contracts; this applies to distance contracts for
                financial services only if the notary confirms that the rights
                of the consumer under ยง 312d Paragraph 2 BGB are safeguarded.
              </Trans>
            </Typography>
          </li>
        </ul>

        <Typography variant="h4">
          <Trans id="Widerrufsbelehrung_Premature expiration of the right of revocation">
            Premature expiration of the right of revocation
          </Trans>
        </Typography>

        <Typography>
          <Trans id="Widerrufsbelehrung_The consumer's right of revocation shall expire prematurely in the following cases">
            The consumer's right of revocation shall expire prematurely in the
            following cases
          </Trans>
        </Typography>

        <ul
          css={css`
            list-style: decimal;
          `}
        >
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_Contracts for the delivery of sealed goods which are not suitable for return for reasons of health protection or hygiene if their seal has been removed after delivery;">
                Contracts for the delivery of sealed goods which are not
                suitable for return for reasons of health protection or hygiene
                if their seal has been removed after delivery;
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_Contracts for the delivery of goods if these have been inseparably mixed with other goods after delivery due to their nature;">
                Contracts for the delivery of goods if these have been
                inseparably mixed with other goods after delivery due to their
                nature;
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_Contracts for the delivery of sound or video recordings or computer software in a sealed package if the seal has been removed after delivery;">
                Contracts for the delivery of sound or video recordings or
                computer software in a sealed package if the seal has been
                removed after delivery;
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_Contracts for the provision of services, if the Contractor has completely provided the service and has only begun to provide the service after the Consumer has given his express consent and at the same time confirmed his knowledge that he loses his right of revocation if the Contractor has completely fulfilled the contract;">
                Contracts for the provision of services, if the Contractor has
                completely provided the service and has only begun to provide
                the service after the Consumer has given his express consent and
                at the same time confirmed his knowledge that he loses his right
                of revocation if the Contractor has completely fulfilled the
                contract;
              </Trans>
            </Typography>
          </li>
          <li>
            <Typography>
              <Trans id="Widerrufsbelehrung_Contracts for the delivery of digital content not on a physical data carrier, if the contractor has begun to execute the contract after the consumer has expressly agreed that the contractor will begin to execute the contract before the expiry of the revocation period and has confirmed his knowledge that he will lose his right of revocation by his agreement at the beginning of the execution of the contract.">
                Contracts for the delivery of digital content not on a physical
                data carrier, if the contractor has begun to execute the
                contract after the consumer has expressly agreed that the
                contractor will begin to execute the contract before the expiry
                of the revocation period and has confirmed his knowledge that he
                will lose his right of revocation by his agreement at the
                beginning of the execution of the contract.
              </Trans>
            </Typography>
          </li>
        </ul>

        <Divider />

        <Card>
          <CardContent
            css={css`
              display: grid;
              grid-gap: 16px;
            `}
          >
            <Typography variant="h4">
              <Trans id="Widerrufsbelehrung_Template revocation form">
                Template revocation form
              </Trans>
            </Typography>

            <Typography>
              <Trans id="Widerrufsbelehrung_(If you want to cancel the contract, please fill out this form and send it to us.)">
                (If you want to cancel the contract, please fill out this form
                and send it to us.)
              </Trans>
            </Typography>

            <Typography>
              -&nbsp;<Trans id="Widerrufsbelehrung_To">To</Trans>
            </Typography>

            <Typography>
              {pharmacy.name}
              <br />
              <LinguiMessage message={imprintMessages.owner} />:{" "}
              {pharmacy.owner}
              <br />
              {pharmacy.address.street}
              <br />
              {pharmacy.address.zip} {pharmacy.address.city}
              <br />
              <LinguiMessage message={imprintMessages.email} />:{" "}
              {pharmacy.email}
              <br />
              <LinguiMessage message={imprintMessages.fax} />: {pharmacy.fax}
            </Typography>

            <Typography>
              -&nbsp;
              <Trans id="Widerrufsbelehrung_I/we (*) hereby cancel the purchase contract concluded by me/us (*). of the following goods (*)/the provision of the following service (*)">
                I/we (*) hereby cancel the purchase contract concluded by me/us
                (*). of the following goods (*)/the provision of the following
                service (*)
              </Trans>
            </Typography>

            <Typography>
              -&nbsp;
              <Trans id="Widerrufsbelehrung_Ordered on (*)/received on (*)">
                Ordered on (*)/received on (*)
              </Trans>
            </Typography>
            <Typography>
              -&nbsp;
              <Trans id="Widerrufsbelehrung_Name(s) of consumer(s)">
                Name(s) of consumer(s)
              </Trans>
            </Typography>
            <Typography>
              -&nbsp;
              <Trans id="Widerrufsbelehrung_Address of consumer(s)">
                Address of consumer(s)
              </Trans>
            </Typography>
            <Typography>
              -&nbsp;
              <Trans id="Widerrufsbelehrung_Signature of consumer(s) (only for paper communication)">
                Signature of consumer(s) (only for paper communication)
              </Trans>
            </Typography>
            <Typography>
              -&nbsp;
              <Trans id="Widerrufsbelehrung_date">date</Trans>
            </Typography>

            <Divider />
            <Typography variant="caption">
              <Trans id="Widerrufsbelehrung_(*) delete as appropriate">
                (*) delete as appropriate
              </Trans>
            </Typography>
          </CardContent>
        </Card>
      </div>

      <Footer />
    </div>
  );
}
