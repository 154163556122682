import { MessageDescriptor } from "@lingui/core/cjs/i18n";
import { useLingui } from "@lingui/react";
import { ReactNode } from "react";
import * as React from "react";
import { AppHelmet } from "./AppHelmet";

export interface AppHelmetI18nProps {
  pageTitleMessage: MessageDescriptor;
  pageTitleValues?: { [key: string]: ReactNode };
  keywordsMessage?: MessageDescriptor;
  keywordsValues?: { [key: string]: ReactNode };
  descriptionMessage?: MessageDescriptor;
  descriptionValues?: { [key: string]: ReactNode };
}

export function AppHelmetI18n({
  pageTitleMessage,
  pageTitleValues,
  keywordsMessage,
  keywordsValues,
  descriptionMessage,
  descriptionValues,
  ...props
}: AppHelmetI18nProps) {
  const { i18n } = useLingui();

  return (
    <AppHelmet
      pageTitle={i18n._(pageTitleMessage, pageTitleValues)}
      keywords={
        keywordsMessage ? i18n._(keywordsMessage, keywordsValues) : undefined
      }
      description={
        descriptionMessage
          ? i18n._(descriptionMessage, descriptionValues)
          : undefined
      }
      {...props}
    />
  );
}
