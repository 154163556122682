import { faFrownOpen } from "@fortawesome/pro-light-svg-icons";
import { Button } from "@material-ui/core";

import * as React from "react";

import { FluidTypography } from "../app-util-components/FluidTypography";
import { LinguiMessage } from "../app-util-components/LinguiMessage";
import { SizedFaIcon } from "../app-util-components/SizedFaIcon";
import { appMessages } from "../app-utilities/appMessages";
import { contentContainer } from "../app-utilities/cssClasses";
import { errorContainerCss } from "./EternalAppError";
import { DefaultPage } from "./layout/DefaultPage";

interface AppErrorProps {}

const appErrorMessages = {
  thatShouldNotHaveHappened: {
    id: "thatShouldNotHaveHappened",
    message: "Sorry, that should not have happened!",
  },
  errorOccurred: {
    id: "errorOccurred",
    message: "An error occurred.",
  },
  weInvestigateAndTryToSolveProblem: {
    id: "weInvestigateAndTryToSolveProblem",
    message:
      "We will investigate this Problem and try to resolve it as fast as possible.",
  },
  sorryForInconveniences: {
    id: "sorryForInconveniences",
    message: "Please accept our apologies for the inconvenience caused.",
  },
  tryToRefreshPageOnError: {
    id: "tryToRefreshPageOnError",
    message:
      "Please try to reload this page. If the problem persists, try again later today or tomorrow.",
  },
};

export class AppError extends React.PureComponent<AppErrorProps> {
  render() {
    return (
      <DefaultPage>
        <div css={[contentContainer, errorContainerCss]}>
          <FluidTypography type="headline1" paragraph={true}>
            <LinguiMessage
              message={appErrorMessages.thatShouldNotHaveHappened}
            />
          </FluidTypography>
          <FluidTypography
            type="headline3"
            style={{ display: "flex" }}
            paragraph={true}
          >
            <LinguiMessage message={appErrorMessages.errorOccurred} />{" "}
            <SizedFaIcon
              style={{ margin: "auto 8px" }}
              fontSize={32}
              icon={faFrownOpen}
            />
          </FluidTypography>
          <FluidTypography paragraph={true}>
            <LinguiMessage message={appErrorMessages.tryToRefreshPageOnError} />{" "}
            <LinguiMessage
              message={appErrorMessages.weInvestigateAndTryToSolveProblem}
            />{" "}
            <LinguiMessage message={appErrorMessages.sorryForInconveniences} />
          </FluidTypography>
          <Button
            onClick={() => window.location.reload(true)}
            variant="contained"
            color="secondary"
          >
            <LinguiMessage message={appMessages.reloadPage} />
          </Button>
        </div>
      </DefaultPage>
    );
  }
}
