import { Button } from "@material-ui/core";
import { WithIcon } from "@apoly-42/apoly-components";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import { UrlConsumer } from "../url";
import { LinguiMessage } from "./LinguiMessage";
import { appMessages } from "../app-utilities/appMessages";

interface InnerHistoryBackButtonProps {
  history: History;
}

const InnerHistoryBackButton: React.FunctionComponent<InnerHistoryBackButtonProps> = ({
  history,
}) => (
  <UrlConsumer>
    {ctx =>
      ctx.urlHistory.length > 1 && (
        <Button
          variant="outlined"
          onClick={() => {
            history.goBack();
          }}
        >
          <WithIcon iconLeft={true} icon={faArrowLeft}>
            <div style={{ marginTop: 4 }}>
              <LinguiMessage message={appMessages.back} />
            </div>
          </WithIcon>
        </Button>
      )
    }
  </UrlConsumer>
);

export const HistoryBackButton = withRouter<InnerHistoryBackButtonProps>(
  InnerHistoryBackButton,
);
