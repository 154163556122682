import { FnItemTplVar, TplItemKeyValue } from "../tplTypes";

type FnItemBase<Values> = { tplVar: FnItemTplVar<Values> };
type StrItemBase = { tplVar: string };

export enum ItemTypes {
  FnItemStage1 = 1,
  FnItemStage2,
  FnItem,
  StrItemStage1,
  StrItemStage2,
  StrItem,
}

export interface FnItemStage1<Values> extends FnItemBase<Values> {
  type: ItemTypes.FnItemStage1;
  possibleStart: number;
  prevItem: Stage1Item<Values> | undefined;
}

export interface StrItemStage1<Values> extends StrItemBase {
  type: ItemTypes.StrItemStage1;
  possibleStart: number;
  prevItem: Stage1Item<Values> | undefined;
}

export interface FnItemStage2<Values> extends FnItemBase<Values> {
  type: ItemTypes.FnItemStage2;
  end: number;
}

export interface StrItemStage2 extends StrItemBase {
  type: ItemTypes.StrItemStage2;
  start: number;
  end: number;
}

export interface FnItem<Values> extends FnItemBase<Values> {
  type: ItemTypes.FnItem;
  start: number;
  end: number;
  extracted?: string;
  templateResult?: TplItemKeyValue<keyof Values> | boolean;
  prevItem: TemplateItem<Values> | undefined;
}

export interface StrItem<Values> extends StrItemBase {
  type: ItemTypes.StrItem;
  start: number;
  end: number;
  prevItem: TemplateItem<Values> | undefined;
}

export type Stage1Item<Values> = FnItemStage1<Values> | StrItemStage1<Values>;
export type Stage2Item<Values> = FnItemStage2<Values> | StrItemStage2;
export type TemplateItem<Values> = FnItem<Values> | StrItem<Values>;
