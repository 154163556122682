import { T } from "ramda";
import * as React from "react";
import { Redirect } from "react-router";
import { veritasPharmacyCode } from "../app-theme/pharmacy-theme/customisations-veritas";
import { RequiredUrlPathConsumer } from "../app-util-components/RequiredUrlPathConsumer";
import { cond } from "../app-utilities/fn-utils";
import { build, extract, matches } from "../tpl/tpl";
import { PharmacyBase } from "./PharmacyBase";
import { pharmacyBase } from "./routes";
import { localDeliveryRoute } from "./routes-helper";

interface AppContentProps {}

const renderRoute = cond<string, React.ReactNode>(
  [
    [
      matches(pharmacyBase, { pharmacyChannel: T, pharmacyUrlCode: T }),
      route => (
        <PharmacyBase
          {...extract(
            pharmacyBase,
            {
              pharmacyChannel: (str: string) => str.split("/")[0],
              pharmacyUrlCode: (str: string) => str.split("/")[0],
            },
            route,
          )}
        />
      ),
    ],
  ],
  () => (
    <Redirect
      push={true}
      to={build(pharmacyBase, {
        pharmacyChannel: localDeliveryRoute,
        pharmacyUrlCode: veritasPharmacyCode,
      })}
    />
  ),
);

export class AppContent extends React.PureComponent<AppContentProps> {
  render() {
    return <RequiredUrlPathConsumer>{renderRoute}</RequiredUrlPathConsumer>;
  }
}
