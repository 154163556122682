import { Typography } from "@material-ui/core";
import { css } from "@emotion/core";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { LinguiMessage } from "../../../app-util-components/LinguiMessage";
import { appMessages } from "../../../app-utilities/appMessages";
import { selectBasePath } from "../../../deprecated/deprecated-apoly-app/redux/shopPharmacy/reducer";
import { build } from "../../../tpl/tpl";
import { CtxPathLink } from "../../../url-utils";
import { checkoutClosureMessages } from "../../pages/checkout/CheckoutClosure";
import { PharmacyContextValues } from "../../PharmacyContext";
import {
  agbPath,
  dataSecurityPath,
  imprintPath,
  widerrufsbelehrungPath,
} from "../../routes";
import { pharmacyCtxToPath } from "../PharmacyBaseLink";

const container = css`
  margin: auto;
  display: grid;
  grid-gap: 16px;
  grid-auto-columns: auto;
  grid-auto-flow: row;
  @media (min-width: 800px) {
    grid-auto-flow: column;
  }
`;

const link = css`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const messages = {
  agbDownload: {
    id: "agbDownload",
    message: "conditions of use & sale (pdf-download)",
  },
};

export function LawAgreements(props: PharmacyContextValues) {
  const basePath = useSelector(selectBasePath);

  return (
    <div css={container}>
      <a href={build(agbPath, {})} target="_blank" css={link}>
        <Typography color="textPrimary" component="span">
          <LinguiMessage message={messages.agbDownload} />
        </Typography>
      </a>
      <CtxPathLink path={pharmacyCtxToPath(dataSecurityPath, props)} css={link}>
        <Typography color="textPrimary" component="span">
          <LinguiMessage message={appMessages.dataSecurity} />
        </Typography>
      </CtxPathLink>
      <CtxPathLink path={pharmacyCtxToPath(imprintPath, props)} css={link}>
        <Typography color="textPrimary" component="span">
          <LinguiMessage message={appMessages.imprint} />
        </Typography>
      </CtxPathLink>
      {props.pharmacy && (
        <Link to={widerrufsbelehrungPath(basePath)} css={link}>
          <Typography color="textPrimary" component="span">
            <LinguiMessage
              message={checkoutClosureMessages.cancellationPolicy}
            />
          </Typography>
        </Link>
      )}
    </div>
  );
}
