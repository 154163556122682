import React from "react";
import { Route } from "react-router-dom";
import { ReservationOverview } from "../app/pages/ReservationOverview";
import { Widerrufsbelehrung } from "../app/pages/Widerrufsbelehrung";
import {
  dashboardReservationRoute,
  widerrufsbelehrungPath,
} from "../app/routes";
import { PharmacyChannel } from "../app/routes-helper";
import {
  LoadContactPage,
  LoadDataSecurityPage,
  LoadDeliveryCostsPage,
  LoadFaqPage,
  LoadImprintPage,
  LoadJobsPage,
  LoadLoggedOutPage,
  LoadLoginOrContinueAsGuest,
  LoadLoginPage,
  LoadMedicSearch,
  LoadOtcOrderCompletePage,
  LoadOtcPaymentFailedPage,
  LoadPartnerPharmacyPage,
  LoadPressPage,
  LoadProductPage,
  LoadRegionFinderPage,
  LoadRegisterPage,
  LoadRxOrderCompletePage,
  LoadShoppingCartPage,
  LoadSignUpOrContinueAsGuest,
  LoadSubmitPrescriptionPage,
} from "./deprecated-apoly-app/components/pages/LoadPagesAsync";
import LoadChangeUserDataPage from "./deprecated-apoly-app/components/pages/userSubPages/LoadChangeUserDataPage";
import PrivateRoute from "./deprecated-apoly-app/components/routeComponenents/PrivateRoute";
import Route404Switch from "./deprecated-apoly-app/containers/Route404Switch";
import {
  checkoutPath,
  checkoutPathLoginOrContinueAsGuest,
  checkoutPathSignUpContinueAsGuest,
  contactPathSetPharmacy,
  dataSecuritySetPharmacyPath,
  deliveryCostsSetPharmacyPath,
  faqSetPharmacyPath,
  imprintSetPharmacyPath,
  jobsSetPharmacyPath,
  loggedOutSetPharmacyPath,
  loginSetPharmacyPath,
  medicSearchSetPharmacyPath,
  otcOrderCompletePath,
  otcPaymentFailedPath,
  partnerPharmacyPath,
  pressSetPharmacyPath,
  regionFinderPath,
  registerSetPharmacyPath,
  rxOrderCompletePath,
  shoppingCartPath,
  singleProductSetPharmacyPath,
  submitPrescriptionPath,
  userPathWithBase,
} from "./deprecated-apoly-app/routes/paths";
import { PharmacyCheckout } from "./PharmacyCheckout";

interface PharmacyShopRoutesProps {
  basePath: string;
  pharmacyChannel: PharmacyChannel;
}

export class PharmacyShopRoutes extends React.PureComponent<
  PharmacyShopRoutesProps
> {
  render() {
    const { basePath } = this.props;

    return (
      <Route404Switch>
        <Route
          exact
          path={widerrufsbelehrungPath(basePath)}
          component={Widerrufsbelehrung}
        />

        <Route
          exact={true}
          path={this.props.basePath}
          component={LoadPartnerPharmacyPage}
        />
        <Route
          exact={true}
          path={checkoutPath(this.props.basePath)}
          component={PharmacyCheckout}
        />
        <Route
          exact={true}
          path={regionFinderPath(this.props.basePath)}
          component={LoadRegionFinderPage}
        />
        <Route
          exact={true}
          path={checkoutPathLoginOrContinueAsGuest(this.props.basePath)}
          component={LoadLoginOrContinueAsGuest}
        />
        <Route
          exact={true}
          path={loggedOutSetPharmacyPath(this.props.basePath)}
          component={LoadLoggedOutPage}
        />
        <Route
          exact={true}
          path={checkoutPathSignUpContinueAsGuest(this.props.basePath)}
          component={LoadSignUpOrContinueAsGuest}
        />
        <Route
          exact={true}
          path={faqSetPharmacyPath(this.props.basePath)}
          component={LoadFaqPage}
        />
        <Route
          exact={true}
          path={jobsSetPharmacyPath(this.props.basePath)}
          component={LoadJobsPage}
        />
        <Route
          exact={true}
          path={pressSetPharmacyPath(this.props.basePath)}
          component={LoadPressPage}
        />
        <Route
          exact={true}
          path={registerSetPharmacyPath(this.props.basePath)}
          component={LoadRegisterPage}
        />
        <Route
          exact={true}
          path={loginSetPharmacyPath(this.props.basePath)}
          component={LoadLoginPage}
        />
        <Route
          exact={true}
          path={loggedOutSetPharmacyPath(this.props.basePath)}
          component={LoadLoggedOutPage}
        />
        <Route
          exact={true}
          path={contactPathSetPharmacy(this.props.basePath)}
          component={LoadContactPage}
        />
        <Route
          exact={true}
          path={imprintSetPharmacyPath(this.props.basePath)}
          component={LoadImprintPage}
        />
        <Route
          exact={true}
          path={dataSecuritySetPharmacyPath(this.props.basePath)}
          component={LoadDataSecurityPage}
        />
        <Route
          exact={true}
          path={otcPaymentFailedPath(this.props.basePath, ":otcOrderKey")}
          component={LoadOtcPaymentFailedPage}
        />
        <Route
          exact={true}
          path={otcOrderCompletePath(this.props.basePath, ":otcOrderKey")}
          component={LoadOtcOrderCompletePage}
        />

        <Route
          exact={true}
          path={rxOrderCompletePath(this.props.basePath, ":rxOrderKey")}
          component={LoadRxOrderCompletePage}
        />
        <Route
          exact={true}
          path={shoppingCartPath(this.props.basePath)}
          component={LoadShoppingCartPage}
        />
        <Route
          exact={true}
          path={partnerPharmacyPath(this.props.basePath)}
          component={LoadPartnerPharmacyPage}
        />
        <Route
          exact={true}
          path={medicSearchSetPharmacyPath(this.props.basePath)}
          component={LoadMedicSearch}
        />

        <Route
          render={routeProps => (
            <ReservationOverview orderKey={routeProps.match.params.orderKey} />
          )}
          exact={true}
          path={dashboardReservationRoute(basePath, ":orderKey")}
        />

        <Route
          exact={true}
          path={deliveryCostsSetPharmacyPath(this.props.basePath)}
          component={LoadDeliveryCostsPage}
        />
        <Route
          path={singleProductSetPharmacyPath(
            this.props.basePath,
            ":productUrlCode",
            ":pzn",
          )}
          render={({
            match: {
              params: { productUrlCode, pzn },
            },
          }) => (
            <LoadProductPage
              key={`${productUrlCode}-${pzn}`}
              medicineUrlCode={productUrlCode}
              pzn={pzn}
            />
          )}
        />

        <PrivateRoute
          path={userPathWithBase(this.props.basePath)}
          component={LoadChangeUserDataPage}
        />
        {this.props.pharmacyChannel === PharmacyChannel.local && [
          <Route
            key={submitPrescriptionPath(this.props.basePath)}
            exact={true}
            path={submitPrescriptionPath(this.props.basePath)}
            component={LoadSubmitPrescriptionPage}
          />,
        ]}
      </Route404Switch>
    );
  }
}
