import { toError } from "@apoly-42/apoly-utils";
import React from "react";
import Loads from "react-loads";
import { RenderProp } from "../app-utilities/reactUtilTypes";

export interface LoadingState<T> {
  state: string;
  error: Error;
  response: T;
  isError: boolean;
  isIdle: boolean; // boolean	Returns true if the state is idle (fn has not been triggered).
  isLoading: boolean;
  isSuccess: boolean;
  isTimeout: boolean;
  resetState: () => void; // function()	Reset state back to idle.
  load: () => Promise<T>; // function(...args)	Trigger to load fn
}

export interface LoadsWrapperProps<T, E = Error> {
  fn: () => Promise<T>;
  delay?: number;
  loadOnMount?: boolean;
  crashComponentOnError?: boolean | ((error: E) => boolean);
  children?: RenderProp<LoadingState<T>>;
}

// https://github.com/jxom/react-loads#children-render-props
export class Load<T, E = Error> extends React.PureComponent<
  LoadsWrapperProps<T, E>
> {
  state = { error: null };

  handleError = (error: E) => {
    const { crashComponentOnError = true } = this.props;

    const shouldCrash =
      typeof crashComponentOnError === "function"
        ? crashComponentOnError(error)
        : crashComponentOnError;

    if (shouldCrash) {
      this.setState({ error });
    }

    throw error;
  };

  callPromise = () => this.props.fn().catch(this.handleError);

  renderChildren = (load: LoadingState<T>) =>
    this.props.children ? this.props.children(load) : null;

  render() {
    if (this.state.error) {
      throw toError(this.state.error);
    }

    const { delay = 0, loadOnMount = true } = this.props;

    return (
      <Loads fn={this.callPromise} delay={delay} loadOnMount={loadOnMount}>
        {this.renderChildren}
      </Loads>
    );
  }
}
