import * as React from "react";
import { Fragment, useContext, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  PharmacyThemeProvider,
  pharmacyUrlCodeToPharmacyTheme,
} from "../app-theme/pharmacy-theme/pharmacyTheme";
import { PharmacyShopRoutes } from "../deprecated/PharmacyShopRoutes";
import { build } from "../tpl/tpl";
import { Pharmacy } from "./apolyApi";
import { AppConsumer } from "./AppContext";
import { AppMuiTheme } from "./layout/AppMuiTheme";
import { LoadPharmacy } from "./LoadPharmacy";
import { PssAbholautomatEntryScreen } from "./pages/PssAbholautomatEntryScreen";
import { PharmacyCtx } from "./PharmacyContext";
import { ResetAppTimer } from "./ResetAppTimer";
import {
  dashboardReservationRoute,
  pharmacyBase,
  pharmacyBasePath,
  PharmacyBasePathProps,
} from "./routes";
import { PharmacyChannel, routeToChannel } from "./routes-helper";

function InnerPharmacyBase(props: PharmacyBasePathProps & RouteComponentProps) {
  const pharmacyTheme = pharmacyUrlCodeToPharmacyTheme(props.pharmacyUrlCode);
  const channel = routeToChannel(props.pharmacyChannel);

  const pharmacyCtx = useContext(PharmacyCtx.context);
  const [isPssEntry, setIsPssEntry] = useState(
    channel === PharmacyChannel.pss &&
      // zeige welcome-screen nur an, wenn es die default-seite ist
      window.location.href.substr(-"/abholautomat".length) === "/abholautomat",
  );

  return (
    <PharmacyThemeProvider value={pharmacyTheme}>
      {channel === PharmacyChannel.customerBoard ||
      channel === PharmacyChannel.pss ? (
        <ResetAppTimer />
      ) : null}

      <AppConsumer>
        {appCtx => (
          <LoadPharmacy
            pharmacyUrlCode={props.pharmacyUrlCode}
            setEnhancedPharmacy={pharmacyCtx.enhancedPharmacy.setValue}
            setPharmacy={pharmacyCtx.pharmacy.setValue}
            setPharmacyIsLoading={pharmacyCtx.pharmacyIsLoading.setValue}
            serverTime={appCtx.serverTime.value}
            setPharmacyChannel={pharmacyCtx.pharmacyChannel.setValue}
            pharmacyChannelFromRoute={props.pharmacyChannel}
          />
        )}
      </AppConsumer>

      <AppMuiTheme palette={pharmacyTheme.palette}>
        <Fragment>
          {pharmacyCtx.enhancedPharmacy.value ? (
            isPssEntry ? (
              <PssAbholautomatEntryScreen
                onFoundReservation={orderKey => {
                  const base = pharmacyBasePath({
                    pharmacyChannel: pharmacyCtx.pharmacyChannel
                      .value as PharmacyChannel,
                    pharmacy: pharmacyCtx.pharmacy.value as Pharmacy,
                  });
                  props.history.push(dashboardReservationRoute(base, orderKey));
                  setIsPssEntry(false);
                }}
                onWantShopping={() => setIsPssEntry(false)}
              />
            ) : (
              <PharmacyShopRoutes
                basePath={build(pharmacyBase, props)}
                pharmacyChannel={channel}
              />
            )
          ) : null}
        </Fragment>
      </AppMuiTheme>
    </PharmacyThemeProvider>
  );
}

export const PharmacyBase = withRouter<
  PharmacyBasePathProps & RouteComponentProps
>(InnerPharmacyBase);
