import { setupI18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import React, { ReactNode, useEffect, useState } from "react";
import moment from "moment";
import { useAsyncEffect } from "../app-utilities/hook-utils";
import { LanguageContextProps } from "./LanguageContext";

interface AsyncLanguageLoadableProps {
  language: LanguageContextProps["language"];
  children: ReactNode;
}

export function AsyncLanguageLoadable({
  language,
  children,
}: AsyncLanguageLoadableProps) {
  const [i18n, setI18n] = useState(() => ({ v: setupI18n() }));

  useEffect(() => {
    document.body.dir = language === "ar" ? "rtl" : "ltr";
  }, [language]);

  useAsyncEffect(
    async abortSignal => {
      let l = language;

      switch (l) {
        case "ar": {
          const res = await Promise.all([
            await import("moment/locale/ar"),
            await import("../translations/lingui/ar"),
          ]);
          await i18n.v.load("ar", res[1].default);
          break;
        }
        case "ru": {
          const res = await Promise.all([
            await import("moment/locale/ru"),
            await import("../translations/lingui/ru"),
          ]);
          await i18n.v.load("ru", res[1].default);
          break;
        }
        case "tr": {
          const res = await Promise.all([
            await import("moment/locale/tr"),
            await import("../translations/lingui/tr"),
          ]);
          await i18n.v.load("tr", res[1].default);
          break;
        }
        case "en": {
          const res = await import("../translations/lingui/en");
          await i18n.v.load("en", res.default);
          break;
        }
        default:
        case "de": {
          // setze die lokalität direkt auf de, falls es nicht bekannt ist:
          l = "de";

          const res = await Promise.all([
            await import("moment/locale/de"),
            await import("../translations/lingui/de"),
          ]);
          await i18n.v.load("de", res[1].default);
          break;
        }
      }

      if (abortSignal.aborted) {
        return;
      }

      moment.locale(l);
      await i18n.v.activate(l);
      setI18n({ v: i18n.v });
    },
    [language],
  );

  return <I18nProvider i18n={i18n.v}>{children}</I18nProvider>;
}

// TODO set rtl/ltr which depends on locale ( props.locale === 'ar' ? 'rtl' : 'ltr' )
// const setBodyTextDirection = textDirection =>  (document.body.dir = textDirection);
