import { faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { LinguiMessage } from "../../../../../app-util-components/LinguiMessage";
import { appMessages } from "../../../../../app-utilities/appMessages";
import { ListItemButtonWithIcon } from "../ListItemButtonWithIcon";

export interface CloseDrawerButtonProps {
  handleDrawer: () => void;
}

export const CloseDrawerButton: React.SFC<CloseDrawerButtonProps> = ({
  handleDrawer,
}) => (
  <ListItemButtonWithIcon
    text={<LinguiMessage message={appMessages.closeDrawer} />}
    icon={faCaretRight}
    onClickEvent={handleDrawer}
  />
);
