import { Typography } from "@material-ui/core";
import React, { HTMLAttributes, ReactNode } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  label: ReactNode;
  text: ReactNode;
}

export function TextWithLabel({ label, text, ...props }: Props) {
  return (
    <div {...props}>
      <Typography component="div" variant="caption" color="textSecondary">
        {label}
      </Typography>
      <Typography component="div" css={{ marginTop: -4 }}>
        {text}
      </Typography>
    </div>
  );
}
