import { css } from "@emotion/core";

export const mediaUp = minWidth => `@media (min-width: ${minWidth})`;

export const mediaDown = maxWidth => `@media (max-width: ${maxWidth})`;

export const mediaBetween = (minWidth, maxWidth) =>
  `@media (min-width: ${minWidth}) and (max-width: ${maxWidth})`;

export const smUp = mediaUp("600px"); // tablet

export const mdUp = mediaUp("960px"); // small laptops / tablet landscape

export const lgUp = mediaUp("1280px"); // greater laptops / desktop-PC

export const appGrid = css`
  display: grid;
  grid-gap: 16px;
  height: 100%;
`;

export const threeItemsInGridRow = css`
  ${appGrid};
  height: auto;
  justify-items: center;
  grid-template-columns: 1fr;
  ${mdUp} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const contentContainer = css`
  width: 100%;
  margin: 0 auto;
  padding: 8px;
  ${lgUp} {
    width: 80%;
  }
`;

export const contentScreen = (minHeight, height) => css`
  padding: 8px;
  min-height: ${minHeight};
  height: ${height || "auto"};
`;

export const h1ContentScreen = css`
  ${contentScreen("60px", "auto")};
`;

export const h2ContentScreen = css`
  ${contentScreen("50px", "auto")};
`;

export const priceWithTax = css`
  display: flex;
  flex-direction: column;
  align-items: right;
`;

export const h3ContentScreen = css`
  ${contentScreen("40px", "auto")};
`;

export const gridArea = areaName => css`
  grid-area: ${areaName};
`;

export const fullWidth = css`
  width: 100%;
`;

export const fullWidthWithPadding = css`
  width: 100%;
  padding: 8px;
`;

export const userOrderCompleteGrid = css`
  ${appGrid};
  align-items: flex-start;
  height: auto;
  grid-template-areas:
    "headline"
    "text"
    "backToHomeBtn"
    "gotToDetailsBtn";
  grid-template-columns: 1fr;
  ${smUp} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "headline headline"
      "text text"
      "backToHomeBtn gotToDetailsBtn";
  }
  ${mdUp} {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      "headline headline headline headline headline headline"
      "text text text text text image"
      "backToHomeBtn backToHomeBtn backToHomeBtn gotToDetailsBtn gotToDetailsBtn gotToDetailsBtn";
  }
`;

export const pharmacyCtaGrid = css`
  ${appGrid};
  align-items: flex-start;
  height: auto;
  grid-template-areas:
    "happyPill list"
    "cta cta";
  grid-template-columns: 25% 1fr;
  ${mdUp} {
    grid-template-areas:
      "list list"
      "cta cta";
  }
  ${lgUp} {
    grid-template-areas:
      "happyPill list"
      "cta cta";
    grid-template-columns: 33.333% minmax(0 1fr);
  }
`;
