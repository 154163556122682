import { faBox, faShoppingCart } from "@fortawesome/pro-light-svg-icons";
import { faPills, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defineMessage, Trans } from "@lingui/macro";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import shadows from "@material-ui/core/styles/shadows";
import { css } from "@emotion/core";
import QRCode from "qrcode.react";
import * as React from "react";
import { useContext, useEffect, useState } from "react";

import { AppHelmetI18n } from "../../app-util-components/AppHelmetI18n";
import { CtaButton } from "../../app-util-components/CtaButton";
import { appGrid } from "../../app-utilities/cssClasses";
import { Form } from "../../app-utilities/react-utils";
import { ABHOL_SECRET, APOLY_API_BASE_URL } from "../../environment";
import { Footer } from "../layout/Footer";
import { NavBar } from "../layout/NavBar";
import { PharmacyCtx } from "../PharmacyContext";
import { useClientSize } from "../react-utils";
import bgPic from "./assets/abholautomat-landingpage-hintergrundbild.jpg";

const messages = {
  PssAbholautomatEntryScreenTitle: defineMessage({
    id: "PssAbholautomatEntryScreenTitle",
    message: "Ihr Apoly-Abholautomat",
  }),
};

interface Props {
  onFoundReservation(orderKey: string): void;
  onWantShopping(): void;
}

type GetOrderInfoForPinResponse =
  | { order_type: "not_found" }
  | { order_type: "dashboard_order"; order_key: string }
  | { order_type: "online_pss_reservation" };

function newUrl(urlString: string, queryParams: { [key: string]: string }) {
  const url = new URL(urlString);
  Object.keys(queryParams).forEach(key => {
    return url.searchParams.append(key, queryParams[key]);
  });

  return url;
}

export function PssAbholautomatEntryScreen(props: Props) {
  const [pinInput, setPinInput] = useState<HTMLInputElement>();
  const clientSize = useClientSize();
  const pharmacy = useContext(PharmacyCtx.context);

  const [isFindOrderDialogOpen, setIsFindOrderDialogOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [pin, setPin] = useState("");

  useEffect(() => {
    if (!pinInput || !isFindOrderDialogOpen) {
      return;
    }

    const tid = setTimeout(() => pinInput.focus(), 100);
    return () => clearTimeout(tid);
  }, [isFindOrderDialogOpen, pinInput]);

  if (!pharmacy.pharmacy.value) {
    return null;
  }

  return (
    <div
      css={css`
        background-image: url("${bgPic}");
        background-size: cover;
      `}
      style={{
        minHeight: clientSize.height,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppHelmetI18n
        pageTitleMessage={messages.PssAbholautomatEntryScreenTitle}
      />
      <NavBar hideBtns={true} />

      <Dialog open={isFindOrderDialogOpen}>
        <DialogTitle>
          <Trans>PIN-Code eingeben</Trans>
        </DialogTitle>
        <DialogContent style={{ width: 400 }}>
          <Form
            onSubmit={async () => {
              setIsSearching(true);

              const url = newUrl(
                `${APOLY_API_BASE_URL}/get_order_info_for_pin`,
                { pin },
              );
              const response = await fetch(url.toString());

              const res = (await response.json()) as GetOrderInfoForPinResponse;

              switch (res.order_type) {
                case "not_found":
                  setNotFound(true);
                  break;
                case "dashboard_order":
                  props.onFoundReservation(res.order_key);
                  break;
              }

              setIsSearching(false);
            }}
            css={appGrid}
          >
            <Typography>
              <Trans>Geben Sie bitte Ihren 6-stelligen PIN-Code ein.</Trans>
            </Typography>
            <TextField
              inputRef={ref => setPinInput(ref)}
              disabled={isSearching}
              error={notFound}
              helperText={
                notFound
                  ? "Für die eingegebene PIN konnten wir leider keine Reservierung finden, bitte überprüfen Sie Ihre Eingabe"
                  : undefined
              }
              variant="outlined"
              placeholder="123456"
              required={true}
              inputProps={{
                maxLength: 6,
                minLength: 6,
                inputMode: "numeric",
              }}
              onChange={e => {
                setNotFound(false);
                setPin(e.target.value);
              }}
              value={pin}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isSearching && <CircularProgress />}
                    {!isSearching && pin && (
                      <IconButton onClick={() => setPin("")}>
                        <FontAwesomeIcon
                          icon={faTimes}
                          style={{ width: 16, height: 16 }}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <div style={{ display: "flex" }}>
              <Button onClick={() => setIsFindOrderDialogOpen(false)}>
                <Trans>abbrechen</Trans>
              </Button>
              <CtaButton
                disabled={isSearching}
                style={{ marginLeft: "auto" }}
                type="submit"
                variant="contained"
                color="secondary"
              >
                <Trans>Reservierung finden</Trans>
              </CtaButton>
            </div>
          </Form>
        </DialogContent>
      </Dialog>

      <div
        css={css`
          flex: 1;
          display: grid;
          grid-gap: 40px;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: min-content;
          grid-template-areas: "heading heading" "to_pin to_shop" "qr qr";
          justify-items: center;
          justify-content: space-evenly;
          align-content: space-evenly;
        `}
      >
        <Typography
          align="center"
          css={css`
            grid-area: heading;
            max-width: 600px;
            text-shadow: hsl(190, 22%, 80%) 1px 1px 10px;
            color: hsl(210, 22%, 50%);
          `}
          variant="h2"
        >
          <Trans>Willkommen bei Ihrer {pharmacy.pharmacy.value.name}</Trans>
        </Typography>
        <Paper
          elevation={2}
          component={Button as any}
          variant="contained"
          onClick={() => setIsFindOrderDialogOpen(true)}
          css={css`
            box-shadow: ${shadows[8]};
            max-width: 400px;
            width: 100%;
            grid-area: to_pin;
            padding: 40px 24px;
            color: hsl(210, 22%, 65%);
            background-color: hsl(180, 22%, 99%);
            text-transform: none;
          `}
        >
          <span
            css={css`
              display: grid;
              grid-gap: 16px;
              font-size: 16px;
              justify-items: center;
            `}
          >
            <span className="fa-layers fa-10x fa-fw">
              <FontAwesomeIcon icon={faBox} />
              <FontAwesomeIcon icon={faPills} transform="shrink-9 down-3" />
            </span>
            <Typography
              variant="h5"
              style={{ fontWeight: 500 }}
              align="center"
              color="inherit"
            >
              <Trans>Reservierung abholen</Trans>
            </Typography>
          </span>
        </Paper>
        <Paper
          elevation={2}
          component={Button as any}
          variant="contained"
          onClick={props.onWantShopping}
          css={css`
            box-shadow: ${shadows[8]};
            max-width: 400px;
            width: 100%;
            grid-area: to_shop;
            padding: 40px 24px;
            color: hsl(210, 22%, 65%);
            background-color: hsl(180, 22%, 99%);
            text-transform: none;
          `}
        >
          <span
            css={css`
              display: grid;
              grid-gap: 16px;
              font-size: 16px;
              justify-items: center;
            `}
          >
            <FontAwesomeIcon icon={faShoppingCart} size="10x" />
            <Typography
              style={{ fontWeight: 500 }}
              color="inherit"
              variant="h5"
            >
              <Trans>Produkte kaufen</Trans>
            </Typography>
          </span>
        </Paper>
        <Paper
          elevation={2}
          css={css`
            padding: 40px 24px;
            grid-area: qr;
            max-width: 360px;
            display: grid;
            grid-gap: 16px;
            justify-items: center;
          `}
        >
          <QRCode value={ABHOL_SECRET} size={128} />
          <Typography align="center">
            <Trans>
              Scannen Sie den QR-Code mit der Abholbestätigung aus Ihrer E-Mail
            </Trans>
          </Typography>
        </Paper>
      </div>

      <Footer />
    </div>
  );
}
