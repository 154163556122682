import { LinguiMessage } from "@apoly-42/apoly-components";
import { ButtonWithIcon, GridRow } from "@apoly-42/material-ui";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { selectBasePath } from "../redux/shopPharmacy/reducer";

const messages = {
  noItemsInShoppingCart: {
    id: "noItemsInShoppingCart",
    message: "No items in shopping cart!",
  },
  backToPharmacy: {
    id: "backToPharmacy",
    message: "Go back to your selected Pharmacy",
  },
};

const InnerNoItemsInShoppingCart = ({ basePath }) => (
  <Grid container spacing={2}>
    <GridRow item xs={12}>
      <Typography variant="h5">
        <LinguiMessage message={messages.noItemsInShoppingCart} />
      </Typography>
    </GridRow>
    <GridRow item>
      <ButtonWithIcon
        icon={faArrowLeft}
        color="secondary"
        variant="contained"
        component={Link}
        to={basePath}
      >
        <LinguiMessage message={messages.backToPharmacy} />
      </ButtonWithIcon>
    </GridRow>
  </Grid>
);

const mapStateToProps = state => ({
  basePath: selectBasePath(state),
});

const enhance = compose(connect(mapStateToProps));

const NoItemsInShoppingCart = enhance(InnerNoItemsInShoppingCart);

InnerNoItemsInShoppingCart.propTypes = {
  basePath: PropTypes.string.isRequired,
};

export default NoItemsInShoppingCart;
