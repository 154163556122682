import { equals, cond } from "ramda";
import { POSTAL_COST } from "../../constants";
import { countryIsoCode2IsPartOfEurope } from "../countrys";

export const DELIVERY_COSTS_DE_POSTAL = 4.81;
export const DELIVERY_COSTS_AT = 8.99;
export const DELIVERY_COSTS_EUROPE = 15.99;

function getNationalDeliveryPrice(enhancedPharmacy, transmission) {
  return transmission !== "postal"
    ? enhancedPharmacy.nextCourierTimeSpans.length > 0
      ? enhancedPharmacy.nextCourierTimeSpans[0].price
      : null
    : DELIVERY_COSTS_DE_POSTAL;
}

function getInternationalDeliveryPrice(deliveryCountry) {
  return deliveryCountry === "DE" ? DELIVERY_COSTS_AT : DELIVERY_COSTS_EUROPE;
}

function getDeliveryPrice(enhancedPharmacy, deliveryCountry, transmission) {
  return deliveryCountry === "AT"
    ? getNationalDeliveryPrice(enhancedPharmacy, transmission)
    : getInternationalDeliveryPrice(deliveryCountry);
}

export function deliveryPriceFromEnhancedPharmacy(
  enhancedPharmacy,
  deliveryCountry = "AT",
  transmission
) {
  return countryIsoCode2IsPartOfEurope(deliveryCountry)
    ? getDeliveryPrice(enhancedPharmacy, deliveryCountry, transmission)
    : null;
}

export const getPostalCost = cond([
  [equals("AT"), () => POSTAL_COST],
  [equals("DE"), () => DELIVERY_COSTS_AT],
  [() => true, () => DELIVERY_COSTS_EUROPE],
]);
