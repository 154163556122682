import { Typography } from "@material-ui/core";
import React from "react";

import { LinguiMessage } from "../../../app-util-components/LinguiMessage";
import { appMessages } from "../../../app-utilities/appMessages";

/* eslint-disable react/no-array-index-key */

const messages = {
  orderEveryProductByPhone: {
    id: "orderEveryProductByPhone",
    message:
      "1 You can order every medicine at your local pharmacy, if not regulated by law, by phone, even if it is not listed on this website",
  },
  priceAndAvailabilityForPharmacyOnly: {
    id: "priceAndAvailabilityForPharmacyOnly",
    message:
      "2 Price and availability shown on this page just apply to your selected pharmacy.",
  },
  paymentMethodsDependOnPharmacy: {
    id: "paymentMethodsDependOnPharmacy",
    message: "3 Available payment methods depend on selected pharmacy.",
  },
};

const footNotes = [
  <LinguiMessage message={messages.orderEveryProductByPhone} />,
  <LinguiMessage message={messages.priceAndAvailabilityForPharmacyOnly} />,
  <LinguiMessage message={messages.paymentMethodsDependOnPharmacy} />,
];

export const FootNotes: React.SFC = () => (
  <React.Fragment>
    <Typography paragraph={true} color="textPrimary">
      <LinguiMessage message={appMessages.risksAndSideEffects} />
    </Typography>
    {footNotes.map((footNote, index) => (
      <Typography key={index} variant="caption" color="textSecondary">
        {footNote}
      </Typography>
    ))}
  </React.Fragment>
);
