import { Stepper } from "@material-ui/core";
import * as React from "react";
import { LinguiMessage } from "../../../../app-util-components/LinguiMessage";
import { MuiStepperStep } from "../../../../app-util-components/MuiStepperStep";
import { BillingDataSummary } from "../../../../app-utilities/toStringUtils";
import { commonMessages } from "../../../../deprecated/deprecated-apoly-app/constants/messages/commonMessages";
import { PaymentMethod } from "../../../apolyApi";
import { Address } from "../../../apolyApi";
import { CheckoutPssStep } from "../checkoutTypes";
import { PaymentSummary } from "../PaymentSummary";

interface CheckoutPssStepperProps {
  step: CheckoutPssStep;
  email: string | undefined;
  billingAddress?: Address;
  paymentMethod: PaymentMethod | undefined;
  onEditStepClick: (step: CheckoutPssStep) => void;
  renderStepContent?: (step: CheckoutPssStep) => React.ReactNode;
  className?: string;
}

export class CheckoutPssStepper extends React.PureComponent<
  CheckoutPssStepperProps
> {
  render() {
    const {
      billingAddress,
      email,
      step,
      paymentMethod,
      onEditStepClick,
      renderStepContent,
      className,
    } = this.props;

    return (
      <Stepper nonLinear={true} orientation="vertical" className={className}>
        <MuiStepperStep
          currentStep={step}
          step={CheckoutPssStep.billingData}
          stepLabel={<LinguiMessage message={commonMessages.billingDataStep} />}
          summary={
            email && (
              <BillingDataSummary
                email={email}
                billingAddress={billingAddress}
              />
            )
          }
          onEditStepClick={onEditStepClick}
        >
          {renderStepContent && renderStepContent(CheckoutPssStep.billingData)}
        </MuiStepperStep>
        <MuiStepperStep
          currentStep={step}
          step={CheckoutPssStep.payment}
          stepLabel={<LinguiMessage message={commonMessages.paymentMethod} />}
          summary={
            paymentMethod && <PaymentSummary paymentMethod={paymentMethod} />
          }
          onEditStepClick={onEditStepClick}
        >
          {renderStepContent && renderStepContent(CheckoutPssStep.payment)}
        </MuiStepperStep>
        <MuiStepperStep
          currentStep={step}
          step={CheckoutPssStep.closure}
          stepLabel={<LinguiMessage message={commonMessages.finish} />}
          onEditStepClick={onEditStepClick}
        >
          {renderStepContent && renderStepContent(CheckoutPssStep.closure)}
        </MuiStepperStep>
      </Stepper>
    );
  }
}
