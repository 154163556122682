import { Dispatch, useEffect, useReducer, useState } from "react";
import { onEvent, throttle } from "./utils";

interface ClientSize {
  width: number;
  // the width with `px` as string
  widthPx: string;
  height: number;
  // the height with `px` as string
  heightPx: string;
}

// gets the size of the window minus scrollbars, if visible
function getWindowSize() {
  const { clientWidth, clientHeight } = document.documentElement;

  return {
    width: clientWidth,
    widthPx: `${clientWidth}px`,
    height: clientHeight,
    heightPx: `${clientHeight}px`,
  };
}

// da resize-event gethrottled wird, kann es aufgerufen werden,
// nachdem es eigentlich disposed wurde, weswegen dies geprüft wird
export function onResize(handler: () => void) {
  let aborted = false;
  const disposeEvent = onEvent(
    "resize",
    throttle(() => {
      // wenn innerhalb des throttlens das event disposed wurde,
      // darf es nicht mehr ausgeführt werden
      if (aborted) {
        return;
      }
      handler();
    }, 66),
  );

  return () => {
    aborted = true;
    disposeEvent();
  };
}

export function useClientSize(): ClientSize {
  const [size, setSize] = useState<ClientSize>(() => getWindowSize());

  useEffect(() => {
    return onResize(() => setSize(getWindowSize()));
  }, []);

  return size;
}

export function useArray<T>(defaultArray?: T[]) {
  interface State {
    items?: T[];
    item?: T;
    index: number;
  }

  type Actions =
    | { type: "set_items"; items: T[] }
    | { type: "toggle_next_item" };

  function reducer(state: State, action: Actions): State {
    switch (action.type) {
      case "set_items":
        return { items: action.items, index: 0, item: action.items[0] };
      case "toggle_next_item":
        const { items, index } = state;

        if (!items) {
          return state;
        }

        const nextIndex = index + 1;
        return {
          ...state,
          index: nextIndex,
          item: items[nextIndex % items.length],
        };
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    items: defaultArray,
    index: 0,
  });

  // explicitly define return-type for TS
  return [state, dispatch] as [State, Dispatch<Actions>];
}
