import { Typography } from "@material-ui/core";
import * as React from "react";

import { LinguiMessage } from "../app-util-components/LinguiMessage";
import { Address, Pharmacy } from "../app/apolyApi";

export interface AddressFromInput {
  name: string;
  street: string;
  zip: string;
  city: string;
  notes?: string;
}

export const addressToString = ({ name, city, street, zip, notes }: Partial<AddressFromInput>) => {
  return [name, street, `${zip} ${city}`.trim(), notes].filter(Boolean).join(", ");
};

const messages = {
  billingAddressSummary: {
    id: "billingAddressSummary",
    message: "Rechnungsadresse: {addressSummary}",
  },
  deliveryAddressSummary: {
    id: "deliveryAddressSummary",
    message: "Lieferungsaddresse:",
  },
  pickUpAddressSummary: {
    id: "pickUpAddressSummary",
    message: "Abholungsaddresse:",
  },
  pickUpPssAddressSummary: {
    id: "pickUpPssAddressSummary",
    message: "Im Abholautomaten reservieren bei Apotheke:",
  },
};

export const BillingDataSummary: React.SFC<{
  billingAddress?: Address;
  telephone: string;
  email: string;
  birthday: string;
}> = (props) => (
  <Typography component="p" variant="caption" color="textSecondary">
    <span>{props.email}</span>
    <br />
    <span>{props.telephone}</span>
    {props.billingAddress && (
      <span>
        <br />
        <LinguiMessage
          message={messages.billingAddressSummary}
          values={{ addressSummary: addressToString(props.billingAddress) }}
        />
      </span>
    )}
    <br />
    <span>Geb. am {props.birthday}</span>
  </Typography>
);

export const PickUpSummary: React.SFC<{
  pharmacy: Pharmacy;
  isPss?: boolean;
}> = (props) =>
  props.pharmacy && (
    <React.Fragment>
      <Typography component="p" variant="caption" color="textSecondary">
        <LinguiMessage message={props.isPss ? messages.pickUpPssAddressSummary : messages.pickUpAddressSummary} />
      </Typography>
      <Typography component="p" variant="caption" color="textSecondary">
        {props.pharmacy.name}, {props.pharmacy.phone}
      </Typography>
      <Typography component="p" variant="caption" color="textSecondary">
        {props.pharmacy.address.street}
      </Typography>
      <Typography component="p" variant="caption" color="textSecondary">
        {props.pharmacy.address.zip} {props.pharmacy.address.city}
      </Typography>
    </React.Fragment>
  );

export const DeliverySummary: React.SFC<{
  deliveryAddress: Address;
}> = (props) => (
  <React.Fragment>
    <Typography component="p" variant="caption" color="textSecondary">
      <LinguiMessage message={messages.deliveryAddressSummary} />
    </Typography>
    <Typography component="p" variant="caption" color="textSecondary">
      {props.deliveryAddress.name}
    </Typography>
    <Typography component="p" variant="caption" color="textSecondary">
      {props.deliveryAddress.street}
    </Typography>
    <Typography component="p" variant="caption" color="textSecondary">
      {props.deliveryAddress.zip} {props.deliveryAddress.city}
    </Typography>
    {props.deliveryAddress.notes && (
      <Typography component="p" variant="caption" color="textSecondary">
        {props.deliveryAddress.notes}
      </Typography>
    )}
  </React.Fragment>
);
