import { css } from "@emotion/core";

type Width = number | string;

export const mediaMinWidth = (min: Width) => `@media (min-width: ${min})`;
export const mediaMaxWidth = (max: Width) => `@media (max-width: ${max})`;
export const mediaBetween = (minWidth: Width, maxWidth: Width) =>
  `@media (min-width: ${minWidth}) and (max-width: ${maxWidth})`;

export const xsDown = mediaMaxWidth("600px"); // tablet
export const smUp = mediaMinWidth("600px"); // tablet
export const mdUp = mediaMinWidth("960px"); // small laptops / tablet landscape
export const lgUp = mediaMinWidth("1280px"); // greater laptops / desktop-PC

export const displayBlock = css`
  display: block;
`;

export const contentContainer = css`
  width: 100%;
  margin: 0 auto;
  padding: 8px;
  ${lgUp} {
    width: 80%;
  }
`;

// 58 px footerbutton (34px dense-button-height + 16px margin-top + 8px margin-bottom)
const toAppHeight = (navbarHeight: number) => navbarHeight + 58;
export const contentMinHeight = css`
  min-height: calc(100vh - ${toAppHeight(56)}px);

  @media (min-width: 0px) and (orientation: landscape) {
    min-height: calc(100vh - ${toAppHeight(48)}px);
  }

  ${smUp} {
    min-height: calc(100vh - ${toAppHeight(64)}px);
  }
`;

export const appGrid = css`
  display: grid;
  grid-gap: 16px;
`;

export const flex = css`
  display: flex;
`;
export const flexRight = css`
  margin-left: auto;
`;

export const gridArea = (areaName: string) => css`
  grid-area: ${areaName};
`;

export const flexAlignCenter = css`
  ${flex};
  align-items: center;
`;
export const flexAlignStart = css`
  ${flex};
  align-items: flex-start;
`;

export const verticalCenteredInFlex = css`
  margin: auto 8px;
`;

export const blackWhiteFilter = css`
  filter: grayscale(100%);
  &:hover {
    filter: grayscale(0%);
  },
`;

export const buttonXs = css`
  @media (max-width: 600px) {
    min-width: 0;
  }
`;

export const maxWidthHeight = css`
  max-width: 100%;
  max-height: 100%;
`;
