import { loadCSS } from "fg-loadcss";
import moment from "moment-timezone";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "./app/App";
import timeZoneData from "./bootstrap/berlinTimeZoneData.json";
import "./bootstrap/polyfills";
import {
  DeprecatedAppBootstrap,
  initDeprecatedApp,
} from "./deprecated/deprecated-apoly-app-bootstrap/deprecated-app-bootstrap";
import { dataLayer } from "./googleDataLayerPush";
import { registerServiceWorker } from "./registerServiceWorker";
import "./apoly-tailwind.css";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

initDeprecatedApp();

window.dataLayer = dataLayer;

// the berlinTimeZoneData is compiled via the createTimeZoneData.js-node-script for 2015-2020,
moment.tz.load(timeZoneData);

ReactDOM.render(
  <DeprecatedAppBootstrap>
    <App />
  </DeprecatedAppBootstrap>,
  document.getElementById("root") as HTMLElement,
);

loadCSS(
  new URL("https://fonts.googleapis.com/css?family=Lora|Roboto:300,400,500"),
);

registerServiceWorker();
