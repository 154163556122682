import React from "react";
import { rfcUrl, UrlConsumer } from "../url";
import { RfcUrl, UrlType } from "../url/rfcUrl";

interface SyncRouteProps {
  url: RfcUrl | undefined;
  setUrl(url: RfcUrl): void;
}

// A simple component that shows the pathname of the current location
class SyncUrlWithBrowser extends React.PureComponent<SyncRouteProps> {
  componentDidMount() {
    this.updateRfcUrlFromWindowLocation();
    window.addEventListener("popstate", this.handlePopState);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handlePopState);
  }

  handlePopState = () => this.updateRfcUrlFromWindowLocation();

  updateRfcUrlFromWindowLocation() {
    const url = this.props.url;
    if (url && window.location.href === url.toString()) {
      return;
    }

    this.props.setUrl(rfcUrl(window.location.href));
  }

  componentDidUpdate(prevProps: SyncRouteProps) {
    if (this.props.url && this.props.url !== prevProps.url) {
      const val = this.props.url.toString();

      if (val !== window.location.href) {
        if (this.props.url.urlType === UrlType.major) {
          window.history.pushState(undefined, undefined, val);
        } else {
          window.history.replaceState(undefined, undefined, val);
        }
      }
    }
  }

  render() {
    return null;
  }
}

export const SyncUrlContextWithBrowser: React.SFC = () => (
  <UrlConsumer>
    {ctx => <SyncUrlWithBrowser setUrl={ctx.setUrl} url={ctx.url} />}
  </UrlConsumer>
);
