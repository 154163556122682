import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Downshift, { DownshiftProps } from "downshift";
import { css } from "@emotion/core";
import React, { Fragment, HTMLAttributes, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DbArticle, DbSymptom } from "../../app-utilities/app-types";
import { useAsyncEffect } from "../../app-utilities/hook-utils";
import productFallbackImg400Px from "../../deprecated/deprecated-apoly-app/components/imageComponents/productImages/apoly-product-image-400px.png";
import { getSymptomImage } from "../../deprecated/deprecated-apoly-app/constants/assets/symptomImages/getSymptomImage";
import { selectBasePath } from "../../deprecated/deprecated-apoly-app/redux/shopPharmacy/reducer";
import { singleProductSetPharmacyPath } from "../../deprecated/deprecated-apoly-app/routes/paths";
import {
  searchArticles,
  searchSymptomsQuickSearch,
} from "../../deprecated/deprecated-apoly-app/utilities/apis/apolyApi/apolyApi";
import { denormalizeArticleResponse } from "../../deprecated/deprecated-apoly-app/utilities/apis/apolyApi/schemas/searchArticlesResponseSchema";
import { PharmacyCtx } from "../PharmacyContext";
import { isPssChannel } from "../routes-helper";

function wait(ms: number) {
  return new Promise(r => setTimeout(r, ms));
}

function parallel(promises: (() => Promise<any>)[]) {
  return Promise.all(promises.map(p => p()));
}

const menuItemOverrides = css`
  // überschreibe default 24px
  height: auto;
  // überschreibe jeweils 11px
  padding-top: 8px;
  padding-bottom: 8px;
`;

const menuRowGrid = css`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: minmax(0, 80px) 1fr;
  height: 48px;
  align-items: center;
`;

function TypedDownshift<T>(props: DownshiftProps<T>) {
  return <Downshift {...props} />;
}

type Item =
  | ({ type: "article" } & DbArticle)
  | ({ type: "symptom" } & DbSymptom);

interface Props extends HTMLAttributes<HTMLDivElement> {
  onSelectSymptom(s: DbSymptom): void;
  onSelectArticle(a: DbArticle): void;
  inputValue: string;
  onInputValueChange(v: string): void;
  autoFocus?: boolean;
}

export function MedicineSearch({
  onSelectSymptom,
  onSelectArticle,
  inputValue,
  onInputValueChange,
  autoFocus = false,
  ...props
}: Props) {
  const [foundArticles, setFoundArticles] = useState<DbArticle[]>([]);
  const [foundSymptoms, setFoundSymptoms] = useState<DbSymptom[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const pharmacyCtx = useContext(PharmacyCtx.context);
  const basePath = useSelector<string>(selectBasePath);

  useAsyncEffect(
    async abortSignal => {
      // warte, dass nicht für jeden input ein request gestartet muss
      await wait(300);
      // wenn es in den letzten 300 Sekunden einen anderen input gibt, wird abgebrochen
      if (abortSignal.aborted) return;

      if (
        !pharmacyCtx.pharmacy.value ||
        !pharmacyCtx.pharmacyChannel.value ||
        inputValue.length < 2
      ) {
        return;
      }

      setIsLoading(true);

      const pId = pharmacyCtx.pharmacy.value.pharmacyId;
      const pChannel = pharmacyCtx.pharmacyChannel.value;

      async function loadMedicines() {
        const response = await searchArticles(pId, {
          medicineName: inputValue,
          medicineLimit: 3,
          onlyPssAvailable: isPssChannel(pChannel),
        } as any);

        if (abortSignal.aborted) return;

        const articles = denormalizeArticleResponse(
          response.entities,
          response.result.articles,
        ).articles as DbArticle[];

        const medicineIds = Object.keys(response.entities.medicines);
        // Pro Medikament nur ein Artikel nutzen
        const distinctMedicineArticles = medicineIds
          .map(medicineId => {
            function articleHasMedicineId(a: DbArticle) {
              return (
                a.package.medicine.medicineId.toString() ===
                medicineId.toString()
              );
            }

            return (
              articles
                // nur artikel für das Medikament
                .filter(articleHasMedicineId)
                // sortiere nach Preis (günstigste zuerst, teuerster zuletzt)
                .sort((a, b) => Number(a.price) - Number(b.price))
                // nimm das letzte, was dem teuersten entspricht
                .pop()
            );
          })
          .filter(Boolean) as DbArticle[];

        setFoundArticles(distinctMedicineArticles);
      }

      async function loadSymptoms() {
        const symptoms = (await searchSymptomsQuickSearch(inputValue, {
          limit: 3,
        } as any)) as DbSymptom[];

        if (abortSignal.aborted) return;

        setFoundSymptoms(symptoms);
      }

      await parallel([loadMedicines, loadSymptoms]);

      setIsLoading(false);
    },
    [inputValue],
  );

  return (
    <TypedDownshift<Item>
      stateReducer={(state, changes) => {
        switch (changes.type) {
          // mouseUp entspricht einem klick außerhalb, was dazu führt, dass der input-value resetted wird:
          // https://github.com/downshift-js/downshift/issues/640
          case Downshift.stateChangeTypes.mouseUp:
            return {
              ...changes,
              inputValue: changes.inputValue || state.inputValue,
            };
          default:
            return changes;
        }
      }}
      inputValue={inputValue}
      onInputValueChange={onInputValueChange}
      onSelect={i => {
        if (!i) return;

        if (i.type === "symptom") {
          onSelectSymptom(i);
          return;
        }

        onSelectArticle(i);
      }}
      itemToString={i => {
        if (!i) {
          return "";
        }
        if (i.type === "article") {
          return i.package.medicine.name;
        }
        return i.name;
      }}
    >
      {d => {
        return (
          <div
            {...props}
            {...d.getRootProps()}
            css={css`
              position: relative;
            `}
          >
            <TextField
              fullWidth={true}
              variant="outlined"
              InputProps={{
                ...(d.getInputProps({
                  onKeyDown: (event: KeyboardEvent) => {
                    if (event.key === "Enter") {
                      // da bei enter etwas passieren soll, schließen wir das menu
                      d.closeMenu();
                    }
                  },
                }) as any),
                startAdornment: (
                  <InputAdornment position="start" style={{ fontSize: 16 }}>
                    <FontAwesomeIcon
                      style={{ color: "hsla(180, 10%, 70%)" }}
                      icon={faSearch}
                    />
                  </InputAdornment>
                ),
                endAdornment: isLoading ? (
                  <InputAdornment position="end">
                    <CircularProgress />
                  </InputAdornment>
                ) : (
                  undefined
                ),
              }}
              placeholder="Produktname, PZN oder Symptom eingeben..."
              type="search"
              required={true}
              autoFocus={autoFocus}
            />
            <Paper
              elevation={2}
              {...d.getMenuProps()}
              css={css`
                width: 100%;
                position: absolute;
                z-index: 1;
              `}
            >
              {d.isOpen && (
                <Fragment>
                  {foundArticles.length > 0 && (
                    <Typography
                      variant="h6"
                      style={{ marginTop: 16, marginBottom: 8, marginLeft: 16 }}
                    >
                      <Trans>Produkte</Trans>
                    </Typography>
                  )}

                  {foundArticles.map((a, index) => {
                    return (
                      <MenuItem
                        {...d.getItemProps({ item: { type: "article", ...a } })}
                        key={a.articleId}
                        selected={d.highlightedIndex === index}
                        css={menuItemOverrides}
                        component={Link}
                        to={singleProductSetPharmacyPath(
                          basePath,
                          a.package.medicine.urlCode,
                          a.package.urlCode,
                        )}
                      >
                        <div css={menuRowGrid}>
                          <img
                            css={css`
                              justify-self: center;
                              max-width: 100%;
                              max-height: 100%;
                            `}
                            alt={a.package.medicine.name}
                            src={a.package.imgUrl || productFallbackImg400Px}
                          />
                          <div>{a.package.medicine.name} </div>
                        </div>
                      </MenuItem>
                    );
                  })}

                  {foundSymptoms.length > 0 && (
                    <Typography
                      variant="h6"
                      style={{ marginTop: 16, marginBottom: 8, marginLeft: 16 }}
                    >
                      <Trans>Symptome</Trans>
                    </Typography>
                  )}

                  {foundSymptoms.map((s, mapIndex) => {
                    const index = mapIndex + foundArticles.length;

                    return (
                      <MenuItem
                        {...d.getItemProps({ item: { type: "symptom", ...s } })}
                        key={s.symptomId}
                        selected={d.highlightedIndex === index}
                        component="div"
                        css={menuItemOverrides}
                      >
                        <div css={menuRowGrid}>
                          <img
                            css={css`
                              justify-self: center;
                              max-width: 100%;
                              max-height: 100%;
                            `}
                            alt={s.name}
                            src={getSymptomImage(s.pictureUrl)}
                          />
                          <div>{s.name} </div>
                        </div>
                      </MenuItem>
                    );
                  })}
                </Fragment>
              )}
            </Paper>
          </div>
        );
      }}
    </TypedDownshift>
  );
}
