import { parseIntOrDefault } from "@apoly-42/apoly-utils";
import { curry } from "ramda";
import { secondsOfDayFromTimeStr } from "../momentUtilities";

export const timeSpanIsOnWeekday = checkWeekday => courierTimeSpan =>
  parseIntOrDefault(courierTimeSpan.weekday) === checkWeekday;

export const orderUntilIsLaterThan = timeInSeconds => courierTimeSpan =>
  timeInSeconds < secondsOfDayFromTimeStr(courierTimeSpan.orderUntil);

export const openingToIsLaterThan = timeInSeconds => openingTime =>
  timeInSeconds < secondsOfDayFromTimeStr(openingTime.openingTo);

export const filterTimesForWeekday = (checkWeekday, times) =>
  times.filter(({ weekday }) => parseIntOrDefault(weekday) === checkWeekday);

export const isInHoliday = curry(
  (holidays, timeMoment) =>
    holidays.indexOf(
      timeMoment
        .clone()
        .locale("en")
        .format("YYYY-MM-DD"),
    ) >= 0,
);

export const TIME_SPAN_TYPE_ABSOLUTE = "absolute";
export const TIME_SPAN_TYPE_BETWEEN = "between";

export const isAbsoluteTimeSpan = timeSpan =>
  timeSpan.timeSpanType === TIME_SPAN_TYPE_ABSOLUTE;
export const isBetweenTimeSpan = timeSpan =>
  timeSpan.timeSpanType === TIME_SPAN_TYPE_BETWEEN;
