import { LinguiMessage } from "@apoly-42/apoly-components";
import {
  GridContainer,
  GridRow,
  Headline,
  HeadlineWithDivider,
  Loading,
} from "@apoly-42/material-ui";
import { Trans } from "@lingui/macro";
import { Typography, withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { HistoryBackButton } from "../../../../../app-util-components/HistoryBackButton";
import { Load } from "../../../../../app-util-components/Load";
import { transEq } from "../../../../../app/utils";
import { commonMessages } from "../../../constants/messages/commonMessages";
import { BRAND_NAME } from "../../../environment";
import {
  selectBasePath,
  selectPharmacy,
} from "../../../redux/shopPharmacy/reducer";
import {
  contactPath,
  contactPathSetPharmacy,
  imprintPath,
} from "../../../routes/paths";
import { getPharmacyLegalData } from "../../../utilities/apis/apolyApi/apolyApi";
import HelmetComponentWithMessage from "../../helmet/HelmetComponentWithMessage";
import Footer from "../../layout/footer/Footer";
import NavBar from "../../layout/navBar/navbar/NavBar";

const styles = {
  spacing: {
    marginTop: 16,
    marginBottom: 8,
  },
};

export const imprintMessages = {
  imprintAccordingTo: {
    id: "imprintAccordingTo",
    message: "Impressum gemäß § 5 TMG (Telemediengesetz)",
  },
  providerInformation: {
    id: "providerInformation",
    message: "Anbieterinformationen/ Imprint Apoly GmbH",
  },
  providerInformationFor: {
    id: "providerInformationFor",
    message: "Anbieterinformationen/ Imprint {legalName}",
  },
  representedByManagingDirector: {
    id: "representedByManagingDirector",
    message: "Represented by managing director",
  },
  contactPhone: {
    id: "contactPhone",
    message: "Telefon: {phone}",
  },
  contactMail: {
    id: "contactMail",
    message: "E-Mail: {contactMail} de oder über unser {contactForm}.",
  },
  contactWeb: {
    id: "contactWeb",
    message: "Web: {homePage}",
  },
  registerCourt: {
    id: "registerCourt",
    message: "Register court: {city}",
  },
  districtCourt: {
    id: "districtCourt",
    message: "District court: {address}",
  },
  commercialRegisterNumber: {
    id: "commercialRegisterNumber",
    message: "Commercial register number: {number}",
  },
  uStIdNr: {
    id: "uStIdNr",
    message: "USt-IdNr: {number}",
  },
  onlineDisputeSettlement: {
    id: "onlineDisputeSettlement",
    message: "Online dispute settlement",
  },
  europeanCommissionOnlineDisputeSettlementPlatform: {
    id: "europeanCommissionOnlineDisputeSettlementPlatform",
    message:
      "Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS-Plattform) bereit. Sie ist erreichbar unter: {url}.",
  },
  socialMediaChannels: {
    id: "socialMediaChannels",
    message: "Socialmedia Kanäle",
  },
  socialMediaImprint: {
    id: "socialMediaImprint",
    message:
      "Wir weisen hiermit darauf hin, dass die von Apoly genutzten Socialmedia Kanäle bei den Dienstanbietern {fb}, {twitter}, {instagram} and {gPlus} ebenfalls gemäß des Impressums unter {imprint} vertrieben werden.",
  },
  iconGraphics: {
    id: "iconGraphics",
    message: "Icon-Grafiken",
  },
  symptomIconsMadeBy: {
    id: "symptomIconsMadeBy",
    message: " Symptom-Icons von {creator}.",
  },
  owner: {
    id: "owner",
    message: "owner",
  },
  email: {
    id: "email",
    message: "Email",
  },
  phone: {
    id: "phone",
    message: "Phone:",
  },
  fax: {
    id: "fax",
    message: "fax",
  },
  titleImprint: {
    id: "titleImprint",
    message: "Impressum | Apoly",
  },
  titleImprintWithPharmacy: {
    id: "titleImprintWithPharmacy",
    message: "Impressum von {pharmacyName}",
  },
  handelsregistereintrag: {
    id: "handelsregistereintrag",
    message: "Handelsregistereintrag: {handelsregistereintrag}",
  },
  umsatzsteuernummer: {
    id: "umsatzsteuernummer",
    message: "Umsatzsteuer-Identifikationsnummer: {umsatzsteuernummer}",
  },
  berufshaftpflicht: {
    id: "berufshaftpflicht",
    message: "Berufshaftpflichtversicherung: {berufshaftpflicht}",
  },
  titleAufsichtsbehoerde: {
    id: "titleAufsichtsbehoerde",
    message: "Zuständige Aufsichstbehörde",
  },
  titleApothekerkammer: {
    id: "titleApothekerkammer",
    message: "Zuständige Apothekerkammer",
  },
  web: {
    id: "web",
    message: "web: ",
  },
  vicePresident: {
    id: "vicePresident",
    message: "Vizepräsident:",
  },
  president: {
    id: "president",
    message: "Präsident:",
  },
  chamberLawLink: {
    id: "chamberLawLink",
    message: "Link zur Berufsordnung: ",
  },
};

const linebreak_n = <br />;

// TODO aktuell statisch für veritas-apotheke gesetzt wegen wirecard-nerv und dass veritas-apotheke eine filliale der anderen Apotheke ist => müsste man dann in db + dashboard vernünftig anpassen
const InnerImprintPage = ({ classes, basePath, pharmacy }) => (
  <Fragment>
    <NavBar />
    <HelmetComponentWithMessage
      pageTitle={
        pharmacy
          ? imprintMessages.titleImprintWithPharmacy
          : imprintMessages.titleImprint
      }
      path={imprintPath()}
      values={{ pharmacyName: pharmacy && pharmacy.name, brand: BRAND_NAME }}
      noIndex
    />
    <GridContainer withNoScrollPaddingWorkaround justify="center">
      <GridRow item xs={12} lg={10}>
        <HeadlineWithDivider responsive headlineType="h1">
          <LinguiMessage message={imprintMessages.imprintAccordingTo} />
        </HeadlineWithDivider>
      </GridRow>
      <Grid item xs={12} lg={10}>
        <HistoryBackButton />
      </Grid>
      {pharmacy && (
        <GridRow item xs={12} lg={10}>
          <Headline align="left" className={classes.spacing} headlineType="h2">
            <LinguiMessage
              message={imprintMessages.providerInformationFor}
              values={{ legalName: pharmacy.name }}
            />
          </Headline>
          <Typography paragraph={true}>
            <Trans>Die {pharmacy.name} mit der Ortsadresse</Trans>
          </Typography>
          <Typography paragraph={true}>
            Am Weinberg 5,
            <br />
            4845 Regau
            <br />
            Österreich
          </Typography>
          <Typography paragraph={true}>
            <Trans>ist eine Filiale der</Trans>
          </Typography>
          <Typography paragraph={true}>
            Apotheke Puchheim
            <br />
            Mag. pharm. Monika Kaniak-El-Masri
            <br />
            Apothekerin/Konzessionärin
            <br />
            Puchheimerstraße 33
            <br />
            4800 Attnang-Puchheim
            <br />
            <LinguiMessage message={imprintMessages.phone} />: 07674/66991
            <br />
            <LinguiMessage message={imprintMessages.fax} />: 07674/66991-33
            <br />
            <LinguiMessage message={imprintMessages.email} />:
            apotheke_puchheim@utanet.at
            <br />
            UID: ATU65424169 – FN 341926h / LG Wels
          </Typography>

          <Load
            loadOnMount
            fn={() => getPharmacyLegalData(pharmacy.pharmacyId)}
          >
            {({ isLoading, response }) => (
              <Fragment>
                {isLoading && <Loading />}

                {response && (
                  <Fragment>
                    {response.handelsregistereintrag && (
                      <Typography>
                        <LinguiMessage
                          message={imprintMessages.handelsregistereintrag}
                          values={{
                            handelsregistereintrag:
                              response.handelsregistereintrag,
                          }}
                        />
                      </Typography>
                    )}
                    {response.umsatzsteuernummer && (
                      <Typography>
                        <LinguiMessage
                          message={imprintMessages.umsatzsteuernummer}
                          values={{
                            umsatzsteuernummer: response.umsatzsteuernummer,
                          }}
                        />
                      </Typography>
                    )}
                    {response.berufshaftpflicht && (
                      <Typography>
                        <LinguiMessage
                          message={imprintMessages.berufshaftpflicht}
                          values={{
                            berufshaftpflicht: response.berufshaftpflicht,
                          }}
                        />
                      </Typography>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </Load>

          <Typography variant="h5" className={classes.spacing}>
            <LinguiMessage message={imprintMessages.titleAufsichtsbehoerde} />
          </Typography>
          <Typography>
            <Trans>Bundesministerium für Gesundheit</Trans>
          </Typography>
          <Typography>
            <Trans>
              Das Unternehmen ist eine öffentliche Apotheke nach
              österreichischem Apothekengesetz.
              {linebreak_n}
              Ihre Leiterin ist Mitglied der österreichischen Apothekerkammer.
              Berufsrechtliche Vorschrift: Apothekengesetz, Apotheken
              Betriebsordnung, §§ 50 bis 71 Arzneimittelgesetz und Berufssitte
              des Apothekerstandes.
              {linebreak_n}
              Sämtliche hier genannten Rechtsvorschriften können bei der
              österreichischen Apothekerkammer unter{" "}
              {transEq(
                <a href="https://www.apotheker.or.at">www.apotheker.or.at</a>,
              )}{" "}
              eingesehen oder dort angefordert werden.
            </Trans>
          </Typography>

          <Typography align="left" className={classes.spacing} variant="h5">
            <LinguiMessage message={commonMessages.contact} /> {pharmacy.name}
          </Typography>
          <Typography>
            <LinguiMessage message={imprintMessages.email} />: {pharmacy.email}
            <br />
            <LinguiMessage message={imprintMessages.phone} />: {pharmacy.phone}
            <br />
            <LinguiMessage message={imprintMessages.fax} />: {pharmacy.fax}
            <br />
          </Typography>
        </GridRow>
      )}
      <GridRow item xs={12} lg={10}>
        <Headline align="left" className={classes.spacing} headlineType="h2">
          <LinguiMessage message={imprintMessages.providerInformation} />
        </Headline>
        <Typography>
          {BRAND_NAME}
          <br />
          Bernhard-Göring-Straße 162
          <br />
          04277 Leipzig
          <br />
          <LinguiMessage
            message={imprintMessages.representedByManagingDirector}
          />
          <br />
          Luca Christel
          <br />
        </Typography>

        <Headline align="left" className={classes.spacing} headlineType="h2">
          <LinguiMessage message={commonMessages.contact} />:
        </Headline>
        <Typography>
          <LinguiMessage
            message={imprintMessages.contactMail}
            values={{
              contactMail: "service [at] apoly [Punkt]",
              contactForm: (
                <Link
                  to={
                    basePath ? contactPathSetPharmacy(basePath) : contactPath()
                  }
                >
                  <LinguiMessage message={commonMessages.contactForm} />
                </Link>
              ),
            }}
          />
          <br />
          <LinguiMessage
            message={imprintMessages.contactPhone}
            values={{ phone: "+49 (0) 341 946 756 73" }}
          />
          <br />
          <LinguiMessage
            message={imprintMessages.contactWeb}
            values={{
              homePage: <a href="https://apoly.de">https://www.apoly.de</a>,
            }}
          />
          <br />
        </Typography>

        <Typography>
          <LinguiMessage
            message={imprintMessages.registerCourt}
            values={{ city: "Leipzig" }}
          />
          <br />
          <LinguiMessage
            message={imprintMessages.districtCourt}
            values={{
              address: "Leipzig, Bernhard-Göring-Straße 64, 04275 Leipzig",
            }}
          />
          <br />
          <LinguiMessage
            message={imprintMessages.commercialRegisterNumber}
            values={{ number: "HRB 32102" }}
          />
          <br />
          <LinguiMessage
            message={imprintMessages.uStIdNr}
            values={{ number: "DE304344620" }}
          />
          <br />
        </Typography>

        <Headline align="left" className={classes.spacing} headlineType="h2">
          <LinguiMessage message={imprintMessages.onlineDisputeSettlement} />
        </Headline>
        <Typography>
          <LinguiMessage
            message={
              imprintMessages.europeanCommissionOnlineDisputeSettlementPlatform
            }
            values={{
              url: (
                <a
                  href="http://ec.europa.eu/consumers/odr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://ec.europa.eu/consumers/odr/
                </a>
              ),
            }}
          />
        </Typography>

        <Headline align="left" className={classes.spacing} headlineType="h2">
          <LinguiMessage message={imprintMessages.socialMediaChannels} />
        </Headline>
        <Typography>
          <LinguiMessage
            message={imprintMessages.socialMediaImprint}
            values={{
              fb: <a href="https://www.facebook.com/apoly.de/">Facebook</a>,
              twitter: <a href="https://twitter.com/ApolyDE">Twitter</a>,
              instagram: (
                <a href="https://www.instagram.com/Apoly.de/">Instagram</a>
              ),
              gPlus: <a href="https://plus.google.com/+ApolyDe">Google+</a>,
              imprint: (
                <a href="https://apoly.de/impressum.htm">
                  https://www.apoly.de
                </a>
              ),
            }}
          />
        </Typography>

        <Headline align="left" className={classes.spacing} headlineType="h2">
          <LinguiMessage message={imprintMessages.iconGraphics} />
        </Headline>
        <Typography>
          <LinguiMessage
            message={imprintMessages.symptomIconsMadeBy}
            values={{
              creator: (
                <a href="http://www.gluecklicher-montag.de/">
                  Schwarwel / Glücklicher Montag
                </a>
              ),
            }}
          />
        </Typography>
      </GridRow>
    </GridContainer>
    <Footer />
  </Fragment>
);

const mapStateToProps = state => ({
  basePath: selectBasePath(state),
  pharmacy: selectPharmacy(state),
});

const enhance = compose(withStyles(styles), connect(mapStateToProps));

const ImprintPage = enhance(InnerImprintPage);

InnerImprintPage.propTypes = {
  classes: PropTypes.object.isRequired,
  basePath: PropTypes.string,
  pharmacy: PropTypes.object,
};

InnerImprintPage.defaultProps = {
  basePath: null,
  pharmacy: null,
};

export default ImprintPage;
