import { Loading } from "@apoly-42/material-ui";
import { Theme, withTheme } from "@material-ui/core";
import React, { Component } from "react";
import { DbArticle } from "../../../app-utilities/app-types";
import LoadArticlesContainer from "../../../deprecated/deprecated-apoly-app/containers/product/LoadArticlesContainer";
import { AppConsumer } from "../../AppContext";
import { PharmacyValuesConsumer } from "../../PharmacyContext";
import { DeliveryType } from "./OtcOrderTypes";
import { SelectDeliveryTypeView } from "./SelectDeliveryTypeView";

export interface SelectDeliveryTypeProps {
  deliveryType: DeliveryType;
  setDeliveryType: (deliveryType: DeliveryType) => void;
  theme: Theme;
}

interface State {
  isLoading: boolean;
  articles: DbArticle[] | undefined;
}

// todo eigentlich darf pss-abholer nur angezeigt werden wenn apo dazu fähig ist => muss in db geschrieben werden (neues feld in pahrmacy)
class InnerSelectDeliveryType extends Component<
  SelectDeliveryTypeProps,
  State
> {
  state = {
    isLoading: false,
    articles: undefined,
  };

  setIsLoading = (isLoading: boolean) => this.setState({ isLoading });
  setArticles = (articles: DbArticle[]) => this.setState({ articles });

  render() {
    const { deliveryType, setDeliveryType, theme } = this.props;

    const { isLoading, articles } = this.state;

    return (
      <AppConsumer>
        {({ serverTime }) => (
          <PharmacyValuesConsumer>
            {({ enhancedPharmacy, pharmacy }) => {
              if (!enhancedPharmacy || !pharmacy) {
                return null;
              }

              return (
                <div style={{ display: "flex" }}>
                  <LoadArticlesContainer
                    setIsLoading={this.setIsLoading}
                    setError={() => null}
                    setArticles={this.setArticles}
                    pharmacyId={pharmacy.pharmacyId}
                  />

                  {isLoading && <Loading />}

                  {articles && (
                    <SelectDeliveryTypeView
                      deliveryType={deliveryType}
                      setDeliveryType={setDeliveryType}
                      now={serverTime.value}
                      articles={articles}
                      enhancedPharmacy={enhancedPharmacy}
                    />
                  )}
                </div>
              );
            }}
          </PharmacyValuesConsumer>
        )}
      </AppConsumer>
    );
  }
}

export const SelectDeliveryType = withTheme(InnerSelectDeliveryType);
