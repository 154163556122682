import {
  faBoxCheck,
  faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";
import { faBoxCheck as faBoxCheckSolid } from "@fortawesome/pro-solid-svg-icons";
import { defineMessage } from "@lingui/macro";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Switch,
  Theme,
  Typography,
  withTheme,
} from "@material-ui/core";
import { css } from "@emotion/core";
import * as React from "react";

import { LinguiMessage } from "../../../../../app-util-components/LinguiMessage";
import { SizedFaIcon } from "../../../../../app-util-components/SizedFaIcon";
import { DbArticle } from "../../../../../app-utilities/app-types";
import { AppConsumer } from "../../../../../app/AppContext";
import { PharmacyValuesConsumer } from "../../../../../app/PharmacyContext";
import { getPackageOpeningState } from "../../../../../app/utils";
import { commonMessages } from "../../../constants/messages/commonMessages";

interface Props {
  articles: DbArticle[];
  value: boolean;
  onToggle: () => void;
  theme: Theme;
}

const messages = {
  infoMixedShoppingCartTitle: defineMessage({
    id: "infoMixedShoppingCartTitle",
    message: "Achtung, Apotheke geschlossen!",
  }),
  infoMixedShoppingCartWhenClosed: defineMessage({
    id: "infoMixedShoppingCartWhenClosed",
    message:
      "Einige Produkte in Ihrem Warenkorb müssen vor Abgabe durch die Apotheke geprüft werden. Daher sind die Produkte nicht sofort am Abholautomaten verfügbar.",
  }),
  infoToggleForSwitchingProducts: defineMessage({
    id: "infoToggleForSwitchingProducts",
    message:
      "Aktivieren Sie den untenstehenden Schalter, um nur mit den Produkten fortzufahren, die sofort verfügbar sind:",
  }),
  toggleLabelMixedShoppingCartWhenClosed: defineMessage({
    id: "toggleLabelMixedShoppingCartWhenClosed",
    message: "nur sofort verfügbare Produkte auswählen",
  }),
  verfugbarAbX: defineMessage({
    id: "verfugbarAbX",
    message: "verfügbar ab {shortDay} {time} Uhr",
  }),
  nextPickupDate: defineMessage({
    id: "nextPickupDate",
    message: "Nächste",
  }),
};

function InnerRemoveMedicsWhenClosedToggle(props: Props) {
  return (
    <AppConsumer>
      {({ serverTime }) => (
        <PharmacyValuesConsumer>
          {({ enhancedPharmacy }) => {
            if (
              !enhancedPharmacy ||
              enhancedPharmacy.nextOpeningTimes.length <= 0
            ) {
              return null;
            }

            const { openingFromMoment } = enhancedPharmacy.nextOpeningTimes[0];

            const states = props.articles.map(article => {
              return {
                article,
                openingState: getPackageOpeningState(
                  serverTime.value,
                  article,
                  enhancedPharmacy.nextOpeningTimes[0],
                ),
              };
            });

            console.log(states);

            const hasPssAvailable = states.some(({ openingState }) => {
              return openingState === "is_closed_but_pss_available";
            });
            const hasMedicine = states.some(({ openingState }) => {
              return openingState === "is_closed_and_needs_confirmation";
            });

            if (hasMedicine && hasPssAvailable) {
              return (
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        style={{
                          width: 56,
                          height: 56,
                          backgroundColor: props.theme.palette.primary.main,
                        }}
                      >
                        <SizedFaIcon
                          fontSize={32}
                          icon={faExclamationTriangle}
                          transform="up-1"
                        />
                      </Avatar>
                    }
                    title={
                      <LinguiMessage
                        message={messages.infoMixedShoppingCartTitle}
                      />
                    }
                  />
                  <CardContent style={{ marginTop: -16 }}>
                    <div
                      css={css`
                        max-width: 560px;
                        display: grid;
                        grid-gap: 16px;
                      `}
                    >
                      <div>
                        <Typography>
                          <LinguiMessage
                            message={messages.infoMixedShoppingCartWhenClosed}
                          />
                        </Typography>
                        <Typography>
                          <LinguiMessage
                            message={messages.infoToggleForSwitchingProducts}
                          />
                        </Typography>
                      </div>
                      <FormControlLabel
                        style={{ justifySelf: "end" }}
                        control={
                          <Switch
                            value="checkedA"
                            onChange={props.onToggle}
                            checked={props.value}
                          />
                        }
                        label={
                          <LinguiMessage
                            message={
                              messages.toggleLabelMixedShoppingCartWhenClosed
                            }
                          />
                        }
                      />
                      <div
                        css={css`
                          justify-self: end;
                          display: grid;
                          grid-gap: 8px;
                          grid-template-columns: min-content 200px;
                          align-items: center;
                        `}
                      >
                        <SizedFaIcon
                          style={{ color: props.theme.palette.secondary.main }}
                          icon={props.value ? faBoxCheckSolid : faBoxCheck}
                          fontSize={32}
                        />
                        <Typography>
                          {props.value ? (
                            <b>
                              <LinguiMessage
                                message={commonMessages.sofortAvailable}
                              />
                            </b>
                          ) : (
                            <LinguiMessage
                              message={messages.verfugbarAbX}
                              values={{
                                shortDay: openingFromMoment.format("dddd"),
                                time: openingFromMoment.format("HH:mm"),
                              }}
                            />
                          )}
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              );
            }

            return null;
          }}
        </PharmacyValuesConsumer>
      )}
    </AppConsumer>
  );
}

export const RemoveMedicsWhenClosedToggle = withTheme(
  InnerRemoveMedicsWhenClosedToggle,
);
