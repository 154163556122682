import { ClassNames } from "@emotion/core";
import { faChevronDoubleRight } from "@fortawesome/pro-regular-svg-icons";
import { Trans } from "@lingui/macro";
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { LinguiMessage } from "../../../../app-util-components/LinguiMessage";
import { appMessages } from "../../../../app-utilities/appMessages";
import { Form } from "../../../../app-utilities/react-utils";
import { smUp } from "../../../../deprecated/deprecated-apoly-app/components/gridStyled";
import {
  clearSearchFilterOptions,
  setSymptomsFilter,
} from "../../../../deprecated/deprecated-apoly-app/redux/medicSearchFilter/reducer";
import { selectBasePath } from "../../../../deprecated/deprecated-apoly-app/redux/shopPharmacy/reducer";
import {
  medicSearchSetPharmacyPath,
  singleProductSetPharmacyPath,
} from "../../../../deprecated/deprecated-apoly-app/routes/paths";
import { MedicineSearch } from "../../../components/MedicineSearch";
import { TextWithIcon } from "../../../TextWithIcon";

// TODO: mit Search Context
// const clearSearchFilterOptions = () => console.log('filter clear');

export interface InnerSearchBarProps {
  closeSearchBar: () => void;
  open: boolean;
}

export const SearchBar: React.SFC<InnerSearchBarProps> = ({
  closeSearchBar,
  open,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const basePath = useSelector(selectBasePath);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <ClassNames>
      {({ css }) => (
        <Dialog
          classes={{
            paper: css`
    position: absolute;
    overflow-y: initial;
    top: 48px;
    margin: 16px 0;
    width: 80%;
    max-width: 100%;
    @media (max-width: 600px) {
      width: 100%;
      margin: 0;
      top: 56px;
      padding: 8px;,
    }
`,
          }}
          open={open}
          onClose={closeSearchBar}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <LinguiMessage message={appMessages.medicSearch} />
          </DialogTitle>
          <DialogContent style={{ overflowY: "initial" }}>
            <Form
              css={css`
                display: grid;
                grid-gap: 16px;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr 1fr;
                ${smUp} {
                  grid-template-columns: 3fr 1fr;
                  grid-template-rows: 1fr;
                }
              `}
              onSubmit={() => {
                closeSearchBar();
                dispatch(clearSearchFilterOptions());
                history.push(medicSearchSetPharmacyPath(basePath), {
                  searchKeyword: searchInput,
                });
              }}
            >
              <MedicineSearch
                autoFocus={true}
                inputValue={searchInput}
                onInputValueChange={v => setSearchInput(v)}
                onSelectSymptom={s => {
                  closeSearchBar();
                  dispatch(clearSearchFilterOptions());
                  dispatch(setSymptomsFilter(s.symptomId));
                  history.push(medicSearchSetPharmacyPath(basePath), {
                    searchKeyword: "",
                  });
                }}
                onSelectArticle={a => {
                  closeSearchBar();
                  history.push(
                    singleProductSetPharmacyPath(
                      basePath,
                      a.package.medicine.urlCode,
                      a.package.urlCode,
                    ),
                  );
                }}
              />
              <Button type="submit" variant="outlined">
                <TextWithIcon iconRight={faChevronDoubleRight}>
                  <Trans>Produkt finden</Trans>
                </TextWithIcon>
              </Button>
            </Form>
          </DialogContent>
        </Dialog>
      )}
    </ClassNames>
  );
};
