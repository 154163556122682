// onEvent is window.addEventListen, but it returns an unsubscribe-function
export function onEvent<K extends keyof WindowEventMap>(
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions,
): () => void;
export function onEvent(
  type: string,
  listener: EventListener,
  options?: boolean | AddEventListenerOptions,
): () => void;
export function onEvent(
  type: string,
  listener: EventListener,
  options?: boolean | AddEventListenerOptions,
) {
  window.addEventListener(type, listener, options);
  return () => {
    window.removeEventListener(type, listener, options);
  };
}

type Subscriber = () => void;
type Unsubscriber = () => void;
type Subscribe = (subscriber: Subscriber) => Unsubscriber;
export function createNewEvent(): [Subscribe, () => void] {
  const subscribers: { current: Subscriber }[] = [];

  function fire() {
    subscribers.forEach(subscriber => {
      subscriber.current();
    });
  }

  function subscribe(subscriber: Subscriber) {
    const ele = { current: subscriber };
    subscribers.push(ele);

    return () => {
      subscribers.splice(subscribers.indexOf(ele), 1);
    };
  }

  return [subscribe, fire];
}

type DataSubscriber<T> = (v: T) => void;
type DataSubscribe<T> = (subscriber: DataSubscriber<T>) => Unsubscriber;
export function createNewDataEvent<T>(): [DataSubscribe<T>, (v: T) => void] {
  const subscribers: { current: DataSubscriber<T> }[] = [];

  function fire(v: T) {
    subscribers.forEach(subscriber => {
      subscriber.current(v);
    });
  }

  function subscribe(subscriber: DataSubscriber<T>) {
    const ele = { current: subscriber };
    subscribers.push(ele);

    return () => {
      subscribers.splice(subscribers.indexOf(ele), 1);
    };
  }

  return [subscribe, fire];
}

export function trimFirst(str: string, trim: string) {
  if (str.charAt(0) === trim) {
    return str.substr(0, str.length - 1);
  }
  return str;
}

export function trimLast(str: string, trim: string) {
  if (str.charAt(str.length - 1) === trim) {
    return str.substr(0, str.length - 1);
  }
  return str;
}

export function pause(ms: number) {
  return new Promise(r => setTimeout(r, ms));
}

export function nextAnimationFrame() {
  return new Promise(r => requestAnimationFrame(r));
}

export type NonEmptyArray<T> = [T, ...T[]];
export function isNotEmpty<T>(a: T[] | undefined): a is NonEmptyArray<T> {
  return a ? a.length > 0 : false;
}

export function nextItem<T>(i: NonEmptyArray<T>, c: T | undefined): T;
export function nextItem<T>(i: T[], c: T | undefined): T | undefined;
export function nextItem<T>(
  items: T[],
  currentItem: T | undefined,
): T | undefined {
  if (!currentItem) {
    return items[0];
  }

  return items[(items.indexOf(currentItem) + 1) % items.length];
}

export function guard<T>(v: T | undefined | null): T {
  if (v === undefined || v === null) {
    throw new Error("value is null or undefined");
  }
  return v;
}

export function getLocalstorageJson(key: string) {
  try {
    return JSON.parse(localStorage.getItem(key) as string);
  } catch (e) {
    return undefined;
  }
}

// um im lokalise die Parameter korrekt anzuzeigen, dürfen es keine Komponenten sein
// sondern muss in der fn gewrapped sein
export function linguiEq<T>(v: T): T {
  return v;
}
