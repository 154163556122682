import { LinguiMessage } from "@apoly-42/apoly-components";
import PropTypes from "prop-types";
import React from "react";

import PharmacyOpeningTimeSpan from "./PharmacyOpeningTimeSpan";

const messages = {
  notOpen: {
    id: "notOpen",
    message: "This pharmacy is closed until further notice.",
  },
};

const PharmacyOpeningTime = props =>
  props.enhancedNextOpeningTimes.length > 0 ? (
    <span color="inherit">
      <PharmacyOpeningTimeSpan
        openingFromMoment={props.enhancedNextOpeningTimes[0].openingFromMoment}
        openingToMoment={props.enhancedNextOpeningTimes[0].openingToMoment}
        isOpen={props.isOpen}
      />
    </span>
  ) : (
    <span color="inherit">
      <LinguiMessage message={messages.notOpen} />
    </span>
  );

PharmacyOpeningTime.propTypes = {
  enhancedNextOpeningTimes: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default PharmacyOpeningTime;
