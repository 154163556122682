import { Button, Paper } from "@material-ui/core";
import * as React from "react";

import { FluidTypography } from "../../../../app-util-components/FluidTypography";
import { LinguiMessage } from "../../../../app-util-components/LinguiMessage";
import { appMessages } from "../../../../app-utilities/appMessages";
import { appGrid } from "../../../../app-utilities/cssClasses";
import { CtxPathLink } from "../../../../url-utils";
import { pharmacyCtxToPath } from "../../../layout/PharmacyBaseLink";
import { PharmacyValuesConsumer } from "../../../PharmacyContext";
import { shoppingCartPath } from "../../../routes";

const messages = {
  medicsCantBeSent: {
    id: "medicsCantBeSent",
    message: "Leider können diese Medikamente nicht versandt werden",
  },
};

export const PostalDeliveryNotAvailable = () => (
  <PharmacyValuesConsumer>
    {pharmacyCtx => (
      <div style={{ width: "100%" }}>
        <Paper elevation={2} css={appGrid} style={{ padding: 16 }}>
          <FluidTypography type="headline3">
            <LinguiMessage message={messages.medicsCantBeSent} />
          </FluidTypography>
          <FluidTypography>
            <LinguiMessage message={appMessages.noPostalDeliveryAllowed} />
          </FluidTypography>
          <div style={{ alignSelf: "flex-end", display: "flex" }}>
            <Button
              component={compPops => (
                <CtxPathLink
                  {...compPops}
                  path={pharmacyCtxToPath(shoppingCartPath, pharmacyCtx)}
                />
              )}
            >
              <LinguiMessage message={appMessages.back} />
            </Button>
          </div>
        </Paper>
      </div>
    )}
  </PharmacyValuesConsumer>
);
