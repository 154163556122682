import { Loading } from "@apoly-42/material-ui";
import * as React from "react";
import scriptjs from "scriptjs";
import { throwImmediately } from "../../../../app-utilities/fn-utils";
import {
  toWcRequest,
  WirecardRequest,
  WirecardRequestFromApi,
} from "./wirecard-utils";

interface WirecardPaymentPage {
  embeddedPay: (request: WirecardRequest) => void;
}

declare global {
  interface Window {
    // nicht vollständig oder korrekt! keine Doku vorhanden bzw gefunden
    WirecardPaymentPage?: WirecardPaymentPage;
  }
}

const wcUrl =
  process.env.REACT_APP_WIRECARD_EMBEDDED_LOADER_URL ||
  throwImmediately<string>(
    "process.env.REACT_APP_WIRECARD_EMBEDDED_LOADER_URL is undefined",
  );

export const loadWirecardPaymentPageScript = (cb: () => void) =>
  scriptjs(wcUrl, cb);

export function asyncLoadWirecardPaymentPageScript() {
  return new Promise(resolve => loadWirecardPaymentPageScript(resolve));
}

interface Props {
  wcRequestFromApi: WirecardRequestFromApi;
  onStartPayment?(): void;
}

type State = { wireCardPaymentPage: WirecardPaymentPage | undefined };

export class WirecardWrapper extends React.PureComponent<Props, State> {
  state = {
    wireCardPaymentPage: window.WirecardPaymentPage,
  };

  componentDidMount() {
    const { wireCardPaymentPage } = this.state;

    if (wireCardPaymentPage) {
      this.startWcPayment(wireCardPaymentPage);
      return;
    }

    // TODO besser mit https://github.com/muicss/loadjs lösen (error-handling ist da mit drin), aber aktuell nutzt diesesr google-wrapper auch scriptjs weswegen das besser passt
    loadWirecardPaymentPageScript(() => {
      if (wireCardPaymentPage !== window.WirecardPaymentPage) {
        this.setState({ wireCardPaymentPage: window.WirecardPaymentPage });
      }
    });
  }

  componentDidUpdate(_: Props, prevState: State) {
    const { wireCardPaymentPage } = this.state;
    const changed = prevState.wireCardPaymentPage !== wireCardPaymentPage;

    if (changed && wireCardPaymentPage) {
      this.startWcPayment(wireCardPaymentPage);
    }
  }

  startWcPayment = (wireCardPaymentPage: WirecardPaymentPage) => {
    const wcRequest = toWcRequest(this.props.wcRequestFromApi);

    if (this.props.onStartPayment) {
      this.props.onStartPayment();
    }

    wireCardPaymentPage.embeddedPay(wcRequest);
  };

  render() {
    // if wireCardPaymentPage is loaded startWcPayment will be executed
    return this.state.wireCardPaymentPage ? null : <Loading />;
  }
}
