import {
  LinguiMessage,
  WithIcon,
  withLingui,
} from "@apoly-42/apoly-components";
import {
  faCamera,
  faChevronDoubleRight,
} from "@fortawesome/pro-solid-svg-icons";
import { I18n } from "@lingui/core";
import { AppBar, Button, Grid, Hidden, Toolbar } from "@material-ui/core";
import React from "react";
import { PharmacyThemeContext } from "../../app-theme/pharmacy-theme/pharmacyTheme";
import { appMessages } from "../../app-utilities/appMessages";
import { flex } from "../../app-utilities/cssClasses";
import ShoppingCartButton from "../../deprecated/deprecated-apoly-app/components/ShoppingCartButton";
import { CtxPathLink } from "../../url-utils";
import { MuiBtnLink } from "../../url-utils/MuiBtnLink";
import { PharmacyValuesConsumer } from "../PharmacyContext";
import { pharmacyBase, submitPrescriptionPath } from "../routes";
import { PharmacyChannel } from "../routes-helper";
import { RightDrawer } from "./navbar/drawer/RightDrawer";
import { BrandName } from "./navbar/navbar/navBarComponents/BrandName";
import { FindMedicButton } from "./navbar/navbar/navBarComponents/FindMedicButton";
import { ToggleDrawerButton } from "./navbar/navbar/navBarComponents/ToggleDrawerButton";
import { SearchBar } from "./navbar/navbar/SearchBar";
import { pharmacyCtxToPath } from "./PharmacyBaseLink";

interface State {
  drawerOpen: boolean;
  searchBarOpen: boolean;
}

function reloadToMainPath(path: string) {
  if (window.location.href.substr(-path.length) === path) {
    window.location.reload();
  }

  window.location.href = path;
}

export const NavBar = withLingui(
  class InnerNavBar extends React.Component<
    { hideBtns?: boolean; i18n: I18n },
    State
  > {
    state: State = {
      drawerOpen: false,
      searchBarOpen: false,
    };

    toggleDrawer = () => this.setState({ drawerOpen: !this.state.drawerOpen });
    /*
    showUserAvatar = (user: firebase.User | null) =>
      user ? <AvatarForTabbar user={user} /> : null;
*/
    toggleSearchBar = () =>
      this.setState({ searchBarOpen: !this.state.searchBarOpen });

    toggleSearchBarAndDrawer = () => [
      this.toggleDrawer(),
      this.toggleSearchBar(),
    ];

    render() {
      const { i18n, hideBtns = false } = this.props;

      return (
        <PharmacyThemeContext>
          {pharmacyThemeCtx => (
            <PharmacyValuesConsumer>
              {pharmacyCtx => (
                <React.Fragment>
                  <AppBar position="static">
                    <Grid
                      style={
                        pharmacyCtx.pharmacyChannel === PharmacyChannel.pss
                          ? { height: 160 }
                          : undefined
                      }
                      container={true}
                      justify="center"
                      alignItems="flex-end"
                      spacing={0}
                    >
                      <Grid item={true} xs={12} lg={10} style={{ flexGrow: 1 }}>
                        <Toolbar>
                          <div>
                            <div>
                              <CtxPathLink
                                style={{ margin: "auto 8px" }}
                                noDeco={true}
                                path={pharmacyCtxToPath(
                                  pharmacyBase,
                                  pharmacyCtx,
                                )}
                              >
                                {pharmacyThemeCtx.logo ? (
                                  <img
                                    alt={
                                      pharmacyCtx.pharmacy &&
                                      pharmacyCtx.pharmacy.name
                                    }
                                    src={pharmacyThemeCtx.logo}
                                    style={{
                                      marginBottom:
                                        hideBtns &&
                                        pharmacyCtx.pharmacyChannel ===
                                          PharmacyChannel.pss
                                          ? 8
                                          : undefined,
                                      maxHeight:
                                        pharmacyCtx.pharmacyChannel ===
                                        PharmacyChannel.pss
                                          ? 80
                                          : 40,
                                    }}
                                  />
                                ) : (
                                  <BrandName component="span" />
                                )}
                              </CtxPathLink>
                            </div>
                            {!hideBtns &&
                              pharmacyCtx.pharmacyChannel ===
                                PharmacyChannel.pss && (
                                <Button
                                  style={{ marginBottom: 8, marginTop: 8 }}
                                  variant="outlined"
                                  onClick={() => {
                                    reloadToMainPath(
                                      pharmacyCtxToPath(
                                        pharmacyBase,
                                        pharmacyCtx,
                                      ),
                                    );
                                  }}
                                >
                                  Reservierung abholen
                                </Button>
                              )}
                          </div>
                          {!hideBtns && (
                            <div
                              style={
                                pharmacyCtx.pharmacyChannel ===
                                PharmacyChannel.pss
                                  ? {
                                      marginLeft: "auto",
                                      marginTop: "auto",
                                      marginBottom: 8,
                                    }
                                  : { marginLeft: "auto" }
                              }
                              css={flex}
                            >
                              <FindMedicButton
                                toggleSearchBar={this.toggleSearchBar}
                                disabled={this.state.searchBarOpen}
                              />
                              {pharmacyCtx.pharmacyChannel ===
                                PharmacyChannel.local && (
                                <Hidden smDown={true}>
                                  <MuiBtnLink
                                    color="inherit"
                                    path={pharmacyCtxToPath(
                                      submitPrescriptionPath,
                                      pharmacyCtx,
                                    )}
                                    aria-label={i18n._(
                                      appMessages.submitPrescription,
                                    )}
                                  >
                                    <WithIcon
                                      iconFontSize={16}
                                      icon={faCamera}
                                      iconLeft={true}
                                    >
                                      <WithIcon
                                        iconFontSize={16}
                                        icon={faChevronDoubleRight}
                                      >
                                        <LinguiMessage
                                          message={
                                            appMessages.submitPrescription
                                          }
                                        />
                                      </WithIcon>
                                    </WithIcon>
                                  </MuiBtnLink>
                                </Hidden>
                              )}
                              <ShoppingCartButton />
                              <ToggleDrawerButton
                                {...pharmacyCtx}
                                toggleDrawer={this.toggleDrawer}
                              />
                            </div>
                          )}
                        </Toolbar>
                      </Grid>
                    </Grid>
                  </AppBar>
                  <RightDrawer
                    open={this.state.drawerOpen}
                    toggleSearchBarAndDrawer={this.toggleSearchBarAndDrawer}
                    handleDrawer={this.toggleDrawer}
                    pharmacy={pharmacyCtx.pharmacy}
                    pharmacyCtx={pharmacyCtx}
                  />
                  <SearchBar
                    open={this.state.searchBarOpen}
                    closeSearchBar={this.toggleSearchBar}
                  />
                </React.Fragment>
              )}
            </PharmacyValuesConsumer>
          )}
        </PharmacyThemeContext>
      );
    }
  },
);
