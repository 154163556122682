// aktuell ausgelagert wegen Struktur mit js-files die zyklisch darauf zugreifen könnten

import { equals } from "ramda";
import { useContext } from 'react';
import { cond, throwF } from "../app-utilities/fn-utils";
import { PharmacyConsumer, PharmacyCtx } from './PharmacyContext';

export const localDeliveryRoute = "lokal";
const mailOrderRoute = "regional";
const pssRoute = "abholautomat";
const customerBoardRoute = "offizin";

export enum PharmacyChannel {
  local = 1,
  mailOrder, // versandhandel
  pss, // automaten
  customerBoard,
}

export const isLocalChannel = (channel: PharmacyChannel) =>
  channel === PharmacyChannel.local;

export const isCustomerBoardChannel = (channel: PharmacyChannel) =>
  channel === PharmacyChannel.customerBoard;

export const isPssChannel = (channel: PharmacyChannel) =>
  channel === PharmacyChannel.pss;

export function useIsPssChannel() {
  const a = useContext(PharmacyCtx.context);

  return a.pharmacyChannel.value === PharmacyChannel.pss;
}

export function channelToRoute(p: PharmacyChannel) {
  switch (p) {
    case PharmacyChannel.local:
      return localDeliveryRoute;
    case PharmacyChannel.mailOrder:
      return mailOrderRoute;
    case PharmacyChannel.pss:
      return pssRoute;
    case PharmacyChannel.customerBoard:
      return customerBoardRoute;
  }
}

export const routeToChannel = cond<string, PharmacyChannel>(
  [
    [equals(localDeliveryRoute), () => PharmacyChannel.local],
    [equals(mailOrderRoute), () => PharmacyChannel.mailOrder],
    [equals(pssRoute), () => PharmacyChannel.pss],
    [equals(customerBoardRoute), () => PharmacyChannel.customerBoard],
  ],
  throwF(val => `unknown channel ${val}`),
);
