import { logError } from "@apoly-42/apoly-utils";
import { logErrorGracefully } from "./failGracefully";

const getErrorMsg = error =>
  `no Error-handler given for wrapHandlerWithGracefulLogging with error "${error &&
    error.message}"`;

export const wrapHandlerWithGracefulLogging = handler => (error, ...params) => {
  try {
    const extra = params && params.length > 0 && params;

    logErrorGracefully(error, { extra }); // logError und logErrorGracefully müssen importiert werden
  } catch (internalError) {
    logError(internalError);
  }

  if (!handler) {
    return wrapHandlerWithGracefulLogging(() => error)(
      new Error(getErrorMsg(error)),
      ...params,
    );
  }

  return params && params.length > 0
    ? handler(error, ...params)
    : handler(error);
};

export default wrapHandlerWithGracefulLogging;
