// import it as dynamic script, cause some blockers will block scripts with loading google-scripts in it
const loadInitTagManager = () =>
  import("./initTagManager").then(initTagManagerModule =>
    initTagManagerModule.default(),
  );

export const asyncGaOptOut = () =>
  import("./initTagManager").then(initTagManagerModule =>
    initTagManagerModule.gaOptout(),
  );

export default loadInitTagManager;
