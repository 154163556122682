import { createNewEvent, getLocalstorageJson, pause } from "./utils";
import {
  DependencyList,
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";

type CleanUpFn = () => void;
export function useAsyncEffect(
  fn: (effect: {
    signal: { isAborted: boolean };
    onCleanUp: (cleanUpFn: CleanUpFn) => void;
  }) => Promise<void>,
  deps?: DependencyList,
) {
  const [error, setError] = useState();

  if (error) {
    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error(error);
    }
  }

  useEffect(() => {
    const [onCleanUp, fireCleanUp] = createNewEvent();
    function onceCleanUp(fn: () => void) {
      // funktion wird nur einmal ausgeführt und danach entfernt
      const unsubscribe = onCleanUp(() => {
        fn();
        unsubscribe();
      });
    }
    const signal = { isAborted: false };

    fn({ signal, onCleanUp: onceCleanUp })
      .then(() => {
        if (signal.isAborted) {
          // möglicherweise wurde ein cleanUp definiert, obwohl es schon abgebrochen war,
          // weshalb es hier nochmal ausgeführt wird, obwohl es unten schon ausgeführt wurde
          // da events aber nur einmalig ausgeführt werden (onceCleanUp), ist es kein Problem
          fireCleanUp();
          return;
        }
      })
      .catch(error => {
        setError(error);
      });

    return () => {
      signal.isAborted = true;
      // führe nach abort clean-up aus
      fireCleanUp();
    };
  }, deps);
}

export function useEleWidthTracker<T extends HTMLElement>(): [
  number | undefined,
  (r: T) => void,
] {
  const [width, setWidth] = useState<number>();
  const [ref, setRef] = useState<T>();

  useAsyncEffect(
    async ({ signal }) => {
      if (!ref) {
        return;
      }
      // only for ts (type-checking => isDefined)
      const cRef = ref;

      async function loop() {
        if (signal.isAborted) return;

        if (cRef.clientWidth !== width) {
          setWidth(cRef.clientWidth);
        }

        await pause(200);
        requestAnimationFrame(loop);
      }

      requestAnimationFrame(loop);
    },
    [ref, width],
  );

  return [width, setRef];
}

// gets the size of the window minus scrollbars, if visible
function getWindowSize() {
  let { clientWidth, clientHeight } = document.documentElement;
  // Material-UI Menu-component setzt den body auf overflow hidden und
  // fügt das entsprechende padding-right dafür ein
  // damit das design dann nicht springt, wird es abgefangen:
  if (document.body.style.overflow === "hidden") {
    let pRight = document.body.style.paddingRight
      ? Number(document.body.style.paddingRight.replace("px", ""))
      : 0;
    pRight = isNaN(pRight) ? 0 : pRight;
    clientWidth = clientWidth - pRight;
  }

  return {
    width: clientWidth,
    height: clientHeight,
  };
}

export interface Size {
  width: number;
  height: number;
}

export function useClientSize() {
  const [size, setSize] = useState<Size>(() => getWindowSize());

  useAsyncEffect(
    async ({ signal }) => {
      async function loop() {
        if (signal.isAborted) return;

        const newSize = getWindowSize();
        if (newSize.width !== size.width || newSize.height !== size.height) {
          setSize(newSize);
        }

        await pause(200);
        requestAnimationFrame(loop);
      }

      requestAnimationFrame(loop);
    },
    [size],
  );

  return size;
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<{ v: T; prev: T | undefined }>({
    v: value,
    prev: undefined,
  });

  // update `ref` only, if the value really changes,
  // the previous value is kept persistent in `prev`,
  // so it also contains the previous value for
  // further renders with the same `value`.
  if (ref.current.v !== value) {
    ref.current = {
      prev: ref.current.v,
      v: value,
    };
  }

  return ref.current.prev;
}

const counters = [0];
export function useClientId(prefix = ""): string {
  const cId = useRef<string>();
  if (!cId.current) {
    const len = counters.length;
    counters[len - 1] = counters[len - 1] + 1;
    if (counters[len - 1] >= 10000) {
      counters.push(0);
    }

    cId.current = [prefix, "__internal_client_id", ...counters]
      .filter(Boolean)
      .join("_");
  }

  return cId.current;
}

export function useLSState<T>(
  key: string,
  initialValue?: T,
): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState<T>(() => {
    const v = getLocalstorageJson(key);
    if (v) {
      return v;
    }
    return initialValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [state]);

  return [state, setState];
}

export function useConst<T>(fn: () => T): T {
  const r = useRef<T>();
  if (!r.current) {
    r.current = fn();
  }
  return r.current;
}
