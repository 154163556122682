/* eslint-disable react/require-default-props,import/no-unresolved */
/* tslint:disable */
import { LinguiEuro, LinguiMessage } from "@apoly-42/apoly-components";
import { onPropsDidUpdate } from "@apoly-42/apoly-utils";
import {
  ButtonWithIcon,
  ShowComponentWhileLoading,
} from "@apoly-42/material-ui";
import {
  faChevronDoubleLeft,
  faChevronDoubleRight,
} from "@fortawesome/pro-solid-svg-icons";
import {
  Button,
  Divider,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose, withState } from "recompose";
import { appContext } from "../../../../../app/AppContext";
import {
  PharmacyCtx,
  PharmacyValuesConsumer,
} from "../../../../../app/PharmacyContext";
import { PharmacyChannel } from "../../../../../app/routes-helper";
import { getPackageOpeningState } from "../../../../../app/utils";
import { commonMessages } from "../../../constants/messages/commonMessages";
import { flex, flexRight } from "../../../constants/styles/commonClasses";
import LoadArticlesContainer from "../../../containers/product/LoadArticlesContainer";
import { selectUser } from "../../../redux/auth/reducer";
import { selectBasePath } from "../../../redux/shopPharmacy/reducer";
import { deleteMultipleItemsFromShoppingCart } from "../../../redux/shoppingCart/reducer";
import {
  getShoppingCartArticlesIdArray,
  getShoppingCartLength,
  selectProducts,
} from "../../../redux/shoppingCart/selectors";
import {
  checkoutPath,
  checkoutPathLoginOrContinueAsGuest,
  medicSearchSetPharmacyPath,
} from "../../../routes/paths";
import NoItemsInShoppingCart from "../../NoItemsInShoppingCart";
import { RemoveMedicsWhenClosedToggle } from "./RemoveMedicsWhenClosedToggle";
import ShoppingCartTableView from "./shoppingCartTable/ShoppingCartTableView";

const messages = {
  couponCodeAvailable: {
    id: "couponCodeAvailable",
    message: "Coupon code available ?",
  },
  checkout: {
    id: "checkout",
    message: "checkout",
  },
  reserveMedicines: {
    id: "reserveMedicines",
    message: "reserve medicines",
  },
};

const InnerShoppingCartView = props => {
  if (!props.shoppingCartLength || props.shoppingCartLength <= 0) {
    return <NoItemsInShoppingCart />;
  }

  const price = props.localArticles
    ? props.localArticles.reduce((sum, article) => {
        if (!props.products[article.articleId]) {
          return sum;
        }

        return article.price * props.products[article.articleId].count + sum;
      }, 0)
    : undefined;

  const serverTime = useContext(appContext.context).serverTime.value;
  const enhancedPharmacy = useContext(PharmacyCtx.context).enhancedPharmacy
    .value;

  if (!enhancedPharmacy || !serverTime) {
    return null;
  }

  const states = props.localArticles
    ? props.localArticles.map(article => {
        return {
          article,
          openingState: getPackageOpeningState(
            serverTime,
            article,
            enhancedPharmacy.nextOpeningTimes[0],
          ),
        };
      })
    : [];

  const usePssAvailability =
    states.length >= 0 &&
    states.every(({ openingState }) => {
      return openingState === "is_closed_but_pss_available";
    });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <React.Fragment>
          <LoadArticlesContainer
            pharmacyId={props.pharmacyId}
            articleIdArray={props.articleIdArray}
            setArticles={props.setArticles}
            setIsLoading={props.setIsLoading}
            setError={props.setError}
          />

          {props.isLoading && (
            <ShowComponentWhileLoading isLoading={props.isLoading} />
          )}

          {!props.isLoading && props.articles && (
            <ShoppingCartTableView
              removeNonPssAvailable={props.removeNonPssAvailable}
              articles={props.articles}
              usePssAvailability={usePssAvailability}
            />
          )}

          {!props.isLoading && !props.articles && <NoItemsInShoppingCart />}
        </React.Fragment>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} style={flex}>
        {price >= 0 && (
          <Typography style={flexRight}>
            <LinguiMessage message={commonMessages.totalAmount} />:{" "}
            <LinguiEuro value={price} />
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {props.articles && (
          <RemoveMedicsWhenClosedToggle
            onToggle={() => {
              props.setRemoveNonPssAvailable(!props.removeNonPssAvailable);
            }}
            value={props.removeNonPssAvailable}
            articles={props.articles}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          className={props.classes.fullWidthXs}
          variant="contained"
          component={Link}
          to={medicSearchSetPharmacyPath(props.basePath)}
          icon={faChevronDoubleLeft}
        >
          <LinguiMessage message={commonMessages.continueShopping} />
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} style={flex}>
        <PharmacyValuesConsumer>
          {pharmacyCtx =>
            pharmacyCtx.pharmacyChannel === PharmacyChannel.customerBoard ? (
              <ButtonWithIcon
                className={props.classes.fullWidthXs}
                variant="contained"
                style={flexRight}
                color="secondary"
                onClick={props.submit}
                iconRight
                icon={faChevronDoubleRight}
              >
                <LinguiMessage message={messages.reserveMedicines} />
              </ButtonWithIcon>
            ) : (
              <ButtonWithIcon
                className={props.classes.fullWidthXs}
                variant="contained"
                color="secondary"
                style={flexRight}
                onClick={() => {
                  const newRoute = props.user
                    ? checkoutPath(props.basePath)
                    : checkoutPathLoginOrContinueAsGuest(props.basePath);

                  if (props.removeNonPssAvailable) {
                    const idsToRemove = props.articles
                      .filter(article => {
                        const isTax2 =
                          article.package.medicine.taxkennzeichen ===
                          "tax_2_freie";
                        const isPssAvailable =
                          isTax2 && article.pss_availability === "available";
                        return !isPssAvailable;
                      })
                      .map(article => article.articleId);

                    props.deleteMultipleItemsFromShoppingCart({
                      articleIds: idsToRemove,
                      pharmacyId: props.pharmacyId,
                    });
                  }

                  props.history.push(newRoute);
                }}
                iconRight
                icon={faChevronDoubleRight}
              >
                <LinguiMessage message={messages.checkout} />
              </ButtonWithIcon>
            )
          }
        </PharmacyValuesConsumer>
      </Grid>
    </Grid>
  );
};

const styles = {
  fullWidthXs: {
    "@media(max-width: 600px)": {
      width: "100%",
    },
  },
};

InnerShoppingCartView.propTypes = {
  pharmacyId: PropTypes.any,
  submit: PropTypes.any,
  shoppingCartLength: PropTypes.any,
  articleIdArray: PropTypes.any,
  setArticles: PropTypes.any,
  setIsLoading: PropTypes.any,
  setError: PropTypes.any,
  isLoading: PropTypes.any,
  articles: PropTypes.any,
  classes: PropTypes.any,
  user: PropTypes.any,
  basePath: PropTypes.any,
  removeNonPssAvailable: PropTypes.any,
  setRemoveNonPssAvailable: PropTypes.any,
  localArticles: PropTypes.any,
  // eslint-disable-next-line react/no-unused-prop-types
  setLocalArticles: PropTypes.any,
  products: PropTypes.any,
  history: PropTypes.any,
  deleteMultipleItemsFromShoppingCart: PropTypes.any,
};

const mapStateToProps = state => ({
  products: selectProducts(state),
  shoppingCartLength: getShoppingCartLength(state),
  articleIdArray: getShoppingCartArticlesIdArray(state),
  basePath: selectBasePath(state),
  user: selectUser(state),
});

const enhance = compose(
  withState("articles", "setArticles", null),
  withState("isLoading", "setIsLoading", false),
  withState("error", "setError", null),
  withState("removeNonPssAvailable", "setRemoveNonPssAvailable", false),
  withState("localArticles", "setLocalArticles", null),
  withRouter,
  onPropsDidUpdate(["removeNonPssAvailable", "articles"], props => {
    if (!props.articles) {
      return;
    }

    const newArticles = props.removeNonPssAvailable
      ? props.articles.filter(article => {
          const isTax2 =
            article.package.medicine.taxkennzeichen === "tax_2_freie";
          const isPssAvailable =
            isTax2 && article.pss_availability === "available";
          return isTax2 && isPssAvailable;
        })
      : props.articles;

    props.setLocalArticles(newArticles);
  }),
  withStyles(styles),
  connect(mapStateToProps, { deleteMultipleItemsFromShoppingCart }),
);

const ShoppingCartView = enhance(InnerShoppingCartView);

ShoppingCartView.propTypes = {
  submit: PropTypes.func,
  pharmacyId: PropTypes.number.isRequired,
};
ShoppingCartView.defaultProps = {
  submit: null,
};

export default ShoppingCartView;
