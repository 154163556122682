import { WithIcon } from "@apoly-42/apoly-components";
import { faChevronDoubleRight, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Button, Collapse, FormControlLabel, Radio, Switch } from "@material-ui/core";
import { FormikFields } from "formik-fields";
import * as React from "react";

import { AddressDialog } from "../../../app-util-components/address-utils/AddressDialog";
import { FluidTypography } from "../../../app-util-components/FluidTypography";
import { noEmptyFormField } from "../../../app-util-components/form-utils/formikFieldValidators";
import { MuiFormikTextFieldI18n } from "../../../app-util-components/form-utils/MuiFormikTextFieldI18n";
import { LinguiMessage } from "../../../app-util-components/LinguiMessage";
import { formMessages } from "../../../app-utilities/appMessages";
import { appGrid } from "../../../app-utilities/cssClasses";
import { addressToString } from "../../../app-utilities/toStringUtils";
import { commonMessages } from "../../../deprecated/deprecated-apoly-app/constants/messages/commonMessages";
import { Address } from "./OtcOrderTypes";

interface CheckoutBillingDataProps {
  onSubmit: (data: {
    billingAddress?: Address | undefined;
    email: string;
    telephone?: string;
    birthday: string;
  }) => void;
  showTelephone?: boolean | undefined;
  email?: string;
  telephone?: string;
  billingAddress?: Address;
  birthday: string;
  addresses: Address[];
  onSubmitNewAddress: (address: Address) => void;
}

type BillingDataForm = {
  email: string;
  telephone: string;
  billingAddress: Address | undefined;
  birthday: string;
};

const messages = {
  chooseYourBillingAddress: {
    id: "chooseYourBillingAddress",
    message: "Wählen Sie Ihre Rechnungsadresse aus oder fügen Sie eine neue hinzu.",
  },
  addBillingAddress: {
    id: "addBillingAddress",
    message: "Fügen Sie bitte eine neue Rechnungsadresse hinzu.",
  },
};

export class CheckoutBillingData extends React.Component<
  CheckoutBillingDataProps,
  { withBillingAddress: boolean; addresses: Address[]; showDialog: boolean }
> {
  state = {
    withBillingAddress: !!this.props.billingAddress,
    addresses: this.props.addresses,
    showDialog: false,
  };

  newAddress = (address: Address) => {
    this.setState({ addresses: [...this.state.addresses, address] });
  };

  toggleWithBillingAddress = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ withBillingAddress: e.target.checked });

  toggleShowDialog = () => this.setState({ showDialog: !this.state.showDialog });

  isValidAddress = (address: undefined | Address) => address && this.state.addresses.indexOf(address) >= 0;

  isValidTelephone = (telephone: undefined | string) => telephone && telephone !== "";

  validate = (values: BillingDataForm) => ({
    billingAddress:
      this.state.withBillingAddress && !this.isValidAddress(values.billingAddress) ? (
        <LinguiMessage message={formMessages.noEmptyField} />
      ) : undefined,
    telephone:
      this.props.showTelephone && !this.isValidTelephone(values.telephone) ? (
        <LinguiMessage message={formMessages.noEmptyField} />
      ) : undefined,
  });

  render() {
    const { addresses, withBillingAddress, showDialog } = this.state;

    const { showTelephone, billingAddress, telephone, email, onSubmitNewAddress, birthday } = this.props;

    return (
      <FormikFields<BillingDataForm>
        validate={this.validate}
        fields={{
          email: { initialValue: email || "", validate: noEmptyFormField },
          telephone: { initialValue: telephone || "" },
          billingAddress: { initialValue: billingAddress },
          birthday: { initialValue: birthday },
        }}
        onSubmit={(values) =>
          this.props.onSubmit({
            telephone: values.telephone,
            email: values.email,
            billingAddress: withBillingAddress ? values.billingAddress : undefined,
            birthday: values.birthday,
          })
        }
      >
        {(fields, formikBag) => (
          <React.Fragment>
            <form css={appGrid} onSubmit={formikBag.handleSubmit}>
              <MuiFormikTextFieldI18n
                autoFocus
                labelMessage={formMessages.email}
                type="email"
                autoComplete="email"
                required={true}
                placeholderMessage={formMessages.emailPlaceholder}
                field={fields.email}
              />

              {showTelephone && (
                <MuiFormikTextFieldI18n
                  labelMessage={formMessages.telephone}
                  type="tel"
                  autoComplete="tel"
                  required={true}
                  placeholderMessage={formMessages.telephone}
                  field={fields.telephone}
                />
              )}

              <MuiFormikTextFieldI18n
                labelMessage={formMessages.birthday}
                type="date"
                autoComplete="bday"
                required={true}
                placeholderMessage={formMessages.birthday}
                field={fields.birthday}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <FormControlLabel
                control={<Switch checked={withBillingAddress} onChange={this.toggleWithBillingAddress} />}
                label={<LinguiMessage message={commonMessages.billingAddress} />}
              />

              {/* padding to fix cut radio button */}
              <Collapse in={withBillingAddress} style={{ paddingLeft: 4 }}>
                <div css={appGrid}>
                  <FluidTypography>
                    <LinguiMessage
                      message={addresses.length > 0 ? messages.chooseYourBillingAddress : messages.addBillingAddress}
                    />
                  </FluidTypography>

                  {addresses.map((address, i) => (
                    <FormControlLabel
                      key={i}
                      name={fields.billingAddress.name}
                      checked={fields.billingAddress.value === address}
                      onChange={(_, checked) => checked && fields.billingAddress.setValue(address)}
                      control={<Radio color={fields.billingAddress.error ? "secondary" : "primary"} />}
                      label={addressToString(address)}
                    />
                  ))}

                  {fields.billingAddress.error && (
                    <FluidTypography color="error">{fields.billingAddress.error}</FluidTypography>
                  )}

                  <Button
                    style={{ justifySelf: "flex-start" }}
                    variant={addresses.length > 0 ? "outlined" : "contained"}
                    color={addresses.length > 0 ? undefined : "secondary"}
                    onClick={this.toggleShowDialog}
                  >
                    <WithIcon icon={faPlus} iconLeft={true}>
                      <LinguiMessage message={commonMessages.addAddress} />
                    </WithIcon>
                  </Button>
                </div>
              </Collapse>
              <Button type="submit" style={{ justifySelf: "flex-end" }} variant="contained" color="secondary">
                <WithIcon icon={faChevronDoubleRight}>
                  <LinguiMessage message={commonMessages.continue} />
                </WithIcon>
              </Button>
            </form>
            <AddressDialog
              isOpen={showDialog}
              onSubmit={(address) => {
                onSubmitNewAddress(address);
                fields.billingAddress.setValue(address);
                this.setState({
                  addresses: [...addresses, address],
                  showDialog: false,
                });
              }}
              onCloseClick={this.toggleShowDialog}
            />
          </React.Fragment>
        )}
      </FormikFields>
    );
  }
}
