import { Trans } from "@lingui/macro";
import { Typography } from "@material-ui/core";
import * as React from "react";
import { LinguiMessage } from "../../../app-util-components/LinguiMessage";
import { commonMessages } from "../../../deprecated/deprecated-apoly-app/constants/messages/commonMessages";
import { getPaymentMethodImage } from "../../../deprecated/deprecated-apoly-app/utilities/paymentMethod/paymentMethodsUtilities";
import { PaymentMethod } from "../../apolyApi";
import { PosTerminalIconSvg } from "./CheckoutPayment";

interface PaymentSummaryProps {
  paymentMethod: PaymentMethod;
}

export class PaymentSummary extends React.PureComponent<PaymentSummaryProps> {
  render() {
    const { paymentMethod } = this.props;

    return paymentMethod === "cash" ? (
      <div className="p-2 grid grid-gap-2 align-content-center justify-items-center">
        <img
          src={getPaymentMethodImage(paymentMethod as never)}
          className="max-w-32 max-h-16"
          alt={paymentMethod}
        />
        <Typography className="leading-none" align="center">
          <LinguiMessage message={commonMessages.cash} />
        </Typography>
      </div>
    ) : paymentMethod === "hobex_terminal" ? (
      <div className="p-2 grid grid-gap-1 justify-items-center text-primary">
        <PosTerminalIconSvg className="max-w-full h-24 fill-current" />
        <Typography variant="caption" className="text-gray-700">
          <Trans>Kartenzahlung</Trans>
        </Typography>
      </div>
    ) : (
      <div className="p-2">
        <img
          src={getPaymentMethodImage(paymentMethod as never)}
          className="max-w-32 max-h-16"
          alt={paymentMethod}
        />
      </div>
    );
  }
}
