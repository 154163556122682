import { defineMessage } from "@lingui/macro";
import { Stepper } from "@material-ui/core";
import * as React from "react";
import { LinguiMessage } from "../../../../app-util-components/LinguiMessage";
import { MuiStepperStep } from "../../../../app-util-components/MuiStepperStep";
import { BillingDataSummary, DeliverySummary, PickUpSummary } from "../../../../app-utilities/toStringUtils";
import { commonMessages } from "../../../../deprecated/deprecated-apoly-app/constants/messages/commonMessages";
import { PaymentMethod, Pharmacy } from "../../../apolyApi";
import { PharmacyValuesConsumer } from "../../../PharmacyContext";
import { CheckoutDefaultStep } from "../checkoutTypes";
import { Address, DeliveryType } from "../OtcOrderTypes";
import { PaymentSummary } from "../PaymentSummary";

interface CheckoutDefaultStepperProps {
  step: CheckoutDefaultStep;
  email: string | undefined;
  birthday?: string;
  deliveryType: DeliveryType;
  billingAddress?: Address;
  deliveryAddress?: Address;
  paymentMethod: PaymentMethod | undefined;
  onEditStepClick: (step: CheckoutDefaultStep) => void;
  renderStepContent?: (step: CheckoutDefaultStep) => React.ReactNode;
  className?: string;
}

export const checkoutDefaultStepperMessages = {
  deliveryOrPickup: defineMessage({
    id: "deliveryOrPickup",
    message: "Lieferung oder Abholung",
  }),
};

export class CheckoutDefaultStepper extends React.PureComponent<CheckoutDefaultStepperProps> {
  renderDeliveryDataSummary = (pharmacy: Pharmacy) => [
    this.props.deliveryType === DeliveryType.pickUp && <PickUpSummary key="PickUpSummary" pharmacy={pharmacy} />,
    this.props.deliveryType === DeliveryType.pssReservation && (
      <PickUpSummary key="PickUpSummary" pharmacy={pharmacy} isPss={true} />
    ),
    this.props.deliveryType === DeliveryType.courier && this.props.deliveryAddress && (
      <DeliverySummary key="DeliverySummary" deliveryAddress={this.props.deliveryAddress} />
    ),
    this.props.deliveryType === DeliveryType.mailOrder && this.props.deliveryAddress && (
      <DeliverySummary key="DeliverySummary" deliveryAddress={this.props.deliveryAddress} />
    ),
  ];

  render() {
    const {
      billingAddress,
      email,
      step,
      telephone,
      paymentMethod,
      onEditStepClick,
      renderStepContent,
      className,
      birthday,
    } = this.props;

    return (
      <Stepper nonLinear={true} orientation="vertical" className={className}>
        <MuiStepperStep
          currentStep={step}
          step={CheckoutDefaultStep.billingData}
          stepLabel={<LinguiMessage message={commonMessages.billingDataStep} />}
          summary={
            email && (
              <BillingDataSummary
                email={email}
                telephone={telephone}
                billingAddress={billingAddress}
                birthday={birthday}
              />
            )
          }
          onEditStepClick={onEditStepClick}
        >
          {renderStepContent && renderStepContent(CheckoutDefaultStep.billingData)}
        </MuiStepperStep>

        <MuiStepperStep
          currentStep={step}
          step={CheckoutDefaultStep.deliveryData}
          stepLabel={<LinguiMessage message={checkoutDefaultStepperMessages.deliveryOrPickup} />}
          summary={
            step > 1 && (
              <PharmacyValuesConsumer>
                {(pharmacyCtx) => pharmacyCtx.pharmacy && this.renderDeliveryDataSummary(pharmacyCtx.pharmacy)}
              </PharmacyValuesConsumer>
            )
          }
          onEditStepClick={onEditStepClick}
        >
          {renderStepContent && renderStepContent(CheckoutDefaultStep.deliveryData)}
        </MuiStepperStep>
        <MuiStepperStep
          currentStep={step}
          step={CheckoutDefaultStep.payment}
          stepLabel={<LinguiMessage message={commonMessages.paymentMethod} />}
          summary={paymentMethod && <PaymentSummary paymentMethod={paymentMethod} />}
          onEditStepClick={onEditStepClick}
        >
          {renderStepContent && renderStepContent(CheckoutDefaultStep.payment)}
        </MuiStepperStep>
        <MuiStepperStep
          currentStep={step}
          step={CheckoutDefaultStep.closure}
          stepLabel={<LinguiMessage message={commonMessages.finish} />}
          onEditStepClick={onEditStepClick}
        >
          {renderStepContent && renderStepContent(CheckoutDefaultStep.closure)}
        </MuiStepperStep>
      </Stepper>
    );
  }
}
