import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { pqfToString, rfcUrl, UrlConsumer, UrlType } from "../url";
import { RfcUrl } from "../url/rfcUrl";

interface SyncRouteProps {
  url: RfcUrl | undefined;
  setUrl(url: RfcUrl): void;
}

const locationToString = ({
  search,
  pathname,
  hash,
}: {
  search: string;
  pathname: string;
  hash: string;
}) => `${pathname}${search}${hash}`;

class InnerSyncUrlWithReactRouter extends React.PureComponent<
  SyncRouteProps & RouteComponentProps
> {
  componentDidMount() {
    this.syncUrl();
  }

  componentDidUpdate(prevProps: SyncRouteProps & RouteComponentProps) {
    const { location, url, setUrl } = this.props;

    if (!url) {
      return;
    }

    const newUrl = rfcUrl(locationToString(location), url);

    if (url.toString() === newUrl.toString()) {
      return;
    }

    if (url !== prevProps.url) {
      if (url.urlType === UrlType.major) {
        this.props.history.push(pqfToString(url));
      } else {
        this.props.history.replace(pqfToString(url));
      }
    } else {
      setUrl(newUrl);
    }
  }

  syncUrl() {
    if (!this.props.url) {
      return;
    }

    const { location, url, setUrl } = this.props;

    const newUrl = rfcUrl(locationToString(location), url);

    if (url.toString() !== newUrl.toString()) {
      setUrl(newUrl);
    }
  }

  render() {
    return null;
  }
}

// A simple component that shows the pathname of the current location
// @ts-ignore
const SyncUrlWithReactRouter = withRouter<SyncRouteProps>(
  InnerSyncUrlWithReactRouter,
);

export const SyncUrlWithCtxReactRouter: React.SFC = () => (
  <UrlConsumer>
    {ctx => <SyncUrlWithReactRouter setUrl={ctx.setUrl} url={ctx.url} />}
  </UrlConsumer>
);
