import { pipeX } from "@apoly-42/apoly-utils";
import getCourierTimeSpansForNextDays from "../pharmacyTimes/getCourierTimeSpansForNextDays";
import getOpeningTimesForNextDays from "../pharmacyTimes/getOpeningTimesForNextDays";

export const insertNextCourierTimeSpans = moment => pharmacyWithHolidays => {
  const { pharmacy, nextHolidays } = pharmacyWithHolidays;
  return {
    ...pharmacyWithHolidays,
    nextCourierTimeSpans: getCourierTimeSpansForNextDays(
      3,
      moment,
      pharmacy.courierTimeSpans,
      nextHolidays,
    ),
  };
};

export const insertNextOpeningTimes = moment => pharmacyWithHolidays => {
  const { pharmacy, nextHolidays } = pharmacyWithHolidays;
  return {
    ...pharmacyWithHolidays,
    nextOpeningTimes: getOpeningTimesForNextDays(
      7,
      moment,
      pharmacy.openingTimes,
      nextHolidays,
    ),
  };
};

const isOpenAt = (moment, openingTime) =>
  openingTime.openingFromMoment < moment &&
  moment < openingTime.openingToMoment;

export const insertIsOpen = moment => enhancedPharmacy => ({
  ...enhancedPharmacy,
  isOpen:
    enhancedPharmacy.nextOpeningTimes.length > 0 &&
    isOpenAt(moment, enhancedPharmacy.nextOpeningTimes[0]),
});

export function getEnhancedPharmacy(
  pharmacy,
  nextPharmacyHolidays,
  germanServerTimeMoment,
) {
  return pipeX(
    () => ({
      pharmacy,
      nextHolidays: nextPharmacyHolidays,
    }),
    insertNextCourierTimeSpans(germanServerTimeMoment),
    insertNextOpeningTimes(germanServerTimeMoment),
    insertIsOpen(germanServerTimeMoment),
  );
}
