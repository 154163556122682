import { Grid } from "@material-ui/core";
import React from "react";
import { FluidTypography } from "../../../../../app-util-components/FluidTypography";
import { LinguiMessage } from "../../../../../app-util-components/LinguiMessage";
import { appMessages } from "../../../../../app-utilities/appMessages";
import {
  flex,
  verticalCenteredInFlex,
} from "../../../../../app-utilities/cssClasses";
import { Languages } from "../../../../../languages/LanguageContext";
import { FlagButton } from "./FlagButton";

export const ChangeLocaleDrawer: React.SFC = () => (
  <Grid container={true} spacing={2}>
    <Grid css={flex} item={true} xs={12}>
      <FluidTypography css={verticalCenteredInFlex}>
        <LinguiMessage message={appMessages.selectLanguage} />:
      </FluidTypography>
    </Grid>
    <Grid item={true} xs={6}>
      <FlagButton language={Languages.en} title={"English"} flagCode={"gb"} />
    </Grid>
    <Grid item={true} xs={6}>
      <FlagButton language={Languages.de} title={"Deutsch"} flagCode={"de"} />
    </Grid>
    <Grid item={true} xs={6}>
      <FlagButton language={Languages.ar} title={"العربية"} flagCode={"sy"} />
    </Grid>
    <Grid item={true} xs={6}>
      <FlagButton language={Languages.tr} title={"Türkçe"} flagCode={"tr"} />
    </Grid>
    <Grid item={true} xs={6}>
      <FlagButton language={Languages.ru} title={"Русский"} flagCode={"ru"} />
    </Grid>
  </Grid>
);
