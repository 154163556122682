import PropTypes from "prop-types";
import React from "react";
import striptags from "striptags";
import { maxWidthHeight } from "../../../../../app-utilities/cssClasses";
import productImage400px from "./apoly-product-image-400px.png";

// cdn8.apopixx.de/500/web_schraeg_png/03321472.png
export const getSmallImageUrl = imgUrl => imgUrl || productImage400px;

const ProductImageSmall = ({ imgUrl, name, ...props }) => (
  <img
    {...props}
    css={maxWidthHeight}
    src={imgUrl || productImage400px}
    alt={striptags(name)}
  />
);

ProductImageSmall.propTypes = {
  imgUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
};

ProductImageSmall.defaultProps = {
  imgUrl: null,
};

export default ProductImageSmall;
