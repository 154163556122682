import { MessageDescriptor } from "@lingui/core/cjs/i18n";
import { Trans } from "@lingui/react";
import * as React from "react";
import { ComponentType, ReactNode } from "react";

interface LMProps {
  component?: string | ComponentType<any>;
  values?: { [key: string]: ReactNode };
  message: MessageDescriptor;
}
export function LinguiMessage(props: LMProps) {
  return (
    <Trans
      id={props.message}
      values={props.values}
      render={
        props.component ||
        // props.tagName um legacy-kompatibilität herzustellen
        // @ts-ignore
        props.tagName ||
        "span"
      }
    />
  );
}
