import { LinguiMessage, NotFoundTemplate } from "@apoly-42/apoly-components";
import {
  GridContainer,
  GridRow,
  Headline,
  rowTypes,
} from "@apoly-42/material-ui";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import apoly404 from "../constants/assets/apoly404_black.png";
import {
  flex,
  flexAlignFlexStart,
  fullMaxWidthWithMarginAuto,
  marginAuto,
} from "../constants/styles/commonClasses";
import { HOST_NAME } from "../environment";
import { selectBasePath } from "../redux/shopPharmacy/reducer";
import { contactPath, contactPathSetPharmacy } from "../routes/paths";
import Footer from "./layout/footer/Footer";
import NavBar from "./layout/navBar/navbar/NavBar";
import ReturnToHomeButton from "./ReturnToHomeButton";

const messages = {
  contactUs: {
    id: "contactUs",
    message: "contact us",
  },
  pageNotFoundHeader: {
    id: "pageNotFoundHeader",
    message: "Ouch! Something went totally wrong here!",
  },
  pageNotFoundText: {
    id: "pageNotFoundText",
    message: "You tried visiting {path}, but we don't know this path. Sorry!",
  },
};

const InnerPageNotFoundError = ({ basePath, location }) => (
  <React.Fragment>
    <NavBar />
    <GridContainer withNoScrollPaddingWorkaround justify="center">
      <GridRow
        item
        type={rowTypes.section}
        xs={12}
        lg={10}
        style={flex}
        noPaddingBottom
      >
        <div style={marginAuto}>
          <NotFoundTemplate
            contactUsLink={
              <Link
                to={basePath ? contactPathSetPharmacy(basePath) : contactPath()}
              >
                <LinguiMessage message={messages.contactUs} />
              </Link>
            }
            align="center"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} style={flexAlignFlexStart}>
                <img
                  style={{ ...fullMaxWidthWithMarginAuto, maxHeight: 200 }}
                  src={apoly404}
                  alt="404"
                />
              </Grid>
              <Grid item xs={12}>
                <Headline align="center" headlineType="h1">
                  <LinguiMessage message={messages.pageNotFoundHeader} />
                </Headline>
                <Typography align="center">
                  <LinguiMessage
                    message={messages.pageNotFoundText}
                    values={{ path: `${HOST_NAME}${location.pathname}` }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </NotFoundTemplate>
        </div>
      </GridRow>
      <GridRow item type={rowTypes.paragraph} xs={12} lg={10}>
        <ReturnToHomeButton />
      </GridRow>
    </GridContainer>
    <Footer />
  </React.Fragment>
);

const mapStateToProps = state => ({
  basePath: selectBasePath(state),
});

const enhance = compose(withRouter, connect(mapStateToProps));

const PageNotFoundError = enhance(InnerPageNotFoundError);

InnerPageNotFoundError.propTypes = {
  basePath: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

export default PageNotFoundError;
