import {
  faFacebookF,
  faGooglePlusG,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Fab, Grid } from "@material-ui/core";
import { css } from "@emotion/core";
import React from "react";
import { flexAlignCenter } from "../../../app-utilities/cssClasses";
import { FooterHeadline } from "./FooterHeadline";

const container = css`
  display: flex;
  margin: auto;
`;

const button = css`
    margin: 0 8px;
    @media(max-width: 600px) {
      width: 48px;
      height: 48px;
    `;

export interface SocialMediaProps {
  title?: React.ReactNode;
  colored?: boolean;
}

export const SocialMedia: React.SFC<SocialMediaProps> = ({
  title = null,
  colored = false,
}) => {
  const iconColor = colored ? "primary" : "default";
  return (
    <Grid container={true} spacing={2}>
      {title && (
        <Grid item={true} xs={12}>
          <FooterHeadline align="center">{title}</FooterHeadline>
        </Grid>
      )}
      <Grid item={true} xs={12} css={flexAlignCenter}>
        <div css={container}>
          <Fab
            color={iconColor}
            aria-label="facebook"
            target="_blank"
            rel="noopener"
            href={"https://www.facebook.com/apoly.de/"}
            css={button}
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </Fab>
          <Fab
            color={iconColor}
            aria-label="twitter"
            target="_blank"
            rel="noopener"
            href={"https://twitter.com/ApolyDE"}
            css={button}
          >
            <FontAwesomeIcon icon={faTwitter} />
          </Fab>
          <Fab
            color={iconColor}
            aria-label="google plus"
            target="_blank"
            rel="noopener"
            href={"https://plus.google.com/+ApolyDe"}
            css={button}
          >
            <FontAwesomeIcon icon={faGooglePlusG} />
          </Fab>
          <Fab
            color={iconColor}
            aria-label="instagram"
            target="_blank"
            rel="noopener"
            href={"https://www.instagram.com/Apoly.de/"}
            css={button}
          >
            <FontAwesomeIcon icon={faInstagram} />
          </Fab>
        </div>
      </Grid>
    </Grid>
  );
};
