import { LinguiEuro, LinguiMessage } from "@apoly-42/apoly-components";
import { CustomTooltip } from "@apoly-42/material-ui";
import { faShoppingCart } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLingui } from "@lingui/react";
import { Badge, Button, Hidden, withStyles } from "@material-ui/core";
import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { appMessages } from "../../../app-utilities/appMessages";
import {
  buttonXs,
  verticalCenteredInFlex,
} from "../constants/styles/commonClasses";
import { selectBasePath } from "../redux/shopPharmacy/reducer";
import {
  getShoppingCartLength,
  getShoppingCartTotalPrice,
} from "../redux/shoppingCart/selectors";
import { shoppingCartPath } from "../routes/paths";

const styles = {
  verticalCenteredInFlexResponsive: {
    ...verticalCenteredInFlex,
    "@media(max-width: 350px)": {
      margin: "auto 4px",
    },
  },
  buttonXs,
  badge: {
    top: -10,
    right: -4,
  },
};

const ShoppingCartButton = ({
  classes,
  basePath,
  shoppingCartPrice,
  shoppingCartLength,
}) => {
  const { i18n } = useLingui();

  return (
    <Badge
      badgeContent={shoppingCartLength || 0}
      color={
        shoppingCartLength && shoppingCartLength > 0 ? "secondary" : "default"
      }
    >
      <CustomTooltip
        id="shoppingCart"
        title={
          <span>
            {i18n._(appMessages.totalAmount)}
            :&nbsp;
            <LinguiEuro value={Number(shoppingCartPrice || 0)} />
          </span>
        }
        placement="bottom"
      >
        <Button
          className={cn(
            classes.verticalCenteredInFlexResponsive,
            classes.buttonXs,
          )}
          variant={
            shoppingCartLength && shoppingCartLength > 0 ? "contained" : "text"
          }
          color={
            shoppingCartLength && shoppingCartLength > 0
              ? "secondary"
              : "inherit"
          }
          component={Link}
          aria-label={i18n._(appMessages.shoppingCart)}
          to={shoppingCartPath(basePath)}
        >
          <Hidden smDown>
            <LinguiMessage message={appMessages.shoppingCart} />
          </Hidden>
          <FontAwesomeIcon
            style={{ fontSize: 16, margin: "auto 8px" }}
            icon={faShoppingCart}
          />
        </Button>
      </CustomTooltip>
    </Badge>
  );
};

ShoppingCartButton.propTypes = {
  classes: PropTypes.object.isRequired,

  basePath: PropTypes.string.isRequired,
  shoppingCartLength: PropTypes.number,
  shoppingCartPrice: PropTypes.string,
};
ShoppingCartButton.defaultProps = {
  shoppingCartLength: 0,
  shoppingCartPrice: "0.00",
};

const mapStateToProps = state => ({
  shoppingCartLength: getShoppingCartLength(state),
  shoppingCartPrice: getShoppingCartTotalPrice(state),
  basePath: selectBasePath(state),
});

const enhance = compose(connect(mapStateToProps), withStyles(styles));

export default enhance(ShoppingCartButton);
